export class FeatureDailyUsage {
  year: number;
  month: number;
  unit: string;
  productCode: string;
  dailyUsage: { [day: number]: number };
  subId: string;
  name: string;
  constructor() {
    this.dailyUsage = {};
  }
}
