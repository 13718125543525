import { UserDataSelector } from './../states/user-data.state.selector';
import { SharedDriveFolder } from './../model/shared-drive-folder';
import { CloudObjStatus } from './../model/cloud-obj.state';
import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CloudFolder } from '../model/cloud-folder';
import { AccessRight } from '../model/cloud-access-right';
import { CloudFile } from '../model/cloud-file';
import { CloudStorageType, CloudObjType, CloudObjState } from '../model/cloud-obj.state';
import { SharedFile } from '../model/shared-file';
import { SharedFolder } from '../model/shared-folder';
import { HttpErrorResponse, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import JSZip from 'jszip';
import { SharedDriveFile } from '../model/shared-drive-file';
import { FileServerService } from '../api/file-server.service';
import { Resource } from '../model/resource';
import { OriginFile } from '../model/origin-file';
import { OriginFolder } from '../model/origin-folder';
import { FolderHierarchy } from '../model/explorer.model';
@Injectable()
export class CloudFileService {

    constructor(
        private api: ApiService,
        private fsApi: FileServerService,
        private userDataSelector: UserDataSelector,
    ) { }

    get base64CurrentUserId(): string {
        return this.encode(this.userDataSelector.userId);
    }

    /**Uses base64 encoding. */
    private encode(val: string): string {
        return btoa(val);
    }
    private encodeFilename(val: string): string {
       return encodeURIComponent(val);
    }
    NAME_MAX_LENGTH: number = 100;

    private _uploads: CloudObjState[] = [];

    updaterStatus = new Subject<boolean>();

    clearUploadNotifications() {
        this._uploads = [];
    }

    addObjState(cloudObjState: CloudObjState) {
        console.log(cloudObjState);
        this._uploads.push(cloudObjState);
        this.updaterStatus.next(true);
    }

    deleteObjState(cloudObjState: CloudObjState): void {
        console.log(cloudObjState);
        let index = this._uploads.findIndex(u => u.id == cloudObjState.id);
        if (index !== -1) {
            this._uploads.splice(index, 1);
            this.updaterStatus.next(true);
        }
    }

    completeObjState(cloudObjState: CloudObjState, err: any = null) {
        const upload = this._uploads.find(u => u.id == cloudObjState.id);
        if (upload) {
            upload.status = CloudObjStatus.Completed;
            if (err) {
                upload.status = CloudObjStatus.Error;
                upload.error = typeof err == "string" ? err : err?.error?.error;
            }
            this.updaterStatus.next(true);
        }
    }

    prepareDownloadObjState(cloudObjState: CloudObjState, err: any = null) {
        const upload = this._uploads.find(u => u.id == cloudObjState.id);
        if (upload) {
            upload.status = CloudObjStatus.Preparing;
            if (err) {
                upload.status = CloudObjStatus.Error;
                upload.error = err.error.error;
            }
            // this.updaterStatus.next(true);
        }
    }

    downloadingObjState(cloudObjState: CloudObjState, progress: number, err: any = null) {
        const upload = this._uploads.find(u => u.id == cloudObjState.id);
        if (upload) {
            upload.status = CloudObjStatus.InProgress;
            upload.progress = progress;
            if (err) {
                upload.status = CloudObjStatus.Error;
                upload.error = err.error.error;
            }
            // this.updaterStatus.next(true);
        }
    }

    //#region Validators
    isNameLengthExceed(name: string): boolean {
        return name ? name.length > this.NAME_MAX_LENGTH : false;
    }

    isNameDuplicated(resources: Resource[], name: string): boolean {
        let item = resources.find(
        (f) => f.nameWithoutExt?.trim() == Resource.getNameWithoutExt(name)?.trim()
        );
        return !!item;
    }
   //#endregion

    //#region Share Link
    // TODO Missing API on file server
    getPersonalFolderOrFileShareLink(fullPath: string = null, isFolder: boolean = false) {
        var endpoint = `p/f/${this.encode(fullPath)}/link`;
        return this.fsApi.getAsync<any>(endpoint)
            .then((result: string) => result)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    getOrgFolderOrFileShareLink(orgId: string, fullPath: string = null, isFolder: boolean = false) {
        if (!orgId || !fullPath) return null;
        const endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/f/${this.encode(fullPath)}/link`;
        return this.fsApi.get<string>(endpoint)
            .pipe(
                map((result: string) => {
                    return result;
                }),
                catchError(err => {
                    this.api.handleError(err);
                    throw err;
                })
            ).toPromise();
    }

    acceptShareInvite(orgId: string, originPath: string, isFolder: boolean, ownerId: string) {
        var body = {
            fullPath: originPath,
            isFolder: isFolder,
            ownerId: ownerId
        };
        var endpoint = `storage/sharelink/${orgId.toLowerCase()}/accept`;
        return this.api.post<any>(endpoint, body)
            .pipe(
                map((result: any) => {
                    return result;
                }),
                catchError(err => {
                    this.api.handleError(err);
                    throw err;
                })
            ).toPromise();

    }
    //#endregion Share Link

    //#region User Folder
    checkUserFolder(
        orgId: string,
        path: string = null,
    ): Promise<boolean> {
        if (!orgId) return Promise.resolve(null);
        const base64OrgId = this.encode(orgId);
        const endpoint = (!!path) ? `o/${base64OrgId}/u/${this.base64CurrentUserId}/f/${this.encode(path)}/check` : `o/${base64OrgId}/u/${this.base64CurrentUserId}/f/check`;

        return this.fsApi
            .getAsync(endpoint)
            .then(
                (res) => true,
                (res) => false)
            .catch((err) => {
                throw err;
            });
    }

    /**Returns metadata for a folder in the user profile. If path isn't provided, it reads the root folder.*/
    readUserFolder(orgId: string, path: string = null, depth?: FolderHierarchy): Promise<CloudFolder> {
        return this._readUserResource(orgId, path, depth)
            .then((dto) => {
                if (!dto) return null;
                let folder = CloudFolder.parse(dto);
                if (folder.isAppDataFolder) {
                    return null;
                } else {
                    folder.folders = folder.folders.filter(
                        (f) => f.isFolder && !f.isAppDataFolder
                    );
                }
                return folder;
            })
            .catch((err) => {
                this.api.handleError(err);
                throw err;
            });
    }

    /**Returns the metadata for a resource in the user profile drive. 
     * If path isn't provided, it reads the root folder.
     * 
     * Use readUserFolder() for a CloudFolder return type.*/
    _readUserResource(orgId: string, path: string = null, depth?: FolderHierarchy): Promise<unknown> {
        if (!orgId) return null;
        const base64OrgId = this.encode(orgId);
        var endpoint = (!!path) ? `o/${base64OrgId}/u/${this.base64CurrentUserId}/f/${this.encode(path)}/meta` : `o/${base64OrgId}/u/${this.base64CurrentUserId}/f/meta`;
        if (!!depth) endpoint += `?d=${depth}`;

        return this.fsApi.getAsync(endpoint);
    }

    /**
     * Reads first level of Shared with Me Drive.
     * @param orgId 
     * @returns Shared dummy of files and folders at root level.
     */
    async listUserSharedFolder(orgId: string): Promise<SharedFolder> {
        if (!orgId) return null;
        const endpoint = `o/${this.encode(orgId)}/u/${
          this.base64CurrentUserId
        }/s/meta`;
        var list = await this.fsApi.getAsync<any[]>(endpoint, orgId);
        let sharedFolder = new SharedFolder();
        sharedFolder.isRoot = true;
        sharedFolder.name = "Shared With Me";
        if (!list || list.length == 0) return sharedFolder;

        sharedFolder.files = list
          .filter((r) => Resource.isFile(r))
          .map((r) => SharedFile.parse(r));
        sharedFolder.folders = list
          .filter((r) => !Resource.isFile(r))
          .map((r) => SharedFolder.parse(r));

        return sharedFolder;
    }

    /**
     * Reads a SharedFolder in the user's Shared with Me Drive.
     * @param orgId 
     * @param sharedDummyPath path of the folder being read.
     * @returns OriginFolder of the SharedFolder being read.
     */
    readUserSharedFolder(orgId: string, sharedDummyPath: string, depth?: FolderHierarchy): Promise<OriginFolder> {
        if (!orgId || !sharedDummyPath) return null;
        var endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/s/${this.encode(sharedDummyPath)}/meta`;
        if (!!depth) endpoint += `?d=${depth}`;

        return this.fsApi.getAsync<any>(endpoint)
            .then(dto => {
                if (!dto || !Resource.isFolder(dto)) return Promise.reject("Failed to read shared folder.");
                return OriginFolder.parse(dto);
            })
            .catch(err => {
                this.api.handleError(err);
                throw err;
            });
    }

    /**
     * Reads an OriginFolder in the user's Shared with Me Drive. 
     * OriginFolder means the user does not have a shared dummy for this folder.
     * @param orgId 
     * @param parentSharedDummyPath shared path of shared folder containing the folder being read.
     * @param folderPath origin path of folder being read. 
     * @returns OriginFolder of folder being read.
     */
    readUserSharedOriginFolder(orgId: string, parentSharedDummyPath: string, folderPath: string, depth?: FolderHierarchy): Promise<OriginFolder> {
        if (!orgId || !parentSharedDummyPath || !folderPath) return null;
        var endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/s/${this.encode(parentSharedDummyPath)}/f/${this.encode(folderPath)}/meta`;
        if (!!depth) endpoint += `?d=${depth}`;

        return this.fsApi.getAsync<any>(endpoint)
            .then(dto => {
                if (!dto || !Resource.isFolder(dto)) return Promise.reject("Failed to read shared folder.");;
                return OriginFolder.parse(dto);
            })
            .catch(err => {
                this.api.handleError(err);
                throw err;
            });
    }

    checkUserSharedFolder(orgId: string) {
        if (!orgId) return Promise.resolve(null);

        const endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/s/check`;
        return this.fsApi.getAsync<any>(endpoint)
            .then(
                (res) => true,
                (err) => false)
            .catch((err) => {
                throw err;
            });
    }

    /**If parentPath is null, creates folder at root.*/
    createUserFolder(orgId: string, folderName: string, parentPath: string = null) {
        if (!orgId || !folderName) return null;

        const base64OrgId = this.encode(orgId);
        const endpoint = !!parent ? `o/${base64OrgId}/u/${this.base64CurrentUserId}/f/${this.encode(parentPath)}/create` : `o/${base64OrgId}/u/${this.base64CurrentUserId}/f/create`;

        return this.fsApi.postAsync<any>(endpoint, JSON.stringify(folderName))
            .then((dto) => {
                if (!dto || !Resource.isFolder(dto)) return null;
                return CloudFolder.parse(dto);
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                    throw err;
            });
    }

    /**Returns true if rename was successful.*/
    renameUserItem(orgId: string, name: string, path: string) {
        if (!orgId || !name || !path) return null;
        const base64OrgId = this.encode(orgId);
        const encodedResourcePath = this.encode(path);
        const endpoint = `o/${base64OrgId}/u/${this.base64CurrentUserId}/f/${encodedResourcePath}/rename`;

        return this.fsApi.put<any>(endpoint, JSON.stringify(name))
            .pipe(
                map((dto) => {
                    return !!dto;
                }),
                catchError(err => {
                    this.api.handleError(err);
                    throw err;
                })
            );
    }

    /**Returns true if batch move was successful.*/
    moveUserItems(orgId: string, path: string, destinationPath: string, destinationName: string, filePaths: string[] = [], folderPaths: string[] = []) {
        if (!orgId || !path || !destinationPath) return null;
        if (folderPaths.length == 0 && filePaths.length == 0) return of<boolean>(true);

        const base64OrgId = this.encode(orgId);
        var options = {
            destinationPath: destinationPath,
            currentPath: path,
            folderPaths: folderPaths,
            filePaths: filePaths,
        }

        const endpoint = `o/${base64OrgId}/u/${this.base64CurrentUserId}/f/move`;

        var cloudObjState = new CloudObjState(orgId, null, path, null, CloudStorageType.User, CloudObjType.Move, destinationName, null, CloudObjStatus.InProgress);
        this.addObjState(cloudObjState);

        return this.fsApi.post<null>(endpoint, options)
            .pipe(
                map((res: HttpResponse<null>) => {
                    this.completeObjState(cloudObjState);
                    return res.ok;
                }),
                catchError((err: HttpErrorResponse) => {
                    this.completeObjState(cloudObjState, err.error);
                    this.api.handleError(err);
                    throw err;
                })
            );
    }

    /**Returns true if batch copy was successful.*/
    copyUserItems(orgId: string, path: string, destinationPath: string, destinationName: string, filePaths: string[] = [], folderPaths: string[] = []) {
        if (!orgId || !path || !destinationPath) return null;
        if (folderPaths.length == 0 && filePaths.length == 0) return of<boolean>(true);
        var options = {
            currentPath: path,
            destinationPath: destinationPath,
            folderPaths: folderPaths,
            filePaths: filePaths,
        }
        const base64OrgId = this.encode(orgId);
        const endpoint = `o/${base64OrgId}/u/${this.base64CurrentUserId}/f/copy`;

        var cloudObjState = new CloudObjState(orgId, null, path, null, CloudStorageType.User, CloudObjType.Copy, destinationName, null, CloudObjStatus.InProgress);
        this.addObjState(cloudObjState);

        return this.fsApi.post<any>(endpoint, options)
            .pipe(
                map((res) => {
                    this.completeObjState(cloudObjState);
                    return true;
                }),
                catchError((err) => {
                    this.completeObjState(cloudObjState, err.error);
                    this.api.handleError(err);
                    throw err;
                })
            );
    }

    /**Deletes a file or folder in user profile drive.*/
    deleteUserResource(orgId: string, path: string) {
        const base64OrgId = this.encode(orgId);
        const encodedResourcePath = this.encode(path);
        const endpoint = `o/${base64OrgId}/u/${this.base64CurrentUserId}/f/${encodedResourcePath}`;

        return this.fsApi.deleteAsync(endpoint)
            .then((res) => true)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    deleteMultipleUserResources(orgId: string, folderPaths: string[] = [], filePaths: string[] = []) {
        if (!orgId) return null;
        if (folderPaths.length == 0 && filePaths.length == 0) return of<boolean>(true);
        var options = {
            folderPaths: folderPaths,
            filePaths: filePaths,
        }
        const base64OrgId = this.encode(orgId);
        const endpoint = `o/${base64OrgId}/u/${this.base64CurrentUserId}/f`;

        return this.fsApi.delete(endpoint, options)
            .pipe(
                map((res) => true),
                catchError((err) => {
                    this.api.handleError(err);
                    throw err;
                })
            );
    }

    deleteUserSharedDummy(orgId: string, path: string) {
        const endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/s/f/${this.encode(path)}`;

        return this.fsApi.deleteAsync(endpoint)
            .then((res) => true)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    pendingUserUploads(orgId: string): CloudObjState[] {
        return this._uploads.filter(u =>
            u.orgId == orgId &&
            u.storageType == CloudStorageType.User);
    }

    /**Supports uploading file, folder, or Google Drive resource to user profile.
     * 
     * Returns observable that can be parsed into a FileDTO if a file or Google Drive item was uploaded, null if a folder was uploaded.*/
    streamUserResource(orgId: string, folderPath: string, fileBlobs: Blob[] = [],
        additionalHeaders: {
            key: string;
            val: string;
        }[] = []): Promise<any> {
        if (!orgId || !folderPath) return null;
        const formData = new FormData();
        var addHeaders = [{ key: 'DestinationPath', val: folderPath }, ...additionalHeaders];

        for (let i = 0; i < fileBlobs.length; i++) {
            formData.append("Files", fileBlobs[i]);
        }
        const base64OrgId = this.encode(orgId);
        const endpoint = `o/${base64OrgId}/u/${this.base64CurrentUserId}/f/upload`;

        return this.fsApi.postAsync<any>(endpoint, formData, null, null, null, true, addHeaders);
    }

    streamUserFile(orgId: string, name: string, folderPath: string = null, files: File[]): Promise<CloudFile> {
        if (!orgId || !folderPath || !files) return null;

        var cloudObjState = new CloudObjState(orgId, null, folderPath, files, CloudStorageType.User, CloudObjType.Upload, name);
        this.addObjState(cloudObjState);

        var addHeaders = [{ key: 'Type', val: 'file' }];

        return this.streamUserResource(orgId, folderPath, files, addHeaders)
            .then((dto) => {
                if (!dto || !Resource.isFile(dto)) return null;
                    this.completeObjState(cloudObjState);
                    return CloudFile.parse(dto);
            })
            .catch((err) => {
                this.completeObjState(cloudObjState, err);
                    this.api.handleError(err);
                    throw err;
            });
    }

    async streamUserFolder(orgId: string, parentName: string, files: File[], size: number, folderName: string, folderPath: string): Promise<boolean> {
        if (!orgId || !folderPath) return null;

        var cloudObjState = new CloudObjState(orgId, null, folderPath, files, CloudStorageType.User, CloudObjType.Zip, parentName, null, CloudObjStatus.InProgress);
        this.addObjState(cloudObjState);

        try {
            let blob = await this.zipFolder(files);
            var addHeaders = [{ key: 'Type', val: 'folder' }, { key: 'Size', val: size.toString() }, { key: 'DisplayName', val: this.encodeFilename(folderName)}];

            return this.streamUserResource(orgId, folderPath, [blob], addHeaders)
                .then(() => {
                    this.completeObjState(cloudObjState);
                    return true;
                }).catch((err) => {
                    this.fsApi.handleError(err);
                    this.completeObjState(cloudObjState, err);
                    return false;
                });
        } catch (err) {
            this.completeObjState(cloudObjState, err);
        }
    }

    /**Supports uploading file, folder, or Google Drive resource to shared with me.
     * 
     * Returns observable that can be parsed into a FileDTO if a file or Google Drive item was uploaded, null if a folder was uploaded.*/
    streamUserShareResource(orgId: string, folderPath: string, sharedPath: string, fileBlobs: Blob[] = [],
        additionalHeaders: {
            key: string;
            val: string;
        }[] = []): Promise<any> {
        if (!orgId || !folderPath) return null;
        const formData = new FormData();
        var addHeaders = [{ key: 'DestinationPath', val: folderPath }, ...additionalHeaders];

        for (let i = 0; i < fileBlobs.length; i++) {
            formData.append("Files", fileBlobs[i]);
        }

        const endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/s/${this.encode(sharedPath)}/f/upload`;

        return this.fsApi.postAsync<any>(endpoint, formData, null, null, null, true, addHeaders);
    }

    streamUserShareFile(orgId: string, name: string, folderPath: string, sharedPath: string, files: File[]): Promise<OriginFile> {
        var cloudObjState = new CloudObjState(orgId, null, folderPath, files, CloudStorageType.User, CloudObjType.Upload, name);
        this.addObjState(cloudObjState);

        var addHeaders = [{ key: 'Type', val: 'file' }];

        return this.streamUserShareResource(orgId, folderPath, sharedPath, files, addHeaders)
            .then((results) => {
                if (!results) return null;
                    this.completeObjState(cloudObjState);
                    return OriginFile.parse(results);

            })
            .catch((err) => {
                this.completeObjState(cloudObjState, err);
                this.fsApi.handleError(err);
                throw err;
            });

    }

    async streamUserShareFolder(orgId: string, parentName: string, folderPath: string, sharedPath: string, files: File[], size: number, folderName: string): Promise<boolean> {
        var cloudObjState = new CloudObjState(orgId, null, folderPath, files, CloudStorageType.User, CloudObjType.Zip, parentName, null, CloudObjStatus.InProgress);
        this.addObjState(cloudObjState);

        try {
            let blob = await this.zipFolder(files);

            var addHeaders = [{ key: 'Type', val: 'folder' }, { key: 'Size', val: size.toString() }, { key: 'DisplayName', val: this.encodeFilename(folderName)}];

            return this.streamUserShareResource(orgId, folderPath, sharedPath, [blob], addHeaders)
            .then(() => {
                this.completeObjState(cloudObjState);
                return true;
            }).catch((err) => {
                this.fsApi.handleError(err);
                this.completeObjState(cloudObjState, err);
                return false;
            });
        } catch (err) {
            this.completeObjState(cloudObjState, err);
        }
    }

    downloadUserResourceAsync(orgId: string, name: string, fullPath: string, size: number, showProgress: boolean = true): Promise<{ blob: Blob, fileName: string }> {
        const endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/f/${this.encode(fullPath)}/download`;

        if (showProgress) {
            var cloudObjState = new CloudObjState(
                orgId,
                null,
                fullPath,
                [],
                CloudStorageType.User,
                CloudObjType.Download,
                name,
                size,
                CloudObjStatus.Preparing
            );
            this.addObjState(cloudObjState);

            return this.downloadWithProgressFromFileServer(
                cloudObjState,
                endpoint,
                null);

        } else {
            return this.fsApi
                .getAsync<any>(endpoint, null, null, { responseType: "blob" })
                .then((results: Blob) => {
                    if (!results || results.size == 0) return null;
                    return { blob: results, fileName: name };
                })
                .catch((err) => {
                    this.fsApi.handleError(err);
                    throw err;
                });
        }
    }

    downloadMultiUserResourcesAsync(orgId: string, filePaths: string[], folderPaths: string[], size: number, name: string = null): Promise<{ blob: Blob, fileName: string }> {
        var options = {
            FolderPaths: folderPaths,
            FilePaths: filePaths
        };
        const endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/f/download`;

        var cloudObjState = new CloudObjState(
            orgId,
            null,
            null,
            [],
            CloudStorageType.User,
            CloudObjType.Download,
            name,
            size,
            CloudObjStatus.Preparing
        );
        this.addObjState(cloudObjState);

        return this.downloadMultipleWithProgressFromFileServer(cloudObjState, endpoint, options, null);
    }

    /**Gets the size of a folder, includes content in its subfolders. */
    getUserFolderSize(orgId: string, folderPath: string): Promise<number> {
        const endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/f/${this.encode(folderPath)}/size`;

        return this.fsApi.getAsync<number>(endpoint)
        .catch((err) => {
            this.api.handleError(err);
            throw err;
        });
    }

    /**Gets the total sum of the given folders sizes. */
    getTotalUserFoldersSize(orgId: string, folderPaths: string[]): Promise<number> {
        var body = {
            folderPaths: folderPaths
        }

        const endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/f/size`;

        return this.fsApi.postAsync<number>(endpoint, body)
        .catch((err) => {
            this.api.handleError(err);
            throw err;
        });
    }

    private downloadMultipleWithProgress(
        cloudObjState: CloudObjState,
        endpoint: string,
        body: any,
        removeContentType?: boolean,
        elResourceIdentity?: string,
    ): Observable<{ blob: Blob, fileName: string }> {
        return this.api
            .post<any>(endpoint, body, removeContentType, elResourceIdentity, {
                observe: "events",
                responseType: "blob",
                reportProgress: true,
                withCredentials: true
            })
            .pipe(
                map((event: HttpEvent<any>) => this.handleDownloadInProgress(event, cloudObjState)
                ),
                catchError(async (err) => {
                    let errorMsg = err;
                    if (err) {
                        let errorBlob = err.error;
                        if (errorBlob) {
                            errorMsg = await errorBlob.text();//read blob as text
                        }
                    }
                    this.completeObjState(cloudObjState, errorMsg);
                    this.api.handleError(err);
                    throw err;
                })
            );
    }

    private downloadWithProgressFromFileServer(
        cloudObjState: CloudObjState,
        endpoint: string,
        elResourceIdentity?: string,
    ): Promise<{ blob: Blob, fileName: string }> {
        let options = {
            observe: "events",
            responseType: "blob",
            reportProgress: true,
            withCredentials: true
        };

        return this.fsApi
            .getAsync<any>(endpoint, null, elResourceIdentity, options)
            .then((event: HttpEvent<any>) => this.handleDownloadInProgress(event, cloudObjState))
            .catch(async (err) => {
                let errorMsg = err;
                    if (err) {
                        let errorBlob = err.error;
                        if (errorBlob) {
                            errorMsg = await errorBlob.text();//read blob as text
                        }
                    }
                    this.completeObjState(cloudObjState, errorMsg);
                    this.fsApi.handleError(err);
                    throw err;
            });
    }

    private downloadMultipleWithProgressFromFileServer(
        cloudObjState: CloudObjState,
        endpoint: string,
        body: any,
        elResourceIdentity?: string,
    ): Promise<{ blob: Blob, fileName: string }> {
        return this.fsApi
            .postAsync<any>(endpoint, body, elResourceIdentity, {
                observe: "events",
                responseType: "blob",
                reportProgress: true,
                withCredentials: true
            })
            .then((event: HttpEvent<any>) => this.handleDownloadInProgress(event, cloudObjState))
            .catch(async (err) => {
                let errorMsg = err;
                    if (err) {
                        let errorBlob = err.error;
                        if (errorBlob) {
                            errorMsg = await errorBlob.text();//read blob as text
                        }
                    }
                    this.completeObjState(cloudObjState, errorMsg);
                    this.fsApi.handleError(err);
                    throw err;
            });
    }

    private handleDownloadInProgress(event: HttpEvent<any>, cloudObjState: CloudObjState) {
        if (event.type === HttpEventType.DownloadProgress) {
            let total = event.total
                ? event.total
                : cloudObjState.estimatedSize;
            const percentage = (100 / total) * event.loaded;

            this.downloadingObjState(cloudObjState, percentage);
        }
        // finished
        if (event.type === HttpEventType.Response) {
            let fileName = cloudObjState.displayName;
            let content = event.headers.get("Content-Disposition");
            if (content) {
                let r = content.split("attachment;filename=");
                fileName = r.length >= 1 ? r[1] : null;
                fileName = decodeURIComponent(fileName);
            }
            if (cloudObjState.displayName != fileName) {//sync file name, especially for zipped file download
                cloudObjState.displayName = fileName;
            }
            this.completeObjState(cloudObjState);
            var blob = event.body;
            return { blob, fileName };
        }
    }

    updateUserResourceShareClaims(orgId: string, path: string, accessRights: AccessRight[], notifyUser: boolean, isFile: boolean = false) {
        var claims = accessRights.map(o => o.toUpdateClaimDTO());
        var options = {
            NotifyUser: notifyUser,
            Claims: claims
        };
        const endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/f/${this.encode(path)}/claim`;

        return this.fsApi.putAsync<any>(endpoint, options)
            .then((dto) => {
                return isFile ? CloudFile.parse(dto) : CloudFolder.parse(dto);
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    changeUserNotificationSettings(orgId: string, path: string, isEnabled: boolean, isFile: boolean = false) {
        if (isEnabled) return this.enableUserResourceNotificationSettings(orgId, path, isFile);
        else return this.disableUserResourceNotificationSettings(orgId, path, isFile);
    }

    enableUserResourceNotificationSettings(orgId: string, path: string, isFile: boolean = false): Promise<CloudFile | CloudFolder> {
        const endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/f/${this.encode(path)}/notif/enable`;

        return this.fsApi
            .putAsync<any>(endpoint, null)
            .then((result: any) => {
                return isFile ? CloudFile.parse(result) : CloudFolder.parse(result);
            })
            .catch((err) => {
                this.api.handleError(err);
                throw err;
            });
    }

    disableUserResourceNotificationSettings(orgId: string, path: string, isFile: boolean = false): Promise<CloudFile | CloudFolder> {
        const endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/f/${this.encode(path)}/notif/disable`;

        return this.fsApi
            .putAsync<any>(endpoint, null)
            .then((result: any) => {
                return isFile ? CloudFile.parse(result) : CloudFolder.parse(result);
            })
            .catch((err) => {
                this.api.handleError(err);
                throw err;
            });
    }

    /**
     * Recreates the shared dummy file/folder (if it has been deleted before)
     * Only called when the user wants to redirect to this resource 
     * @param path 
     * @returns true if shared dummy is recreated, false if failed
     */
    repairUserSharedDummy(orgId: string, path: string) {
        const endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/s/f/${this.encode(path)}/dummy-repair`;

        return this.fsApi.postAsync<any>(endpoint, null)
            .then((dto) => dto)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    createUserShareFolder(orgId: string, folderName: string, fullPath: string, sharedPath: string) {
        var endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/s/${this.encode(sharedPath)}/f/${this.encode(fullPath)}/create`;

        return this.fsApi.postAsync<any>(endpoint, JSON.stringify(folderName))
            .then((res) => OriginFolder.parse(res))
            .catch((err) => {
                this.fsApi.handleError(err);
                    throw err;
            });
    }

    /**
     * Downloads a file located inside a folder shared to the user.
     */
    downloadUserSharedFileAsync(orgId: string, name: string, fullPath: string, sharedPath: string, size: number, showProgress: boolean = true): Promise<{ blob: Blob, fileName: string }> {
        var endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/s/${this.encode(sharedPath)}/f/${this.encode(fullPath)}/download`;
        if (showProgress) {
            var cloudObjState = new CloudObjState(
                orgId,
                null,
                fullPath,
                [],
                CloudStorageType.User,
                CloudObjType.Download,
                name,
                size,
                CloudObjStatus.Preparing
            );
            this.addObjState(cloudObjState);

            return this.downloadWithProgressFromFileServer(
                cloudObjState,
                endpoint,
                null
            );
        } else {
            return this.fsApi
                .getAsync<any>(endpoint, null, null, { responseType: "blob" })
                .then((results: Blob) => {
                    if (!results || results.size == 0) return null;
                    return { blob: results, fileName: name };
                })
                .catch((err) => {
                    this.fsApi.handleError(err);
                    throw err;
                });
        }
    }

    /**
     * Downloads a file at the root of the user's Shared with Me Drive.
     */
    downloadUserSharedFileAtRootAsync(orgId: string, name: string, sharedPath: string, size: number, showProgress: boolean = true): Promise<{ blob: Blob, fileName: string }> {
        var endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/s/${this.encode(sharedPath)}/f/download`;
        if (showProgress) {
            var cloudObjState = new CloudObjState(
                orgId,
                null,
                sharedPath,
                [],
                CloudStorageType.User,
                CloudObjType.Download,
                name,
                size,
                CloudObjStatus.Preparing
            );
            this.addObjState(cloudObjState);

            return this.downloadWithProgressFromFileServer(
                cloudObjState,
                endpoint,
                null
            );
        } else {
            return this.fsApi
                .getAsync<any>(endpoint, null, null, { responseType: "blob" })
                .then((results: Blob) => {
                    if (!results || results.size == 0) return null;
                    return { blob: results, fileName: name };
                })
                .catch((err) => {
                    this.fsApi.handleError(err);
                    throw err;
                });
        }
    }

    downloadMultiUserSharedFileAsync(orgId: string, filePaths: string[], folderPaths: string[], size: number, sharedPath: string, name: string = null): Promise<{ blob: Blob, fileName: string }> {
        var options = {
            FilePaths: filePaths,
            FolderPaths: folderPaths,
        };
        var endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/s/${this.encode(sharedPath)}/f/download`;

        var cloudObjState = new CloudObjState(
            orgId,
            null,
            null,
            [],
            CloudStorageType.User,
            CloudObjType.Download,
            name,
            size,
            CloudObjStatus.Preparing
        );
        this.addObjState(cloudObjState);

        return this.downloadMultipleWithProgressFromFileServer(cloudObjState, endpoint, options, null);
    }

    /**Gets the size of a folder, includes content in its subfolders. */
    getUserSharedFolderSize(orgId: string, folderPath: string, sharedPath: string): Promise<number> {
        const endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/s/${this.encode(sharedPath)}/f/${this.encode(folderPath)}/size`;

        return this.fsApi.getAsync<number>(endpoint)
        .catch((err) => {
            this.api.handleError(err);
            throw err;
        });
    }

    /**Gets the total sum of the given folders sizes. */
    getTotalUserSharedFoldersSize(orgId: string, folderPaths: string[], sharedPath: string): Promise<number> {
        var endpoint=`o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/s/${this.encode(sharedPath)}/f/size`;

        return this.fsApi.postAsync<any>(endpoint, folderPaths)
            .then((res) => res)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    renameUserSharedItem(
        orgId: string,
        name: string,
        fullPath: string = null,
        sharedPath: string = null
    ) {

        var endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/s/${this.encode(sharedPath)}/f/${this.encode(fullPath)}/rename`
        return this.fsApi.putAsync<any>(endpoint, JSON.stringify(name))
            .then((result) => {
                return !!result;
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    moveUserSharedItem(
        orgId: string, path: string, sharedPath: string, destinationPath: string, destinationName: string, filePaths: string[] = [], folderPaths: string[] = []
    ) {
        var body = {
            folderPaths: folderPaths,
            filePaths: filePaths,
            currentPath: path,
            destinationPath: destinationPath
        }

        var endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/s/${this.encode(sharedPath)}/f/move`;

        var cloudObjState = new CloudObjState(orgId, null, path, null, CloudStorageType.User, CloudObjType.Move, destinationName, null, CloudObjStatus.InProgress);
        this.addObjState(cloudObjState);

        return this.fsApi.postAsync<any>(endpoint, body)
            .then(() => {
                this.completeObjState(cloudObjState);
                return true;
            })
            .catch((err) => {
                this.completeObjState(cloudObjState, err);
                this.fsApi.handleError(err);
                throw err;
            });
    }

    copyUserSharedItem(
        orgId: string, path: string, sharedPath: string, destinationPath: string, destinationName: string, filePaths: string[] = [], folderPaths: string[] = []
    ) {
        var body = {
            folderPaths: folderPaths,
            filePaths: filePaths,
            currentPath: path,
            destinationPath: destinationPath,
        }
        var endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/s/${this.encode(sharedPath)}/f/copy`;

        var cloudObjState = new CloudObjState(orgId, null, path, null, CloudStorageType.User, CloudObjType.Copy, destinationName, null, CloudObjStatus.InProgress);
        this.addObjState(cloudObjState);

        return this.fsApi.postAsync<any>(endpoint, body)
            .then(() => {
                this.completeObjState(cloudObjState);
                return true;
            })
            .catch((err) => {
                this.completeObjState(cloudObjState, err);
                this.fsApi.handleError(err);
                throw err;
            });
    }

    deleteUserSharedItem(
        orgId: string,
        fullPath: string = null,
        sharedPath: string = null
    ) {
        var endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/s/${this.encode(sharedPath)}/f/${this.encode(fullPath)}`;

        return this.fsApi.deleteAsync<any>(endpoint)
            .then(() => true)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    deleteMultiUserSharedItem(
        orgId: string,
        folderPaths: string[] = [],
        filePaths: string[] = [],
        sharedPath: string = null,
    ) {
        var body = {
            filePaths: filePaths,
            folderPaths: folderPaths,
        };
        var endpoint = `o/${this.encode(orgId)}/u/${this.base64CurrentUserId}/s/${this.encode(sharedPath)}/f`;

        return this.fsApi.deleteAsync<any>(endpoint, body)
            .then(() => true)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    // removeUserSharedAccess(
    //     orgId: string,
    //     fullPath: string = null,
    //     sharedPath: string = null,
    //     isFile: boolean
    //     ){
    //     var body = {
    //       path: fullPath,
    //       sharedPath: sharedPath,
    //       isFile: isFile,
    //     };
    //     var endpoint = `storage/${orgId.toLowerCase()}/users/share/rm-access`;
    //     return this.api.postAsync<any>(endpoint, body)
    //         .then((result: boolean) => {
    //             return result;
    //         })
    //         .catch((err) => {
    //             this.api.handleError(err);
    //         throw err;
    //         });    
    // }
    //#endregion User Folder

    //### Personal Folder ###
    checkPersonalFolder(
        path: string = null,
    ): Promise<boolean> {
        var endpoint = path ? `p/f/${this.encode(path)}/check` : `p/f/check`;

        return this.fsApi
            .getAsync(endpoint)
            .then((res) => true,
                    (res) => false)
            .catch((err) => {
                throw err;
            });
    }

    readPersonalFolder(path: string = null, depth?: FolderHierarchy): Promise<CloudFolder> {
        var endpoint = path ? `p/f/${this.encode(path)}/meta` : `p/f/meta`;
        if (!!depth) endpoint += `?d=${depth}`;

        return this.fsApi.getAsync<any>(endpoint)
            .then((dto) => {
                if (!dto) return null;
                    let folder = CloudFolder.parse(dto);
                    if (folder.isAppDataFolder) {
                        return null;
                    } else {
                        folder.folders = folder.folders.filter(
                            (f) => f.isFolder && !f.isAppDataFolder
                        );
                        return folder;
                    }
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });

    }

    /**
     * Reads a SharedFolder in the personal org user's Shared with Me Drive.
     * @param orgId 
     * @param sharedDummyPath path of the folder being read.
     * @returns OriginFolder of the SharedFolder being read.
     */
    readPersonalSharedFolder(sharedDummyPath: string, depth?: FolderHierarchy): Promise<OriginFolder> {
        if (!sharedDummyPath) return null;
        var endpoint = `p/s/${this.encode(sharedDummyPath)}/meta`;
        if (!!depth) endpoint += `?d=${depth}`;

        return this.fsApi.getAsync<any>(endpoint)
            .then(dto => {
                if (!dto || !Resource.isFolder(dto)) return Promise.reject("Failed to read shared folder.");
                return OriginFolder.parse(dto);
            })
            .catch(err => {
                this.api.handleError(err);
                throw err;
            });
    }

    /**
     * Reads an OriginFolder in the personal org user's Shared with Me Drive. 
     * OriginFolder means the user does not have a shared dummy for this folder.
     * @param parentSharedDummyPath shared path of shared folder containing the folder being read.
     * @param folderPath origin path of folder being read. 
     * @returns OriginFolder of folder being read.
     */
    readPersonalSharedOriginFolder(parentSharedDummyPath: string, folderPath: string, depth?: FolderHierarchy): Promise<OriginFolder> {
        if (!parentSharedDummyPath || !folderPath) return null;
        var endpoint = `p/s/${this.encode(parentSharedDummyPath)}/f/${this.encode(folderPath)}/meta`;
        if (!!depth) endpoint += `?d=${depth}`;

        return this.fsApi.getAsync<any>(endpoint)
            .then(dto => {
                if (!dto || !Resource.isFolder(dto)) return Promise.reject("Failed to read shared folder.");;
                return OriginFolder.parse(dto);
            })
            .catch(err => {
                this.api.handleError(err);
                throw err;
            });
    }

    checkPersonalSharedFolder() {
        var endpoint = `p/s/check`;

        return this.fsApi
            .getAsync(endpoint)
            .then(() => true)
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    // sharePersonalFile(path: string, userIds: string[], toNotifyUser: boolean){
    //     var options = {
    //         path: path,
    //         UserIdentities: userIds,
    //         ToNotifyUser: toNotifyUser
    //     };
    //     var endpoint = `storage/personal/share/file`;

    //     return this.api.post<any>(endpoint, options)
    //         .pipe(
    //             map(dto => {
    //                 if (!dto) return null;
    //                 return SharedFile.parse(dto);
    //             }),
    //             catchError(err => {
    //                 this.api.handleError(err);
    //                 throw err;
    //             })
    //         );
    // }

    // sharePersonalFolder(path: string, userIds: string[], toNotifyUser: boolean){
    //     var options = {
    //         path: path,
    //         UserIdentities: userIds,
    //         ToNotifyUser: toNotifyUser
    //     };
    //     var endpoint = `storage/personal/share/folder`;

    //     return this.api.post<any>(endpoint, options)
    //         .pipe(
    //             map(dto => {
    //                 if (!dto) return null;
    //                 return SharedFolder.parse(dto);
    //             }),
    //             catchError(err => {
    //                 this.api.handleError(err);
    //                 throw err;
    //             })
    //         );
    // }
    
    /**
     * Reads first level of personal org's Shared with Me Drive.
     * @returns Shared dummy of files and folders at root level.
     */
    async listPersonalSharedFolder(): Promise<SharedFolder>{
        const endpoint = `p/s/meta`;
        var list = await this.fsApi.getAsync<any[]>(endpoint);
        let sharedFolder = new SharedFolder();
        sharedFolder.isRoot = true;
        sharedFolder.name = "Shared With Me";
        if (!list || list.length == 0) return sharedFolder;

        sharedFolder.files = list
          .filter((r) => Resource.isFile(r))
          .map((r) => SharedFile.parse(r));
        sharedFolder.folders = list
          .filter((r) => !Resource.isFile(r))
          .map((r) => SharedFolder.parse(r));

        return sharedFolder;
    }

    /**
     * Recreates the shared dummy file/folder (if it has been deleted before)
     * Only called when the user wants to redirect to this resource 
     * @param path 
     * @returns true if shared dummy is recreated, false if failed
     */
    repairPersonalSharedDummy(path: string) {
        const endpoint = `p/s/f/${this.encode(path)}/dummy-repair`;

        return this.fsApi.postAsync<any>(endpoint, null)
            .then((dto) => dto)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    savePersonalResourceClaims(path: string, accessRights: AccessRight[], toNotifyUser: boolean, isFile: boolean = false) {
        var body = {
            Claims: accessRights.map(o => o.toUpdateClaimDTO()),
            NotifyUser: toNotifyUser
        }
        var endpoint = `p/f/${this.encode(path)}/claim`;

        return this.fsApi.putAsync<any>(endpoint, body)
            .then((dto) => {
                return isFile ? CloudFile.parse(dto) : CloudFolder.parse(dto);
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    changePersonalNotificationSettings(path: string, isEnabled: boolean, isFile: boolean = false) {
        if (isEnabled) return this.enablePersonalNotificationSettings(path, isFile);
        else return this.disablePersonalNotificationSettings(path, isFile);
    }

    enablePersonalNotificationSettings(path: string, isFile: boolean = false) {
        var endpoint = `p/f/${this.encode(path)}/notif/enable`;

        return this.fsApi
            .putAsync<any>(endpoint, null)
            .then((result: any) => {
                return isFile ? CloudFile.parse(result) : CloudFolder.parse(result);
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    disablePersonalNotificationSettings(path: string, isFile: boolean = false) {
        var endpoint = `p/f/${this.encode(path)}/notif/disable`;

        return this.fsApi
            .putAsync<any>(endpoint, null)
            .then((result: any) => {
                return isFile ? CloudFile.parse(result) : CloudFolder.parse(result);
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    createPersonalFolder(folderName: string, parent: string = null) {
        var endpoint = `p/f/${this.encode(parent)}/create`;

        return this.fsApi.postAsync<any>(endpoint, JSON.stringify(folderName))
            .then((dto) => {
                if (!dto) return null;
                return CloudFolder.parse(dto);
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                    throw err;
            });
    }

    renamePersonalItem(name: string, path: string, isFile: boolean = false) {
        var endpoint = `p/f/${this.encode(path)}/rename`;

        return this.fsApi.putAsync<any>(endpoint, JSON.stringify(name))
            .then((result) => result)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    movePersonalItems(path: string, destinationPath: string, destinationName: string, filePaths: string[] = [], folderPaths: string[] = []) {
        var body = {
            folderPaths: folderPaths,
            filePaths: filePaths,
            currentPath: path,
            destinationPath: destinationPath
        }

        var endpoint = `p/f/move`;

        var cloudObjState = new CloudObjState(null, null, path, null, CloudStorageType.Personal, CloudObjType.Move, destinationName, null, CloudObjStatus.InProgress);
        this.addObjState(cloudObjState);

        return this.fsApi.postAsync<any>(endpoint, body)
            .then((res) => {
                this.completeObjState(cloudObjState);
                return true;
            })
            .catch((err) => {
                this.completeObjState(cloudObjState, err);
                    this.fsApi.handleError(err);
                    throw err;
            });
    }

    copyPersonalItems(path: string, destinationPath: string, destinationName: string, filePaths: string[] = [], folderPaths: string[] = []) {
        var body = {
            folderPaths: folderPaths,
            filePaths: filePaths,
            currentPath: path,
            destinationPath: destinationPath
        }

        var endpoint = `p/f/copy`;

        var cloudObjState = new CloudObjState(null, null, path, null, CloudStorageType.Personal, CloudObjType.Copy, destinationName, null, CloudObjStatus.InProgress);
        this.addObjState(cloudObjState);

        return this.fsApi.postAsync<any>(endpoint, body)
            .then((res) => {
                this.completeObjState(cloudObjState);
                return true;
            })
            .catch((err) => {
                this.completeObjState(cloudObjState, err);
                    this.fsApi.handleError(err);
                    throw err;
            });
    }

    deletePersonalResource(path: string) {
        var endpoint = `p/f/${this.encode(path)}`;

        return this.fsApi.deleteAsync<any>(endpoint)
            .then(() => true)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    deletePersonalResources(
        folderPaths: string[] = [],
        filePaths: string[] = [],
    ) {
        var options = {
            filePaths: filePaths,
            folderPaths: folderPaths,
        };
        var endpoint = `p/f`;

        return this.fsApi.deleteAsync<any>(endpoint, options)
            .then(() => true)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    deletePersonalSharedDummy(path: string) {
        const endpoint = `p/s/f/${this.encode(path)}`;

        return this.fsApi.deleteAsync(endpoint)
            .then((res) => true)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    pendingPersonalUploads(): CloudObjState[] {
        return this._uploads.filter(u => u.storageType == CloudStorageType.Personal);
    }

    streamPersonalResource(folderPath: string, fileBlobs: Blob[] = [],
        additionalHeaders: {
            key: string;
            val: string;
        }[] = []): Promise<any> {
        if (!folderPath) return null;
        const formData = new FormData();
        var addHeaders = [{ key: 'DestinationPath', val: folderPath }, ...additionalHeaders];

        for (let i = 0; i < fileBlobs.length; i++) {
            formData.append("Files", fileBlobs[i]);
        }

        const endpoint = `p/f/upload`;

        return this.fsApi.postAsync<any>(endpoint, formData, null, null, null, true, addHeaders);
    }

    streamPersonalFile(name: string, folderPath: string, files: File[]): Promise<CloudFile> {
        var cloudObjState = new CloudObjState(null, null, folderPath, files, CloudStorageType.Personal, CloudObjType.Upload, name);
        this.addObjState(cloudObjState);

        var addHeaders = [{ key: 'Type', val: 'file' }];

        return this.streamPersonalResource(folderPath, files, addHeaders)
            .then((dto) => {
                if (!dto || !Resource.isFile(dto)) return null;
                    this.completeObjState(cloudObjState);
                    return CloudFile.parse(dto);
            })
            .catch((err) => {
                this.completeObjState(cloudObjState, err);
                    this.api.handleError(err);
                    throw err;
            });
    }

    async streamPersonalFolder(parentName: string, folderPath: string = null, size: number, files: File[], folderName: string): Promise<boolean> {
        if (!folderPath) return null;

        var cloudObjState = new CloudObjState(null, null, folderPath, files, CloudStorageType.Personal, CloudObjType.Zip, parentName, null, CloudObjStatus.InProgress);
        this.addObjState(cloudObjState);

        try {
          let blob = await this.zipFolder(files);
          var addHeaders = [
            { key: "Type", val: "folder" },
            { key: "Size", val: size.toString() },
            { key: "DisplayName", val: this.encodeFilename(folderName) },
          ];

          return this.streamPersonalResource(folderPath, [blob], addHeaders)
                .then(() => {
                    this.completeObjState(cloudObjState);
                    return true;
                }).catch((err) => {
                    this.fsApi.handleError(err);
                    this.completeObjState(cloudObjState, err);
                    return false;
                });
        } catch (err) {
          this.completeObjState(cloudObjState, err);
        }
    }
    /**Supports uploading file, folder, or Google Drive resource to shared with me.
     * 
     * Returns observable that can be parsed into a FileDTO if a file or Google Drive item was uploaded, null if a folder was uploaded.*/
    private _streamPersonalShareResource(folderPath: string, sharedPath: string, fileBlobs: Blob[] = [],
        additionalHeaders: {
            key: string;
            val: string;
        }[] = []): Promise<any> {
        if (!folderPath) return null;
        const formData = new FormData();
        var addHeaders = [{ key: 'DestinationPath', val: folderPath }, ...additionalHeaders];

        for (let i = 0; i < fileBlobs.length; i++) {
            formData.append("Files", fileBlobs[i]);
        }

        const endpoint = `p/s/${this.encode(sharedPath)}/f/upload`;

        return this.fsApi.postAsync<any>(endpoint, formData, null, null, null, true, addHeaders);
    }

    streamPersonalShareFile(name: string, folderPath: string, sharedPath: string, files: File[]): Promise<OriginFile> {
        var cloudObjState = new CloudObjState(null, null, folderPath, files, CloudStorageType.Personal, CloudObjType.Upload, name);
        this.addObjState(cloudObjState);

        var addHeaders = [{ key: 'Type', val: 'file' }];

        return this._streamPersonalShareResource(folderPath, sharedPath, files, addHeaders)
            .then((results) => {
                if (!results) return null;
                    this.completeObjState(cloudObjState);
                    return OriginFile.parse(results);

            })
            .catch((err) => {
                this.completeObjState(cloudObjState, err);
                this.fsApi.handleError(err);
                throw err;
            });

    }

    async streamPersonalShareFolder(parentName: string, folderPath: string, sharedPath: string, files: File[], size: number, folderName: string): Promise<boolean> {
        var cloudObjState = new CloudObjState(null, null, folderPath, files, CloudStorageType.Personal, CloudObjType.Zip, parentName, null, CloudObjStatus.InProgress);
        this.addObjState(cloudObjState);
        
        try {
            let blob = await this.zipFolder(files);

            var addHeaders = [{ key: 'Type', val: 'folder' }, { key: 'Size', val: size.toString() }, { key: 'DisplayName', val: this.encodeFilename(folderName)}];

            return this._streamPersonalShareResource(folderPath, sharedPath, [blob], addHeaders)
                    .then(() => {
                        this.completeObjState(cloudObjState);
                        return true;
                    }).catch((err) => {
                        this.fsApi.handleError(err);
                        this.completeObjState(cloudObjState, err);
                        return false;
                    });
        } catch (err) {
            this.completeObjState(cloudObjState, err);
        }
    }

    streamGDrivePersonalFolder(name: string, folderPath: string = null, data: any, token: string): Promise<CloudFile> {
        var fileId = data.docs[0].id;
        var fileName = data.docs[0].name;
        var mediaSize = data.docs[0].sizeBytes;
        var mediaType = data.docs[0].mimeType;
        var mediaGoogleType = data.docs[0].type;


        var cloudObjState = new CloudObjState(null, null, folderPath, [], CloudStorageType.Personal, CloudObjType.Upload, name)
        this.addObjState(cloudObjState);

        var addHeaders = [
            { key: 'Type', val: 'gdrive' },
            { key: 'GoogleFileId', val: fileId },
            { key: 'DisplayName', val: this.encodeFilename(fileName) },
            { key: 'ContentType', val: mediaType },
            { key: 'MediaType', val: mediaGoogleType },
            { key: 'GoogleAccessToken', val: token },
            { key: 'Size', val: mediaSize }
        ];

        return this.streamPersonalResource(folderPath, undefined, addHeaders)
            .then((dto) => {
                if (!dto || !Resource.isFile(dto)) return null;
                    this.completeObjState(cloudObjState);
                    return CloudFile.parse(dto);
            })
            .catch((err) => {
                this.completeObjState(cloudObjState, err);
                    this.api.handleError(err);
                    throw err;
            });
    }

    streamGDrivePersonalSharedFolder(folderName: string, folderPath: string, sharedPath: string, data: any, token: string): Promise<OriginFile> {
        var fileId = data.docs[0].id;
        var fileName = data.docs[0].name;
        var mediaSize = data.docs[0].sizeBytes;
        var mediaType = data.docs[0].mimeType;
        var mediaGoogleType = data.docs[0].type;

        var cloudObjState = new CloudObjState(null, null, folderPath, [], CloudStorageType.Personal, CloudObjType.Upload, folderName)
        this.addObjState(cloudObjState);

        var addHeaders = [
            { key: 'Type', val: 'gdrive' },
            { key: 'GoogleFileId', val: fileId },
            { key: 'DisplayName', val: this.encodeFilename(fileName) },
            { key: 'ContentType', val: mediaType },
            { key: 'MediaType', val: mediaGoogleType },
            { key: 'GoogleAccessToken', val: token },
            { key: 'Size', val: mediaSize }
        ]

        return this._streamPersonalShareResource(folderPath, sharedPath, [], addHeaders)
            .then((dto) => {
                if (!dto) return null;
                    this.completeObjState(cloudObjState);
                    return OriginFile.parse(dto);
            })
            .catch((err) => {
                this.completeObjState(cloudObjState, err);
                    this.fsApi.handleError(err);
                    throw err;
            });
    }

    /**Streams the Google Drive file into a user profile folder.*/
    streamGDriveUserFolder(orgId: string, folderName: string, folderPath: string = null, data: any, token: string): Promise<CloudFile> {
        var fileId = data.docs[0].id;
        var fileName = data.docs[0].name;
        var mediaSize = data.docs[0].sizeBytes;
        var mediaType = data.docs[0].mimeType;
        var mediaGoogleType = data.docs[0].type;


        var cloudObjState = new CloudObjState(orgId, null, folderPath, [], CloudStorageType.User, CloudObjType.Upload, folderName)
        this.addObjState(cloudObjState);

        var addHeaders = [
            { key: 'Type', val: 'gdrive' },
            { key: 'GoogleFileId', val: fileId },
            { key: 'DisplayName', val: this.encodeFilename(fileName) },
            { key: 'ContentType', val: mediaType },
            { key: 'MediaType', val: mediaGoogleType },
            { key: 'GoogleAccessToken', val: token },
            { key: 'Size', val: mediaSize }
        ];

        return this.streamUserResource(orgId, folderPath, undefined, addHeaders)
            .then((dto) => {
                if (!dto || !Resource.isFile(dto)) return null;
                    this.completeObjState(cloudObjState);
                    return CloudFile.parse(dto);
            })
            .catch((err) => {
                this.completeObjState(cloudObjState, err);
                    this.api.handleError(err);
                    throw err;
            });

    }

    streamGDriveUserSharedFolder(orgId: string, folderName: string, folderPath: string, sharedPath: string, data: any, token: string): Promise<OriginFile> {
        var fileId = data.docs[0].id;
        var fileName = data.docs[0].name;
        var mediaSize = data.docs[0].sizeBytes;
        var mediaType = data.docs[0].mimeType;
        var mediaGoogleType = data.docs[0].type;

        var cloudObjState = new CloudObjState(orgId, null, folderPath, [], CloudStorageType.User, CloudObjType.Upload, folderName)
        this.addObjState(cloudObjState);

        var addHeaders = [
            { key: 'Type', val: 'gdrive' },
            { key: 'GoogleFileId', val: fileId },
            { key: 'DisplayName', val: this.encodeFilename(fileName) },
            { key: 'ContentType', val: mediaType },
            { key: 'MediaType', val: mediaGoogleType },
            { key: 'GoogleAccessToken', val: token },
            { key: 'Size', val: mediaSize }
        ]

        return this.streamUserShareResource(orgId, folderPath, sharedPath, undefined, addHeaders)
            .then((dto) => {
                if (!dto) return null;
                    this.completeObjState(cloudObjState);
                    return OriginFile.parse(dto);
            })
            .catch((err) => {
                this.completeObjState(cloudObjState, err);
                    this.fsApi.handleError(err);
                    throw err;
            });
    }

    downloadPersonalFileAsync(name: string, fullPath: string, size: number): Promise<{ blob: Blob, fileName: string }> {
        var endpoint = `p/f/${this.encode(fullPath)}/download`;

        var cloudObjState = new CloudObjState(
            null,
            null,
            fullPath,
            [],
            CloudStorageType.Personal,
            CloudObjType.Download,
            name,
            size,
            CloudObjStatus.Preparing
        );
        this.addObjState(cloudObjState);

        return this.downloadWithProgressFromFileServer(cloudObjState, endpoint, null);
    }

    downloadMultiPersonalFileAsync(fullPaths: string[], folderPaths: string[], size: number, name: string = null): Promise<{ blob: Blob, fileName: string }> {
        var options = {
            FilePaths: fullPaths,
            FolderPaths: folderPaths
        };

        var endpoint = `p/f/download`;

        var cloudObjState = new CloudObjState(
            null,
            null,
            null,
            [],
            CloudStorageType.Personal,
            CloudObjType.Download,
            name,
            size,
            CloudObjStatus.Preparing
        );
        this.addObjState(cloudObjState);

        return this.downloadMultipleWithProgressFromFileServer(cloudObjState, endpoint, options, null);
    }

    getPersonalFolderSize(folderPath: string): Promise<number> {
        if (!folderPath) return Promise.reject("Missing folder path.");
        const endpoint = `p/f/${this.encode(folderPath)}/size`;
        return this.fsApi.getAsync<number>(endpoint)
        .catch(err => {
            this.api.handleError(err);
            throw err;
        });
    }

    getTotalPersonalFoldersSize(folderPaths: string[]): Promise<number> {
        var endpoint = `p/f/size`;

        return this.fsApi.postAsync<any>(endpoint, folderPaths)
            .then((res) => res)
            .catch((err) => {
                this.api.handleError(err);
                throw err;
            });
    }

    createPersonalShareFolder(folderName: string, fullPath: string, sharedPath: string) {
        var endpoint = `p/s/${this.encode(sharedPath)}/f/${this.encode(fullPath)}/create`;

        return this.fsApi.postAsync<any>(endpoint, JSON.stringify(folderName))
            .then((res) => OriginFolder.parse(res))
            .catch((err) => {
                this.fsApi.handleError(err);
                    throw err;
            });
    }

    downloadPersonalSharedFileAsync(name: string, fullPath: string, sharedPath: string, size: number, showProgress: boolean = true): Promise<{ blob: Blob, fileName: string }> {
        var endpoint = `p/s/${this.encode(sharedPath)}/f/${this.encode(fullPath)}/download`;
        if (showProgress) {
            var cloudObjState = new CloudObjState(
                null,
                null,
                fullPath,
                [],
                CloudStorageType.Personal,
                CloudObjType.Download,
                name,
                size,
                CloudObjStatus.Preparing
            );
            this.addObjState(cloudObjState);

            return this.downloadWithProgressFromFileServer(
                cloudObjState,
                endpoint,
                null
            );
        } else {
            return this.fsApi
                .getAsync<any>(endpoint, null, null, { responseType: "blob" })
                .then((results: Blob) => {
                    if (!results || results.size == 0) return null;
                    return { blob: results, fileName: name };
                })
                .catch((err) => {
                    this.fsApi.handleError(err);
                    throw err;
                });
        }
    }

    downloadPersonalSharedFileAtRootAsync(name: string, sharedPath: string, size: number, showProgress: boolean = true): Promise<{ blob: Blob, fileName: string }> {
        var endpoint = `p/s/${this.encode(sharedPath)}/f/download`;
        if (showProgress) {
            var cloudObjState = new CloudObjState(
                null,
                null,
                sharedPath,
                [],
                CloudStorageType.Personal,
                CloudObjType.Download,
                name,
                size,
                CloudObjStatus.Preparing
            );
            this.addObjState(cloudObjState);

            return this.downloadWithProgressFromFileServer(
                cloudObjState,
                endpoint,
                null
            );
        } else {
            return this.fsApi
                .getAsync<any>(endpoint, null, null, { responseType: "blob" })
                .then((results: Blob) => {
                    if (!results || results.size == 0) return null;
                    return { blob: results, fileName: name };
                })
                .catch((err) => {
                    this.fsApi.handleError(err);
                    throw err;
                });
        }
    }

    downloadMultiPersonalSharedFileAsync(filePaths: string[], folderPaths: string[], size: number, sharedPath: string, name: string = null): Promise<{ blob: Blob, fileName: string }> {
        var options = {
            FilePaths: filePaths,
            FolderPaths: folderPaths,
        };
        var endpoint = `p/s/${this.encode(sharedPath)}/f/download`;

        var cloudObjState = new CloudObjState(
            null,
            null,
            null,
            [],
            CloudStorageType.Personal,
            CloudObjType.Download,
            name,
            size,
            CloudObjStatus.Preparing
        );
        this.addObjState(cloudObjState);

        return this.downloadMultipleWithProgressFromFileServer(cloudObjState, endpoint, options, null);
    }
   
    getPersonalSharedFoldersSize(folderPaths: string[], sharedPath: string): Promise<number> {
        var endpoint=`p/s/${this.encode(sharedPath)}/f/size`;

        return this.fsApi.postAsync<any>(endpoint, folderPaths)
            .then((res) => res)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    getPersonalSharedFolderOriginSize(folderPath: string, sharedPath: string): Promise<number> {
        const endpoint = `p/s/${this.encode(sharedPath)}/f/${this.encode(folderPath)}/size`;

        return this.fsApi.getAsync<any>(endpoint)
            .then((res) => res)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    renamePersonalSharedItem(
        name: string,
        fullPath: string,
        sharedPath: string,
    ) {
        var endpoint = `p/s/${this.encode(sharedPath)}/f/${this.encode(fullPath)}/rename`
        return this.fsApi.putAsync<any>(endpoint, JSON.stringify(name))
            .then((result) => {
                return !!result;
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    movePersonalSharedItem(
        path: string, sharedPath: string, destinationPath: string, destinationName: string, filePaths: string[] = [], folderPaths: string[] = []
    ) {
        var body = {
            folderPaths: folderPaths,
            filePaths: filePaths,
            currentPath: path,
            destinationPath: destinationPath
        }

        var endpoint = `p/s/${this.encode(sharedPath)}/f/move`;

        var cloudObjState = new CloudObjState(null, null, path, null, CloudStorageType.Personal, CloudObjType.Move, destinationName, null, CloudObjStatus.InProgress);
        this.addObjState(cloudObjState);

        return this.fsApi.postAsync<any>(endpoint, body)
            .then(() => {
                this.completeObjState(cloudObjState);
                return true;
            })
            .catch((err) => {
                this.completeObjState(cloudObjState, err);
                this.fsApi.handleError(err);
                throw err;
            });
    }

    copyPersonalSharedItem(
        path: string, sharedPath: string, destinationPath: string, destinationName: string, filePaths: string[] = [], folderPaths: string[] = []
    ) {
        var body = {
            folderPaths: folderPaths,
            filePaths: filePaths,
            currentPath: path,
            destinationPath: destinationPath,
        }
        var endpoint = `p/s/${this.encode(sharedPath)}/f/copy`;

        var cloudObjState = new CloudObjState(null, null, path, null, CloudStorageType.Personal, CloudObjType.Copy, destinationName, null, CloudObjStatus.InProgress);
        this.addObjState(cloudObjState);

        return this.fsApi.postAsync<any>(endpoint, body)
            .then(() => {
                this.completeObjState(cloudObjState);
                return true;
            })
            .catch((err) => {
                this.completeObjState(cloudObjState, err);
                this.fsApi.handleError(err);
                throw err;
            });
    }

    deletePersonalSharedItem(
        fullPath: string,
        sharedPath: string,
    ) {
        var endpoint = `p/s/${this.encode(sharedPath)}/f/${this.encode(fullPath)}`;

        return this.fsApi.deleteAsync<any>(endpoint)
            .then(() => true)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    deleteMultiPersonalSharedItem(
        folderPaths: string[] = [],
        filePaths: string[] = [],
        sharedPath: string = null,
    ) {
        var body = {
            filePaths: filePaths,
            folderPaths: folderPaths,
        };
        var endpoint = `p/s/${this.encode(sharedPath)}/f`;

        return this.fsApi.deleteAsync<any>(endpoint, body)
            .then(() => true)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    // removePersonalSharedAccess(
    //     fullPath: string = null,
    //     sharedPath: string = null,
    //     isFile: boolean
    // ) {
    //     var body = {
    //         path: fullPath,
    //         sharedPath: sharedPath,
    //         isFile: isFile,
    //     };
    //     var endpoint = `storage/personal/share/rm-access`;
    //     return this.api.postAsync<any>(endpoint, body)
    //         .then((result: boolean) => {
    //             return result;
    //         })
    //         .catch((err) => {
    //             this.api.handleError(err);
    //             throw err;
    //         });
    // }

    // ### General ###
    getFileOrFolderShareLink(fullPath: string, isFolder: boolean, isPersonalOrg: boolean, orgId: string) {
        if (isPersonalOrg) return this.getPersonalFolderOrFileShareLink(fullPath, isFolder);
        return this.getOrgFolderOrFileShareLink(orgId, fullPath, isFolder);
    }

    //#region Enterprise Drive
    checkDriveFolder(orgId: string, driveId: string): Promise<boolean> {
        var endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/check`;

        return this.fsApi
            .getAsync(endpoint)
            .then(() => true, () => false)
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    readDriveFolder(orgId: string, driveId: string, path: string = null, depth?: FolderHierarchy): Promise<SharedDriveFolder> {

        var endpoint = path ?
            `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/${this.encode(path)}/meta`
            : `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/meta`;
            if (!!depth) endpoint += `?d=${depth}`;

        return this.fsApi.getAsync<any>(endpoint)
            .then((dto) => {
                if (!dto) return null;
                    let folder = SharedDriveFolder.parseWithDriveId(dto, driveId);
                    if (folder.isAppDataFolder) {
                        return null;
                    } else {
                        folder.folders = folder.folders.filter(
                            (f) => f.isFolder && !f.isAppDataFolder
                        );
                        return folder;
                    }
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                    throw err;
            });

    }

    createDriveFolder(orgId: string, driveId: string, folderName: string, parent: string = null): Promise<SharedDriveFolder> {
        var endpoint = parent ?
            `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/${this.encode(parent)}/create` :
            `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/create`;

        return this.fsApi.postAsync<any>(endpoint, JSON.stringify(folderName))
            .then((dto) => {
                if (!dto) return null;
                    return SharedDriveFolder.parseDrive(dto);
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    renameDriveItem(orgId: string, driveId: string, name: string, path: string) {
        var endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/${this.encode(path)}/rename`;

        return this.fsApi.putAsync<any>(endpoint, JSON.stringify(name))
            .then((result) => !!result)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    moveDriveItems(orgId: string, driveId: string, path: string, destinationPath: string, destinationName: string, filePaths: string[] = [], folderPaths: string[] = []) {
        var options = {
            folderPaths: folderPaths,
            filePaths: filePaths,
            currentPath: path,
            destinationPath: destinationPath
        }

        var endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/move`;

        var cloudObjState = new CloudObjState(orgId, null, path, null, CloudStorageType.User, CloudObjType.Move, destinationName, null, CloudObjStatus.InProgress);
        this.addObjState(cloudObjState);

        return this.fsApi.postAsync<any>(endpoint, options)
            .then(() => {
                this.completeObjState(cloudObjState);
                    return true;
            })
            .catch((err) => {
                this.completeObjState(cloudObjState, err);
                    this.fsApi.handleError(err);
                    throw err;
            });
    }

    copyDriveItems(orgId: string, driveId: string, path: string, destinationPath: string, destinationName: string, filePaths: string[] = [], folderPaths: string[] = []) {
        var options = {
            folderPaths: folderPaths,
            filePaths: filePaths,
            currentPath: path,
            destinationPath: destinationPath
        }

        var endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/copy`;

        var cloudObjState = new CloudObjState(orgId, null, path, null, CloudStorageType.User, CloudObjType.Copy, destinationName, null, CloudObjStatus.InProgress);
        this.addObjState(cloudObjState);

        return this.fsApi.postAsync<any>(endpoint, options)
            .then(() => {
                this.completeObjState(cloudObjState);
                return true;
            })
            .catch((err) => {
                this.completeObjState(cloudObjState, err);
                    this.fsApi.handleError(err);
                    throw err;
            });
    }

    deleteDriveFolder(orgId: string, driveId: string, path: string) {
        var endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/${this.encode(path)}`;

        return this.fsApi.deleteAsync<any>(endpoint)
            .then(() => true)
            .catch((err) => {
                this.fsApi.handleError(err);
                    throw err;
            });
    }

    deleteMultiDriveResources(orgId: string, driveId: string, folderPaths: string[] = [], filePaths: string[] = []) {
        var options = {
            filePaths: filePaths,
            folderPaths: folderPaths
        };

        var endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f`;

        return this.fsApi.deleteAsync<any>(endpoint, options)
            .then(() => true)
            .catch((err) => {
                this.fsApi.handleError(err);
                    throw err;
            });
    }

    pendingDriveUploads(orgId: string): CloudObjState[] {
        return this._uploads.filter(u =>
            u.orgId == orgId &&
            u.storageType == CloudStorageType.User);
    }

    /**Supports uploading file, folder, or Google Drive resource to drive.
     * 
     * Returns observable that can be parsed into a FileDTO if a file or Google Drive item was uploaded, null if a folder was uploaded.*/
    streamDriveResource(orgId: string, driveId: string, folderPath: string, fileBlobs: Blob[] = [],
        additionalHeaders: {
            key: string;
            val: string;
        }[] = []): Promise<any> {
        if (!orgId || !folderPath) return null;
        const formData = new FormData();
        var addHeaders = [{ key: 'DestinationPath', val: folderPath }, ...additionalHeaders];

        for (let i = 0; i < fileBlobs.length; i++) {
            formData.append("Files", fileBlobs[i]);
        }

        const endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/upload`;

        return this.fsApi.postAsync<any>(endpoint, formData, null, null, null, true, addHeaders);
    }

    streamDriveFile(orgId: string, driveId: string, name: string, folderPath: string = null, files: File[]): Promise<CloudFile> {
        var cloudObjState = new CloudObjState(orgId, null, folderPath, files, CloudStorageType.User, CloudObjType.Upload, name);
        this.addObjState(cloudObjState);

        var addHeaders = [{ key: 'Type', val: 'file' }];

        return this.streamDriveResource(orgId, driveId, folderPath, files, addHeaders)
            .then((result) => {
                if (!result) return null;
                    this.completeObjState(cloudObjState);
                    return CloudFile.parse(result);
            })
            .catch((err) => {
                this.completeObjState(cloudObjState, err);
                this.fsApi.handleError(err);
                throw err;
            });
    }

    async streamDriveFolder(orgId: string, driveId: string, parentName: string, folderPath: string = null, size: number, files: File[], folderName: string): Promise<boolean> {
        var cloudObjState = new CloudObjState(orgId, null, folderPath, files, CloudStorageType.User, CloudObjType.Zip, parentName, null, CloudObjStatus.InProgress);
        this.addObjState(cloudObjState);

        try {
            let blob = await this.zipFolder(files);

            var addHeaders = [
            { key: "Type", val: "folder" },
            { key: "Size", val: size.toString() },
            { key: "DisplayName", val: folderName },
            ];

            return this.streamDriveResource(orgId, driveId, folderPath, [blob], addHeaders)
                .then(() => {
                    this.completeObjState(cloudObjState);
                    return true;
                }).catch((err) => {
                    this.fsApi.handleError(err);
                    this.completeObjState(cloudObjState, err);
                    return false;
                });
        } catch (err) {
            this.completeObjState(cloudObjState, err);
        }
    }

    downloadDriveFileAsync(orgId: string, driveId: string, name: string, fullPath: string, size: number, showProgress: boolean = true): Promise<{ blob: Blob, fileName: string }> {
        var endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/${this.encode(fullPath)}/download`;

        if (showProgress) {
            var cloudObjState = new CloudObjState(
                orgId,
                null,
                fullPath,
                [],
                CloudStorageType.User,
                CloudObjType.Download,
                name,
                size,
                CloudObjStatus.Preparing
            );
            this.addObjState(cloudObjState);

            return this.downloadWithProgressFromFileServer(
                cloudObjState,
                endpoint,
                null
            );
        } else {
            return this.fsApi
                .getAsync<any>(endpoint, null, null, { responseType: "blob" })
                .then((results: Blob) => {
                    if (!results || results.size == 0) return null;
                    return { blob: results, fileName: name };
                })
                .catch((err) => {
                    this.fsApi.handleError(err);
                    throw err;
                });
        }
    }

    downloadMultiDriveFileAsync(orgId: string, driveId: string, filePaths: string[], folderPaths: string[], size: number, name: string = null): Promise<{ blob: Blob, fileName: string }> {
        var options = {
            FilePaths: filePaths,
            FolderPaths: folderPaths
        };
        var endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/download`;

        var cloudObjState = new CloudObjState(
            orgId,
            null,
            null,
            [],
            CloudStorageType.User,
            CloudObjType.Download,
            name,
            size,
            CloudObjStatus.Preparing
        );
        this.addObjState(cloudObjState);

        return this.downloadMultipleWithProgressFromFileServer(cloudObjState, endpoint, options, null);
    }

    getDriveFolderSize(orgId: string, driveId: string, folderPath: string): Observable<number> {
        const endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/${this.encode(folderPath)}/size`;

        return this.fsApi.get<number>(endpoint)
            .pipe(
                map((size) => {
                    return size;
                }),
                catchError(err => {
                    this.api.handleError(err);
                    throw err;
                })
            );
    }

    getDriveFoldersSize(orgId: string, driveId: string, folderPaths: string[]): Promise<number> {
        var endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/size`;

        return this.fsApi.postAsync<any>(endpoint, folderPaths)
            .then((results: any) => results)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    changeDriveNotificationSettings(orgId: string, driveId: string, path: string, isEnabled: boolean, isFile: boolean = false) {
        if (isEnabled) return this.enableDriveNotificationSettings(orgId, driveId, path, isFile);
        else return this.disableDriveNotificationSettings(orgId, driveId, path, isFile);
    }

    enableDriveNotificationSettings(orgId: string, driveId: string, path: string, isFile: boolean = false) {
        var endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/${this.encode(path)}/notif/enable`;

        return this.fsApi
            .putAsync<any>(endpoint, null)
            .then((result: any) => {
                return isFile ? SharedDriveFile.parseWithDriveId(result, driveId) : SharedDriveFolder.parseWithDriveId(result, driveId);
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    disableDriveNotificationSettings(orgId: string, driveId: string, path: string, isFile: boolean = false) {
        var endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/${this.encode(path)}/notif/disable`;

        return this.fsApi
            .putAsync<any>(endpoint, null)
            .then((result: any) => {
                return isFile ? SharedDriveFile.parseWithDriveId(result, driveId) : SharedDriveFolder.parseWithDriveId(result, driveId);
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    saveDriveResourceClaims(orgId: string, driveId: string, path: string, accessRights: AccessRight[], toNotifyUser: boolean, isFile: boolean = false) {
        var body = {
            Claims: accessRights.map(o => o.toUpdateClaimDTO()),
            NotifyUser: toNotifyUser
        };
        var endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/${this.encode(path)}/claim`;

        return this.fsApi.putAsync<any>(endpoint, body)
            .then((dto) => {
                return isFile ? SharedDriveFile.parseWithDriveId(dto, driveId) : SharedDriveFolder.parseWithDriveId(dto, driveId);
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    streamGDriveDriveFolder(orgId: string, driveId: string, folderName: string, folderPath: string = null, data: any, token: string): Promise<CloudFile> {
        var fileId = data.docs[0].id;
        var fileName = data.docs[0].name;
        var mediaSize = data.docs[0].sizeBytes;
        var mediaType = data.docs[0].mimeType;
        var mediaGoogleType = data.docs[0].type;

        var cloudObjState = new CloudObjState(orgId, null, folderPath, [], CloudStorageType.User, CloudObjType.Upload, folderName)
        this.addObjState(cloudObjState);

        var addHeaders = [
            { key: 'Type', val: 'gdrive' },
            { key: 'GoogleFileId', val: fileId },
            { key: 'DisplayName', val: this.encodeFilename(fileName) },
            { key: 'ContentType', val: mediaType },
            { key: 'MediaType', val: mediaGoogleType },
            { key: 'GoogleAccessToken', val: token },
            { key: 'Size', val: mediaSize }

        ]

        return this.streamDriveResource(orgId, driveId, folderPath, [], addHeaders)
            .then((dto) => {
                if (!dto) return null;
                    this.completeObjState(cloudObjState);
                    return CloudFile.parse(dto);
            })
            .catch((err) => {
                this.completeObjState(cloudObjState, err);
                    this.fsApi.handleError(err);
                    throw err;
            });
    }

    getDriveOrFileShareLink(orgId: string, driveId: string, fullPath: string = null, isFolder: boolean = false) {
        var endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/${this.encode(fullPath)}/link`;
        return this.fsApi.getAsync<any>(endpoint)
            .then((result: string) => result)
            .catch((err) => {
                this.fsApi.handleError(err);
                    throw err;
            });
    }

    getDriveResourceInfo(orgId: string, path: string, driveId: string): Promise<AccessRight[]> {
        var endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/${this.encode(path)}/link-info`;

        return this.fsApi.getAsync<any>(endpoint)
            .then((result) => {
                return result.map((f) => AccessRight.parse(f));
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }
    //#endregion

    //#region Shared Drive Root
    checkSharedDrives(orgId: string): Promise<boolean> {
        var endpoint = `o/${this.encode(orgId)}/d/check`;
        return this.fsApi
            .getAsync(endpoint)
            .then((res) => true,
            (err) => false)
            .catch((err) => {
                throw err;
            });
    }

    listSharedDrives(orgId: string): Promise<SharedDriveFolder> {
        var endpoint = `o/${this.encode(orgId)}/d/meta`;
        return this.fsApi
            .getAsync<any>(endpoint)
            .then((result) => {
                return SharedDriveFolder.parseCollection(result);
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    createSharedDrive(orgId: string, name: string): Promise<SharedDriveFolder> {
        var endpoint = `o/${this.encode(orgId)}/d/create`;
        return this.fsApi
            .postAsync<any>(endpoint, JSON.stringify(name))
            .then((result: any) => {
                return SharedDriveFolder.parseDrive(result);
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    renameSharedDrive(orgId: string, driveId: string, name: string): Promise<SharedDriveFolder> {
        var endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/rename`;

        return this.fsApi
            .putAsync<any>(endpoint, JSON.stringify(name))
            .then((result: any) => {
                return SharedDriveFolder.parseDrive(result);
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    updateSharedDriveClaims(orgId: string, driveId: string, accessRights: AccessRight[]) {
        var body = {
            claims: accessRights
        };
        var endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}/f/claim`;

        return this.fsApi
            .putAsync<any>(endpoint, body)
            .then((result: any) => {
                return SharedDriveFolder.parseDrive(result);
            })
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }

    deleteSharedDrive(orgId: string, driveId: string): Promise<boolean> {
        var endpoint = `o/${this.encode(orgId)}/d/${this.encode(driveId)}`;

        return this.fsApi
            .deleteAsync<any>(endpoint)
            .then((result: any) => true)
            .catch((err) => {
                this.fsApi.handleError(err);
                throw err;
            });
    }
    //#endregion

    async zipFolder(files: File[]): Promise<Blob> {
            var zip = new JSZip();
            //var blob = null;

            let blob: Blob = await new Promise<Blob>(async (resolve, reject) => {
                let promises: Promise<any>[] = [];

                for (let i = 0; i < files.length; i++) {
                    let filePromise = new Promise((resolve, reject) => {
                        const file = files[i];
                        const reader = new FileReader();

                        reader.onload = async function (event) {
                                const fileContent = event.target.result;
                                // Add the file to the JSZip archive
                                console.log("[uploadfolderdeleg] webkitrelpath %o", file.webkitRelativePath);
                                zip.file(file.webkitRelativePath, fileContent);  
                                resolve(fileContent);                             
                            };
                        
                        reader.readAsArrayBuffer(file);
                    })

                    promises.push(filePromise);                   
                }

                // Wait for all promises to be resolved
                await Promise.all(promises);

                var z = await zip.generateAsync({ type: "blob", compression: "DEFLATE", compressionOptions: {
                    level: 9
                } });

                resolve(z);               
            })

            //console.log("[uploadfolderdeleg] blob %o", blob);
            return blob;
            //return blob;        
    }
}