const readableContentType = {
    "application/pdf": "PDF Document",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "Microsoft Word Document",
    "application/msword": "Microsoft Word Document",
    "application/vnd.ms-excel": "Microsoft Excel Worksheet",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "Microsoft Excel Worksheet",
    "application/vnd.ms-powerpoint": "Microsoft PowerPoint Presentation",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": "Microsoft PowerPoint Presentation",
    "text/csv": "CSV File",
    "text/plain": "Text Document",
    "image/jpeg": "JPEG File",
    "image/gif": "GIF File",
    "image/png": "PNG File",
    "image/bmp": "BMP File",
    "video/mp4": "MP4 File",
    "video/x-msvideo": "AVI File",
    "audio/mpeg": "MP3 File",
    "application/vnd.americandynamics.acc": "ACC File",
    "application/x-rar-compressed": "RAR File",
    "application/zip": "Compressed (zipped) Folder",
    "application/vnd.google-apps.document": "Microsoft Word Document",
    "application/vnd.google-apps.presentation": "Microsoft PowerPoint Presentation",
    "application/vnd.google-apps.spreadsheet": "Microsoft Excel Worksheet",

};

function capitalize(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export class StorageSearchResource {
    id: string;
    absolutePath: string;
    driveType: string;
    displayName: string;
    type: string;
    contentType: string;
    lastModified: Date;
    orgId: string;
    orgType: string;
    fileSize: number;
    ownerId: string;
    ownerName: string;

    get icon(): string {
        if (this.type == "Folder") return 'folder';
        return this.getIcon(this.contentType);
    }

    get isFromOrgUserDrive(): boolean {
        return this.driveType === "orguser-drive";
    }

    get isFolder(): boolean {
        return this.type === "Folder";
    }

    /**Returns path of the parent folder (the folder containing this resource). 
     * If the resource is a folder, returns the folder path without the dummy file included. */ 
    get folderPath(): string {
        // ? this.absolutePath.substring(0, this.absolutePath.lastIndexOf('/', this.absolutePath.lastIndexOf('/')-1)) 
        return this.isFolder 
        ? this.absolutePath.slice(0, -2) 
        : this.absolutePath.substring(0, this.absolutePath.lastIndexOf('/'));
    }

    protected getIcon(mimeType: string): string {
        if (mimeType == null) return "file-o";
        switch (mimeType) {
            case "application/pdf":
                return "file-pdf-o";
            case "application/msword":
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                return "file-word-o";
            case "application/vnd.ms-excel":
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            case "text/csv":
                return "file-excel-o";
            case "text/plain":
                return "file-text-o";
            case "image/jpeg":
            case "image/gif":
            case "image/png":
            case "image/bmp":
                return "file-photo-o";
            case "video/mp4":
            case "video/quicktime":
            case "video/x-msvideo":
                return "file-movie-o";
            case "audio/mpeg":
            case "audio/webm":
            case "audio/aac":
                return "file-sound-o";
            case "application/vnd.rar":
            case "application/zip":
                return "file-zip-o";
            default:
                return "file-o";
        }
    }

    static parse(dto: any): StorageSearchResource {
        if (dto == null) return null;

        if (dto.originPath) return SharedStorageSearchResource.parse(dto);
    
        const res = new StorageSearchResource();
        res.id = dto.id;
        res.absolutePath = dto.absolutePath;
        res.driveType = dto.driveType;
        res.displayName = dto.displayName;
        res.type = dto.type;
        if (!!dto.contentType) res.contentType = readableContentType[dto.contentType] || capitalize(dto.contentType.split('/')[0]);
        res.lastModified = dto.lastModified;
        res.orgId = dto.orgId;
        res.orgType = dto.orgType;
        res.fileSize = dto.fileSize;
        res.ownerId = dto.ownerId;
        res.ownerName = dto.ownerName;

        return res;
    }

    toRedirectResource() : SearchRedirectResource {
        let redirectRes = new SearchRedirectResource();
        redirectRes.drive = this.driveType;
        redirectRes.isFolder = this.isFolder;
        redirectRes.folderPath = this.folderPath;
        redirectRes.name = this.displayName;
        redirectRes.originContainer = this.orgId;
        redirectRes.originPath = this.isFolder ? this.folderPath : this.absolutePath;
        redirectRes.path = redirectRes.originPath;
        return redirectRes;
    }
}

export class SharedStorageSearchResource extends StorageSearchResource {
    originOrgId: string;
    originPath: string;

    get originFolderPath(): string {
        // ? this.originPath.substring(0, this.originPath.lastIndexOf('/', this.originPath.lastIndexOf('/')-1))
        return this.isFolder 
        ? this.originPath.slice(0, -2) 
        : this.originPath.substring(0, this.originPath.lastIndexOf('/'));
    }

    static parse(dto: any): SharedStorageSearchResource {
        if (dto == null) return null;
    
        const res = new SharedStorageSearchResource();
        res.id = dto.id;
        res.absolutePath = dto.absolutePath;
        res.driveType = dto.driveType;
        res.displayName = dto.displayName;
        res.type = dto.type;
        if (!!dto.contentType) res.contentType = readableContentType[dto.contentType] || capitalize(dto.contentType.split('/')[0]);
        res.lastModified = dto.lastModified;
        res.orgId = dto.orgId;
        res.orgType = dto.orgType;
        res.fileSize = dto.fileSize;
        res.ownerId = dto.ownerId;
        res.ownerName = dto.ownerName;
        res.originOrgId = dto.originOrgId;
        res.originPath = dto.originPath;

        return res;
    }

    toRedirectResource() : SearchRedirectResource {
        let redirectRes = new SearchRedirectResource();
        redirectRes.drive = this.driveType;
        redirectRes.isFolder = this.isFolder;
        redirectRes.folderPath = this.folderPath;
        redirectRes.name = this.displayName;
        redirectRes.originContainer = this.originOrgId;
        redirectRes.originPath = this.isFolder ? this.originFolderPath : this.originPath;
        redirectRes.path = this.isFolder ? this.folderPath : this.absolutePath;
        return redirectRes;
    }
}

export class SearchRedirectResource {
    drive: string;
    folderPath: string;
    originPath: string;
    originContainer: string;
    path: string;
    name: string;
    isFolder: boolean;
}