import { FileObjState } from './../../model/file-obj.state';
import { Component, OnInit } from "@angular/core";
import { Explorer } from "../../model/explorer.model";
import { Resource } from "../../model/resource";
import { CloudFolder } from "../../model/cloud-folder";
import { SharedFolder } from "../../model/shared-folder";
import { EnterpriseSelector } from "../../states/enterprise.state.selector";
import { OrgType } from "../../enum/org-type";
import { ExplorerService } from "../../services/explorer.service";
import { UserDataSelector } from "../../states/user-data.state.selector";
import { Subscription, distinctUntilChanged } from "rxjs";
import { MatDialogRef } from "@angular/material/dialog";
import { CloudFile } from "../../model/cloud-file";
import { OriginFolder } from 'app/core/model/origin-folder';
import { SharedFile } from 'app/core/model/shared-file';
import { OriginFile } from 'app/core/model/origin-file';

@Component({
    selector: 'cloud-file-explorer',
    templateUrl: './cloud-file-explorer.component.html',
    styleUrls: ['./cloud-file-explorer.component.scss']
  })
  
  export class CloudFileExplorerComponent implements OnInit {

    orgId: string;
    orgType: OrgType;

    // tabs
    hasMyDrive: boolean = true;
    hasSharedDrive: boolean = true;
    selectedDrive: string = 'local';

    private _foldersSub: Subscription;
    private _breadcrumbsSub: Subscription;

    explorer: Explorer;
    redirectResource: Resource;
    isLoadingResource: boolean = false;
    resources: Resource[] = [];
    breadCrumbs: CloudFolder[] | SharedFolder[] = [];

    get currentDrive() {
        return this.explorer.currentDrive;
    }

    get selectedFolder(): CloudFolder | SharedFolder | OriginFolder {
        return this.currentDrive?.currentFolder;
    }

    get onGoToFolder() {
        return this._onGoToFolder.bind(this);
    }

    get onOpen() {
        return this._onOpen.bind(this);
    }

    get refresh() {
        return this._refresh.bind(this);
    }

    constructor(
        private enterpriseSelector: EnterpriseSelector, 
        private explorerService: ExplorerService,
        private userDataSelector: UserDataSelector,
        public dialogRef: MatDialogRef<CloudFileExplorerComponent>) {}
    
    ngOnInit(): void {
        this.orgId = this.enterpriseSelector.getCurrentOrgId();
        this.orgType = this.enterpriseSelector.getCurrentOrgType();

        if (!this.orgId) return;
        this.explorer = this.explorerService.buildPickers(
        this.orgId,
        this.orgType,
        this.userDataSelector.userId
        );

        this.explorer.getDrive('drive')
        .checkReadAccessDeleg().then((hasAccess) => {
          this.hasSharedDrive = hasAccess;
          if (!hasAccess && this.selectedDrive == 'drive') this.switchDrives('shared');
        }); 
        // hide My Drive if not enabled
        let drive = this.explorer.getDrive('local');
        if (drive == null) {
          this.hasMyDrive = false; 
          this.switchDrives('shared');
        }
        else {
          // default is My Drive
          this.explorer.switchDrive(drive);
          drive.checkReadAccessDeleg().then((hasAccess) => {
            this.hasMyDrive = hasAccess;
            if (!this.hasMyDrive) this.switchDrives('shared');
          }); 
        }

        if (this._foldersSub) {
            this._foldersSub.unsubscribe();
        }
        this._foldersSub = this.currentDrive.resourcesSubject
            .pipe(
              distinctUntilChanged((prev, curr) => {
                if (curr == null) return true;
                if (prev == null && curr != null) return false;
                return JSON.stringify(prev) === JSON.stringify(curr);
              })
            )
            .subscribe((res) => {
              this.resources = res;
            });
          this._refresh(true, true).finally(() => {
            this.isLoadingResource = false;
          });

          this._breadcrumbsSub = this.currentDrive.breadCrumbsSubject
          .pipe(
            distinctUntilChanged((prev, curr) => {
              if (curr == null) return true;
              if (prev == null && curr != null) return false;
              return JSON.stringify(prev) === JSON.stringify(curr);
            })
          )
          .subscribe((res) => {
            this.breadCrumbs = res;
          });

    }

    private _onOpen(resource: Resource){
      if (resource.isFile) {
        this._onItemDblClick(resource as CloudFile | SharedFile | OriginFile);
      } else {
        this._onGoToFolder(resource as CloudFolder | SharedFolder);
      }
    }

    _onGoToFolder(folder: CloudFolder | SharedFolder, forward: boolean = true) {
      this.isLoadingResource = true;
      return this.currentDrive
          .goToFolder(folder, forward)
          .finally(() => {
          this.isLoadingResource = false;
          });
    }
  
    private _refresh(
    showLoading: boolean = false,
    toRoot: boolean = false
    ): Promise<CloudFolder | SharedFolder> {
    this.isLoadingResource = showLoading;
    return this.currentDrive
        .list(
        toRoot
            ? null
            : this.selectedFolder && !this.selectedFolder.isRoot
            ? this.selectedFolder
            : null
        )
        .catch((err) => {
        console.error(err);
        return null;
        })
        .finally(() => {
        if (this.isLoadingResource == true) {
            this.isLoadingResource = false;
        }
        });
    }

    _onItemDblClick(res: CloudFile | SharedFile | OriginFile) {
      let file = new FileObjState();
        file.size = res.sizeInBytes;
        file.name = res.name
        file.extension = res.extension;
        file.mimeType = res.contentType;
        file.icon = res.icon;
        file.cloudContainerName = res.container;
        if (res instanceof SharedFile) {
          file.url = res.originPath;
        } else {
          file.url = res.path;
        }
      this.dialogRef.close(file);
    }

    switchDrives(driveName: string) {
      if (!driveName || this.selectedDrive === driveName) return;
      this.selectedDrive = driveName;
      let drive = this.explorer.getDrive(driveName);
      this.explorer.switchDrive(drive ?? this.explorer.defaultDrive);
      this._onDriveSwitch();
    }

    async _onDriveSwitch() {
        this.currentDrive.clearBreadCrumbs();
    
        // reset
        this.resources = [];
        this.isLoadingResource = true;
        try {
          //dynamic expand tree
          if (this._breadcrumbsSub) {
            this._breadcrumbsSub.unsubscribe();
          }
          this._breadcrumbsSub = this.currentDrive.breadCrumbsSubject
            .pipe(
              distinctUntilChanged((prev, curr) => {
                if (curr == null) return true;
                if (prev == null && curr != null) return false;
                return JSON.stringify(prev) === JSON.stringify(curr);
              })
            )
            .subscribe((res) => {
              this.breadCrumbs = res;
            });
    
          //update tree
          if (this._foldersSub) {
            this._foldersSub.unsubscribe();
          }
          this._foldersSub = this.currentDrive.resourcesSubject
            .pipe(
              distinctUntilChanged((prev, curr) => {
                if (curr == null) return true;
                if (prev == null && curr != null) return false;
                return JSON.stringify(prev) === JSON.stringify(curr);
              })
            )
            .subscribe((res) => {
              this.resources = res;
            });
          await this._refresh(true, true).finally(() => {
            this.isLoadingResource = false;
          });
        } catch (ex) {
          console.error(ex);
          this.isLoadingResource = false;
        }
    }
  }