import { InjectionToken, Injector } from "@angular/core";
import { MenuItem } from "../../core/ui/menu-item";
import { Plugin } from "../plugin.abstract";
import * as _ from "lodash";
import { ToolsAuthService } from "../common-services/tools-auth.service";
import { AbstractStateSelector } from "../../core/states/abstract.state.selector";
import { ToolsLocalStateSelector } from "../common-services/tools-localstate.selector";
export class IETPlugIn extends Plugin {
    get tapRedirectPath(): string {
        return null;
    }

    get launchToRedirectPath(): string {
        return null;
    }
    get launchToRedirectPriority(): number {
        return -1;
    }
    get launchToRedirectEnabled(): boolean {
        return false;
    }
    
    get appId(): string {
        return "ext-iet-plugin";
    }
    get path(): string {
        return "incomeexpense";
    }
    get iconUrl(): string {
        return "account_balance_wallet";
    }
    get loadModule(): Promise<any> {
        return import('./income-and-expense.module').then(m => m.IncomeExpenseModule);
    }

    private _authService: ToolsAuthService;
    private _elstateselector: AbstractStateSelector;
    private _toolstateselector: ToolsLocalStateSelector;
    private _rootMenu: MenuItem;

    private _currentFeeds: any;

    constructor(injector: Injector) {
        super(injector);

        /// === Use parent inject method to inject your custom service class ==
        /// The injected service provider should not assume to be single instance,
        /// the Angular injector may create different instance because of the lazy load module.
        if (!this._authService) {
            this._authService = this.inject(ToolsAuthService);
            console.log("[Plugin] %s, injected: %o", this.appId, this._authService);
        }
        if (!this._elstateselector) {
            this._elstateselector = this.inject(AbstractStateSelector);
        }
        if (!this._toolstateselector) {
            this._toolstateselector = this.inject(ToolsLocalStateSelector);
        }

        // init current feeds
        // this._currentFeeds = this._sampleService.getFeeds();
    }

    onOrgSwitched(args: any): Promise<void> {
        if (!args) {return Promise.resolve(); }

        let userId = args.userId;
        let industryId = args.industryId.toUpperCase();
        let role = args.role.toUpperCase();

        console.log("[Plugin] %s, onOrgSwitched: %o", this.appId, { userId, industryId, role });
        this._rootMenu = MenuItem.create(this.appId, this.path, this.iconUrl, "MAIN.INCOME_AND_EXPENSE");
        this._rootMenu.isEnabled =  industryId !== "PERSONAL";
        if (industryId === 'REPRODUCTIVE' && role === 'CLIENT') {
            this._rootMenu.isEnabled = false;
        }
        this.onMenuUpdated$.next([this._rootMenu]);
        return Promise.resolve();
    }

    onFeedsReceived(data: any): Promise<void> {
        console.log("[Plugin] %s, onFeedsReceived: %o", this.appId, data);
        if (_.isEqual(data, this._currentFeeds)) {return Promise.resolve(); }

        // find for new feeds
        let newFeeds = _.differenceBy(data, this._currentFeeds, (x) => {
            return x.id;
        });
        console.log("[Plugin] %s, New feeds: %o", this.appId, newFeeds);

        // update menu
        if (newFeeds) {
            this._rootMenu.showBadge = true;
            this.onMenuUpdated$.next([this._rootMenu]);
        }

        // this._sampleService.saveFeeds(data);
        // console.log("[Plugin] %s, _sampleService: %o", this.appId, this._sampleService);
    }

    onCardAction(actionName: string, args: any){
        return null;
    }

    onToolDisabled(): Promise<void> {
        return Promise.resolve();
    }
}
