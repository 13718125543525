import { InMemMessageState } from './../states/inmem.message.state';
import { InMemMessageSelector } from './../states/inmem.message.selector';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppStateSelector } from './../states/app.state.selector';
import { Injectable } from "@angular/core";
import { BaseHub } from "./base.hub";
import { environment as ENV } from "../../../environments/environment";
import { HubEvent } from "./hub.event";
import { Subscription, Observable, of, throwError, forkJoin, from } from "rxjs";
import { Select } from "@ngxs/store";

import {
  map,
  tap,
  flatMap,
  filter,
  distinct,
  mergeMap,
} from "rxjs/operators";
import { SubSink } from "subsink";
import { HubStateSelector } from "../states/hub.state.selector";
import { MessagingSelector } from "../states/messaging.state.selector";
import { AuthStateSelector } from "../states/auth.state.selector";
import { NetworkService } from "../services/network.service";
import { EnterpriseSelector } from "../states/enterprise.state.selector";
import { UserDataSelector } from "../states/user-data.state.selector";
import { ApiService } from "../api/api.service";
import { HubState } from "../states/hub.state";
import {
  RoomHubConnectionState,
  HubConnectionState,
  HubConnectionStatus,
  HubHandshakeStatus,
} from "../model/hubConnection.state";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import { MessagingState } from "../states/messaging.state";
import { MessageState, DecryptStatus, MsgReaction } from "../model/message.state";
import { RoomState } from "../model/room.state";
import { ParticipantState } from "../model/participant.state";
import { ParticipantStatus } from "../model/participant.model";
import { MessageSendStatus, MessageStatus, MessageType } from "../model/message.model";
import { UIState } from "../states/ui.state";
import {
  FileObjState,
  FileDownloadStatus,
} from "../model/file-obj.state";
import { pocolog } from "pocolog";
import { FileState } from "../states/file.state";
import { FileStateSelector } from "../states/file.state.selector";
import { MediaService } from "../services/media.service";
import { PubSub } from '../services/pubsub.service';
import { FcpService } from '../fcp/fcp.service';
import { UserDataState } from '../states/user-data.state';
import { OrgUserState } from '../model/org.state';
import * as _ from "lodash";
import { OrgHub } from './org.hub';
import { TokenProvider } from '../services/jwt-token.provider';
import { MsgCursor } from '../model/msgCursor';
import { StorageLocationType } from '../states/storage-location-type.enum';
import { OrgType } from '../enum/org-type';
import { Resource } from '../model/resource';

@Injectable({
  providedIn: "root",
})
export class RoomHub extends BaseHub {
  //Room hub Listener Events
  private MESSAGING: string = "msg";
  private ROOM: string = "room";
  private MSG_FLAG: string = "msg-flag";
  //Room hub Trigger Events
  NEW_MSG: string = "new-msg";
  UPDATE_MSG: string = "update-msg";
  UPDATE_MSG_REACTIONS: string = "update-msg-reactions";
  DELETE_MSG: string = "delete-msg";
  FAIL_TO_SEND_MSG: string = "fail-to-send-msg";
  LIST_ROOM: string = "ls-room";
  NEW_ROOM: string = "new-room";
  UPDATE_ROOM: string = "update-room";
  JOIN_ROOM: string = "join-room";
  INVITE_ROOM: string = "invite-room";
  LEAVE_ROOM: string = "leave-room";
  CHANGE_ADMIN: string = "change-admin";
  UPDATE_PARTICIPANT: string = "update-participant";
  UPDATE_ROOMS_PARTICIPANTS: string = "update-rooms-participants";
  ADD_MSG_FLAG: string = "add-msg-flag";
  REMOVE_MSG_FLAG: string = "remove-msg-flag";
  //Room hub method name
  private SEND_TEXT_METHOD: string = "send-text";
  private FOWARD_MEDIA_WITH_URL: string = "forward-media-with-url";
  private SEND_STORAGE_MEDIA_MESSAGE: string = "send-storage-media-message";
  private NEW_DIRECT_ROOM_METHOD: string = "new-direct-room";
  private NEW_GROUP_ROOM_METHOD: string = "new-group-room";
  private HANDSHAKE_METHOD: string = "handshake";
  private HANDSHAKE_BY_ORG_METHOD: string = "handshake-by-org-2";
  private JOIN_ROOM_METHOD: string = "join-room";
  private INVITE_ROOM_METHOD: string = "invite-room";
  private LEAVE_ROOM_METHOD: string = "leave-room";
  private REMOVE_ROOM_METHOD: string = "remove-room";
  private UPDATE_ROOM_METHOD: string = "update-room";
  private KICK_PARTICIPANT_METHOD: string = "kick-participant";
  private DELETE_MSG_METHOD: string = "delete-msg";
  private HISTORY_ROOM_METHOD: string = "room-history";
  private LATEST_ROOM_MSGS_METHOD: string = "latest-msgs";
  // private ROOM_MSGS_METHOD: string = "room-msgs";
  private ADD_MSG_FLAG_METHOD: string = "msg-flag-add";
  private REMOVE_MSG_FLAG_METHOD: string = "msg-flag-remove";
  private EDIT_MSG_METHOD: string = "edit-msg";
  private GET_ROOM_METHOD: string = "get-room-by-matrix-id";
  private HIDE_ROOM_METHOD: string = "hide-room";
  private REACT_MSG_METHOD: string = "react-msg";
  private EXPORT_METHOD: string = "export";

  private _subs: SubSink;
  private _pendingTextSub: Subscription;
  private _pendingMeetSub: Subscription;
  private _pendingCalendarSub: Subscription;
  private _pendingMediaSub: Subscription;
  private _activeRoomId: string;
  private _isFirstLogin: boolean = false;

  private _hubStateSelector: HubStateSelector;
  //private _orgSelector: EnterpriseStateSelector;
  public static MAX_MEET_PARTICIPANT: number = 30;
  private _maxResendAttempt: number = 1;
  private MAX_DECRYPT_ATTEMPT: number = 5;

  constructor(
    msgSelector: MessagingSelector,
    hubSelector: HubStateSelector,
    public authSelector: AuthStateSelector,
    networkService: NetworkService,
    pubSub: PubSub,
    appStateSelector: AppStateSelector,
    userDataSelector: UserDataSelector,
    tokenProvider: TokenProvider,
    private enterpriseSelector: EnterpriseSelector,
    private inMemMsgSelector: InMemMessageSelector,
    private fileStateSelector: FileStateSelector,
    private api: ApiService,
    private mediaService: MediaService,
    private fcp: FcpService,
    private orgHub: OrgHub,
    private snackBar: MatSnackBar
  ) {
    super(
      "roomHub",
      ENV.roomHubLink,
      msgSelector,
      appStateSelector,
      authSelector,
      networkService,
      pubSub,
      userDataSelector,
      tokenProvider
    );
    this._hubStateSelector = hubSelector;
    //this._orgSelector = enterpriseSelector;
    //console.log("[MessagingStateSnapshot] %o", msgSnapshot);
    this._subs = new SubSink();

    console.log("[roomhub] init");
    this.initRoomHub();
    //this.subscribeOnHubConnected();
    //this.subscribeJoinRoom();
  }

  //#region  ngxs operators

  @Select(HubState.roomhub)
  roomhubConnection$: Observable<RoomHubConnectionState>;

  @Select(MessagingSelector.encryptedMessages)
  encryptedMessages$: Observable<MessageState[]>;

  @Select(MessagingSelector.encryptedMsgPreviews)
  encryptedMsgPreviews$: Observable<MessageState[]>;

  @Select(UserDataState.publicKey)
  onPublicKeyChanged$: Observable<string>;

  @Emitter(HubState.setRoomHubConnection)
  setRoomHubConnection: Emittable<RoomHubConnectionState>;

  @Emitter(HubState.updateBatchNumber)
  updateBatchNumber: Emittable<string>;

  // @Emitter(DecryptedMsgState.updateMsgId)
  // updatePlaintext: Emittable<PlaintextState>;

  @Emitter(HubState.updateRoomHubHandshakeStatus)
  updateHandshakeStatus: Emittable<HubHandshakeStatus>;

  get setHubConnectionStatus(): Emittable<HubConnectionState> {
    return this.setRoomHubConnection;
  }
  get hub$(): Observable<HubConnectionState> {
    return this.roomhubConnection$;
  }
  get hubSnapshot(): HubConnectionState {
    return this._hubStateSelector.getRoomHub();
  }
  get setHandshakeStatus(): Emittable<HubHandshakeStatus> {
    return this.updateHandshakeStatus;
  }
  get userPublicKey(): string {
    return this.userDataSelector.publicKey;
  }

  // @Select(MessagingState.pendingParticipation)
  // pendingParticipation$: Observable<ParticipantState[]>;

  @Select(MessagingState.allMessages)
  messages$: Observable<MessageState[]>;

  @Select(MessagingState.pendingTextMessages)
  pendingTextMessages$: Observable<MessageState[]>;

  @Select(MessagingState.pendingMeetMessages)
  pendingMeetMessages$: Observable<MessageState[]>;

  @Select(MessagingState.pendingMediaMessages)
  pendingMediaMessages$: Observable<MessageState[]>;

  @Select(MessagingState.pendingCalendarMeetMessages)
  pendingCalendarMeetMessages$: Observable<MessageState[]>;

  @Select(MessagingState.rooms)
  rooms$: Observable<RoomState[]>;

  @Select(MessagingSelector.currentUserParticipants)
  currentUserParticipants$: Observable<ParticipantState[]>;

  @Emitter(MessagingState.addOrUpdateMessage)
  public addOrUpdateMessage: Emittable<MessageState>;

  @Emitter(MessagingState.addOrUpdateMsgReactions)
  public addOrUpdateMsgReactions: Emittable<{ msgId: string; reactions: MsgReaction[] }>;

  @Emitter(FileState.addOrUpdateFile)
  addOrUpdateFile: Emittable<FileObjState>;

  @Emitter(MessagingState.addOrUpdateMessages)
  public addOrUpdateMessages: Emittable<MessageState[]>;

  @Emitter(MessagingState.addOrUpdateMsgPreviews)
  public addOrUpdateMsgPreviews: Emittable<MessageState[]>;

  @Emitter(MessagingState.updateSendStatus)
  public updateSendStatus: Emittable<MessageState>;

  @Emitter(MessagingState.deleteMessage)
  public deleteMessage: Emittable<MessageState>;

  @Emitter(MessagingState.addOrUpdateRooms)
  public addOrUpdateRooms: Emittable<RoomState[]>;

  @Emitter(MessagingState.addOrUpdateRoom)
  public addOrUpdateRoom: Emittable<RoomState>;

  @Emitter(MessagingState.removeRoom)
  public removeRoom: Emittable<RoomState>;

  @Emitter(MessagingState.addOrUpdateParticipants)
  public addOrUpdateParticipants: Emittable<ParticipantState[]>;

  @Emitter(MessagingState.addOrUpdateParticipant)
  public addOrUpdateParticipant: Emittable<ParticipantState>;

  @Select(UIState.activeRoom)
  activeRoom$: Observable<string>;

  @Emitter(MessagingState.removeMsgFlag)
  removeMsgFlag: Emittable<{ msgId: string, flags: string[] }>;

  @Emitter(MessagingState.addOrUpdateMsgFlags)
  addOrUpdateMsgFlags: Emittable<{ msgId: string, flags: string[] }[]>

  @Emitter(InMemMessageState.setMsgCursor)
  setMsgCursor: Emittable<{ roomMatrixId: string, cursor: MsgCursor }>

  @Emitter(InMemMessageState.setForwardCursor)
  setForwardCursor: Emittable<{ roomMatrixId: string, cursor: string }>

  @Emitter(InMemMessageState.setBackwardCursor)
  setBackwardCursor: Emittable<{ roomMatrixId: string, cursor: string }>
  //#endregion

  get pendingParticipation$(): Observable<ParticipantState[]> {
    return this.currentUserParticipants$.pipe(
      map((r) => r.filter((p) => p.status === ParticipantStatus.Pending))
    );
  }
  //#endregion

  //#region  Private functions

  private initRoomHub() {
    let self = this;
    if (self._subs) self._subs.unsubscribe();
    self._subs.sink = this.userDataSelector
      .onLoggedInChanged()
      .subscribe(() => {
        this._isFirstLogin = true;
      });

    self._subs.sink = self.activeRoom$.subscribe((res) => {
      this._activeRoomId = res;
    });

    self._subs.sink = self.onHubConnected$.subscribe(() => {
      // console.log("[roomhub] onHubConnected$");
      if (!self.connection) {
        // console.log("[roomhub] hub connection is null");
        return;
      }

      // console.log("[roomhub] register messaging event");
      self.register(self.MESSAGING);
      // console.log("[roomhub] register room event");
      self.register(self.ROOM);

      self.register(self.MSG_FLAG);
      // console.log("[roomhub] start hanshake");   
      // let orgId = this.enterpriseSelector.getCurrentOrgId();
      // if (orgId) {
      //   self.handshake(orgId);
      // }
    });

    self._subs.sink = self.channelEvent$(self.MESSAGING).subscribe((event) => {
      // console.log("[roomhub]-onHubEvent-msg, %o", event);
      self.onMessageEventReceived(event);
    });

    self._subs.sink = self.channelEvent$(self.ROOM).subscribe((event) => {
      // console.log("[roomhub]-onHubEvent-room, %o", event);
      self.onRoomEventReceived(event);
    });

    self._subs.sink = self.channelEvent$(self.MSG_FLAG).subscribe(event => {
      //console.log("[roomhub]-onHubEvent-msg-flag, %s", event);
      self.onMsgFlagReceived(event);
    });

    self._subs.sink = self.pendingParticipation$.subscribe(
      (participantStates: ParticipantState[]) => {
        // console.log("[roomhub]-pendingJoined, %o", participantStates);
        if (!participantStates) return;
        if (participantStates.length == 0) return;
        let promises: Promise<any>[] = [];
        participantStates.forEach((p) => {
          promises.push(this.invoke(this.JOIN_ROOM_METHOD, p.roomId));
        });

        Promise.all(promises);
      }
    );

    self._subs.sink = self.onHubConnectionStatusChanged.subscribe((state) => {
      if (!state) return;

      if (this.isConnected) {
        this.onPendingTextMessage();
      } else if (this.isOffline) {
        if (this._pendingTextSub) this._pendingTextSub.unsubscribe();
      }
    });

    if (this.networkService.isOnline) {
      this.onPendingMeetMessage();
      this.onPendingMediaMessage();
      this.onPendingCalendarMeetMessage();
    }

    self._subs.sink = this.networkService.onNetworkChanged.subscribe(
      (connected) => {
        if (connected == true) {
          this.onPendingMeetMessage();
          this.onPendingMediaMessage();
          this.onPendingCalendarMeetMessage();
        } else {
          //stop sending if no internet connection
          if (this._pendingMeetSub) this._pendingMeetSub.unsubscribe();
          if (this._pendingMediaSub) this._pendingMediaSub.unsubscribe();
          if (this._pendingCalendarSub) this._pendingCalendarSub.unsubscribe();
        }
      }
    );
    self._subs.sink = this.encryptedMessages$.subscribe(async eMsgs => {
      console.info("[roomHub] ##### E2E #### total msgs to be decrypted pending: %s", eMsgs.length);
      if (eMsgs.length == 0) return;
      if (!this.userDataSelector.publicKey) return;

      let dMsgs = await this.decryptAndSaveMsgsAsync(eMsgs);
      this.addOrUpdateMessages.emit(dMsgs).toPromise();
    })

    self._subs.sink = this.encryptedMsgPreviews$.subscribe(async eMsgs => {
      console.info("[roomHub] ##### E2E #### total msg previews to be decrypted pending: %s", eMsgs.length);
      if (eMsgs.length == 0) return;
      if (!this.userDataSelector.publicKey) return;

      let dMsgs = await this.decryptAndSaveMsgsAsync(eMsgs);
      this.addOrUpdateMsgPreviews.emit(dMsgs).toPromise();
    })
    // file handling not needed
  }

  private decryptAndSaveMsgsAsync(msgs: MessageState[]) {
    msgs.forEach(m => {
      if (!m.content || m.content.trim() == "") {
        m.isDecrypted = DecryptStatus.NotApplicable;
        m.plaintext = null;
      } else {
        if (m.isDecrypted === DecryptStatus.Success
          && !m.plaintext
          && m.content) {
          //revert back to pending if found success status without plaintext
          m.isDecrypted = DecryptStatus.Pending;

        } else {
          const decrypted = this.fcp.decryptMsg(m.content);
          if (decrypted && decrypted.trim() != "") {
            //m.content = decrypted;
            m.plaintext = decrypted;
            m.isDecrypted = DecryptStatus.Success;
            //console.log("decryptAndSaveMsgsAsync: success decrypt: %s", decrypted);
          } else {
            if (!m.decryptAttempt) m.decryptAttempt = 0;
            m.decryptAttempt++;
            m.plaintext = null;

            if (m.decryptAttempt >= this.MAX_DECRYPT_ATTEMPT) {
              m.isDecrypted = DecryptStatus.Fail;
            }
          }
        }
      }
    });

    return msgs;
  }


  private onPendingTextMessage() {
    if (this._pendingTextSub) this._pendingTextSub.unsubscribe();

    const self = this;

    this._pendingTextSub = this.pendingTextMessages$
      .pipe(filter(p => !!p), flatMap(p => p), distinct(p => p.tempId))
      .subscribe(msg => {
        if (msg.sendStatus == MessageSendStatus.PendingToSent && msg.status != MessageStatus.Deleted) {
          this.invokeSendTextMethod(_.clone(msg));
        } else {
          console.warn("[room.hub] intend to send a deleted or not pending to send message: %o", msg);
        }
      });
  }

  private onPendingMeetMessage() {
    if (this._pendingMeetSub) this._pendingMeetSub.unsubscribe();

    const self = this;
    this._pendingMeetSub = this.pendingMeetMessages$
      .pipe(filter(p => !!p), flatMap(p => p), distinct(p => p.tempId))
      .subscribe(msg => {
        self.invokeSendMeetApi(msg);

      });
  }

  private onPendingMediaMessage() {
    if (this._pendingMediaSub) this._pendingMediaSub.unsubscribe();

    const self = this;
    this._pendingMediaSub = this.pendingMediaMessages$
      .pipe(filter(p => !!p), flatMap(p => p), distinct(p => p.tempId))
      .subscribe(msg => {
        if(msg.fileFromCloud){
          self.invokeSendStorageMediaApi(msg);
        }else{
          self.invokeSendMediaApi(msg);
        }
        

      });
  }

  private onPendingCalendarMeetMessage() {
    if (this._pendingCalendarSub) this._pendingCalendarSub.unsubscribe();

    const self = this;
    this._pendingCalendarSub = this.pendingCalendarMeetMessages$
      .pipe(
        filter((p) => !!p),
        mergeMap((p) => p),
        distinct((p) => p.tempId)
      )
      .subscribe((msg) => {
        self.invokeSendCalendarMeetApi(msg);
      });
  }

  resendMessage(msg: MessageState) {
    if (!msg) return;

    switch (msg.type) {
      case MessageType.Text:
        return this.invokeSendTextMethod(msg, true);
      case MessageType.Meet:
        return this.invokeSendMeetApi(msg);
      case MessageType.Calendar:
        return this.invokeSendCalendarMeetApi(msg);
      case MessageType.File:
      case MessageType.Audio:
      case MessageType.Image:
        return this.invokeSendMediaApi(msg);
      default:
        return;
    }

  }

  private checkSendAttempt(msg: MessageState) {
    msg.sendAttempt++;
    if (msg.sendAttempt >= this._maxResendAttempt) {
      msg.sendStatus = MessageSendStatus.Failed;
      this.updateSendStatus.emit(msg);
    } else {
      setTimeout(() => {
        msg.sendStatus = MessageSendStatus.PendingToSent;
        this.updateSendStatus.emit(msg);
      }, 5000);
    }
  }

  async invokeSendTextMethod(msg: MessageState, isResend: boolean = false) {
    var toSend: MessageState = _.clone(msg);

    try {
      console.log("[room.hub] sending text: %o", toSend);

      //get again from state to prevent double encrypt
      var inStateMsg = this.msgSelector.getMessage(toSend.id || toSend.tempId);

      if (!inStateMsg) {
        console.error("[room.hub] the intend to send msg was not found in state repository: %o", toSend);
        return;
      } else if (!isResend && inStateMsg.sendStatus != MessageSendStatus.PendingToSent) {
        console.error("[room.hub] the intend to send msg was not in pendingToSent status: %o", inStateMsg);
        return;
      }

      toSend.sendStatus = MessageSendStatus.Sending;
      await this.updateSendStatus.emit(toSend).toPromise();
      console.log("[room.hub] set msg as sending: %o", toSend);

      let encryptedMsg: string = "";
      let isEncrypted: boolean = false;

      if (toSend.plaintext && toSend.plaintext.trim() != "") {
        console.log("[room.hub] encrypting msg: %o", toSend);

        var encrypted = await this.encryptText(msg.roomId, msg.plaintext);

        encryptedMsg = encrypted ? encrypted : toSend.plaintext;
        isEncrypted = encrypted ? true : false;
      }

      console.log("[room.hub] invoke api: %o", {
        roomMatrixId: toSend.roomMatrixId,
        tempId: toSend.tempId,
        encryptedMsg,
        showCard: toSend.showCard,
        isEncrypted
      });

      this.invoke$(
        this.SEND_TEXT_METHOD,
        toSend.roomMatrixId,
        toSend.tempId,
        encryptedMsg,
        toSend.showCard,
        isEncrypted,
        toSend.replyTo
      )
        .pipe(
          map(event => {
            if (!event || !event.isSuccess) throw new Error(event.error);
            return this.parseMessageState(event.dto);
          })
        )
        .subscribe(
          (sentMessage: MessageState) => {
            if (sentMessage) {
              toSend.sendStatus = MessageSendStatus.Sent;
              toSend.id = sentMessage.id;
              toSend.content = sentMessage.content;
              toSend.serverTimeStamp = sentMessage.serverTimeStamp;
              this.addOrUpdateMessage.emit(toSend);
            } else {
              this.checkSendAttempt(toSend);
            }
          },
          err => {
            this.checkSendAttempt(toSend);
            console.error(err);
          }
        );
    } catch (err) {
      this.checkSendAttempt(toSend);
      console.error(err);
    }
  }

  async invokeSendMediaApi(msg: MessageState) {
    try {
      var toSend: MessageState = _.clone(msg);

      const file = this.fileStateSelector.getFile(toSend.mediaId);
      // console.log("[invokeSendMediaApi] %s", msg);
      toSend.sendStatus = MessageSendStatus.Sending;
      // prepare subscription for fileUpload progress
      this.mediaService.initFileUploadProgress(toSend.mediaId);

      await this.addOrUpdateMessage.emit(toSend);

      const orgId = this.enterpriseSelector.getCurrentOrgId();
      const hubConnectionId = this.hubSnapshot.id;
      const userId = this.userDataSelector.userId;

      let encryptedMsg: string = "";
      let isEncrypted: boolean = false;

      if (toSend.plaintext && toSend.plaintext.trim() != "") {
        var encrypted = await this.encryptText(toSend.roomId, toSend.plaintext);

        encryptedMsg = encrypted ? encrypted : toSend.plaintext;
        isEncrypted = encrypted ? true : false;
      }

      const formData = new FormData();
      const newFile = await this.mediaService.convertObjUrlToFile(file.url,
        file.name,
        file.mimeType)
      formData.append("File", newFile, file.name);
      formData.append("RoomId", toSend.roomMatrixId);
      formData.append("OrgUnitId", toSend.orgId);
      formData.append("HubConnectionId", hubConnectionId);
      formData.append("TempId", toSend.tempId);
      formData.append("Content", encryptedMsg);
      formData.append("ShowCard", toSend.showCard ? "true" : "false")
      formData.append("IsEncrypted", isEncrypted ? "true" : "false");
      if (toSend.customDuration != null) {
        formData.append("AudioDuration", toSend.customDuration.toString());
      }
      formData.append("Type", toSend.type.toString());
      if (!!toSend.replyTo) formData.append("ReplyTo", toSend.replyTo);

      return this.mediaService.streamFile("matrix/stream", formData, file).then(
        (res) => {
          const sentMsg = this.parseMessageState(res);
          if (sentMsg) {
            if (toSend.plaintext) {
              sentMsg.isDecrypted = DecryptStatus.Success;
              sentMsg.plaintext = toSend.plaintext;
            }
            const oldMediaId = toSend.mediaId;
            //update msg state
            sentMsg.sendStatus = MessageSendStatus.Sent;
            this.addOrUpdateMessage.emit(sentMsg);


            //update file state
            let file = this.fileStateSelector.getFile(oldMediaId);
            if (file) {
              file.id = sentMsg.mediaId;
              file.url = sentMsg.mediaUrl;
              if (sentMsg.fwt) {
                file.fwt = sentMsg.fwt;
                file.fwtEncoded = true;
              }

              file.downloadedPath = res.toString();
              file.downloadStatus = FileDownloadStatus.Ready;
              this.addOrUpdateFile.emit(file);
            }
          }
        },
        (err) => {
          this.checkSendAttempt(toSend);
          this.snackBar.open("Error uploading file " + file.name, "OK", {
            duration: 3000,
          });

          console.error(err);
        }
      ).catch(err => {
      this.checkSendAttempt(msg);
      console.error(err);
      });
    } catch (err) {
      this.checkSendAttempt(toSend);
      console.error(err);
    }
  }

  async invokeSendStorageMediaApi(msg: MessageState) {
    try {
      console.log("invokeSendStorageMediaApi start");
      const oldMediaId = msg.mediaId;

      msg.sendStatus = MessageSendStatus.Sending;
      await this.updateSendStatus.emit(msg).toPromise();

      const orgId = this.enterpriseSelector.getCurrentOrgId();
      const hubConnectionId = this.connectionId;

      let encryptedMsg: string = "";
      let isEncrypted: boolean = false;

      if (msg.plaintext && msg.plaintext.trim() != "") {
        var receivers = await this.getReceivers(msg.roomId);
        var encrypted = await this.fcp.encryptMsg(msg.plaintext, receivers);

        encryptedMsg = encrypted ? encrypted : msg.plaintext;
        isEncrypted = encrypted ? true : false;
      }

      var newMsg = {
        orgId: msg.orgId,
        roomMatrixId: msg.roomMatrixId,
        type: msg.type,
        tempId: msg.tempId,
        content: encryptedMsg,
        mediaUrl: msg.mediaUrl,
        fileName: msg.mediaName,
        mimeType: msg.mimeType,
        audioDuration: msg.customDuration,
        isEncrypted: isEncrypted,
        hubConnectionId: hubConnectionId,
        cloudContainerName: msg.fileFromCloud
      };

      this.invoke$(this.SEND_STORAGE_MEDIA_MESSAGE, newMsg)
      .pipe(
        map((event) => {
          if (!event || !event.isSuccess) throw new Error(event.error);
          return this.parseMessageState(event.dto);
        })
        ).subscribe(
          ((sentMsg: MessageState) => {
            if (sentMsg) {
              if (msg.plaintext) {
                sentMsg.isDecrypted = DecryptStatus.Success;
                sentMsg.plaintext = msg.plaintext;
              }
              let file = this.fileStateSelector.getFile(oldMediaId);
              //update msg state
              sentMsg.mediaSize = file.size;
              sentMsg.sendStatus = MessageSendStatus.Sent;
  
              this.addOrUpdateMessage.emit(sentMsg);
  
              //update file state
              
              if (file) {
                file.id = sentMsg.mediaId;
                file.url = sentMsg.mediaUrl;
                if (sentMsg.fwt) {
                  file.fwt = sentMsg.fwt;
                  file.fwtEncoded = true;
                }
                this.addOrUpdateFile.emit(file);
              }
            }
          })
        )
    } catch (err) {
      this.checkSendAttempt(msg);
      //msg.sendStatus = SendStatus.Failed;
      //this.addOrUpdateMessage.emit(msg);
      console.error(err);
      return;
    }
  }

  private async invokeSendMeetApi(msg: MessageState) {
    try {
      var toSend: MessageState = _.clone(msg);
      toSend.sendStatus = MessageSendStatus.Sending;
      await this.updateSendStatus.emit(toSend).toPromise();

      const hubConnectionId = this.hubSnapshot.id;
      const userId = this.userDataSelector.userId;

      let encryptedMsg: string = "";
      let isEncrypted: boolean = false;

      if (toSend.plaintext && toSend.plaintext.trim() != "") {
        var encrypted = await this.encryptText(msg.roomId, msg.plaintext);

        encryptedMsg = encrypted ? encrypted : toSend.plaintext;
        isEncrypted = encrypted ? true : false;
      }

      let body = {
        content: encryptedMsg,
        roomId: toSend.roomMatrixId,
        tempId: toSend.tempId,
        hubConnectionId: hubConnectionId,
        type: MessageType.Meet,
        isEncrypted: isEncrypted,
        replyTo: msg.replyTo
      };

      this.api
        .postAsync("meet", body, userId)
        .then
        (res => {
          if (res) {
            toSend.sendStatus = MessageSendStatus.Sent;
            toSend.id = res.id;
            toSend.content = res.content;
            toSend.meetIat = res.iat;
            toSend.meetExp = res.exp;
            toSend.meetUrl = res.url;
            toSend.serverTimeStamp = res.serverReceiveTime ? res.serverReceiveTime : res.serverTimeStamp;
            this.addOrUpdateMessage.emit(toSend);

          } else {
            this.checkSendAttempt(toSend);
            //msg.sendStatus = SendStatus.PendingToSent;
          }
        })
        .catch(err => {
          this.checkSendAttempt(toSend);
          //msg.sendStatus = SendStatus.PendingToSent;
          //this.addOrUpdateMessage.emit(msg);
          console.error(err);
        });
    } catch (err) {
      this.checkSendAttempt(toSend);
      //msg.sendStatus = SendStatus.PendingToSent;
      //this.addOrUpdateMessage.emit(msg);
      console.error(err);
    }
  }

  private async invokeSendCalendarMeetApi(msg: MessageState) {
    try {
      var toSend: MessageState = _.clone(msg);
      toSend.sendStatus = MessageSendStatus.Sending;
      await this.updateSendStatus.emit(toSend).toPromise();

      const hubConnectionId = this.hubSnapshot.id;
      const userId = this.userDataSelector.userId;

      let encryptedMsg: string = "";
      let isEncrypted: boolean = false;

      if (toSend.plaintext && toSend.plaintext.trim() != "") {
        var encrypted = await this.encryptText(toSend.roomId, toSend.plaintext);

        encryptedMsg = encrypted ? encrypted : toSend.plaintext;
        isEncrypted = encrypted ? true : false;
      }

      let body = {
        content: encryptedMsg,
        roomId: toSend.roomMatrixId,
        tempId: toSend.tempId,
        hubConnectionId: hubConnectionId,
        type: MessageType.Calendar,
        isEncrypted: isEncrypted,
        startDate: toSend.startDate,
        endDate: toSend.endDate,
        recurrence: toSend.recurrence,
        meetingTitle: toSend.meetingTitle,
        orgId: toSend.orgId,
        meetingContent: toSend.plaintext,
        replyTo: msg.replyTo
      }

      this.api
        .postAsync("meet/calendar", body, userId)
        .then
        (res => {
          if (res) {
            toSend.sendStatus = MessageSendStatus.Sent;
            toSend.id = res.id;
            toSend.content = res.content;
            toSend.meetIat = res.iat;
            toSend.meetExp = res.exp;
            toSend.meetUrl = res.url;
            toSend.serverTimeStamp = res.serverReceiveTime ? res.serverReceiveTime : res.serverTimeStamp;
            toSend.meetingTitle = res.meetingTitle;
            toSend.startDate =res.startDate;
            toSend.endDate = res.endDate;
            toSend.recurrence = res.recurrence;

            this.addOrUpdateMessage.emit(toSend);

          } else {
            this.checkSendAttempt(toSend);
            //msg.sendStatus = SendStatus.PendingToSent;
          }
        })
        .catch(err => {
          this.checkSendAttempt(toSend);
          //msg.sendStatus = SendStatus.PendingToSent;
          //this.addOrUpdateMessage.emit(msg);
          console.error(err);
        });
    } catch (err) {
      this.checkSendAttempt(toSend);
      //msg.sendStatus = SendStatus.PendingToSent;
      //this.addOrUpdateMessage.emit(msg);
      console.error(err);
    }
  }

  toggleHideRoom(matrixId: string, isHidden: boolean): Promise<ParticipantState> {
    if (!matrixId) return Promise.reject("Toggle hide room missing matrixId.");
    return this.invoke(
      this.HIDE_ROOM_METHOD,
      matrixId,
      isHidden
    ).then((event) => {
      if (!event || !event.isSuccess) throw new Error(event.error);
      let participant = this.parseParticipantState(event.dto);
      if (!participant) return Promise.reject("Error toggling hide room.");
      this.addOrUpdateParticipant.emit(participant);
      return Promise.resolve(participant);
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject("Error toggling hide room.");
    });
  }

  export(
    orgType: OrgType,
    orgId: string,
    roomMatrixId: string,
    startTimeStamp: number,
    endTimeStamp: number,
    storageType: StorageLocationType,
    fileName: string = "",
    includeMedia: boolean = false,
    folderPath: string = "",
    sharedPath: string = "",
    driveId: string = ""
  ): Promise<boolean> {
    if (!roomMatrixId) return Promise.reject("Export chat missing matrixId.");
    let storageName = "user-explorer";
    if (orgType == OrgType.Personal) {
      if (storageType === StorageLocationType.CloudUserFolder) {
        storageName = "personal-explorer";
      } else if (storageType === StorageLocationType.CloudShareFolder) {
        storageName = "personal-share-explorer";
      }
    } else {
      if (storageType === StorageLocationType.CloudEnterprise) {
        storageName = "drive-explorer";
      } else if (storageType === StorageLocationType.CloudUserFolder) {
        storageName = "user-explorer";
      } else if (storageType === StorageLocationType.CloudShareFolder) {
        storageName = "shared-explorer";
      }
    }
    return this.invoke(
      this.EXPORT_METHOD,
      orgId,
      roomMatrixId,
      startTimeStamp,
      endTimeStamp,
      storageName,
      fileName,
      includeMedia,
      folderPath,
      sharedPath,
      driveId
    )
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return Promise.resolve(event.dto);
      })
      .catch((err) => {
        console.error(err);
        return Promise.reject("Error export chat.");
      });
  }

  //#region Edit Message
  editMessage(msg: MessageState): Promise<MessageState> {
    if (msg == null) return Promise.reject("Edited message cannot be null.");
    const beforeEdit = this.msgSelector.getMessage(msg.id);
    if (beforeEdit == null)
      return Promise.reject("Cannot find original message.");

    if (msg.type == MessageType.Audio || beforeEdit.type == MessageType.Audio)
      return Promise.reject("Edit audio message is not supported.");

    if (msg.type == MessageType.Image || msg.type == MessageType.File) {
      if (msg.mediaId == null) return Promise.reject("File not found");

      if (beforeEdit.mediaId != null && msg.mediaId == beforeEdit.mediaId) {
        //no change on file
        if (beforeEdit.plaintext != msg.plaintext) {
          return this.invokeEditTextMethod(msg);
        }
      } else {
        //change file
        //add new file
        if (msg.fileFromCloud){
          return this.invokeEditStorageMediaApi(msg);
        }else{
          return this.invokeEditMediaApi(msg);
        }
      }
    } else if (msg.type == MessageType.Meet) {
      if (beforeEdit.meetUrl != null && msg.meetUrl == beforeEdit.meetUrl) {
        //no change on file
        if (beforeEdit.plaintext != msg.plaintext) {
          return this.invokeEditTextMethod(msg);
        }
      } else {
        //add meet
        //change meet
        return this.invokeEditMeetApi(msg);
      }
    } else if (msg.type == MessageType.Calendar) {
      if (msg.meetingTitle == null) return Promise.reject("File not found");

      if (
        msg.meetingTitle == beforeEdit.meetingTitle ||
        (beforeEdit.startDate != null &&
          msg.startDate == beforeEdit.startDate) ||
        (beforeEdit.endDate != null && msg.endDate == beforeEdit.endDate) ||
        msg.recurrence == beforeEdit.recurrence
      ) {
        //no change on calendar
        if (beforeEdit.plaintext != msg.plaintext) {
          return this.invokeEditTextMethod(msg);
        }
      }
      //change calendar
      //add new calendar
      return this.invokeEditCalendarMeetApi(msg);
      
    } else {
        return this.invokeEditTextMethod(
          msg,
          msg.type == beforeEdit.type ? false : true
        );
    }

    return this.invokeEditTextMethod(msg);
  }

  private async invokeEditTextMethod(msg: MessageState, removeAttachment: boolean = false): Promise<MessageState> {
    var toSend: MessageState = _.cloneDeep(msg);

    try {
      let encryptedMsg: string = "";
      let isEncrypted: boolean = false;

      if (toSend.plaintext && toSend.plaintext.trim() != "") {
        var encrypted = await this.encryptText(msg.roomId, msg.plaintext);

        encryptedMsg = encrypted ? encrypted : toSend.plaintext;
        isEncrypted = encrypted ? true : false;
      }

      console.log("[room.hub] invoke edit msg: %o", {
        roomMatrixId: toSend.roomMatrixId,
        msgId: toSend.tempId,
        removeAttachment,
        encryptedMsg,
        showCard: toSend.showCard,
        isEncrypted,
      });

      return this.invoke(
        this.EDIT_MSG_METHOD,
        toSend.roomMatrixId,
        toSend.id,
        encryptedMsg,
        removeAttachment,
        toSend.showCard,
        isEncrypted,
        msg.replyTo
      ).then((event) => {
          if (!event || !event.isSuccess) throw new Error(event.error);
          return this.parseMessageState(event.dto);
        })
        .then((sentMessage: MessageState) => {
          if (sentMessage) {
            toSend.plaintext = toSend.plaintext ? toSend.plaintext.trim() : "";
            toSend.isEncrypted = isEncrypted;
            if (toSend.isEncrypted) toSend.isDecrypted = DecryptStatus.Success;
            toSend.content = sentMessage.content;
            toSend.type = sentMessage.type;
            toSend.lastEditedTime = sentMessage.lastEditedTime;

            this.addOrUpdateMessage.emit(toSend);

            return toSend;
          } else {
            return Promise.reject("Failed to edit msg");
          }
        })
        .catch((err) => {
          console.error(err);
          return Promise.reject("Failed to edit msg");
        });
    } catch (err) {
      console.error(err);
      return Promise.reject("Failed to edit msg");
    }
  }

  private async invokeEditMediaApi(msg: MessageState) {
    try {
      var toSend: MessageState = _.cloneDeep(msg);
      const file = this.fileStateSelector.getFile(toSend.mediaId);
      if (file == null) return Promise.reject("File not found.");
      // prepare subscription for fileUpload progress
      this.mediaService.initFileUploadProgress(toSend.mediaId);

      const hubConnectionId = this.hubSnapshot.id;

      let encryptedMsg: string = "";
      let isEncrypted: boolean = false;

      if (toSend.plaintext && toSend.plaintext.trim() != "") {
        var encrypted = await this.encryptText(toSend.roomId, toSend.plaintext);

        encryptedMsg = encrypted ? encrypted : toSend.plaintext;
        isEncrypted = encrypted ? true : false;
      }

      const formData = new FormData();
      const newFile = await this.mediaService.convertObjUrlToFile(
        file.url,
        file.name,
        file.mimeType
      );
      formData.append("File", newFile, file.name);
      formData.append("MsgId", toSend.id);
      formData.append("RoomId", toSend.roomMatrixId);
      formData.append("OrgUnitId", toSend.orgId);
      formData.append("HubConnectionId", hubConnectionId);
      formData.append("TempId", toSend.tempId);
      formData.append("Content", encryptedMsg);
      formData.append("ShowCard", toSend.showCard ? "true" : "false");
      formData.append("IsEncrypted", isEncrypted ? "true" : "false");
      if (toSend.customDuration != null) {
        formData.append("AudioDuration", toSend.customDuration.toString());
      }
      formData.append("Type", toSend.type.toString());
      if (!!toSend.replyTo) formData.append("ReplyTo", toSend.replyTo);

      return this.mediaService.streamFile("matrix/edit/stream", formData, file).then(
        (res) => {
          const sentMsg = this.parseMessageState(res);
          if (sentMsg) {
            toSend.plaintext = toSend.plaintext ? toSend.plaintext.trim() : "";
            toSend.isEncrypted = isEncrypted;
            if (toSend.isEncrypted) toSend.isDecrypted = DecryptStatus.Success;
            toSend.content = sentMsg.content;
            toSend.type = sentMsg.type;
            toSend.fwt = sentMsg.fwt;
            toSend.mediaId = sentMsg.mediaId;
            toSend.mediaUrl = sentMsg.mediaUrl;
            toSend.lastEditedTime = sentMsg.lastEditedTime;

            this.addOrUpdateMessage.emit(toSend);

            const oldMediaId = toSend.mediaId;

            //update file state
            let file = this.fileStateSelector.getFile(oldMediaId);
            if (file) {
              file.id = sentMsg.mediaId;
              file.url = sentMsg.mediaUrl;
              if (sentMsg.fwt) {
                file.fwt = sentMsg.fwt;
                file.fwtEncoded = true;
              }

              file.downloadedPath = res.toString();
              file.downloadStatus = FileDownloadStatus.Ready;
              this.addOrUpdateFile.emit(file);
            }
            return toSend;
          } else {
            return Promise.reject("Failed to edit msg");
          }
        },
        (err) => {
          console.error(err);
          return Promise.reject("Error uploading file " + file.name);
        }
      );
    } catch (err) {
      console.error(err);
      return Promise.reject("Failed to edit msg");
    }
  }

  private async invokeEditStorageMediaApi(msg: MessageState) {
    try {
      var toSend: MessageState = _.cloneDeep(msg);
      const file = this.fileStateSelector.getFile(toSend.mediaId);
      if (file == null) return Promise.reject("File not found.");

      const hubConnectionId = this.hubSnapshot.id;

      let encryptedMsg: string = "";
      let isEncrypted: boolean = false;

      if (toSend.plaintext && toSend.plaintext.trim() != "") {
        var encrypted = await this.encryptText(toSend.roomId, toSend.plaintext);

        encryptedMsg = encrypted ? encrypted : toSend.plaintext;
        isEncrypted = encrypted ? true : false;
      }

      const formData = new FormData();
      // var blob = await this.fileService.readAsBlob(msg.mediaUrl, msg.mimeType);
      // formData.append("Files", blob, toSend.mediaName);
      formData.append("MediaUrl", toSend.mediaUrl);
      formData.append("MsgId", toSend.id);
      formData.append("RoomId", toSend.roomMatrixId);
      formData.append("OrgUnitId", toSend.orgId);
      formData.append("HubConnectionId", hubConnectionId);
      formData.append("TempId", toSend.tempId);
      formData.append("Content", encryptedMsg);
      formData.append("CloudContainerName", msg.fileFromCloud);
      formData.append("ShowCard", toSend.showCard ? "true" : "false");
      formData.append("IsEncrypted", isEncrypted ? "true" : "false");
      if (toSend.customDuration != null) {
        formData.append("AudioDuration", toSend.customDuration.toString());
      }
      formData.append("Type", toSend.type.toString());
      if (!!toSend.replyTo) formData.append("ReplyTo", toSend.replyTo);

      return this.mediaService
        .streamFile(
          "matrix/edit/storageMedia",formData, file
        )
        .then(
          (res) => {
            const sentMsg = this.parseMessageState(res);
            if (sentMsg) {
              const oldMediaId = toSend.mediaId;

              toSend.plaintext = toSend.plaintext ? toSend.plaintext.trim() : "";
              toSend.isEncrypted = isEncrypted;
              toSend.content = sentMsg.content;
              toSend.isDecrypted = DecryptStatus.Success;
              toSend.type = sentMsg.type;
              toSend.fwt = sentMsg.fwt;
              toSend.mediaId = sentMsg.mediaId;
              toSend.mediaUrl = sentMsg.mediaUrl;
              toSend.lastEditedTime = sentMsg.lastEditedTime;

              this.addOrUpdateMessage.emit(toSend);

              //update file state
              let file = this.fileStateSelector.getFile(oldMediaId);
              if (file) {
                file.id = sentMsg.mediaId;
                file.url = sentMsg.mediaUrl;
                file.name = sentMsg.mediaName;
                if (sentMsg.fwt) {
                  file.fwt = sentMsg.fwt;
                  file.fwtEncoded = true;
                }
                file.downloadStatus = FileDownloadStatus.ReadyToDownload;
                this.addOrUpdateFile.emit(file);
              }
              return toSend;
            } else {
              return Promise.reject("Failed to edit msg");
            }
          },
          (err) => {
            console.error(err);
            return Promise.reject("Error uploading file " + file.name);
          }
        );
    } catch (err) {
      console.error(err);
      return Promise.reject("Failed to edit msg");
    }
  }

  private async invokeEditMeetApi(msg: MessageState): Promise<MessageState> {
    try {
      var toSend: MessageState = _.cloneDeep(msg);

      const hubConnectionId = this.hubSnapshot.id;
      const userId = this.userDataSelector.userId;

      let encryptedMsg: string = "";
      let isEncrypted: boolean = false;

      if (toSend.plaintext && toSend.plaintext.trim() != "") {
        var encrypted = await this.encryptText(msg.roomId, msg.plaintext);

        encryptedMsg = encrypted ? encrypted : toSend.plaintext;
        isEncrypted = encrypted ? true : false;
      }

      let body = {
        content: encryptedMsg,
        roomId: toSend.roomMatrixId,
        tempId: toSend.tempId,
        hubConnectionId: hubConnectionId,
        type: MessageType.Meet,
        isEncrypted: isEncrypted,
        msgId: toSend.id,
        meetingUrl: toSend.meetUrl,
        exp: toSend.meetExp,
        iat: toSend.meetIat,
        replyTo: msg.replyTo
      };

      return this.api
        .putAsync("meet", body, userId)
        .then
        (sentMsg => {
          if (sentMsg) {
            toSend.plaintext = toSend.plaintext ? toSend.plaintext.trim() : "";
            toSend.isEncrypted = isEncrypted;
            if (toSend.isEncrypted) toSend.isDecrypted = DecryptStatus.Success;
            toSend.content = sentMsg.content;
            toSend.type = sentMsg.type;
            toSend.meetIat = sentMsg.iat;
            toSend.meetExp = sentMsg.exp;
            toSend.meetUrl = sentMsg.url;
            toSend.lastEditedTime = sentMsg.lastEditedTime;

            this.addOrUpdateMessage.emit(toSend);

            return toSend;
          } else {
            return Promise.reject("Failed to edit msg");
          }
        })
        .catch(err => {
          console.error(err);
          return Promise.reject("Failed to edit msg");
        });
    } catch (err) {
      console.error(err);
      return Promise.reject("Failed to edit msg");
    }
  }

  private async invokeEditCalendarMeetApi(msg: MessageState): Promise<MessageState> {
    try {
      var toSend: MessageState = _.cloneDeep(msg);

      const hubConnectionId = this.hubSnapshot.id;
      const userId = this.userDataSelector.userId;

      let encryptedMsg: string = "";
      let isEncrypted: boolean = false;

      if (toSend.plaintext && toSend.plaintext.trim() != "") {
        var encrypted = await this.encryptText(toSend.roomId, toSend.plaintext);

        encryptedMsg = encrypted ? encrypted : toSend.plaintext;
        isEncrypted = encrypted ? true : false;
      }

      let body = {
        content: encryptedMsg,
        roomId: toSend.roomMatrixId,
        tempId: toSend.tempId,
        hubConnectionId: hubConnectionId,
        type: MessageType.Calendar,
        isEncrypted: isEncrypted,
        startDate: toSend.startDate,
        endDate: toSend.endDate,
        recurrence: toSend.recurrence,
        meetingTitle: toSend.meetingTitle,
        orgId: toSend.orgId,
        meetingContent: toSend.plaintext,
        msgId: toSend.id,
        meetingUrl: toSend.meetUrl,
        exp: toSend.meetExp,
        iat: toSend.meetIat,
        replyTo: msg.replyTo
      }

      return this.api
        .putAsync("meet/calendar", body, userId)
        .then
        (sentMsg => {
          if (sentMsg) {
            toSend.plaintext = toSend.plaintext ? toSend.plaintext.trim() : "";
            toSend.isEncrypted = isEncrypted;
            if (toSend.isEncrypted) toSend.isDecrypted = DecryptStatus.Success;
            toSend.content = sentMsg.content;
            toSend.type = sentMsg.type;
            toSend.meetIat = sentMsg.iat;
            toSend.meetExp = sentMsg.exp;
            toSend.meetUrl = sentMsg.url;
            toSend.meetingTitle = sentMsg.meetingTitle;
            toSend.startDate =sentMsg.startDate;
            toSend.endDate = sentMsg.endDate;
            toSend.recurrence = sentMsg.recurrence;
            toSend.lastEditedTime = sentMsg.lastEditedTime;

            this.addOrUpdateMessage.emit(toSend);
            return toSend;
          } else {
            return Promise.reject("Failed to edit msg");
          }
        })
        .catch(err => {
          console.error(err);
          return Promise.reject("Failed to edit msg");
        });
    } catch (err) {
      console.error(err);
      return Promise.reject("Failed to edit msg");
    }
  }
  //#endregion
  
  private async encryptText(roomId: string, content: string){
    try {
      var receivers = await this.getReceivers(roomId);
      var encrypted = await this.fcp.encryptMsg(content, receivers);
      return encrypted;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  handshakeMethod(orgId: string = null): Promise<any> {
    return this.invoke(this.HANDSHAKE_BY_ORG_METHOD, orgId).then((event) => {
      if (!event || !event.isSuccess) {
        this.updateHandshakeStatus.emit(HubHandshakeStatus.Failed);
        throw new Error(event.error);
      }

      return event.dto;
    }).catch((err) => {
      return err;
    });
  }

  async updateHandshakeData(dtos: any[]) {
    if (!dtos) return null;
    if (dtos.length == 0) return [];

    let rooms = this.parseRoomStates(dtos, true);
    if (rooms && rooms.length > 0) {
      await this.addOrUpdateRooms.emit(rooms).toPromise();
    }

    let msgs: MessageState[] = [];
    let ptrs: ParticipantState[] = [];
    dtos.forEach((dto) => {
      if (!dto) return;
      if (!!dto.messages && dto.messages.length > 0) {
        console.info("room %s | Handshake retrieved %s messages | ReachedHistory: %s | HistorybatchKey: %s", dto.roomMatrixId, dto.messages.length, dto.historyBatchNumber == null, dto.historyBatchNumber);
        msgs.push(...this.parseMessageStates(dto.messages));
      }
      if (!!dto.participants && dto.participants.length > 0) {
        ptrs.push(...this.parseParticipantStates(dto.participants));
      }
    });

    if (msgs && msgs.length > 0) {
      // const currentUserMatrixId = this.userDataSelector.userProfile
      //   .matrixId;

      //first time login, all msgs treated as read
      //TODO move unread implementation to server
      // msgs.forEach(m => {
      //   m.sendStatus = MessageSendStatus.Sent;
      // });

      //this.addOrUpdateMessages.emit(msgs);

      //update msg flags
      this.addOrUpdateMsgFlags.emit(
        msgs.map((m) => {
          return { msgId: m.id, flags: m.flags };
        })
      );
    }

    if (ptrs && ptrs.length > 0) {
      this.addOrUpdateParticipants.emit(ptrs);
    }

    return rooms;
  }

  // handshake(orgId: string = null) {
  //   // const batchKey = this._hubStateSelector.getRoomHub().lastBatchKey || "";
  //   // console.info("[RoomHub] Handshake batch key: %s", batchKey);
  //   //passing null orgId will get personal org data from server
  //   console.log("[roomHub] - begin handshake");
  //   console.time("[roomHub] - handshake op 1");
  //   return this.invoke(this.HANDSHAKE_BY_ORG_METHOD, orgId)
  //     .then((res: HubEvent) => {
  //       console.time("[roomHub] - handshake op 2");
  //       if (!res || !res.isSuccess) {
  //         console.error(res.error);
  //         this.updateHandshakeStatus.emit(HubHandshakeStatus.Failed);
  //       } else {
  //         console.log("[roomHub] success handshake, %o", res);
  //         let rooms = this.parseListRoomEvent(res);
  //         if (rooms && rooms.length > 0) {
  //           console.log("[roomHub] update rooms");
  //           this.addOrUpdateRooms.emit(rooms);
  //           // console.log(
  //           //   "[roomHub] update batch key: %s",
  //           //   rooms[0].nextBatchNumber
  //           // );
  //           // this.updateBatchNumber.emit(rooms[0].nextBatchNumber);
  //         }
  //         const currentUserMatrixId = this.userDataSelector.userProfile.matrixId;

  //         let msgs = this.parseListRoomMessageEvent(res);
  //         if (msgs && msgs.length > 0) {
  //           console.log("[roomHub] update messages");
  //           msgs.forEach((m) => (m.sendStatus = MessageSendStatus.Sent));
  //           // if (!batchKey || batchKey.trim() == "") {
  //           if (this._isFirstLogin) {
  //             //do nothing
  //           } else {

  //             const unreadMsgs = msgs.filter(m => m.senderMatrixId !== currentUserMatrixId);
  //             this.addRoomUnreads.emit(unreadMsgs);
  //           }
  //           this.addOrUpdateMessages.emit(msgs);

  //           //update msg flags
  //           this.addOrUpdateMsgFlags.emit(
  //             msgs.map((m) => {
  //               return { msgId: m.id, flags: m.flags };
  //             })
  //           );
  //         } else {
  //           console.log("[roomHub] no messages to update");
  //         }

  //         let ptrs = this.parseListRoomParticipantEvent(res);
  //         if (ptrs && ptrs.length > 0) {
  //           // console.log("[roomHub] update participants");
  //           this.addOrUpdateParticipants.emit(ptrs);
  //         } else {
  //           console.log("[roomHub] no participants to update");
  //         }
  //         this.updateHandshakeStatus.emit(HubHandshakeStatus.Completed);
  //       }
  //       console.timeEnd("[roomHub] - handshake op 2");
  //       console.log("[roomHub] - done handshake");
  //       console.timeEnd("[roomHub] - handshake op 1");
  //     })
  //     .catch((err) => console.error(err));
  // }

  private onMessageEventReceived(event: HubEvent): void {
    //console.log("[onMessageEventReceived] triggered");
    if (!event) return;
    if (event.name == this.UPDATE_MSG_REACTIONS){
      if (!event.dto.reactions) return;
      let reactions = this.parseMsgReactions(event.dto.reactions);
      if (!reactions) return;
      this.addOrUpdateMsgReactions.emit({
        msgId: event.dto.msgId,
        reactions: reactions,
      });
      return;
    }

    const msg = this.parseMessageEvent(event);
    if (!msg) return;
    msg.sendStatus = msg.sendStatus ? msg.sendStatus : MessageSendStatus.Sent;
    //console.log("[onMessageEventReceived] received: r:%s, m:%s", msg.roomId, msg.id);
    if (event.name === this.NEW_MSG || event.name === this.UPDATE_MSG) {
      msg.sendStatus = msg.sendStatus ? msg.sendStatus : MessageSendStatus.Sent;
      this.addOrUpdateMessage.emit(msg);
    } else if (event.name === this.DELETE_MSG) {
      this.deleteMessage.emit(msg);
    } else if(event.name ===this.FAIL_TO_SEND_MSG) {
      //only change fail status not other properties
      let existing = this.msgSelector.getMessage(msg.tempId);
      if (existing) {
        existing.sendStatus = MessageSendStatus.Failed;
        this.addOrUpdateMessage.emit(existing);
      }
    } else {
      console.error("Unsupported messaging event: %s", event.name);
    }
  }

  private onRoomEventReceived(event: HubEvent): void {
    if (!event) return;
    if (!event.dto) return;

    if (event.name === this.UPDATE_PARTICIPANT) {
      let participant = this.parseParticipantState(event.dto);
      if (!participant) return;
      //update participant
      this.addOrUpdateParticipant.emit(participant);
    } else if (event.name === this.UPDATE_ROOMS_PARTICIPANTS) {
      let participants = this.parseParticipantStates(event.dto);
      if (!participants || participants.length === 0) return;
      this.addOrUpdateParticipants.emit([...participants]);
    } else {
      if (event.dto.participants && event.dto.participants.length != 0) {
        const participants = this.parseParticipantStates(
          event.dto.participants
        );
        this.addOrUpdateParticipants.emit(participants);
      }

      let room = this.parseRoomState(event.dto);
      if (!room) return;
      this.addOrUpdateRoom.emit(room);
    }
  }

  private onMsgFlagReceived(event: HubEvent): void {
    if (!event) return;
    if (!event.dto) return;

    var dtos: { msgId; flag }[] = event.dto;
    const updates = dtos.map((m) => {
      return { msgId: m.msgId, flags: [m.flag] };
    });

    if (event.name === this.ADD_MSG_FLAG) {
      this.addOrUpdateMsgFlags.emit(updates);
    } else if (event.name === this.REMOVE_MSG_FLAG) {
      updates.forEach((update) => {
        this.removeMsgFlag.emit(update);
      });
    }
  }

  //#endregion

  //#region  Parse functions

  private parseMessageEvent(event: HubEvent): MessageState {
    if (!event) return null;
    if (!event.dto) return null;
    return this.parseMessageState(event.dto);
  }

  private parseListRoomMessageEvent(event: HubEvent): MessageState[] {
    if (!event) return null;
    if (!event.dto) return null;
    if (event.dto.length == 0) return [];

    let dto: any[] = event.dto;
    let messages: MessageState[] = [];

    dto.forEach((room) => {
      if (room && !!room.messages && room.messages.length > 0) {
        messages.push(...this.parseMessageStates(room.messages));
      }
    });
    return messages;
  }

  private parseMessageStates(list: any[]): MessageState[] {
    if (list == null || list.length == 0) return [];
    let messages: MessageState[] = [];
    list.forEach((d) => messages.push(this.parseMessageState(d)));

    return messages;
  }

  private parseMessageState(dto: any): MessageState {
    const msg = new MessageState();
    msg.id = dto.id;
    msg.tempId = dto.tempId;
    msg.roomId = dto.roomInternalId;
    msg.roomMatrixId = dto.roomMatrixId;
    msg.orgId = dto.orgUnitId;
    msg.senderMatrixId = dto.senderMatrixId;

    msg.serverTimeStamp = dto.serverReceiveTime ? dto.serverReceiveTime : dto.serverTimeStamp;

    msg.content = dto.content;
    msg.isEncrypted = dto.isEncrypted == undefined ? false : dto.isEncrypted;
    if (msg.isEncrypted) msg.isDecrypted = DecryptStatus.Pending;
    //msg.isDecrypted = dto.isDecrypted == undefined ? false : dto.isDecrypted;
    msg.showCard = dto.showCard;

    msg.status = dto.status;
    msg.type = dto.type;
    if (msg.type === MessageType.Audio) {
      msg.mimeType = "audio/mpeg";
    }

    //others
    msg.fwt = dto.fwt;
    msg.mediaId = dto.mediaId;

    msg.sendStatus = dto.sendStatus;
    msg.flags = dto.flags ? dto.flags : [];

    msg.mediaUrl = dto.mediaUrl;
    msg.mimeType = dto.mimeType;
    msg.mediaSize = dto.fileSize;
    msg.mediaName = dto.fileName;

    //audio
    msg.customDuration = dto.customDuration;

    //EL Meet
    msg.meetExp = dto.exp;
    msg.meetIat = dto.iat;
    msg.meetUrl = dto.url;

    msg.flags = dto.flags ? dto.flags : [];

    //calendar
    msg.startDate = dto.startDate;
    msg.endDate = dto.endDate;
    msg.recurrence = dto.recurrence;
    msg.meetingTitle = dto.meetingTitle;

    msg.lastEditedTime = dto.lastEditedTime;
    msg.replyTo = dto.replyTo;

    if (dto.reactions) {
      msg.reactions = this.parseMsgReactions(dto.reactions);
    }
      
    return msg;
  }

  private parseMsgReaction(dto: any): MsgReaction{
    if (dto == null) return null;
    let r = new MsgReaction();
    r.content = dto.content;
    r.senders = dto.senders;
    return r;
  }

  private parseMsgReactions(dto: any[]): MsgReaction[] {
    let reactions: MsgReaction[] = [];
    dto.forEach((d) => reactions.push(this.parseMsgReaction(d)));

    return reactions;
  }

  private parseListRoomEvent(event: HubEvent): RoomState[] {
    if (!event) return [];
    if (!event.dto) return [];
    if (event.dto.length == 0) return [];
    return this.parseRoomStates(event.dto);
  }

  private parseRoomStates(dto: any[], saveMsgPreview: boolean = false): RoomState[] {
    let rooms: RoomState[] = [];
    dto.forEach((d) => rooms.push(this.parseRoomState(d, saveMsgPreview)));

    return rooms;
  }

  private parseRoomState(d: any, saveMsgPreview: boolean = false): RoomState {
    let room = new RoomState();
    room.id = d.roomInternalId;
    room.matrixId = d.roomMatrixId;
    room.orgId = d.orgUnitId;
    room.name = d.name;
    room.type = d.type;
    room.timeStamp = d.timeStamp;
    room.creatorMatrixId = d.creator;
    room.createdOn = d.createdOn;
    room.creatorId = d.createdBy;
    room.status = d.entityStatus;

    room.nextBatchNumber = d.nextBatchNumber;
    room.lastBatchNumber = d.historyBatchNumber;
    room.isHistoryLoaded = d.isHistoryLoaded;
    room.imageUrl = d.imageUrl;

    if (d.lastMsg && saveMsgPreview) {
      // const msg = _.head(
      //   _.orderBy(d.messages, ["serverTimeStamp"], ["desc"])
      // );

      room.lastMsg = this.parseMessageState(d.lastMsg);
    }

    if (d.messages && d.messages.length > 0) {
  

      room.messages = d.messages.map((m) => m.id);
    } else {
      room.messages = [];
    }

    if (d.activeParticipants && d.activeParticipants.length > 0) {
      room.participants = d.activeParticipants.map((p) => p.matrixId);
    } else if (d.participants && d.participants.length > 0) {
      room.participants = d.participants.filter((p) => p.status && p.status != ParticipantStatus.Leaved).map((p) => p.matrixId);
    }
    return room;
  }

  private parseListRoomParticipantEvent(event: HubEvent): ParticipantState[] {
    if (!event) return null;
    if (!event.dto) return null;
    if (event.dto.length == 0) return [];

    let dto: any[] = event.dto;
    let results: ParticipantState[] = [];

    dto.forEach((room) => {
      if (room && !!room.participants && room.participants.length > 0) {
        results.push(...this.parseParticipantStates(room.participants));
      }
    });
    return results;
  }

  private parseParticipantStates(list: any[]): ParticipantState[] {
    let results: ParticipantState[] = [];
    list.forEach((d) => results.push(this.parseParticipantState(d)));

    return results;
  }

  private parseParticipantState(dto: any): ParticipantState {
    const p = new ParticipantState();
    p.matrixId = dto.matrixId;
    p.roomId = dto.roomInternalId;
    p.status = dto.status;
    p.userId = dto.userId;
    p.firstName = dto.firstName;
    p.lastName = dto.lastName;
    p.isLeaved = dto.isLeaved;
    p.isRoomHidden = dto.isRoomHidden;
    return p;
  }

  private parseFileObjState(dto: any): FileObjState {
    if (dto == null) return null;

    let file = new FileObjState();
    file.id = dto.messageId;
    file.name = dto.extension ? dto.displayName + '.' + dto.extension : dto.extension;
    file.timeStamp = dto.createdOn;
    file.fwt = dto.fwt;
    file.fwtEncoded = file.fwt ? true : false;
    file.icon = Resource.getType(dto.extension);
    file.size = dto.sizeInBytes;
    file.sizeInString = dto.sizeInString;
    return file;
  }

  private parseFileObjStates(list: any[]): FileObjState[] {
    let results: FileObjState[] = [];
    list.forEach((d) => results.push(this.parseFileObjState(d)));

    return results;
  }

  //#endregion
  private checkMsgSender(participants: string[], matrixId: string) {
    if (!participants || participants.length == 0 || !matrixId) return false;
    var sender = participants.find(p => p == matrixId);
    return sender ? true : false;
  }

  public generateMsgTempId(): string {
    return new Date().getTime().toString();
  }

  public createMsg(roomId: string): MessageState {
    const room = this.msgSelector.getRoom(roomId);
    const currentUserMatrixId = this.userDataSelector.matrixId;
    if (!room) throw new Error("Room was invalid with id: " + roomId);
    if (!currentUserMatrixId) new Error("Current User was invalid");
    const senderInRoom = this.checkMsgSender(room.participants, currentUserMatrixId);
    if (!senderInRoom) throw new Error("Sender is not in this room");

    let msg = new MessageState();
    msg.tempId = this.generateMsgTempId();
    msg.senderMatrixId = currentUserMatrixId;
    msg.roomId = room.id;
    msg.roomMatrixId = room.matrixId;
    msg.orgId = this.enterpriseSelector.getCurrentOrgId();

    let now = new Date();
    msg.serverTimeStamp = now.getTime();
    // msg.serverDisplayDateTime = now.toLocaleTimeString([], {
    //   hour: "2-digit",
    //   minute: "2-digit",
    // });
    msg.sendStatus = MessageSendStatus.PendingToSent;

    return msg;
  }

  public sendText(
    roomId: string,
    content: string,
    showCard: boolean = false,
    isEncrypted: boolean = false,
    replyTo?: string
  ): Observable<string> {
    try {
      let msg = this.createMsg(roomId);
      msg.type = MessageType.Text;
      //msg.content = content ? content.trim() : "";
      msg.plaintext = content ? content.trim() : "";
      msg.showCard = showCard;
      msg.isEncrypted = isEncrypted;
      if (msg.isEncrypted) msg.isDecrypted = DecryptStatus.Success;
      msg.replyTo = replyTo;

      return this.addOrUpdateMessage.emit(msg).pipe(map((i) => msg.tempId));
    } catch (e) {
      throwError(e.toString());
    }
  }

  public sendAudio(
    roomId: string,
    duration: number,
    file: FileObjState,
    isNotForward: boolean = false,
    replyTo?: string
  ): Observable<MessageState> {
    try {
      let msg = this.createMsg(roomId);
      msg.type = MessageType.Audio;
      msg.mediaUrl = file.downloadedPath ? file.downloadedPath : file.url;
      msg.mediaName = file.name;
      msg.customDuration = duration;
      msg.mimeType = "audio/mp3";
      msg.mediaId = file.id;
      if (isNotForward) msg.sendStatus = MessageSendStatus.Sending;
      msg.replyTo = replyTo;

      return forkJoin(
        this.addOrUpdateFile.emit(file),
        this.addOrUpdateMessage.emit(msg)
      ).pipe(map(i => msg));
    } catch (e) {
      throwError(e.toString());
    }
  }

  public sendImage(
    roomId: string,
    content: string,
    file: FileObjState,
    isEncrypted: boolean = false,
    replyTo?: string
  ): Observable<string> {
    try {
      let msg = this.createMsg(roomId);
      msg.type = MessageType.Image;
      //there is no url in form file object
      //msg.mediaUrl = file.downloadedPath ? file.downloadedPath : file.url;
      msg.mediaName = file.name;
      msg.mediaSize = file.size;
      msg.mimeType = file.mimeType;
      //msg.content = content ? content.trim() : "";
      msg.plaintext = content ? content.trim() : "";
      msg.mediaId = file.id;
      msg.isEncrypted = isEncrypted;
      if (msg.isEncrypted) msg.isDecrypted = DecryptStatus.Success;
      msg.replyTo = replyTo;

      return forkJoin(
        this.addOrUpdateFile.emit(file),
        this.addOrUpdateMessage.emit(msg)
      ).pipe(map((i) => msg.tempId));
    } catch (e) {
      throwError(e.toString());
    }
  }

  public sendFile(
    roomId: string,
    content: string,
    file: FileObjState,
    isEncrypted: boolean = false,
    replyTo?: string
  ): Observable<string> {
    try {
      let msg = this.createMsg(roomId);
      msg.type = MessageType.File;
      msg.mediaUrl = file.downloadedPath ? file.downloadedPath : file.url;
      msg.mediaName = file.name;
      msg.mediaSize = file.size;
      msg.mimeType = file.mimeType;
      //msg.content = content ? content.trim() : "";
      msg.plaintext = content ? content.trim() : "";
      msg.mediaId = file.id;
      msg.isEncrypted = isEncrypted;
      msg.fileFromCloud = file.cloudContainerName;
      if (msg.isEncrypted) msg.isDecrypted = DecryptStatus.Success;
      msg.replyTo = replyTo;

      return forkJoin(
        this.addOrUpdateFile.emit(file),
        this.addOrUpdateMessage.emit(msg)
      ).pipe(map((i) => msg.tempId));
    } catch (e) {
      throwError(e.toString());
    }
  }

  public sendMeet(
    roomId: string,
    content: string,
    isEncrypted: boolean = false,
    replyTo?: string
  ): Observable<string> {
    try {
      let msg = this.createMsg(roomId);
      msg.type = MessageType.Meet;
      //msg.content = content ? content.trim() : "";
      msg.plaintext = content ? content.trim() : "";
      msg.isEncrypted = isEncrypted;
      if (msg.isEncrypted) msg.isDecrypted = DecryptStatus.Success;
      msg.replyTo = replyTo;

      return this.addOrUpdateMessage.emit(msg).pipe(map((i) => msg.tempId));
    } catch (e) {
      throwError(e.toString());
    }
  }

  sendCalendarMeet(
    roomId: string,
    content: string,
    meetingTitle: string,
    isEncrypted: boolean = false,
    startDate: Date,
    endDate: Date = null,
    selectedRecurringType: string = null,
    replyTo?: string
  ) {
    try {
      let msg = this.createMsg(roomId);
      msg.type = MessageType.Calendar;
      //msg.content = content ? content.trim() : "";
      msg.plaintext = content ? content.trim() : "";
      msg.isEncrypted = isEncrypted;
      msg.startDate = startDate;
      msg.endDate = endDate;
      msg.recurrence = selectedRecurringType;
      msg.meetingTitle = meetingTitle;

      if (msg.isEncrypted) msg.isDecrypted = DecryptStatus.Success;
      msg.replyTo = replyTo;

      return this.addOrUpdateMessage.emit(msg).pipe(map((i) => msg.tempId));
    } catch (e) {
      throwError(e.toString());
    }
  }

  public async forwardMedia(msg: MessageState): Promise<void> {
    let encryptedMsg: string = "";
    let isEncrypted: boolean = false;

    if (msg.plaintext && msg.plaintext.trim() != "") {
      var receivers = await this.getReceivers(msg.roomId);
      var encrypted = await this.fcp.encryptMsg(msg.plaintext, receivers);

      encryptedMsg = encrypted ? encrypted : msg.content;
      isEncrypted = encrypted ? true : false;
    }

    const hubConnectionId = this.hubSnapshot.id;
    
    var fwMsg = {
      orgId: msg.orgId,
      roomMatrixId: msg.roomMatrixId,
      type: msg.type,
      tempId: msg.tempId,
      content: encryptedMsg,
      mediaUrl: msg.mediaUrl,
      fileName: msg.mediaName,
      mimeType: msg.mimeType,
      audioDuration: msg.customDuration,
      isEncrypted: isEncrypted,
      hubConnectionId: hubConnectionId,
    };
    return this.invoke$(this.FOWARD_MEDIA_WITH_URL, fwMsg).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        // return this.parseMessageState(event.dto);
      })
    ).toPromise();
  }

  public createDirect(userMatrixId: string): Observable<RoomState> {
    const currentUserId = this.userDataSelector.matrixId;
    const currentOrgId = this.enterpriseSelector.getCurrentOrgId();
    const existingRoom = this.msgSelector.getDirectRoom(
      currentUserId,
      userMatrixId,
      currentOrgId
    );

    if (existingRoom) return of(existingRoom);

    return this.invoke$(
      this.NEW_DIRECT_ROOM_METHOD,
      currentOrgId,
      userMatrixId
    ).pipe(
      tap((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        if (!event.dto) throw new Error(event.error);
        if (event.dto.participants && event.dto.participants.length != 0) {
          const participants = this.parseParticipantStates(
            event.dto.participants
          );
          this.addOrUpdateParticipants.emit(participants);
        }
      }),
      map((event) => this.parseRoomState(event.dto)),
      tap((roomState: RoomState) => this.addOrUpdateRoom.emit(roomState))
    );
  }

  public createGroup(
    matrixIds: string[],
    topic: string = ""
  ): Observable<RoomState> {
    const currentOrgId = this.enterpriseSelector.getCurrentOrgId();

    return this.invoke$(
      this.NEW_GROUP_ROOM_METHOD,
      currentOrgId,
      topic,
      matrixIds
    ).pipe(
      tap((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        if (!event.dto) throw new Error(event.error);
        if (event.dto.participants && event.dto.participants.length != 0) {
          const participants = this.parseParticipantStates(
            event.dto.participants
          );
          this.addOrUpdateParticipants.emit(participants);
        }
        return event.dto;
      }),
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseRoomState(event.dto);
      }),
      tap((roomState) => this.addOrUpdateRoom.emit(roomState))
    );
  }

  public invite(roomId: string, matrixIds: string[]): Observable<void> {
    const room = this.msgSelector.getRoom(roomId);
    if (!room) return of();

    return this.invoke$(this.INVITE_ROOM_METHOD, room.matrixId, matrixIds).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return event.dto;
      }),
      tap((dto) => this.addOrUpdateRoom.emit(this.parseRoomState(dto))),
      tap((dto) =>
        this.addOrUpdateParticipants.emit(
          this.parseParticipantStates(dto.participants)
        )
      )
    );
  }

  public leave(roomId: string): Observable<void> {
    const room = this.msgSelector.getRoom(roomId);
    if (!room) return of();

    return this.invoke$(this.LEAVE_ROOM_METHOD, room.matrixId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return event.dto;
      }),
      tap((dto) => this.removeRoom.emit(dto.roomInternalId)),
      tap((dto) =>
        this.addOrUpdateParticipants.emit(
          this.parseParticipantStates(dto.participants)
        )
      )
    );
  }

  public delete(roomId: string): Observable<void> {
    const room = this.msgSelector.getRoom(roomId);
    if (!room) return of();

    return this.invoke$(this.REMOVE_ROOM_METHOD, room.matrixId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return event.dto;
      }),
      tap((dto) => this.removeRoom.emit(dto.roomInternalId)),
      tap((dto) =>
        this.addOrUpdateParticipants.emit(
          this.parseParticipantStates(dto.participants)
        )
      )
    );
  }

  public kick(roomId: string, matrixId: string): Observable<void> {
    const room = this.msgSelector.getRoom(roomId);
    if (!room) return of();

    return this.invoke$(
      this.KICK_PARTICIPANT_METHOD,
      room.matrixId,
      matrixId
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return event.dto;
      }),
      tap((dto) => this.addOrUpdateRoom.emit(this.parseRoomState(dto))),
      tap((dto) =>
        this.addOrUpdateParticipants.emit(
          this.parseParticipantStates(dto.participants)
        )
      )
    );
  }

  public deleteMsg(roomId: string, msgId: string): Observable<void> {
    const room = this.msgSelector.getRoom(roomId);
    if (!room) return of();

    return this.invoke$(this.DELETE_MSG_METHOD, room.matrixId, msgId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return event.dto;
      }),
      tap((dto) => {
        let deletedMsg = this.parseMessageState(dto);
        this.deleteMessage.emit(deletedMsg);
      })
    );
  }

  public updateRoom(roomId: string, name: string): Observable<RoomState> {
    const room = this.msgSelector.getRoom(roomId);
    if (!room) return of();

    return this.invoke$(this.UPDATE_ROOM_METHOD, room.matrixId, name).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return event.dto;
      }),
      tap((dto) => this.addOrUpdateRoom.emit(this.parseRoomState(dto)))
    );
  }

  public getRoomMedia(roomMatrixId: string, continuationToken: string = null): Promise<{ continuationToken: string, files: FileObjState[] }> {
    if (!roomMatrixId) return;

    let endpoint = `matrix/media/${roomMatrixId}`
    if (continuationToken) endpoint += `?cont=${continuationToken}`;

    return this.api.getAsync(endpoint)
    .then((dto: any) => {
      if (!dto) return Promise.reject("Failed to load room media");
      let items = this.parseFileObjStates(dto.items);
      return { 
        continuationToken: dto.token ?? null,
        files: items
      };
    }).catch(err => {
      console.error(err);
      return Promise.reject("Failed to load room media.");
    });
  }

  // this method differs alot from mobile app, caution when merging
  public getRoomHistory(roomMatrixId: string, batchKey: string, msgRetrieveLimit: number = 0): Promise<{ room: RoomState, msgs: MessageState[] }> {
    return this.invoke$(this.HISTORY_ROOM_METHOD, roomMatrixId, batchKey, msgRetrieveLimit).toPromise()
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        const room = this.parseRoomState(event.dto);
        const msgs = this.parseMessageStates(event.dto.messages);
        msgs.forEach((m) => {
          m.sendStatus = m.sendStatus ? m.sendStatus : MessageSendStatus.Sent;
        })
        //server returns lastBatchNumber = null if no more history
        // if (!room.lastBatchNumber || room.lastBatchNumber === batchKey) {
        //   room.isHistoryLoaded = true;
        // }
        console.info("room %s | History retrieved %s messages from batch %s | ReachedHistory %s | Next historyBatch %s", room.matrixId, msgs.length, batchKey, room.isHistoryLoaded, room.lastBatchNumber);
        return { room: room, msgs: msgs };
      }).then((dto) => {
        this.setBackwardCursor.emit({roomMatrixId: roomMatrixId, cursor: dto.room.lastBatchNumber});
        this.addOrUpdateMsgFlags.emit(
          dto.msgs.map((m) => {
            return { msgId: m.id, flags: m.flags };
          })
        );

        // const room = this.parseRoomState(dto);
        // //server returns lastBatchNumber = null if no more history
        // if (!room.lastBatchNumber || room.lastBatchNumber === batchKey) {
        //   dto.room.isHistoryLoaded = true;
        // }

        return Promise.all([
          this.addOrUpdateRoom.emit(dto.room).toPromise(),
          this.addOrUpdateMessages.emit(dto.msgs).toPromise(),
        ]).then(() => dto);
      });
  }

  public getLatestMsg(roomMatrixId: string, isHistoryLoaded: boolean, timestamp: number, msgRetrieveLimit: number): Promise<{ room: RoomState, msgs: MessageState[] }> {
    let cursor = this.inMemMsgSelector.getCursor(roomMatrixId);
    let fCursor = cursor && cursor.backward && cursor.forward ? cursor.forward : null;

    return this.invoke$(this.LATEST_ROOM_MSGS_METHOD, roomMatrixId, fCursor, timestamp, msgRetrieveLimit).toPromise()
      .then((event) => {
        console.log("get latest msgs return %o", event);
        if (!event || !event.isSuccess) throw new Error(event.error);
        const room = this.parseRoomState(event.dto);
        const msgs = this.parseMessageStates(event.dto.messages);
        msgs.forEach((m) => {
          m.sendStatus = m.sendStatus ? m.sendStatus : MessageSendStatus.Sent;
        })
        //server returns lastBatchNumber = null if no more history
        // if (!room.lastBatchNumber) {
        //   room.isHistoryLoaded = true;
        // }
        console.info("room %s | History retrieved %s messages | ReachedHistory %s | Next historyBatch %s", room.matrixId, msgs.length, room.isHistoryLoaded, room.lastBatchNumber);

        return { room: room, msgs: msgs };
      }).then((dto) => {
        //update cursors 
        if (!cursor) {
          let msgCursor = new MsgCursor(dto.room.lastBatchNumber, dto.room.nextBatchNumber);
          //console.log("[updateCursor] set all cursor: %o", msgCursor);
          this.setMsgCursor.emit({roomMatrixId: roomMatrixId, cursor: msgCursor});
        } else if (cursor && dto.room.nextBatchNumber) {
          //console.log("[updateCursor] set forward cursor: %o", dto.room.nextBatchNumber);
          //only update forward cursor, isHistoryLoaded should remain the same
          dto.room.isHistoryLoaded = isHistoryLoaded;
          this.setForwardCursor.emit({roomMatrixId: roomMatrixId, cursor: dto.room.nextBatchNumber});
        }             

        this.addOrUpdateMsgFlags.emit(
          dto.msgs.map((m) => {
            return { msgId: m.id, flags: m.flags };
          })
        );
        return Promise.all([
          this.addOrUpdateRoom.emit(dto.room).toPromise(),
          this.addOrUpdateMessages.emit(dto.msgs).toPromise(),
        ]).then(() => dto);
      });
  }

  public getMsgsByRoom(room: RoomState): Promise<any> {
    return this.getRoomHistory(room.matrixId, room.nextBatchNumber).catch(
      (err) => {
        console.error(err);
      }
    );
  }

  public getRoomByRoomMatrixID(roomMatrixId: string): Promise<RoomState> {
    console.log("real roomMatrixId", roomMatrixId);
    return this.invoke$(this.GET_ROOM_METHOD, roomMatrixId)
      .toPromise()
      .then((event) => {
        console.log("orgId Event", event);
        return this.parseRoomState(event.dto);
      })
      .catch(err => {
        console.log("orgId Event", err);
        console.error(err);
        return null;
      });
  }

  addMsgFlagToServer(flag: string, msgId: string, roomMatrixId: string) {
    return this.invoke(
      this.ADD_MSG_FLAG_METHOD,
      roomMatrixId,
      msgId,
      flag
    )
      .then((res) => {
        this.addOrUpdateMsgFlags.emit([{ msgId: msgId, flags: [flag] }]);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  removeMsgFlagFromServer(flag: string, msgId: string, roomMatrixId: string) {
    return this.invoke(
      this.REMOVE_MSG_FLAG_METHOD,
      roomMatrixId,
      msgId,
      flag
    )
      .then((res) => {
        this.removeMsgFlag.emit({ msgId: msgId, flags: [flag] });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  public async getReceivers(roomId: string): Promise<any[]> {
    let ptcpts = this.msgSelector.getRoomParticipants(roomId);
    ptcpts = ptcpts.filter((r) => r.userId !== this.userDataSelector.userId);

    const currentOrgUsers: OrgUserState[] = this.enterpriseSelector.getCurrentOrgUsers();
    let existing: OrgUserState[] = _.intersectionBy(
      currentOrgUsers,
      ptcpts,
      "userId"
    );
    const nonExisting: ParticipantState[] = _.differenceBy(
      ptcpts,
      existing,
      "userId"
    );

    let noPublicKeyOrgUsers = existing.filter(
      (e) => e.publicKey == null || e.publicKey == ""
    );
    let userIds = nonExisting.map((o) => o.userId);
    userIds = [...userIds, ...noPublicKeyOrgUsers.map((o) => o.userId)];

    let publicKeyList = existing
      .filter((e) => e.publicKey != null && e.publicKey != "")
      .map((u) => {
        return { userId: u.userId, publicKey: u.publicKey };
      });

    if (userIds.length == 0) return Promise.resolve(publicKeyList);

    const room = this.msgSelector.getRoom(roomId);
    if (!room) return;

    return await this.orgHub
      .getPublicKeys(room.orgId, userIds)
      .then((users) => {
        const newPubKeys = users.map(u => {
          return { userId: u.userId, publicKey: u.publicKey }
        });

        publicKeyList = [...publicKeyList, ...newPubKeys];

        return Promise.resolve(publicKeyList);
      })
      .catch((err) => {
        pocolog.error(err);
        return Promise.reject(err);
      });
  }

  reactMsg(roomMatrixId: string, msgId: string, reactions: MsgReaction[]): Promise<MsgReaction[]>{
    return this.invoke(this.REACT_MSG_METHOD, roomMatrixId, msgId, reactions)
      .then(event => {
        if (!event || !event.isSuccess) throw new Error(event.error);

        if (!event.dto || !event.dto.reactions) return null;
        let reactions = this.parseMsgReactions(event.dto.reactions);
        if (!reactions) return null;
        this.addOrUpdateMsgReactions.emit({
          msgId: event.dto.msgId,
          reactions: reactions,
        });

        return reactions;
      }).catch((err) => {
        console.error(err);
        return null;
      });
  }
}
