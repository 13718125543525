import { SharedFile } from "./shared-file";
import { SharedFolder } from "./shared-folder";
import { ReplaySubject } from "rxjs";
import { CloudFile } from "./cloud-file";
import { OrgType } from "./../enum/org-type";
import { Resource } from "./resource";
import { CloudFolder } from "./cloud-folder";
import { SharedResource } from "./shared-resource";
import { CloudObjState } from "./cloud-obj.state";
import {
  GenericResourceDeleg,
  EnableActionDeleg,
  BaseResourceDeleg,
  ListResourcesDeleg,
  ResourceDeleg,
  ResourcesDeleg,
  GetFoldersSizeDeleg,
  RenameDeleg,
  UploadDeleg,
  UploadFromGoogleDeleg,
  NewFolderDeleg,
  SharedResourceDeleg,
  SharedResourcesDeleg,
  SharedRenameDeleg,
  GetSharedFoldersSizeDeleg,
  SharedNewFolderDeleg,
  SharedOpenFileDeleg,
  OpenFileDeleg,
  GetContactDeleg,
  DeleteResourceDeleg,
  ShareDeleg,
  MoveDeleg,
  SharedUploadDeleg,
  SharedUploadFromGoogleDeleg,
  BuildRedirectionFolderDeleg as BuildRedirectResourceDeleg,
  CopyResourcesDeleg,
  BuildSharedDriveDeleg,
  SharedDriveDeleteDeleg,
  SharedDriveUploadDeleg,
  SharedDriveCopyResourcesDeleg,
  SharedDriveUploadFromGoogleDeleg,
  GetSharedDriveAccessDeleg,
  SharedDriveRenameDeleg,
  SharedDriveDeleteResourceDeleg,
  SharedDriveNewFolderDeleg,
  DeleteResourcesDeleg,
  SharedDriveDeleteResourcesDeleg,
  CheckReadAccessDeleg,
  UploadFolderDeleg,
  SharedDriveUploadFolderDeleg,
  SharedUploadFolderDeleg,
  GetHelpTipDeleg,
} from "./resource-delegate";
import { AccessPermission, AccessRight } from "./cloud-access-right";
import { SharedDriveFolder } from "./shared-drive-folder";
import { SharedDriveFile } from "./shared-drive-file";
import { OriginFolder } from "./origin-folder";
import { OriginFile } from "./origin-file";

export abstract class Explorer {
  myDrive: MyDrive;
  sharedWithMeDrive: SharedWithMeDrive;
  private _sharedDrive: SharedDrive;
  public get sharedDrive(): SharedDrive {
    return this._sharedDrive;
  }
  public set sharedDrive(value: SharedDrive) {
    this._sharedDrive = value;
  }
  sharedDrivesCollection: SharedDriveCollection;

  currentDrive: Drive;
  orgId: string;
  orgType: OrgType;
  userId: string;
  get defaultDrive(): Drive {
    return this.myDrive;
  }  
  abstract getDrive(driveName: string): Drive;

  constructor(orgId: string, orgType: OrgType, defaultDrive: Drive) {
    this.orgId = orgId;
    this.orgType = orgType;
    this.currentDrive = defaultDrive;
  }

  switchDrive(drive: Drive) {
    if (this.currentDrive) this.currentDrive.setDefaultRootFolder();
    if (this.currentDrive !== drive) this.currentDrive = drive;
  }
}

export class OrgExplorer extends Explorer {
  get sharedDrive() : SharedDrive{
    if (
      this.sharedDrivesCollection == null ||
      this.sharedDrivesCollection.currentDrive == null
    )
      return null;
    return this.sharedDrivesCollection.currentDrive;
  }

  set sharedDrive(value: SharedDrive){
    if (this.sharedDrivesCollection == null) return;
    this.sharedDrivesCollection.switchDrive(value.id);
  }
  constructor(
    orgId: string,
    myDrive: MyDrive,
    sharedWithMeDrive: SharedWithMeDrive,
    sharedDriveCollection: SharedDriveCollection
  ) {
    super(orgId, OrgType.Business, myDrive);
    this.myDrive = myDrive;
    this.sharedWithMeDrive = sharedWithMeDrive;
    // this.sharedDrive = sharedDrive;
    this.sharedDrivesCollection = sharedDriveCollection;
    // this.sharedDrivesCollection.drives = [sharedDrive];
  }

  getDrive(driveName: string) {
    if (driveName == "local" || driveName.startsWith("local")) {
      return this.myDrive;
    } else if (driveName == "shared" || driveName.startsWith("shared")) {
      return this.sharedWithMeDrive;
    } else if (driveName == "drive" || driveName.startsWith("drive")) {
      return this.sharedDrivesCollection;
    }
  }
}

export class PersonalExplorer extends Explorer {
  constructor(
    orgId: string,
    myDrive: MyDrive,
    sharedWithMeDrive: SharedWithMeDrive,
    sharedDrive: SharedDrive
  ) {
    super(orgId, OrgType.Personal, myDrive);
    this.myDrive = myDrive;
    this.sharedWithMeDrive = sharedWithMeDrive;
    this.sharedDrive = sharedDrive;
  }

  getDrive(driveName: string) {
    if (driveName == "local" || driveName.startsWith("local")) {
      return this.myDrive;
    } else if (driveName == "shared" || driveName.startsWith("shared")) {
      return this.sharedWithMeDrive;
    } else if (driveName == "drive" || driveName.startsWith("drive")) {
      return this.sharedDrive;
    }
  }
}

export enum DriveType {
  Local,
  SharedWithMe,
  EnterpriseShared,
  EnterpriseSharedCollection
}

export enum FolderHierarchy
{
  None,
  All,
  Level1,
  Level2
}

export class HelpTip {
  constructor(key: string, message: string) {
    this.key = key;
    this.message = message;
  }
  public key: string; 
  public message: string; 
}

export abstract class Drive {
  public abstract driveType: DriveType;
  protected _breadCrumbs: CloudFolder[] | SharedFolder[];
  protected abstract _addToBreadCrumbs(
    folder: CloudFolder | SharedFolder,
    forward: boolean
  ): void;
  abstract setDefaultRootFolder(): void;

  protected _currentFolder: CloudFolder | SharedFolder;
  public get currentFolder(): CloudFolder | SharedFolder {
    return this._currentFolder;
  }
  public set currentFolder(value: CloudFolder | SharedFolder) {
    this._currentFolder = value;
  }
  breadCrumbsSubject: ReplaySubject<CloudFolder[] | SharedFolder[]>;
  resourcesSubject: ReplaySubject<Resource[]>;
  fileActions: ResourceAction<any, any, any>[];
  folderActions: ResourceAction<any, any, any>[];
  withinFolderActions: ResourceAction<any, any, any>[];
  uploadActions: ResourceAction<any, any, any>[];
  multiResourcesActions: ResourceAction<Resource[], any, any>[];
  abstract copyDeleg: CopyResourcesDeleg;
  abstract moveDeleg: MoveDeleg;
  abstract getContactsDeleg: GetContactDeleg;
  abstract checkReadAccessDeleg: CheckReadAccessDeleg;
  abstract listDeleg: ListResourcesDeleg;
  abstract openFileDeleg: GenericResourceDeleg;
  abstract downloadDeleg: GenericResourceDeleg;
  abstract multiDownloadDeleg: ResourcesDeleg;
  abstract renameDeleg: RenameDeleg;
  abstract deleteDeleg: DeleteResourceDeleg;
  abstract multiDeleteDeleg: DeleteResourcesDeleg;
  abstract getFolderSizeDeleg: GenericResourceDeleg;
  abstract enableUploadDeleg: EnableActionDeleg<CloudFolder | SharedFolder>;
  abstract uploadDeleg: UploadDeleg;
  abstract uploadFolderDeleg: UploadFolderDeleg;
  abstract uploadFromGoogleDeleg: UploadFromGoogleDeleg;
  abstract newFolderDeleg: GenericResourceDeleg;
  // abstract goToFolder(
  //   folder: CloudFolder | SharedFolder,
  //   forward: boolean
  // ): Promise<void>;
  abstract list(folder?: CloudFolder | SharedFolder, depth?: FolderHierarchy): Promise<CloudFolder | SharedFolder>;
  abstract listRoot(): Promise<CloudFolder | SharedFolder>;  //this method is to refresh folder tree whenever a new resource is added
  abstract buildRedirectResource: BuildRedirectResourceDeleg;

  //#region UI Control
  getHelpTip: GetHelpTipDeleg;
  tableColumns: string[];
  enableUploadsBtn: EnableActionDeleg<Resource[]>;
  listMultipleLevelsEnabled: EnableActionDeleg<CloudFolder | SharedFolder>;
  enableMultiDownloadBtn: EnableActionDeleg<Resource[]>;
  enableMultiMoveBtn: EnableActionDeleg<Resource[]>;
  enableMultiCopyBtn: EnableActionDeleg<Resource[]>;
  enableMultiDeleteBtn: EnableActionDeleg<Resource[]>;
  getProgressDeleg: BaseResourceDeleg<void, any, CloudObjState[]>;
  clearNotificationDeleg: () => void;
  enableExternalShare(resource: Resource): boolean {
    return true;
  }
  getShareTitle(resource: Resource): string {
    return "Share Files";
  }

  canChangeResourceSettings(resource: Resource, userId: string): boolean {
    return resource.ownerId == userId;
  }

  //#region Folder Tree
  isEnableCreateInFolder: EnableActionDeleg<CloudFolder | SharedFolder>;
  getCreateFolderTitle(selectedFolder: CloudFolder | SharedFolder): string {
    return null;
  }
  getCreateFolderInput(selectedFolder: CloudFolder | SharedFolder): string {
    return null;
  }
  //#endregion
  //#endregion

  getDisplayClaims(resource: Resource): AccessRight[] {
    if (resource == null) return [];
    return resource.grantees.filter((g) => g.isSharedAccess || g.isExternalAccess);
  }

  get openFolderDeleg(): GenericResourceDeleg {
    return (resource) => this.list(resource);
  }

  async goToFolder(
    folder: CloudFolder | SharedFolder,
    forward: boolean
  ): Promise<Resource[]> {
    this._addToBreadCrumbs(folder, forward);
    var res = await this.list(folder);
    let resources: Resource[] = [];
    if (res.folders != null) {
      resources.push(...res.folders);
    }

    if (res.files != null) {
      resources.push(...res.files);
    }

    return resources;
  }

  setBreadCrumbs(folders: CloudFolder[] | SharedFolder[]): void {
    if (folders == null) return;
    this._breadCrumbs = [];
    folders.forEach((f) => {
      this._addToBreadCrumbs(f, true);
    });
  }

  getActions(
    resources: Resource[],
    userId: string,
    isUploadAction: boolean = false
  ) {
    if (resources == null || resources.length == 0) {
      let actions = isUploadAction
        ? this.uploadActions
        : this.withinFolderActions;

      return actions == null || actions.length == 0
        ? []
        : actions.filter((a) => a.isEnableDeleg(this.currentFolder, userId));
    } else if (resources.length == 1) {
      let resource = resources[0];
      let actions = resource.isFile ? this.fileActions : this.folderActions;
      return actions.filter((a) => a.isEnableDeleg(resource, userId));
    } else {
      return this.multiResourcesActions.filter((a) =>
        a.isEnableDeleg(resources, userId)
      );
    }
  }

  constructor() {
    this.tableColumns = [
      "checkBox",
      "icon",
      "name",
      "description",
      "sizeInString",
      "modifiedOn",
    ];
    this.resourcesSubject = new ReplaySubject<Resource[]>();
    this.breadCrumbsSubject = new ReplaySubject<
      CloudFolder[] | SharedFolder[]
    >();
    this.fileActions = [];
    this.folderActions = [];
    this.withinFolderActions = [];
    this.multiResourcesActions = [];
    this.isEnableCreateInFolder = (folder, userId) => folder.hasPermission(userId, AccessPermission.Create);
    
    this.enableUploadsBtn = (resource, userId) =>
      this.currentFolder.hasPermission(userId, AccessPermission.UploadFile);
    
    this.listMultipleLevelsEnabled = (resource, userId) =>
      resource.hasPermission(userId, AccessPermission.View);

    this.setDefaultRootFolder();
  }

  static findResourceIndex(resources: Resource[], resource: Resource): number {
    if (resources == null) return -1;
    return resources.findIndex((r) => {
      if (resource.isFile) {
        if (resource instanceof OriginFile && r instanceof SharedFile) {
          return r.originPath === resource.path
        }

        if (resource instanceof CloudFile) {
          let file = resource as CloudFile;
          if (r.isFile && r instanceof CloudFile) {
            let f = r as CloudFile;
            return (
              f.path &&
              file.path &&
              file.path === f.path
            );
          }
        } else if (resource instanceof SharedFile) {
          let file = resource as SharedFile;

          if (r.isFile && r instanceof SharedFile) {
            let f = r as SharedFile;
            return (
              f.accessPath &&
              file.accessPath &&
              file.accessPath === f.accessPath &&
              f.path === resource.path
            );
          }
        }
      } else {
        if (resource instanceof OriginFolder && r instanceof SharedFolder) {
          return r.originPath === resource.path
        }

        if (resource instanceof CloudFolder) {
          let folder = resource as CloudFolder;
          if (folder.isRoot && r.isFolder && r instanceof CloudFolder) {
            let f = r as CloudFolder;
            return f.isRoot;
          }
        } else if (resource instanceof SharedFolder) {
          let folder = resource as SharedFolder;
          if (folder.isRoot && r.isFolder && r instanceof SharedFolder) {
            let f = r as SharedFolder;
            return f.isRoot;
          }
        }
      }

      return r.path === resource.path;
    });
  }

  static findRedirectResourceIndex(
    resources: Resource[],
    resource: Resource
  ): number {
    if (resources == null) return -1;
    return resources.findIndex((r) => {
      if (resource.isFile) {
        if (resource instanceof OriginFile) {
          if (r.isFile && r instanceof OriginFile) {
            return resource.path && r.path && r.path === resource.path;
          } else if (r.isFile && r instanceof SharedFile) {
            return resource.path && r.originPath && resource.path == r.originPath;
          }
        } else if (resource instanceof CloudFile) {
          if (r.isFile && r instanceof CloudFile) {
            return r.path && resource.path && r.path === resource.path;
          }
        } else if (resource instanceof SharedFile) {
          if (r.isFile && r instanceof SharedFile) {
            return (
              r.originPath &&
              resource.originPath &&
              r.originPath === resource.originPath 
            );
          }
        }
      } else if (resource instanceof SharedFolder) {
        if (r.isFolder && r instanceof SharedFolder) {
          return (
            r.originPath &&
            resource.originPath &&
            r.originPath === resource.originPath &&
            r.originContainer === resource.originContainer
          );
        }
      } else if (r instanceof SharedResource) {
        return r.originPath == resource.path; //redirect resource only have origin path as path
      }

      return r.path === resource.path;
    });
  }

  clearBreadCrumbs(): void {
    this._breadCrumbs = [];
    this.breadCrumbsSubject.next(this._breadCrumbs);
  }
}

export class MyDrive extends Drive {
  driveType = DriveType.Local;
  _currentFolder: CloudFolder;
  public get currentFolder(): CloudFolder {
    return this._currentFolder;
  }
  public set currentFolder(value: CloudFolder) {
    this._currentFolder = value;
  }

  listDeleg: BaseResourceDeleg<Resource, any, Promise<CloudFolder>>;
  openFileDeleg: OpenFileDeleg;
  downloadDeleg: ResourceDeleg;
  multiDownloadDeleg: ResourcesDeleg;
  getFolderSizeDeleg: GetFoldersSizeDeleg;
  getContactsDeleg: GetContactDeleg;
  shareDeleg: ShareDeleg;
  renameDeleg: RenameDeleg;
  deleteDeleg: ResourceDeleg;
  multiDeleteDeleg: ResourcesDeleg;
  uploadDeleg: UploadDeleg;
  uploadFolderDeleg: UploadFolderDeleg;
  uploadFromGoogleDeleg: UploadFromGoogleDeleg;
  moveDeleg: MoveDeleg;
  copyDeleg: CopyResourcesDeleg;
  newFolderDeleg: NewFolderDeleg;
  enableUploadDeleg: EnableActionDeleg<CloudFolder>;
  getProgressDeleg: BaseResourceDeleg<void, any, CloudObjState[]>;
  buildRedirectResource: BuildRedirectResourceDeleg;
  checkReadAccessDeleg: CheckReadAccessDeleg;

  constructor({
    listDeleg,
    openFileDeleg,
    downloadDeleg,
    multiDownloadDeleg,
    deleteDeleg,
    multiDeleteDeleg,
    uploadDeleg,
    uploadFolderDeleg,
    uploadFromGoogleDeleg,
    moveDeleg,
    copyDeleg,
    renameDeleg,
    getFolderSizeDeleg,
    newFolderDeleg,
    enableUploadDeleg,
    getProgressDeleg,
    getContactsDeleg,
    shareDeleg,
    clearNotificationDeleg,
    checkReadAccessDeleg,
    getHelpTip
  }: MyDriveMount) {
    super();

    this.listDeleg = listDeleg;
    this.openFileDeleg = openFileDeleg;
    this.downloadDeleg = downloadDeleg;
    this.multiDownloadDeleg = multiDownloadDeleg;
    this.deleteDeleg = deleteDeleg;
    this.multiDeleteDeleg = multiDeleteDeleg;
    this.uploadDeleg = uploadDeleg;
    this.uploadFolderDeleg = uploadFolderDeleg;
    this.uploadFromGoogleDeleg = uploadFromGoogleDeleg;
    this.moveDeleg = moveDeleg;
    this.copyDeleg = copyDeleg;
    this.renameDeleg = renameDeleg;
    this.getFolderSizeDeleg = getFolderSizeDeleg;
    this.newFolderDeleg = newFolderDeleg;
    this.enableUploadDeleg = enableUploadDeleg;
    this.getProgressDeleg = getProgressDeleg;
    this.getContactsDeleg = getContactsDeleg;
    this.shareDeleg = shareDeleg;
    this.clearNotificationDeleg = clearNotificationDeleg;
    this.checkReadAccessDeleg = checkReadAccessDeleg;
    this.getHelpTip = getHelpTip;

    var enableIfNotEmpty = (resources) => {
      if (!resources || resources.length == 0) return false;

      return true;
    };
    this.enableMultiDownloadBtn = enableIfNotEmpty;
    this.enableMultiMoveBtn = enableIfNotEmpty;
    this.enableMultiCopyBtn = enableIfNotEmpty;
    this.enableMultiDeleteBtn = enableIfNotEmpty;

    this.buildRedirectResource = (
      path,
      originPath,
      container,
      isFolder,
      name,
      fullPath
    ) => {
      if (isFolder) {
        let folder = new CloudFolder();
        folder.path = path;
        folder.name = name;
        folder.container = container;
        return folder;
      } else {
        let file = new CloudFile();
        file.path = fullPath;
        file.name = name;
        file.container = container;
        return file;
      }
    };
  }

  setDefaultRootFolder(): void {
    //default root folder
    var mainFolder = new CloudFolder();
    mainFolder.isRoot = true;
    mainFolder.name = "My Drive";
    mainFolder.icon = "hard_drive";
    this.currentFolder = mainFolder;
    this._addToBreadCrumbs(mainFolder);
  }

  async listRoot(): Promise<CloudFolder> {
    let res = await this.listDeleg();
    if (res == null) return new CloudFolder();

    res.name = "My Drive";
    res.icon = "hard_drive";
    res.isRoot = true;

    //if current folder is root
    if (this.currentFolder && this.currentFolder.path == res.path) {
      let resources: Resource[] = [];
      if (res.folders != null) {
        resources.push(...res.folders);
      }

      if (res.files != null) {
        resources.push(...res.files);
      }

      this.resourcesSubject.next(resources);
    }

    return res;
  }

  override list(folder: CloudFolder = null, depth?: FolderHierarchy) {
    if (folder == null) {
      this.setDefaultRootFolder();
    } else {
      this.currentFolder = folder;
    }
    return this.listDeleg(folder, {depth: depth}).then((res) => {
      let resources: Resource[] = [];
      if (folder == null || folder.isRoot) {
        res.name = "My Drive";
        res.icon = "hard_drive";
        res.isRoot = true;
      }
      //prevent override current folder when prev call have not return
      if (
        this.currentFolder.isSameResource(res) ||
        (this.currentFolder.isRoot && res.isRoot)
      ) {
        this.currentFolder = res;
        if (res.folders != null) {
          resources.push(...res.folders);
        }

        if (res.files != null) {
          resources.push(...res.files);
        }

        this.resourcesSubject.next(resources);
      }
      return res;
    });
  }

  _addToBreadCrumbs(folder: CloudFolder, forward: boolean = true): void {
    var prevValue = this._breadCrumbs as CloudFolder[];

    let index = Drive.findResourceIndex(this._breadCrumbs, folder);
    if (forward) {
      if (index == -1) {
        this._breadCrumbs =
          prevValue && prevValue.length > 0 ? [...prevValue, folder] : [folder];
      }
    } else {
      if (index == -1) return;
      this._breadCrumbs = this._breadCrumbs.slice(0, index + 1);
    }
    this.breadCrumbsSubject.next(this._breadCrumbs);
  }
}

export class SharedWithMeDrive extends Drive {
  driveType = DriveType.SharedWithMe;
  _currentFolder: SharedFolder | OriginFolder;
  set currentFolder(data: SharedFolder | OriginFolder) {
    this._currentFolder = data;
    if (!this._currentFolder.isRoot) {
      let index = this.tableColumns.indexOf("emptyCheckBox");
      if (index != -1) {
        this.tableColumns.splice(index, 1, "checkBox"); //replace emptyCheckBox with checkbox
      } else {
        let index = this.tableColumns.indexOf("checkBox");
        if (index == -1) this.tableColumns.splice(0, 0, "checkBox"); //add checkbox
      }
    } else {
      let index = this.tableColumns.indexOf("checkBox");
      if (index != -1) {
        this.tableColumns.splice(index, 1, "emptyCheckBox"); //replace checkBox with emptyCheckBox
      } else {
        let index = this.tableColumns.indexOf("emptyCheckBox");
        if (index == -1) this.tableColumns.splice(0, 0, "emptyCheckBox"); //add emptyCheckBox
      }
    }
  }

  get currentFolder(): SharedFolder | OriginFolder {
    return this._currentFolder;
  }

  listDeleg: BaseResourceDeleg<OriginFolder|SharedFolder, any, Promise<OriginFolder | SharedFolder>>;
  openFileDeleg: ResourceDeleg;
  downloadDeleg: ResourceDeleg;
  multiDownloadDeleg: ResourcesDeleg;
  renameDeleg: SharedRenameDeleg;
  deleteDeleg: DeleteResourceDeleg;
  multiDeleteDeleg: DeleteResourcesDeleg;
  moveDeleg: MoveDeleg;
  copyDeleg: CopyResourcesDeleg;
  getFolderSizeDeleg: GetSharedFoldersSizeDeleg;
  newFolderDeleg: SharedNewFolderDeleg;
  enableUploadDeleg: EnableActionDeleg<SharedFolder>;
  uploadDeleg: UploadDeleg;
  uploadFolderDeleg: UploadFolderDeleg;
  uploadFromGoogleDeleg: UploadFromGoogleDeleg;
  getContactsDeleg: GetContactDeleg;
  removeAccessDeleg: SharedResourceDeleg;
  buildRedirectResource: BuildRedirectResourceDeleg;
  checkReadAccessDeleg: CheckReadAccessDeleg;
  repairSharedDummyDeleg: ResourceDeleg;

  constructor({
    listDeleg,
    openFileDeleg,
    downloadDeleg,
    multiDownloadDeleg,
    moveDeleg,
    copyDeleg,
    renameDeleg,
    getFolderSizeDeleg,
    newFolderDeleg,
    enableUploadDeleg,
    getProgressDeleg,
    getContactsDeleg,
    uploadDeleg,
    uploadFolderDeleg,
    uploadFromGoogleDeleg,
    deleteDeleg,
    multiDeleteDeleg,
    clearNotificationDeleg,
    checkReadAccessDeleg,
    repairSharedDummyDeleg,
    getHelpTip
  }: SharedWithMeDriveMount) {
    super();
    this.getHelpTip = getHelpTip;
    this.getContactsDeleg = getContactsDeleg;
    this.tableColumns = [
      "checkBox",
      "icon",
      "name",
      "sharedBy",
      "description",
      "sizeInString",
      "modifiedOn",
    ];

    let getDefaultSharedPathDeleg = (resource: Resource | Resource[]) => {
      if (!this.currentFolder || !this.currentFolder.path || this.currentFolder.path == "") {
        var r: Resource = Array.isArray(resource)
          ? resource && resource.length > 0
            ? resource[0]
            : null
          : resource;
        return r == null
          ? null
          : r instanceof SharedResource
          ? (r as SharedResource).sharedAccessPath
          : r.path;
      }
      
      return this.currentFolder instanceof OriginFolder
      ? this.currentFolder.dummyFolder?.path ?? this.currentFolder.path
      : this.currentFolder.path;
    };

    let defaultSharePathDeleg = (r, d, s, deleg) =>
      deleg(r, d, s == null || s == "" ? getDefaultSharedPathDeleg(r) : s);
    this.listDeleg = listDeleg;
    this.openFileDeleg = (r, d, s) =>
      defaultSharePathDeleg(r, d, s, openFileDeleg);
    this.downloadDeleg = (r, d, s) =>
      defaultSharePathDeleg(r, d, s, downloadDeleg);
    this.multiDownloadDeleg = (r, d, s) =>
      defaultSharePathDeleg(r, d, s, multiDownloadDeleg);
    this.moveDeleg = (r, d, s) => defaultSharePathDeleg(r, d, s, moveDeleg);
    this.copyDeleg = (r, d, s) => defaultSharePathDeleg(r, d, s, copyDeleg);
    this.renameDeleg = (r, d, s) => defaultSharePathDeleg(r, d, s, renameDeleg);
    this.getFolderSizeDeleg = (r, d, s) =>
      defaultSharePathDeleg(r, d, s, getFolderSizeDeleg);
    this.newFolderDeleg = (r, d, s) =>
      defaultSharePathDeleg(r, d, s, newFolderDeleg);
    this.uploadDeleg = (r, d, s) => defaultSharePathDeleg(r, d, s, uploadDeleg);
    this.uploadFolderDeleg = (r, d, s) => defaultSharePathDeleg(r, d, s, uploadFolderDeleg);
    this.uploadFromGoogleDeleg = (r, d, s) =>
      defaultSharePathDeleg(r, d, s, uploadFromGoogleDeleg);
    this.deleteDeleg = (r, d, s) => defaultSharePathDeleg(r, d, s, deleteDeleg);
    this.multiDeleteDeleg = (r, d, s) => defaultSharePathDeleg(r, d, s, multiDeleteDeleg);

    this.clearNotificationDeleg = clearNotificationDeleg;

    this.enableUploadDeleg = enableUploadDeleg;
    this.getProgressDeleg = getProgressDeleg;
    this.checkReadAccessDeleg = checkReadAccessDeleg;
    this.repairSharedDummyDeleg = repairSharedDummyDeleg;

    this.isEnableCreateInFolder = (folder, userId) => folder.hasPermission(userId, AccessPermission.Create, false, true);

    this.enableUploadsBtn = (resource, userId) =>
      this.currentFolder.hasPermission(userId, AccessPermission.UploadFile, false, true);
    
    this.listMultipleLevelsEnabled = (resource, userId) =>
      !resource.isRoot && resource.hasPermission(userId, AccessPermission.View, false, true);

    this.enableMultiDownloadBtn = (resources) => {
      if (!resources || resources.length == 0) return false;

      return !this.currentFolder.isRoot;
    };

    this.enableMultiMoveBtn = (resources, userId) => {
      if (!resources || resources.length == 0) return false;

      if (this.currentFolder.isRoot) return false;

      return resources.every((r) =>
        (r.isFolder && r.hasPermission(userId, AccessPermission.Update)) ||
        (r.isFile && r.hasPermission(userId, AccessPermission.UpdateFile))
      );
    };

    this.enableMultiCopyBtn = (resources, userId) => {
      if (!resources || resources.length == 0) return false;

      if (this.currentFolder.isRoot) return false;

      return resources.every((r) =>
        (r.isFile && r.hasPermission(userId, AccessPermission.UploadFile))
      );
    };

    this.enableMultiDeleteBtn = (resources, userId) => {
      if (!resources || resources.length == 0) return false;

      if (this.currentFolder.isRoot) return false;

      return resources.every((r) => 
        (r.isFolder && r.hasPermission(userId, AccessPermission.Delete)) ||
        (r.isFile && r.hasPermission(userId, AccessPermission.DeleteFile))
      );
    }

    this.buildRedirectResource = (
      path,
      originPath,
      container,
      isFolder,
      name
    ) => {
      if (isFolder) {
        let folder = new OriginFolder();
        folder.path = originPath;
        folder.name = name;
        folder.container = container;
        return folder;
      } else {
        let file = new OriginFile();
        file.path = originPath;
        file.name = name;
        file.container = container;
        return file;
      }
    };
  }

  setDefaultRootFolder(): void {
    //default root folder
    var mainFolder = new SharedFolder();
    mainFolder.isRoot = true;
    mainFolder.name = "Shared With Me";
    this.currentFolder = mainFolder;
    this._addToBreadCrumbs(mainFolder);
  }

  async listRoot(): Promise<SharedFolder> {
    let f = await this.listDeleg();
    let res = [];

    if (f.files) res = res.concat(f.files);
    if (f.folders) res = res.concat(f.folders);

    let root = new SharedFolder();
    root.isRoot = true;
    root.name = "Shared With Me";

    var contacts = await this.getContactsDeleg();
    res.forEach((r) => {
      var owner = contacts.find((c) => c.userId === r.ownerId);
      r.sharedBy = owner?.displayName;
    });

    root.folders = res.filter(r => r.isFolder).map(a => a as SharedFolder);
    root.files = res.filter(r => r.isFile).map(a => a as SharedFile);

    return root;
  }

  override list(folder: OriginFolder | SharedFolder = null, depth?: FolderHierarchy) {
    if (folder == null) {
      this.setDefaultRootFolder();
    } else {
      this.currentFolder = folder;
    }
    return this.listDeleg(folder, {depth: depth}, (this.currentFolder instanceof OriginFolder) ? (this.currentFolder as OriginFolder).dummyFolder.path : null)
    .then(async (f: OriginFolder | SharedFolder) => {
      let res = [];

      if (f.files) res = res.concat(f.files);
      if (f.folders) res = res.concat(f.folders);
      var contacts = await this.getContactsDeleg();
      res.forEach((r) => {
        var owner = contacts.find((c) => c.userId === r.ownerId);
        r.sharedBy = owner?.displayName;
      });
      
      if (folder == null) {
        let root = new SharedFolder();
        root.isRoot = true;
        root.name = "Shared With Me";
        res.forEach((r) =>
          Resource.isFile(r) ? root.files.push(r) : root.folders.push(r)
        );
        folder = root;
      } else {
        folder = f;
        // this.currentFolder = folder;
      }
      //prevent override current folder when prev call have not return
      if (
        this.currentFolder.isSameResource(folder) ||
        (this.currentFolder.isRoot && folder.isRoot) ||
        (this.currentFolder instanceof SharedFolder &&
          folder instanceof OriginFolder &&
          this.currentFolder.accessPath === folder.path)
      ) {
        this.currentFolder = folder;
        this.resourcesSubject.next(res);
      }
      return folder;
    });
  }

  _addToBreadCrumbs(folder: SharedFolder, forward: boolean = true): void {
    var prevValue = this._breadCrumbs as SharedFolder[];
    let index = Drive.findResourceIndex(this._breadCrumbs, folder);
    if (forward) {
      if (index == -1) {
        this._breadCrumbs =
          prevValue && prevValue.length > 0 ? [...prevValue, folder] : [folder];
      }
    } else {
      if (index == -1) return;
      this._breadCrumbs = this._breadCrumbs.slice(0, index + 1);
    }
    this.breadCrumbsSubject.next(this._breadCrumbs);
  }
}

export class SharedDrive extends Drive {
  id: string;
  claims: AccessRight[];
  name: string;
  driveType = DriveType.EnterpriseShared;
  protected _addToBreadCrumbs(
    folder: CloudFolder,
    forward: boolean = true
  ): void {
    var prevValue = this._breadCrumbs as CloudFolder[];

    let index = Drive.findResourceIndex(this._breadCrumbs, folder);
    if (forward) {
      if (index == -1) {
        this._breadCrumbs =
          prevValue && prevValue.length > 0 ? [...prevValue, folder] : [folder];
      }
    } else {
      if (index == -1) return;
      this._breadCrumbs = this._breadCrumbs.slice(0, index + 1);
    }
    this.breadCrumbsSubject.next(this._breadCrumbs);
  }

  setDefaultRootFolder(): void {
    //default root folder
    var mainFolder = new CloudFolder();
    mainFolder.isRoot = true;
    mainFolder.name = "Shared Drive";
    mainFolder.icon = "storage";
    this.currentFolder = mainFolder;
    this._addToBreadCrumbs(mainFolder);
  }

  copyDeleg: CopyResourcesDeleg;
  moveDeleg: MoveDeleg;
  getContactsDeleg: GetContactDeleg;
  listDeleg: ResourceDeleg;
  openFileDeleg: GenericResourceDeleg;
  downloadDeleg: GenericResourceDeleg;
  multiDownloadDeleg: BaseResourceDeleg<SharedDriveFolder[] |SharedDriveFile[], any, Promise<any>>;
  renameDeleg: SharedDriveRenameDeleg;
  deleteDeleg: SharedDriveDeleteResourceDeleg;
  multiDeleteDeleg: SharedDriveDeleteResourcesDeleg;
  getFolderSizeDeleg: GenericResourceDeleg;
  enableUploadDeleg: EnableActionDeleg<CloudFolder | SharedFolder>;
  uploadDeleg: SharedDriveUploadDeleg;
  uploadFolderDeleg: SharedDriveUploadFolderDeleg;
  uploadFromGoogleDeleg: SharedDriveUploadFromGoogleDeleg;
  shareDeleg: ShareDeleg;
  newFolderDeleg: SharedDriveNewFolderDeleg;
  checkReadAccessDeleg: CheckReadAccessDeleg;

  async listRoot(): Promise<CloudFolder> {
    let res = await this.listDeleg();
    if (res == null) return new CloudFolder();
    res.name = "Shared Drive";
    res.isRoot = true;

    if (this.currentFolder && this.currentFolder.path == res.path) {
      let resources: Resource[] = [];
      if (res.folders != null) {
        resources.push(...res.folders);
      }

      if (res.files != null) {
        resources.push(...res.files);
      }

      this.resourcesSubject.next(resources);
    }

    return res;
    
  }

  override list(folder: CloudFolder = null, depth?: FolderHierarchy) {
    if (folder == null) {
      this.setDefaultRootFolder();
    } else {
      this.currentFolder = folder;
    }
    return this.listDeleg(folder, {depth: depth}).then(async (res) => {
      let resources: Resource[] = [];
      if (folder == null || res.isRoot || folder.isRoot) {
        res.name = "Shared Drive";
        res.isRoot = true;
        let root = this._breadCrumbs[0];
        if (root != null && root.isRoot) {
          root.path = res.path;
        }
      }
      //prevent override current folder when prev call have not return
      if (
        this.currentFolder.isSameResource(res) ||
        (this.currentFolder.isRoot && res.isRoot)
      ) {
        this.currentFolder = res;

        if (res.folders != null) {
          resources.push(...res.folders);
        }

        if (res.files != null) {
          resources.push(...res.files);
        }

        this.resourcesSubject.next(resources);
      }
      return res;
    });
  }

  buildRedirectResource: BuildRedirectResourceDeleg;

  constructor(id: string, name: string, claims: AccessRight[], {
    listDeleg,
    openFileDeleg,
    downloadDeleg,
    multiDownloadDeleg,
    deleteDeleg,
    multiDeleteDeleg,
    uploadDeleg,
    uploadFolderDeleg,
    uploadFromGoogleDeleg,
    moveDeleg,
    copyDeleg,
    renameDeleg,
    getFolderSizeDeleg,
    newFolderDeleg,
    enableUploadDeleg,
    getProgressDeleg,
    getContactsDeleg,
    shareDeleg,
    clearNotificationDeleg,
    checkReadAccessDeleg,
    getHelpTip
  }: SharedDriveMount) {
    super();
    this.id = id;
    this.name = name;
    this.claims = claims;

    this.listDeleg = listDeleg;
    this.openFileDeleg = openFileDeleg;
    this.downloadDeleg = downloadDeleg;
    this.multiDownloadDeleg = multiDownloadDeleg;
    this.deleteDeleg = deleteDeleg;
    this.multiDeleteDeleg = multiDeleteDeleg;
    this.uploadDeleg = uploadDeleg;
    this.uploadFolderDeleg = uploadFolderDeleg;
    this.uploadFromGoogleDeleg = uploadFromGoogleDeleg;
    this.moveDeleg = moveDeleg;
    this.copyDeleg = copyDeleg;
    this.renameDeleg = renameDeleg;
    this.getFolderSizeDeleg = getFolderSizeDeleg;
    this.newFolderDeleg = newFolderDeleg;
    this.enableUploadDeleg = enableUploadDeleg;
    this.getProgressDeleg = getProgressDeleg;
    this.getContactsDeleg = getContactsDeleg;
    this.shareDeleg = shareDeleg;
    this.getHelpTip = getHelpTip;

    this.clearNotificationDeleg = clearNotificationDeleg;
    this.checkReadAccessDeleg = checkReadAccessDeleg;
    var enableIfNotEmpty = (resources) => {
      if (!resources || resources.length == 0) return false;

      return true;
    };
    this.enableMultiDownloadBtn = enableIfNotEmpty;
    this.enableMultiMoveBtn = enableIfNotEmpty;
    this.enableMultiCopyBtn = enableIfNotEmpty;
    this.enableMultiDeleteBtn = enableIfNotEmpty;

    this.buildRedirectResource = (
      path,
      originPath,
      container,
      isFolder,
      name,
      fullPath
    ) => {
      if (isFolder) {
        let folder = new CloudFolder();
        folder.path = path;
        folder.name = name;
        folder.container = container;
        return folder;
      } else {
        let file = new CloudFile();
        file.path = fullPath;
        file.name = name;
        file.container = container;
        return file;
      }
    };
  }
}

export class SharedDriveCollection extends Drive {
  public driveType: DriveType = DriveType.EnterpriseSharedCollection;
  protected _addToBreadCrumbs(
    folder: CloudFolder,
    forward: boolean = true
  ): void {
    var prevValue = this._breadCrumbs as CloudFolder[];

    let index = Drive.findResourceIndex(this._breadCrumbs, folder);
    if (forward) {
      if (index == -1) {
        this._breadCrumbs =
          prevValue && prevValue.length > 0 ? [...prevValue, folder] : [folder];
      }
    } else {
      if (index == -1) return;
      this._breadCrumbs = this._breadCrumbs.slice(0, index + 1);
    }
    this.breadCrumbsSubject.next(this._breadCrumbs);
  }
  setDefaultRootFolder(): void {
    //default root folder
    var mainFolder = new SharedDriveFolder();
    mainFolder.isRoot = true;
    mainFolder.name = "Shared Drive";
    this.currentFolder = mainFolder;
    if (this.currentDrive) this.currentDrive.setDefaultRootFolder();
    this._addToBreadCrumbs(mainFolder);
  }
  _currentFolder: SharedDriveFolder;

  set currentFolder(data: SharedDriveFolder) {
    this._currentFolder = data;
    if (this.currentDrive) {
      this.currentDrive.currentFolder = data;
    }
    if (!this._currentFolder.isRoot) {
      let index = this.tableColumns.indexOf("emptyCheckBox");
      if (index != -1) {
        this.tableColumns.splice(index, 1, "checkBox"); //replace emptyCheckBox with checkbox
      } else {
        let index = this.tableColumns.indexOf("checkBox");
        if (index == -1) this.tableColumns.splice(0, 0, "checkBox"); //add checkbox
      }
    } else {
      let index = this.tableColumns.indexOf("checkBox");
      if (index != -1) {
        this.tableColumns.splice(index, 1, "emptyCheckBox"); //replace checkBox with emptyCheckBox
      } else {
        let index = this.tableColumns.indexOf("emptyCheckBox");
        if (index == -1) this.tableColumns.splice(0, 0, "emptyCheckBox"); //add emptyCheckBox
      }
    }
  }
  get currentFolder(): SharedDriveFolder {
    return this._currentFolder;
  }
  //#region Not used
  copyDeleg: SharedDriveCopyResourcesDeleg;
  moveDeleg: MoveDeleg;
  openFileDeleg: GenericResourceDeleg;
  downloadDeleg: GenericResourceDeleg;
  multiDownloadDeleg: BaseResourceDeleg<SharedDriveFolder[] |SharedDriveFile[], any, Promise<any>>;
  multiDeleteDeleg: SharedDriveDeleteResourcesDeleg;
  getFolderSizeDeleg: GenericResourceDeleg;
  enableUploadDeleg: EnableActionDeleg<SharedDriveFolder>;
  uploadDeleg: SharedDriveUploadDeleg;
  uploadFolderDeleg: SharedDriveUploadFolderDeleg;
  uploadFromGoogleDeleg: SharedDriveUploadFromGoogleDeleg;
  buildRedirectResource: BuildRedirectResourceDeleg;
  getProgressDeleg: BaseResourceDeleg<void, any, CloudObjState[]>;
  //#endregion

  getContactsDeleg: GetContactDeleg;
  listDeleg: GenericResourceDeleg;
  renameDeleg: GenericResourceDeleg;
  newFolderDeleg: GenericResourceDeleg;
  shareDeleg: ShareDeleg;
  buildSharedDriveDeleg: BuildSharedDriveDeleg;
  deleteDeleg: SharedDriveDeleteDeleg;
  checkReadAccessDeleg: CheckReadAccessDeleg

  drives: SharedDrive[];
  currentDriveId: string;

  get currentDrive(): SharedDrive {
    if (this.drives == null || this.drives.length == 0 || !this.currentDriveId)
      return null;
    return this.drives.find((d) => d.id === this.currentDriveId);
  }

  getDisplayClaims(resource: SharedDriveFile | SharedDriveFolder): AccessRight[] {
    if (resource == null) return [];
    return resource.driveId
      ? super.getDisplayClaims(resource)
      : resource.grantees;//drive folder should gap
  }

  getActions(
    resources: Resource[],
    userId: string,
    isUploadAction: boolean = false
  ): ResourceAction<any, any, any>[] {
    if (resources == null || resources.length == 0) {
      if (this.currentDrive == null) return [];

      let actions = isUploadAction
        ? this.currentDrive.uploadActions
        : this.currentDrive.withinFolderActions;

      return actions == null || actions.length == 0
        ? []
        : actions.filter((a) => a.isEnableDeleg(this.currentFolder, userId));
    } else if (resources.length == 1) {
      let resource = resources[0];
      let actions = [];
      if (resource.isFolder) {
        if ((resource as SharedDriveFolder).isRoot) {
          actions = this.withinFolderActions;
        } else if ((resource as SharedDriveFolder).id) {
          actions = this.folderActions;
        } else {
          if (
            this.currentDrive == null ||
            ((resource as SharedDriveFolder).driveId &&
              (resource as SharedDriveFolder).driveId !== this.currentDrive.id)
          ) {
            return [];
          }
          actions = this.currentDrive.folderActions;
        }
      } else {
        if (
          this.currentDrive == null ||
          ((resource as SharedDriveFolder).driveId &&
            (resource as SharedDriveFolder).driveId !== this.currentDrive.id)
        ) {
          return [];
        }
        actions = this.currentDrive.fileActions;
      }
      return actions.filter((a) => a.isEnableDeleg(resource, userId));
    } else {
      if (
        this.currentDrive == null ||
        resources.some(
          (r) =>
            (r as SharedDriveFolder).driveId &&
            (r as SharedDriveFolder).driveId !== this.currentDrive.id
        )
      ) {
        return [];
      }
      return this.currentDrive.multiResourcesActions.filter((a) =>
        a.isEnableDeleg(resources, userId)
      );
    }
  }

  getCreateFolderTitle(selectedFolder: SharedDriveFolder) {
    if (!selectedFolder || selectedFolder.driveId) return null; //fallback to default
    return "New Shared Drive"; //for drives
  }

  getCreateFolderInput(selectedFolder: SharedDriveFolder) {
    if (!selectedFolder || selectedFolder.driveId) return null; //fallback to default
    return "Drive name"; //for drives
  }

  enableExternalShare(resource: SharedDriveFolder | SharedDriveFile): boolean {
    return resource && resource.driveId
      ? this.currentDrive.enableExternalShare(resource)
      : false;
  }

  getShareTitle(resource: SharedDriveFolder | SharedDriveFile) {
    return resource && resource.driveId
      ? this.currentDrive.getShareTitle(resource)
      : "Manage Members";
  }

  canChangeResourceSettings(resource: SharedDriveFolder | SharedDriveFile, userId: string): boolean {
    if (!resource || !resource.driveId) return false;
    return this.currentFolder.hasDrivePermission(userId, AccessPermission.Update, this.currentDrive?.claims);
  }

  private _buildRedirectResource(
    path,
    originPath,
    container,
    isFolder,
    name,
    fullPath
  ) {
    if (isFolder) {
      let folder = new CloudFolder();
      folder.path = path;
      folder.name = name;
      folder.container = container;
      return folder;
    } else {
      let file = new CloudFile();
      file.path = fullPath;
      file.name = name;
      file.container = container;
      return file;
    }
  };

  constructor({
    listDeleg,
    renameDeleg,
    newFolderDeleg,
    shareDeleg,
    buildSharedDriveDeleg,
    getContactsDeleg,
    deleteDeleg,
    checkReadAccessDeleg,
    getHelpTip
  }: SharedDriveCollectionMount) {
    super();
    this.getHelpTip = getHelpTip;
    this.copyDeleg = (r, d, s) =>
    r && r.some((i) => i.driveId)
      ? this.currentDrive.copyDeleg(r, d, s)
      : Promise.reject("Shared Drive root does not support copy");
  this.moveDeleg = (r, d, s) =>
    r && (Array.isArray(r) && r.some((i) => i.driveId) || r.driveId)
      ? this.currentDrive.moveDeleg(r, d, s)
      : null;
  this.openFileDeleg = (r, d, s) =>
    r && (Array.isArray(r) && r.some((i) => i.driveId) || r.driveId)
      ? this.currentDrive.openFileDeleg(r, d, s)
      : Promise.reject("Shared Drive root does not support open file");
  this.downloadDeleg = (r, d, s) =>
    r && r.driveId
      ? this.currentDrive.downloadDeleg(r, d, s)
      : Promise.reject("Shared Drive root does not support download");
  this.multiDownloadDeleg = (r, d, s) =>
    r && r.some((i) => i.driveId)
      ? this.currentDrive.multiDownloadDeleg(r, d, s)
      : Promise.reject("Shared Drive root does not support download");
  this.multiDeleteDeleg = (r, d, s) => 
    r && r.some((i) => i.driveId)
      ? this.currentDrive.multiDeleteDeleg(r, d, s)
      : Promise.reject("Shared Drive root does not support delete");
  this.getFolderSizeDeleg = (r, d, s) =>
    r && r.driveId
      ? this.currentDrive.getFolderSizeDeleg(r, d, s)
      : Promise.reject("Shared Drive root does not support download");
  this.enableUploadDeleg = (r, u) =>
    r && r.driveId ? this.currentDrive.enableUploadDeleg(r, u) : false;
  this.uploadDeleg = (r, d, s) =>
    r && r.driveId
      ? this.currentDrive.uploadDeleg(r, d, s)
      : Promise.reject("Shared Drive root does not support upload");
  this.uploadFolderDeleg = (r, d, s) => 
    r && r.driveId
      ? this.currentDrive.uploadFolderDeleg(r,d, s)
      : Promise.reject("Shared Drive root does not support upload");
  this.uploadFromGoogleDeleg = (r, d, s) =>
    r && r.driveId
      ? this.currentDrive.uploadFromGoogleDeleg(r, d, s)
      : Promise.reject("Shared Drive root does not support upload");
  this.buildRedirectResource = (
    path,
    originPath,
    container,
    isFolder,
    name,
    folder
  ) =>
    this.currentDrive
      ? this.currentDrive.buildRedirectResource(
          path,
          originPath,
          container,
          isFolder,
          name,
          folder
        )
      : this._buildRedirectResource(
        path,
        originPath,
        container,
        isFolder,
        name,
        folder
      );
  this.getProgressDeleg = (r, d, s) =>
    this.currentDrive ? this.currentDrive.getProgressDeleg(r, d, s) : [];   
      
    this.listDeleg = (r, d, s) =>{
      if (r && (r.driveId || r.id)) {
        if (
          this.currentDrive &&
          (this.currentDrive.id == r.driveId || this.currentDrive.id == r.id)
        )
          return this.currentDrive.listDeleg(r, d, s);
        else var drive = this.drives.find((d) => d.id === r.id);
        if (drive) return drive.listDeleg(r, d, s);
      }
      return listDeleg(r, d, s);
    }
      
    this.renameDeleg = (r, d, s) =>
      r && r.driveId
        ? this.currentDrive.renameDeleg(r, d, s)
        : renameDeleg(r, d, s);
    this.newFolderDeleg = (r, d, s) =>
      r && r.driveId
        ? this.currentDrive.newFolderDeleg(r, d, s)
        : newFolderDeleg(r, d, s);
    this.shareDeleg = (r, d, s) =>
      r && r.driveId
        ? this.currentDrive.shareDeleg(r, d, s)
        : shareDeleg(r, d, s);
    this.deleteDeleg = (r, d, s) =>
      r && r.driveId
        ? this.currentDrive.deleteDeleg(r, d, s)
        : deleteDeleg(r, d, s);
    this.enableMultiDownloadBtn = (resources, userId) =>
      this.currentDrive
        ? this.currentDrive.enableMultiDownloadBtn(resources, userId)
        : false;
    this.enableMultiMoveBtn = (resources, userId) =>
      this.currentDrive
        ? this.currentDrive.enableMultiMoveBtn(resources, userId)
        : false;
    this.enableMultiCopyBtn = (resources, userId) =>
      this.currentDrive
        ? this.currentDrive.enableMultiCopyBtn(resources, userId)
        : false;
    this.enableMultiDeleteBtn = (resources, userId) =>
      this.currentDrive
        ? this.currentDrive.enableMultiDeleteBtn(resources, userId)
        : false;
    this.enableUploadsBtn = (resources, userId) =>
        this.currentDrive
        ? this.currentDrive.enableUploadsBtn(resources, userId)
        : false;
    
    this.buildSharedDriveDeleg = buildSharedDriveDeleg;
    this.getContactsDeleg = getContactsDeleg;
    this.checkReadAccessDeleg = () =>
      this.currentDrive
        ? this.currentDrive.checkReadAccessDeleg()
        : checkReadAccessDeleg();

    this.enableUploadsBtn = (resource, userId) =>
      this.currentFolder.hasDrivePermission(userId, AccessPermission.UploadFile, this.currentDrive?.claims);

    this.isEnableCreateInFolder = (folder, userId) => 
      this.currentDrive
      ? this.currentFolder.hasDrivePermission(userId, AccessPermission.Create, this.currentDrive?.claims)
      : folder.hasPermission(userId, AccessPermission.Create);

      this.listMultipleLevelsEnabled = (resource, userId) =>
        resource.isRoot ? false : this.currentFolder.hasDrivePermission(userId, AccessPermission.View, this.currentDrive?.claims);

    this.drives = [];
  }

  async listRoot() {
    var res = await this.listDeleg();
    if (res == null) return new SharedDriveFolder();
    
    this.drives = res.folders.map((f) => 
      this.buildSharedDriveDeleg((f as SharedDriveFolder).id, f.name, f.grantees)
    );
    res.name = "Shared Drive";
    res.isRoot = true;

    if (this.currentFolder && this.currentFolder.path == res.path) {
      let resources: Resource[] = [];
      if (res.folders != null) {
        resources.push(...res.folders);
      }

      if (res.files != null) {
        resources.push(...res.files);
      }

      this.resourcesSubject.next(resources);
    }

    return res;  
  }

  override list(folder: SharedDriveFolder = null, depth?: FolderHierarchy): Promise<SharedDriveFolder> {
    let promise;
    if (folder == null || folder.isRoot) {
      this.currentDriveId = null;
      this.setDefaultRootFolder();
      promise = this.listDeleg().then((res) => {
        this.drives = res.folders.map((f) =>
          this.buildSharedDriveDeleg((f as SharedDriveFolder).id, f.name, f.grantees)
        );
        res.name = "Shared Drive";
        res.isRoot = true;
        return res;
      });
    } else {
      if (!folder.driveId && !folder.id)
        return Promise.reject("Invalid drive Id");
      this.switchDrive(folder.driveId ?? folder.id);
      this.currentFolder = folder;
      promise = this.currentDrive.listDeleg(folder, {depth: depth});
    }
    return promise.then((res) => {
      let resources: Resource[] = [];
      if (folder && folder.id) {
        res.id = folder.id;
      }

      //prevent override current folder when prev call have not return
      if (
        this.currentFolder.isSameResource(res) ||
        (this.currentFolder.isRoot && res.isRoot)
      ) {
        this.currentFolder = res;

        if (res.folders != null) {
          resources.push(...res.folders);
        }

        if (res.files != null) {
          resources.push(...res.files);
        }

        this.resourcesSubject.next(resources);
      }
      return res;
    });
  }

  switchDrive(driveId: string) {
    if (
      driveId == null ||
      this.drives == null ||
      this.drives.length == 0 ||
      this.currentDriveId == driveId
    )
      return;
    var existing = this.drives.find((d) => d.id === driveId);
    if (existing) {
      this.currentDriveId = existing.id;
      this.currentDrive.claims = existing.claims;
    }
  }
}

//#region For file picker used in composers
export class MyDrivePicker extends MyDrive {
  constructor({
    listDeleg,
    openFileDeleg,
    downloadDeleg,
    multiDownloadDeleg,
    deleteDeleg,
    multiDeleteDeleg,
    uploadDeleg,
    uploadFolderDeleg,
    uploadFromGoogleDeleg,
    moveDeleg,
    copyDeleg,
    renameDeleg,
    getFolderSizeDeleg,
    newFolderDeleg,
    enableUploadDeleg,
    getProgressDeleg,
    getContactsDeleg,
    shareDeleg,
    clearNotificationDeleg,
    checkReadAccessDeleg,
    getHelpTip
  }: MyDriveMount) {
    super({listDeleg,
      openFileDeleg,
      downloadDeleg,
      multiDownloadDeleg,
      deleteDeleg,
      multiDeleteDeleg,
      uploadDeleg,
      uploadFolderDeleg,
      uploadFromGoogleDeleg,
      moveDeleg,
      copyDeleg,
      renameDeleg,
      getFolderSizeDeleg,
      newFolderDeleg,
      enableUploadDeleg,
      getProgressDeleg,
      getContactsDeleg,
      shareDeleg,
      clearNotificationDeleg,
      checkReadAccessDeleg,
      getHelpTip});

    this.getHelpTip = null;
    this.enableMultiCopyBtn = null;
    this.enableMultiDeleteBtn = null;
    this.enableUploadsBtn = null;
    this.enableMultiDownloadBtn = null;
    this.enableMultiMoveBtn = null;
    let index = this.tableColumns.indexOf("checkBox");
    if (index != -1) {
      this.tableColumns.splice(index, 1);
    }
  }

 }
export class SharedWithMeDrivePicker extends SharedWithMeDrive {
  // both getter and setter must be overridden or else it uses the parent accessor
  _currentFolder: SharedFolder;
  set currentFolder(data: SharedFolder) {
    this._currentFolder = data;
  }
  get currentFolder(): SharedFolder {
    return this._currentFolder;
  }

  constructor({
    listDeleg,
    openFileDeleg,
    downloadDeleg,
    multiDownloadDeleg,
    moveDeleg,
    copyDeleg,
    renameDeleg,
    getFolderSizeDeleg,
    newFolderDeleg,
    enableUploadDeleg,
    getProgressDeleg,
    getContactsDeleg,
    uploadDeleg,
    uploadFolderDeleg,
    uploadFromGoogleDeleg,
    deleteDeleg,
    multiDeleteDeleg,
    clearNotificationDeleg,
    checkReadAccessDeleg,
    repairSharedDummyDeleg,
    getHelpTip
  }: SharedWithMeDriveMount) {
    super({
      listDeleg,
      openFileDeleg,
      downloadDeleg,
      multiDownloadDeleg,
      moveDeleg,
      copyDeleg,
      renameDeleg,
      getFolderSizeDeleg,
      newFolderDeleg,
      enableUploadDeleg,
      getProgressDeleg,
      getContactsDeleg,
      uploadDeleg,
      uploadFolderDeleg,
      uploadFromGoogleDeleg,
      deleteDeleg,
      multiDeleteDeleg,
      clearNotificationDeleg,
      checkReadAccessDeleg,
      repairSharedDummyDeleg,
      getHelpTip
    });

    this.getHelpTip = null;
    this.enableMultiCopyBtn = null;
    this.enableMultiDeleteBtn = null;
    this.enableUploadsBtn = null;
    this.enableMultiDownloadBtn = null;
    this.enableMultiMoveBtn = null;
    let index = this.tableColumns.indexOf("checkBox");
    if (index != -1) {
      this.tableColumns.splice(index, 1);
    }
  }
 }

 export class SharedDriveCollectionPicker extends SharedDriveCollection {
  constructor({
    listDeleg,
    renameDeleg,
    newFolderDeleg,
    shareDeleg,
    buildSharedDriveDeleg,
    getContactsDeleg,
    deleteDeleg,
    checkReadAccessDeleg,
    getHelpTip
  }: SharedDriveCollectionMount) {
    super({
      listDeleg,
      renameDeleg,
      newFolderDeleg,
      shareDeleg,
      buildSharedDriveDeleg,
      getContactsDeleg,
      deleteDeleg,
      checkReadAccessDeleg,
      getHelpTip
    });

    this.getHelpTip = null;
    this.enableMultiCopyBtn = null;
    this.enableMultiDeleteBtn = null;
    this.enableUploadsBtn = null;
    this.enableMultiDownloadBtn = null;
    this.enableMultiMoveBtn = null;
    let index = this.tableColumns.indexOf("checkBox");
    if (index != -1) {
      this.tableColumns.splice(index, 1);
    }

  this.getProgressDeleg = (r, d, s) =>
    this.currentDrive ? this.currentDrive.getProgressDeleg(r, d, s) : [];   
      
    this.listDeleg = (r, d, s) =>
      r && r.driveId
        ? this.currentDrive.listDeleg(r, d, s)
        : listDeleg(r, d, s);
    
    this.buildSharedDriveDeleg = buildSharedDriveDeleg;
    this.getContactsDeleg = getContactsDeleg;
    this.checkReadAccessDeleg = () =>
      this.currentDrive
        ? this.currentDrive.checkReadAccessDeleg()
        : checkReadAccessDeleg();

    this.drives = [];
  }
}

//#endregion 
export class ResourceAction<R, D, O> {
  icon: string;
  name: string;
  delegate: BaseResourceDeleg<R, D, O>;
  isEnableDeleg?: EnableActionDeleg<Resource | Resource[]>;
  currentFolder: CloudFolder | SharedFolder;

  constructor(
    name: string,
    icon: string,
    delegate: BaseResourceDeleg<R, D, O>,
    permissionSetting: AccessPermissionSetting = null,
    isEnableDeleg: EnableActionDeleg<Resource | Resource[]> = null
  ) {
    this.name = name;
    this.icon = icon;
    this.delegate = delegate;
    let defaultEnableDel = (_) => true;
    if (permissionSetting != null) {
      if (!isEnableDeleg && permissionSetting) {
        isEnableDeleg = (resource, userId) => {
          if (resource instanceof Resource) {
            return resource.hasPermission(
              userId,
              permissionSetting.permission,
              permissionSetting.folderContentOnly,
            );
          } else if (Array.isArray(resource)) {
            return resource.every((r) =>
              r.hasPermission(
                userId,
                permissionSetting.permission,
                permissionSetting.folderContentOnly,
              )
            );
          }
          return true;
        };
      }
    }
    this.isEnableDeleg = isEnableDeleg ?? defaultEnableDel;
  }
}

export class SharedResourceAction<R, D, O> extends ResourceAction<R, D, O> {
  currentFolder: SharedFolder;

  constructor(
    name: string,
    icon: string,
    delegate: BaseResourceDeleg<R, D, O>,
    permissionSetting: AccessPermissionSetting = null,
    isEnableDeleg: EnableActionDeleg<Resource | Resource[]> = null
  ) {
    if (permissionSetting != null) {
      if (!isEnableDeleg && permissionSetting) {
        isEnableDeleg = (resource, userId) => {
          if (resource instanceof Resource) {
            return resource.hasPermission(
              userId,
              permissionSetting.permission,
              permissionSetting.folderContentOnly,
              true
            );
          } else if (Array.isArray(resource)) {
            return resource.every((r) =>
              r.hasPermission(
                userId,
                permissionSetting.permission,
                permissionSetting.folderContentOnly,
                true
              )
            );
          }
          return true;
        };
      }
    }
    // let deleg = (resource, data) => {
    //   var sharedPath =
    //     !this.currentFolder ||
    //     !this.currentFolder.path ||
    //     this.currentFolder.path == ""
    //       ? resource?.path
    //       : this.currentFolder.path;
    //   return delegate(resource, data, sharedPath);
    // };
    super(name, icon, delegate, null, isEnableDeleg);
  }
}

export class SharedDriveResourceAction<D, O> extends ResourceAction<SharedDriveFolder | SharedDriveFile | Resource[], D, O> {
  constructor(
    name: string,
    icon: string,
    getDriveClaimsDeleg: GetSharedDriveAccessDeleg,
    delegate: BaseResourceDeleg<SharedDriveFolder | SharedDriveFile | Resource[], D, O>,
    permissionSetting: AccessPermissionSetting = null,
    isEnableDeleg: EnableActionDeleg<SharedDriveFolder | SharedDriveFile | Resource[]> = null
  ) {

    if (permissionSetting != null) {
      if (!isEnableDeleg && permissionSetting) {
        isEnableDeleg = (resource, userId) => {
          if (resource instanceof SharedDriveFolder || resource instanceof SharedDriveFile) {
            let driveId = resource.driveId ? resource.driveId: resource instanceof SharedDriveFolder? resource.id: null;
            return resource.hasDrivePermission(
              userId,
              permissionSetting.permission,
              getDriveClaimsDeleg(driveId),
            );
          } else if (Array.isArray(resource)) {
            return resource.every((r) =>{
              if (
                r instanceof SharedDriveFolder ||
                r instanceof SharedDriveFile
              ) {
                let driveId = r.driveId
                  ? r.driveId
                  : r instanceof SharedDriveFolder
                  ? r.id
                  : null;

                return r.hasDrivePermission(
                  userId,
                  permissionSetting.permission,
                  getDriveClaimsDeleg(driveId)
                );
              }
                return false;}
            );
          }
          return true;
        };
      }
    }
    super(name, icon, delegate, null, isEnableDeleg);
  }

}

export class AccessPermissionSetting {
  permission: AccessPermission;
  folderContentOnly: boolean;
  constructor(
    permission: AccessPermission,
    folderContentOnly: boolean = false
  ) {
    this.permission = permission;
    this.folderContentOnly = folderContentOnly;
  }
}

interface DriveMount {
  listDeleg: ListResourcesDeleg;
  openFileDeleg: ResourceDeleg;
  downloadDeleg: ResourceDeleg;
  multiDownloadDeleg: ResourcesDeleg;
  getFolderSizeDeleg: GetFoldersSizeDeleg;
  getProgressDeleg: BaseResourceDeleg<void, any, CloudObjState[]>;
  renameDeleg: RenameDeleg;
  deleteDeleg: ResourceDeleg;
  multiDeleteDeleg: DeleteResourcesDeleg;
  getContactsDeleg: GetContactDeleg;
  moveDeleg: MoveDeleg;
  copyDeleg: CopyResourcesDeleg;
  uploadDeleg: UploadDeleg;
  uploadFolderDeleg: UploadFolderDeleg;
  uploadFromGoogleDeleg: UploadFromGoogleDeleg;
  newFolderDeleg: ResourceDeleg;
  enableUploadDeleg: EnableActionDeleg<Resource>;
  getHelpTip: GetHelpTipDeleg;
  clearNotificationDeleg?: () => void;
  checkReadAccessDeleg: CheckReadAccessDeleg;
}

interface MyDriveMount extends DriveMount {
  uploadDeleg: UploadDeleg;
  uploadFolderDeleg: UploadFolderDeleg;
  uploadFromGoogleDeleg: UploadFromGoogleDeleg;
  moveDeleg: MoveDeleg;
  copyDeleg: CopyResourcesDeleg;
  newFolderDeleg: NewFolderDeleg;
  enableUploadDeleg: EnableActionDeleg<CloudFolder>;
  listDeleg: BaseResourceDeleg<Resource, any, Promise<CloudFolder>>;
  openFileDeleg: OpenFileDeleg;
  shareDeleg: ShareDeleg;
}

interface SharedWithMeDriveMount extends DriveMount {
  uploadDeleg: SharedUploadDeleg;
  uploadFolderDeleg:  SharedUploadFolderDeleg;
  uploadFromGoogleDeleg: SharedUploadFromGoogleDeleg;
  listDeleg: BaseResourceDeleg<CloudFolder|SharedFolder, any, Promise<OriginFolder | SharedFolder>>;
  openFileDeleg: SharedOpenFileDeleg;
  downloadDeleg: ResourceDeleg;
  multiDownloadDeleg: ResourcesDeleg;
  multiDeleteDeleg: ResourcesDeleg;
  renameDeleg: SharedRenameDeleg;
  moveDeleg: MoveDeleg;
  copyDeleg: CopyResourcesDeleg;
  getFolderSizeDeleg: GetSharedFoldersSizeDeleg;
  newFolderDeleg: SharedNewFolderDeleg;
  enableUploadDeleg: EnableActionDeleg<SharedFolder>;
  getProgressDeleg: BaseResourceDeleg<void, any, CloudObjState[]>;
  repairSharedDummyDeleg: ResourceDeleg;
}

interface SharedDriveMount extends MyDriveMount {
  newFolderDeleg: SharedDriveNewFolderDeleg;
}
interface SharedDriveCollectionMount {
  listDeleg: BaseResourceDeleg<SharedDriveFolder, any, Promise<SharedDriveFolder>>;
  newFolderDeleg: NewFolderDeleg;
  renameDeleg: RenameDeleg;
  shareDeleg: ShareDeleg;
  buildSharedDriveDeleg: BuildSharedDriveDeleg;
  getContactsDeleg: GetContactDeleg;
  deleteDeleg: SharedDriveDeleteDeleg;
  checkReadAccessDeleg: CheckReadAccessDeleg;
  getHelpTip: GetHelpTipDeleg;
}