import { Component, OnInit } from '@angular/core';
import { ActivityDisplay } from '../../core/model/activity.state';
import { Feed, FeedStatus } from '../../core/model/feed';
import { SubSink } from 'subsink/dist/subsink';
import { EnterpriseState } from '../../core/states/enterprise.state';
import { Observable, distinctUntilChanged } from 'rxjs';
import { OrgState } from '../../core/model/org.state';
import { Select } from '@ngxs/store';
import { SystemHub } from '../../core/hub/system.hub';
import { PubSub } from '../../core/services/pubsub.service';
import { EnterpriseSelector } from '../../core/states/enterprise.state.selector';
import { FeedState } from '../../core/states/feed.state';
import { Emittable, Emitter } from '@ngxs-labs/emitter';
import { InMemFeedState } from '../../core/states/inmem.feed.state';
import { FeedService } from 'app/core/services/feed.service';

@Component({
  selector: 'app-activity-page',
  templateUrl: './activity-page.component.html',
  styleUrls: ['./activity-page.component.scss']
})
export class ActivityPageComponent implements OnInit {
  recentFeeds: Feed[] = [];
  allFeeds: Feed[] = [];
  recentActivityDisplay: ActivityDisplay[] = [];
  allActivityDisplay: ActivityDisplay[] = [];
  isRecentFeedLoading = true;
  isAllFeedLoading = true;
  currentOrg: OrgState;
  private _subSink: SubSink;

  @Select(EnterpriseState.selectedOrg)
  selectedOrg$: Observable<OrgState>;

  @Select(FeedState.recentFeeds)
  recentFeeds$: Observable<Feed[]>;

  @Select(InMemFeedState.feeds)
  allFeeds$: Observable<Feed[]>;

  constructor(
    private systemHub: SystemHub,
    private enterpriseSelector: EnterpriseSelector,
    private feedService: FeedService,
    private pubSub: PubSub) { this._subSink = new SubSink(); }

  get orgId(): string {
    return this.currentOrg ? this.currentOrg.id : "";
  }

  ngOnInit(): void {
    this.currentOrg = this.enterpriseSelector.getCurrentOrg();
    this._subscribe();
  }

  ngOnDestroy(): void {
    if (this._subSink) this._subSink.unsubscribe();
  }

  private _subscribe() {
    // this._subSink.sink = this.selectedOrg$.pipe(
    //   distinctUntilChanged((prev, curr) => {
    //     if (curr == null) return true;
    //     if (prev == null && curr != null) return false;
    //     return prev.id === curr.id && prev.name === curr.name && prev.status === curr.status
    //   })
    // ).subscribe(async (res) => {
    //   this.currentOrg = res ? res : null;
    //   this._getRecentFeed();
    //   this._getAllFeed();
    // });
    this.recentFeeds$
      .subscribe((feeds) => {
        this.recentFeeds = feeds;
        this.recentActivityDisplay = ActivityDisplay.buildActivity(this.recentFeeds);
        this.isRecentFeedLoading = false;
      });
    
    this.allFeeds$
      .pipe(
        distinctUntilChanged((prev, curr) => prev.length == curr.length)
      )
      .subscribe((feeds) => {
        this.allFeeds = feeds;
        this.allActivityDisplay = ActivityDisplay.buildActivity(this.allFeeds);
        this.isAllFeedLoading = false;
      })

    this._subSink.sink = this.systemHub.onHubConnected$.subscribe(async (res) => {
      if (!this.isAllFeedLoading) {
        this._getAllFeed();
      }
    });
  }

  private async _getAllFeed() {
    if (!this.currentOrg) return;
    this.allFeeds = await this.systemHub.getOrgFeed(this.currentOrg.id);
    //this.allActivityDisplay = this._buildActivityList(this.allFeeds);
    this.isAllFeedLoading = false;
  }

    onTabChanged(event: any) {
      if (event.index == 1 && this.isAllFeedLoading) {
        this._getAllFeed();
      }
    }

    setAllActivityRead() {
      this.recentActivityDisplay.forEach(a => a.status = FeedStatus.Read);
      this.feedService.updateRecentsAsRead(this.orgId);
    }
    
  }


