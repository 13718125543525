import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import {SessionService} from './session.service'
//import { ExtStateSelector } from "../../../core/states/ext.state.selector";
import { sms_configuration } from "../configurations/sms_configuration";
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from '../../../core/localStorage/local-storage.service';
import { AbstractStateSelector } from "../../../core/states/abstract.state.selector";

@Injectable({
  providedIn: 'root'
})
export class SMSRequestsService {

  smsBasePath: string;
  USERID: string;
  ORGID: string;
  NODEID: string;
  Authorization: string;

  constructor(public http: HttpClient,public session: SessionService, public localauthstate: LocalStorageService, private authstate: AbstractStateSelector) {

    //this.session.set('smsbasepath', sms_configuration.basepath);
    this.session.set('smsbasepath', environment.apiSMSServiceLink);
    //this.session.set('userid', this.authstate.getCurrentUser().userId );
   }

   setRequestParam() {
    this.smsBasePath = this.session.get('smsbasepath') ? this.session.get('smsbasepath') : '';
    //this.USERID = this.session.get('userid') ? this.session.get('userid') : '';

    if (this.authstate.getCurrentOrg() !=null) {
      this.ORGID =this.authstate.getCurrentOrg().id;
    } else{
      if ((this.localauthstate.getCurrentOU()) != null) {
        this.ORGID = this.localauthstate.getCurrentOU();
      }
      else {
        this.ORGID = '';
      }
 }

    if (this.authstate.getCurrentUser() !=null) {
      this.USERID = this.authstate.getCurrentUser().userId;
    } else {
      if ((this.localauthstate.getUserId()) != null) {
        this.USERID = this.localauthstate.getUserId();
      }else {
        this.USERID ='';
      }
    }
    
    this.NODEID = this.session.get('nodeid') ? this.session.get('nodeid') : '';
    // this.Authorization = 'BasicAuth ' + (this.session.get('token') ? this.session.get('token') : '');
    //this.Authorization = 'Bearer ' + (this.authStateSelector.accessToken);
    if (this.authstate.getAuthData() != null) {
      this.Authorization = 'Bearer ' + this.authstate.getAuthData().accessToken;
    } else {
      if ((this.localauthstate.getAccessToken()) != null) {
        this.Authorization = 'Bearer ' + (this.localauthstate.getAccessToken());
      } else {
        ;
      }
    }
  }

  get(req: any): Observable<any> {
    this.setRequestParam();
    return this.http.get(
      this.smsBasePath + req.uri,
      {
        headers: new HttpHeaders({
          //'Content-Type': req.headerContentType,
          'USERID': this.USERID,
          'ORGID' :this.ORGID,
          'NODEID': this.NODEID,
          'Authorization': this.Authorization
        }),
        params: req.data,
        responseType: (undefined === req.headerResponseType || null === req.headerResponseType) ?
        'json' : req.headerResponseType
      }
    );
  }

  post(req: any): Observable<any> {
    this.setRequestParam();
    return this.http.post(
      this.smsBasePath + req.uri,
      req.data,
      {
        headers: (undefined === req.headerContentType || null === req.headerContentType) ? 
        new HttpHeaders({
          'USERID': this.USERID,
          'ORGID' :this.ORGID,
          'NODEID': this.NODEID,
          'Authorization': this.Authorization
        }) :
        new HttpHeaders({
          'Content-Type': req.headerContentType,
          'USERID': this.USERID,
          'ORGID' :this.ORGID,
          'NODEID': this.NODEID,
          'Authorization': this.Authorization
        }),
        responseType: (undefined === req.headerResponseType || null === req.headerResponseType) ?
        'json' : req.headerResponseType
      });
  }

  put(req: any): Observable<any> {
   this.setRequestParam();
    return this.http.put(this.smsBasePath + req.uri, req.data);
  }

  delete(req: any): Observable<any> {
   this.setRequestParam();
    return this.http.delete(this.smsBasePath + req.uri);
  }
}
