import { BuiltInUser } from './../model/org.state';
import { Injectable, Injector } from "@angular/core";
import { Store, Selector, createSelector } from "@ngxs/store";
import { Observable } from "rxjs";
import { takeLast, map } from "rxjs/operators";
import { ImmutableSelector } from "@ngxs-labs/immer-adapter";
import { UserDataSelector } from "./user-data.state.selector";
import {
  OUState,
  TeamState,
  TeamMemberState,
  ChannelState,
  PostCommentState,
  OrgRoleState,
  PostState,
  OrgState,
  TeamRole,
  OrgUserState,
  ContactState,
  PaymentMethodState,
  BuiltInUserState
} from "../model/org.state";
import { EnterpriseState } from "./enterprise.state";
import { UserDataState } from "./user-data.state";
import { RoleEntity } from "../model/userRole.model";
import { OrgType } from "../enum/org-type";
import * as _ from "lodash";
import { UserStatus } from "../enum/user-status.enum";
import { StringDictionary } from '../util/dictionary';
import { EntityStatus } from '../enum/entity-status.enum';

@Injectable({
  providedIn: "root"
})
export class EnterpriseSelector {
  private static userStateSelector: UserDataSelector;

  constructor(public store: Store, private injector: Injector) {
    EnterpriseSelector.userStateSelector = injector.get<UserDataSelector>(
      UserDataSelector
    );
  }

  orgOus$(orgId: string): Observable<OUState[]> {
    return this.store.select(EnterpriseState.orgOus(orgId));
  }

  orgTeams$(orgId: string): Observable<TeamState[]> {
    return this.store.select(EnterpriseState.orgTeams(orgId));
  }

  orgTeamMembers$(orgId: string): Observable<TeamMemberState[]> {
    return this.store.select(EnterpriseState.orgTeamMembers(orgId));
  }

  orgChannels$(orgId: string): Observable<ChannelState[]> {
    return this.store.select(EnterpriseState.orgChannels(orgId));
  }

  orgPostComments$(postId: string): Observable<PostCommentState[]> {
    return this.store.select(EnterpriseState.orgComments(postId));
  }

  orgRoles$(orgId: string): Observable<OrgRoleState[]> {
    return this.store.select(EnterpriseState.orgRoles(orgId));
  }

  currentPosts$(channelId?: string, counter?: number): Observable<PostState[]> {
    const totalRead = counter || 30;

    if (channelId) {
      return this.store.select(EnterpriseState.currentPosts).pipe(
        map(p => p.filter(i => i.channelId === channelId)),
        takeLast(totalRead)
      );
    } else {
      return this.store
        .select(EnterpriseState.currentPosts)
        .pipe(takeLast(totalRead));
    }
  }

  @Selector([EnterpriseState.teamMembers, EnterpriseState.selectedOrg])
  @ImmutableSelector()
  static currentOrgTeamMembers(
    teamMembers: TeamMemberState[],
    selectedOrg: OrgState
  ): TeamMemberState[] {
    var result = teamMembers.filter(r => !!selectedOrg && r.orgId === selectedOrg.id);
    return _.cloneDeep(result);
  }

  // @Selector([EnterpriseState.channels, EnterpriseState.selectedOrg])
  // @ImmutableSelector()
  // static currentOrgChannels(
  //   channels: ChannelState[],
  //   selectedOrg: OrgState
  // ): ChannelState[] {
  //   return channels.filter(r => !!selectedOrg && r.orgId === selectedOrg.id);
  // }

  // @Selector([EnterpriseState.posts, EnterpriseState.selectedOrg])
  // @ImmutableSelector()
  // static currentOrgPosts(
  //   posts: PostState[],
  //   selectedOrg: OrgState
  // ): PostState[] {
  //   return posts.filter(r => !!selectedOrg && r.orgId === selectedOrg.id);
  // }

  channel(channelId: string): Observable<ChannelState> {
    return this.store.select(EnterpriseSelector.channel(channelId));
  }

  static channel(channelId: string) {
    return createSelector(
      [EnterpriseState.channels],
      (channels: ChannelState[]) => {
        const channel = channels.find(o => o.id === channelId);
        return channel ? _.cloneDeep(channel) : null;
      }
    );
  }

  channels(teamId: string): Observable<ChannelState[]> {
    return this.store.select(EnterpriseSelector.channels(teamId));
  }

  static channels(teamId: string) {
    return createSelector(
      [EnterpriseState.channels],
      (channels: ChannelState[]) => {
        const teamChannels = channels.filter(o => o.teamId === teamId);
        return teamChannels ? _.cloneDeep(teamChannels) : [];
      }
    );
  }

  team(teamId: string): Observable<TeamState> {
    return this.store.select(EnterpriseSelector.team(teamId));
  }

  static team(teamId: string) {
    return createSelector([EnterpriseState.teams], (teams: TeamState[]) => {
      const team = teams.find(o => o.id === teamId);
      return team ? _.cloneDeep(team) : null;
    });
  }

  channelPost(channelId: string): Observable<PostState[]> {
    return this.store.select(EnterpriseSelector.channelPost(channelId));
  }

  //need to get deleted posts to update ui posts
  static channelPost(channelId: string) {
    return createSelector([EnterpriseState.allPosts], (posts: PostState[]) => {
      const result = posts.filter(o => o.channelId === channelId);
      return _.cloneDeep(result);
    });
  }

  post$(postId: string): Observable<PostState> {
    return this.store.select(EnterpriseSelector.post(postId));
  }

  static post(postId: string) {
    return createSelector(
      [EnterpriseState.allPosts],
      (posts: PostState[]) => {
        var result = posts.find(c => c.id === postId || c.tempId === postId);
        return _.cloneDeep(result);
      }
    );
  }

  postComments$(postId: string): Observable<PostCommentState[]> {
    return this.store.select(EnterpriseSelector.postComments(postId));
  }

  getComment$(commentId: string): Observable<PostCommentState> {
    return this.store.select(EnterpriseSelector.comment(commentId));
  }

  static comment(commentId: string) {
    return createSelector(
      [EnterpriseState.allComments],
      (comments: PostCommentState[]) => {
        var result = comments.filter(c => c.id === commentId);
        return _.cloneDeep(result);
      }
    );
  }

  static postComments(postId: string) {
    return createSelector(
      [EnterpriseState.allComments],
      (comments: PostCommentState[]) => {
        var result = comments.filter(c => c.postId === postId);
        return _.cloneDeep(result);
      }
    );
  }

  unreadPostComments$(): Observable<PostCommentState[]> {
    return this.store.select(EnterpriseSelector.unreadPostComments());
  }

  static unreadPostComments() {
    return createSelector(
      [EnterpriseState.currentComments, EnterpriseState.postUnreads],
      (comments: PostCommentState[], unreads: StringDictionary) => {
        var result = comments
          .filter(o => o.status == EntityStatus.Active)
          .filter(o => unreads.isExists(o.postId, o.id));
        return _.cloneDeep(result);
      }
    );
  }

  unreadChannelComments$(channelId: string): Observable<PostCommentState[]> {
    return this.store.select(
      EnterpriseSelector.unreadChannelComments(channelId)
    );
  }

  static unreadChannelComments(channelId: string) {
    return createSelector(
      [EnterpriseState.allComments, EnterpriseState.chUnreads],
      (comments: PostCommentState[], unreads: StringDictionary) => {

        var result = comments
          .filter(o => o.status == EntityStatus.Active && o.channelId === channelId)
          .filter(o => unreads.isExists(channelId, o.id));
        return _.cloneDeep(result);
      }
    );
  }

  canPostInChannel(channelId: string): boolean {
    const channels = this.store.selectSnapshot(
      EnterpriseSelector.postableChannel
    );
    return channels.findIndex(c => c.id === channelId) !== -1;
  }

  currentTeamUsers$(teamId: string): Observable<OrgUserState[]> {
    const team = this.getTeam(teamId);
    return this.store
      .select(EnterpriseState.currentOrgUsers)
      .pipe(map(m => m.filter(i => !!team && !!team.members && team.members.some(t => t === i.userId))));
  }

  getCurrentTeamUsers(teamId: string): OrgUserState[] {
    const team = this.getTeam(teamId);

    const orgUsers = this.store.selectSnapshot(EnterpriseState.currentOrgUsers);
    return orgUsers.filter(
      (i) =>
        !!team && !!team.members && team.members.some((t) => t === i.userId)
    );
  }

  currentTeamChannels$(teamId: string): Observable<ChannelState[]> {
    return this.store
      .select(EnterpriseState.currentChannels)
      .pipe(map(m => m.filter(i => i.teamId === teamId)));
  }

  @Selector([
    EnterpriseState.currentChannels,
    EnterpriseSelector.currentOrgTeamMembers,
    UserDataState.userId
  ])
  @ImmutableSelector()
  static postableChannel(
    channels: ChannelState[],
    teamMembers: TeamMemberState[],
    userId: string
  ): ChannelState[] {
    const members = teamMembers.filter(t => t.userId == userId);

    var result = channels.filter(c => {
      if (c.canCreatePost) return true;

      const member = members.find(m => m.teamId === c.teamId);
      return (
        member &&
        (member.role == TeamRole.Owner || member.role == TeamRole.Admin)
      );
    });

    return _.cloneDeep(result);
  }

  // @Selector([EnterpriseState.currentChannels, EnterpriseState.unreads])
  // @ImmutableSelector()
  // static channelTotalUnreads(channels: ChannelState[], unreads: StringDictionary): number {
  //   if (!channels || channels.length == 0) return 0;
  //   if (!unreads) return 0;

  //   const arr: number[] = _.map(channels, (c: ChannelState) => unreads.count(c.id));

  //   return _.reduce(
  //     arr,
  //     (sum, c) => sum + c,
  //     0
  //   );
  // }
  @Selector([EnterpriseState.chUnreads])
  static channelTotalUnreads(chUnreads: StringDictionary): number {
    if (!chUnreads) return 0;
    const ids = chUnreads.values();
    return ids && ids.length > 0 ? ids.length : 0;
  }

  @Selector([EnterpriseState.sharedUnreads])
  static storageTotalUnreads(sharedUnreads: StringDictionary): number {
    if (!sharedUnreads) return 0;
    const ids = sharedUnreads.values();
    return ids && ids.length > 0 ? ids.length : 0;
  }

  @Selector([EnterpriseState.chUnreads])
  static orgChannelUnreads(chUnreads: StringDictionary): StringDictionary {

    if (!chUnreads) return new StringDictionary();
    const unreads = new StringDictionary({ ...chUnreads });

    return _.cloneDeep(unreads);
  }

  @Selector([
    EnterpriseState.currentPosts,
    EnterpriseSelector.currentOrgTeamMembers
  ])
  @ImmutableSelector()
  static commentablePost(
    posts: PostState[],
    teamMembers: TeamMemberState[]
  ): PostState[] {
    const members = teamMembers.filter(
      t => t.userId == EnterpriseSelector.userStateSelector.userId
    );

    var result = posts.filter(p => {
      if (p.canCreatePostComment) return true;

      const member = members.find(m => m.teamId === p.teamId);
      return (
        member &&
        (member.role == TeamRole.Owner || member.role == TeamRole.Admin)
      );
    });

    return _.cloneDeep(result);
  }

  @Selector([EnterpriseState.orgs, EnterpriseState.users])
  @ImmutableSelector()
  static ownerAdminOrg(orgs: OrgState[], users: OrgUserState[]): OrgState[] {
    const userId = EnterpriseSelector.userStateSelector.userId;

    var result = orgs.filter(o =>
      users.some(
        u =>
          u.orgId == o.id &&
          u.userId == userId &&
          (u.role === RoleEntity[RoleEntity.OWNER] ||
            u.role === RoleEntity[RoleEntity.ADMIN])
      )
    );

    return _.cloneDeep(result);
  }

  @Selector([EnterpriseState.currentOrgUsers, EnterpriseState.currentContacts])
  @ImmutableSelector()
  static orgUserContacts(
    orgUsers: OrgUserState[],
    contacts: ContactState[]
  ): OrgUserState[] {
    var result = orgUsers.filter(u => contacts.some(c => c.userId === u.userId));
    return _.cloneDeep(result);
  }

  contact(contactId: string): Observable<ContactState> {
    return this.store.select(EnterpriseSelector.contact(contactId));
  }

  static contact(contactId: string) {
    return createSelector(
      [EnterpriseState.contacts],
      (contacts: ContactState[]) => {
        const contact = contacts.find(o => o.id === contactId);
        return contact ? _.cloneDeep(contact) : null;
      }
    );
  }

  getOrgs(): OrgState[] {
    return this.store.selectSnapshot(EnterpriseState.orgs);
  }

  getCurrentOrgUsers(): OrgUserState[] {
    const orgUsers = this.store.selectSnapshot(EnterpriseState.currentOrgUsers);
    return orgUsers ? orgUsers : [];
  }

  getCurrentOrgOUs(): OUState[] {
    const ous = this.store.selectSnapshot(EnterpriseState.currentOrgOUs);
    return ous ? ous : [];
  }

  getCurrentOu(): OUState {
    const ou = this.store.selectSnapshot(EnterpriseState.currentOu);
    return ou ? ou : null;
  }

  getCurrentTeamMembers(): TeamMemberState[] {
    const members = this.store.selectSnapshot(
      EnterpriseState.currentTeamMembers
    );
    return members ? members : [];
  }

  getCurrentChannels(): ChannelState[] {
    const list = this.store.selectSnapshot(EnterpriseState.currentChannels);
    return list ? list : [];
  }

  getCurrentUserContact(): ContactState {
    const contact = this.store.selectSnapshot(
      EnterpriseState.currentUserContact
    );
    return contact ? { ...contact } : null;
  }

  //#region Snapshot
  getCurrentOrgUser(): OrgUserState {
    const orgUser = this.store.selectSnapshot(EnterpriseState.currentOrgUser);
    return orgUser;
  }

  getOrgUserContacts(): OrgUserState[] {
    const orgUsers = this.store.selectSnapshot(EnterpriseSelector.orgUserContacts);
    return orgUsers;
  }

  getPersonalOrg(): OrgState {
    const orgs = this.store.selectSnapshot(EnterpriseState.orgs);
    return orgs.find(o => o.type === OrgType.Personal);
  }

  getOrgByOu(ouId: string): OrgState {
    const orgs = this.store.selectSnapshot(EnterpriseState.orgs);
    return orgs.find(o => o.ous.includes(ouId) || o.id === ouId);
  }

  getCurrentOrg(): OrgState {
    const org = this.store.selectSnapshot(EnterpriseState.selectedOrg);
    return org ? { ...org } : null;
  }

  getChUnreads(): StringDictionary {
    const dict = this.store.selectSnapshot(EnterpriseState.chUnreads);
    return dict;
  }

  isChUnread(thread: string): boolean {
    const dict = this.getChUnreads();
    return dict.containsKey(thread);
  }

  getAllPostUnreads(): StringDictionary {
    const dict = this.store.selectSnapshot(EnterpriseState.postUnreads);
    return dict;
  }

  getAllSharedUnreads(): StringDictionary {
    const dict = this.store.selectSnapshot(EnterpriseState.sharedUnreads);
    return dict;
  }

  isPostUnread(thread: string): boolean {
    const dict = this.getAllPostUnreads();
    return dict.containsKey(thread);
  }

  isSharedUnread(thread: string): boolean {
    const dict = this.getAllSharedUnreads();
    return dict.containsKey(thread);
  }

  get pendingUnreadsToClear() {
    return this.store.selectSnapshot(EnterpriseState.pendingUnreadsToClear);
  }

  get isCurrentPersonalOrg(): boolean {
    const org = this.getCurrentOrg();
    return org ? org.type === OrgType.Personal : false;
  }

  canSaveState(orgId: string): boolean {
    if (orgId == null) return false;
    const org = this.getCurrentOrg();
    if (!org) return true;
    return org.id === orgId || _.some(org.connectedOrgs, c => c === orgId);//is same org 
  }

  //#region Org Handling for Personal Space ConnectedOrg
  isCurrentOrg(orgId: string) {
    if (orgId == null) return false;
    const org = this.getCurrentOrg();
    if (!org) return false;
    return org.id === orgId || _.some(org.connectedOrgs, c => c === orgId);
  }

  isSameOrg(orgIdA: string, orgIdB: string) {
    if (!orgIdA || !orgIdB) return false;
    const orgA = this.getOrg(orgIdA);
    const orgB = this.getOrg(orgIdB);

    return orgA && orgB ? orgA.id === orgB.id : false;
  }

  getOrg(orgId: string): OrgState {
    const orgs = this.store.selectSnapshot(EnterpriseState.orgs);
    return orgs && orgs.length != 0
      ? orgs.find(
        o => o.id === orgId || _.some(o.connectedOrgs, c => c === orgId)
      )
      : null;
  }

  getCurrentTeams(): TeamState[] {
    const teams = this.store.selectSnapshot(EnterpriseState.currentTeams);
    return teams ? { ...teams } : [];
  }

  getCurrentOrgDefaultOu(): OUState {
    const ous = this.store.selectSnapshot(EnterpriseState.currentOrgOUs);
    return ous ? ous.find(i => i.isDefault === true) : null;
  }

  getCurrentOrgPaymentMethods(): PaymentMethodState[] {
    const data = this.store.selectSnapshot(
      EnterpriseState.currentPaymentMethods
    );
    return data ? { ...data } : null;
  }
  getCurrentOrgId() {
    const org = this.store.selectSnapshot(EnterpriseState.selectedOrg);
    return org ? org.id : null;
  }

  getCurrentOrgType() {
    const org = this.store.selectSnapshot(EnterpriseState.selectedOrg);
    return org ? org.type : null;
  }

  getOuById(ouId: string): OUState {
    const ous = this.store.selectSnapshot(EnterpriseState.ous);
    return ous.find(o => o.id === ouId);
  }

  getEngagedOu(orgId: string, userId: string): OUState {
    const users = this.getOrgMembership(orgId, userId);
    if (users) {
      const ous = this.store.selectSnapshot<OUState[]>(
        state => state.enterprise.ous
      );
      return ous.find(i => i.id == users.ouId && i.orgId == orgId);
    }

    return null;
  }

  getActiveOrgs(): OrgState[] {
    const orgs: OrgState[] = this.getOrgs();
    const orgUsers: OrgUserState[] = this.getOrgUsers();
    const userId = EnterpriseSelector.userStateSelector.userId;

    const activeOrgs = orgs.filter((o => {
      if (o.status == EntityStatus.Inactive || o.status == EntityStatus.Deleted) return false;
      //filter active membership(not suspended or deleted)
      var orgUser = orgUsers.find(
        i => i.orgId === o.id && i.userId === userId
      );
      return orgUser && orgUser.status == UserStatus.Active;
    }));

    return activeOrgs;
  }

  getAllOus(orgId: string, userId): OUState[] {
    let childOus: OUState[] = [];
    const ou = this.getEngagedOu(orgId, userId);
    const ous = this.store.selectSnapshot<OUState[]>(
      state => state.enterprise.ous
    );
    if (ou.childs.length > 0) {
      childOus = this.getChildOus(ou.childs, ous, childOus);
    }
    childOus.push(ou);

    return childOus;
  }

  getChildOus(ouIds: string[], ous: OUState[], childOus: OUState[]): OUState[] {
    ouIds.forEach(r => {
      let t = ous.find(i => i.id == r);
      if (childOus.findIndex(c => c.id == t.id) == -1) {
        childOus.push(t);
      }

      if (t.childs.length > 0) {
        this.getChildOus(t.childs, ous, childOus); //get child of child ous
      }
    });
    return childOus;
  }

  getOwnedBizOrgs(): OrgState[] {
    const userId = EnterpriseSelector.userStateSelector.userId;

    const orgs = this.store.selectSnapshot<OrgState[]>(
      state => state.enterprise.orgs
    );

    var result = orgs.filter(o =>
      userId == o.createdBy && o.type == OrgType.Business
    );

    return _.cloneDeep(result);
  }

  getOrgUsers(): OrgUserState[] {
    const users: OrgUserState[] = this.store.selectSnapshot<OrgUserState[]>(
      state => state.enterprise.users
    );
    return users.filter(i => i.status !== UserStatus.Deleted);
  }

  getUserByOrg(orgId: string): OrgUserState {
    const orgUsers = this.getOrgUsers();
    const userId = EnterpriseSelector.userStateSelector.userId;
    return _.find(orgUsers, u => u.orgId === orgId && u.userId === userId);
  }

  getOrgMembership(orgId: string, userId: string): OrgUserState {
    const users = this.store.selectSnapshot<OrgUserState[]>(
      state => state.enterprise.users
    );
    return users.find(
      i => this.isSameOrg(orgId, i.orgId) && i.userId === userId
    );
  }

  getOrgRoles(orgId: string) {
    const roles = this.store.selectSnapshot(EnterpriseState.roles);
    return roles.filter(r => r.orgId == orgId);
  }

  getCurrentOrgStaffs() {
    let orgId = this.getCurrentOrgId();
    return this.getOrgRoles(orgId).filter(
      (x) =>
        RoleEntity[x.normalizedName] !== RoleEntity.OWNER &&
        RoleEntity[x.normalizedName] !== RoleEntity.ADMIN
    );
  }

  getChannel(channelId: string): ChannelState {
    const channels = this.store.selectSnapshot<ChannelState[]>(
      state => state.enterprise.channels
    );

    return channels.find(i => i.id === channelId);
  }

  getChannelName(channelId: string): string {
    const channels = this.store.selectSnapshot<ChannelState[]>(
      state => state.enterprise.channels
    );
    return channels.find(i => i.id === channelId).name;
  }

  getTeam(teamId: string): TeamState {
    const teams = this.store.selectSnapshot<TeamState[]>(
      state => state.enterprise.teams
    );

    return teams.find(i => i.id === teamId);
  }

  getChannels(teamId: string): ChannelState[] {
    const channels = this.store.selectSnapshot(EnterpriseSelector.channels(teamId));
    return [...channels];
  }

  getPost(postId: string): PostState {
    const posts = this.store.selectSnapshot<PostState[]>(
      EnterpriseState.allPosts
    );

    return posts.find(i => i.id === postId);
  }


  getPostComment(commentId: string): PostCommentState {
    const comments = this.store.selectSnapshot<PostCommentState[]>(
      EnterpriseState.allComments
    );

    return comments.find(i => i.id === commentId);
  }

  getTeamMember(teamId: string, userId: string): TeamMemberState {
    const teamMembers = this.store.selectSnapshot<TeamMemberState[]>(
      state => state.enterprise.teamMembers
    );

    return teamMembers.find(i => i.teamId == teamId && i.userId == userId);
  }

  getContact(contactId: string): ContactState {
    const contacts = this.store.selectSnapshot<ContactState[]>(
      state => state.enterprise.contacts
    );
    return contacts.find(i => i.id === contactId);
  }

  getContactByUserId(userId: string): ContactState {
    const contacts = this.store.selectSnapshot<ContactState[]>(
      state => state.enterprise.contacts
    );
    return contacts.find(i => i.userId === userId);
  }

  getContacts(): ContactState[] {
    const contacts = this.store.selectSnapshot<ContactState[]>(
      state => state.enterprise.contacts
    );
    return contacts.filter(i => i.status == EntityStatus.Active);
  }

  getPublicKeys(orgId: string): string[] {
    const users = this.store.selectSnapshot<OrgUserState[]>(
      state => state.enterprise.users
    );

    return users.filter(u => u.orgId === orgId).map(u => u.publicKey);
  }

  getBuiltInUsers() {
    const users = this.store.selectSnapshot<BuiltInUserState[]>(
      state => state.enterprise.builtInUsers
    );

    return users ? [...users] : [];
  }

  getChPosts(chId: string): PostState[] {
    const posts = this.store.selectSnapshot<PostState[]>(
      EnterpriseState.allPosts
    );
    const org = this.getCurrentOrg();
    if (org.type == OrgType.Business) {
      return posts.filter(i => i.channelId === chId && i.status !== EntityStatus.Deleted);
    } else {
      return posts.filter(
        (p) =>
          EnterpriseState.isSameOrg(org, p.orgId) && p.status !== EntityStatus.Deleted
      )
    }
  }

  getChComments(chId: string): PostCommentState[] {
    const comments = this.store.selectSnapshot<PostCommentState[]>(
      EnterpriseState.allComments
    );

    const org = this.getCurrentOrg();
    if (org.type == OrgType.Business) {
      return comments.filter(i => i.channelId === chId);
    } else {
      return comments.filter(
        (p) =>
          EnterpriseState.isSameOrg(org, p.orgId)
      )
    }
  }

  getPostComments(postId: string): PostCommentState[] {
    const comments = this.store.selectSnapshot<PostCommentState[]>(
      EnterpriseState.allComments
    );

    return comments.filter(i => i.postId === postId);
  }

  isToolEnabled(orgId: string, appId: string): boolean {
    const org = this.getOrg(orgId);
    return this.isOrgToolEnabled(org, appId);
  }

  isCurrentOrgToolEnabled(appId: string): boolean {
    const org = this.getCurrentOrg();
    return this.isOrgToolEnabled(org, appId);
  }

  isOrgToolEnabled(org: OrgState, appId: string): boolean {
    if (!org) return false;

    const extTools = org.extTools;
    return extTools[appId] ? extTools[appId] : false;
  }

  static getType(filename: string): string {
    var fileNameArr = filename.toLocaleUpperCase().split('.');
    switch (fileNameArr[fileNameArr.length - 1]) {
      case "PDF":
        return "file-pdf-o";
      case "DOC":
      case "DOCX":
        return "file-word-o";
      case "XLS":
      case "XLSX":
      case "CSV":
        return "file-excel-o";
      case "TXT":
        return "file-text-o";
      case "JPG":
      case "JPEG":
      case "GIF":
      case "PNG":
      case "BMP":
        return "file-photo-o";
      case "MP4":
      case "MOV":
      case "AVI":
        return "file-movie-o";
      case "MP3":
      case "ACC":
        return "file-sound-o";
      case "RAR":
      case "ZIP":
        return "file-zip-o";
      default:
        return "file-o";
    }
  }
  //#endregion
}
