import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SMSRequestsService} from '../../services/requests.service'

@Component({
  selector: 'app-sms-pop-up-content',
  templateUrl: './sms-pop-up-content.component.html',
  styleUrls: ['./sms-pop-up-content.component.scss']
})
export class SmsPopUpContentComponent implements OnInit {

  phoneNumber: string = '';
  message: string = '';

  isLoading = false;
  phone: string;
  smsMessage: string;
  //userSettings: UserProfileState;
  sentTo: string;
  code: string;

  showVerifyDialog = false;

  // error variables
  showError = false;
  errorMsg: string;

  showSpinner: boolean;

  public SMSMessageBody = {
    PhoneNumber: null,
    Message:null
  }

  MESSAGE_OUTBOUND: ELSMS_MESSAGE_OUTBOUND=null;

  public SMSMessageInfo = {
    PHONENUMBER: null,
    MESSAGE:null,
    MESSAGE_OUTBOUND:null,
  }

  constructor(private request:SMSRequestsService,public dialogRef: MatDialogRef<SmsPopUpContentComponent>) 
  {
    this.showSpinner = false;
  }

  ngOnInit(): void {}

  sendSMS() {
    if (!this.phone || !this.smsMessage){
      return;
    }else if (!(this.phone == null || this.phone == "" || this.phone[0] == "+")){
      //
    }else{    
      console.log('Sending SMS message...');
      console.log('Phone number:', this.phone);
      console.log('Message:', this.smsMessage);
      this.SMSMessageBody.PhoneNumber = this.phone;
      this.SMSMessageBody.Message = this.smsMessage;
      this.showSpinner = true;
      this.request.post({
        uri: '/actionapi/ELSMS/SendSms',
        data: this.SMSMessageBody,
        headerContentType: 'application/json',
        headerResponseType: 'text'
      }).subscribe((response: any) => {
        if (response.toUpperCase() == "SMS sent successfully".toUpperCase()) {
          console.log('Message sent successfully');
        } else {
          console.log('Message failed to send');
        }
        this.showSpinner = false;
      });

      this.dialogRef.close('sms-send');
    }
  }
}

export interface ELSMS_MESSAGE_OUTBOUND
{
    ELSMS_OUTBOUND_MESSAGE_ID:0,
    MESSAGE_ID:null,
    ELSMSORG_ID:null,
    ELSMSUSER_ID:0,
    MESSAGE_FROM_PHONENUMBER:null,
    MESSAGE_TO_PHONENUMBERS:null,
    MESSAGE_TEXT:null,
    MESSAGE_MEDIAURL:null,
    MESSAGE_DESCRIPTION:null,
    MESSAGE_RESENT_COUNT:0,
    REPLIED_INBOUND_MESSAGE_ID:null,
    MESSAGE_STATUS:null,
    SENTAT:null,
    RESENTAT:null,
    UPDATEDAT:null
}

