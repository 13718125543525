import { FeatureState, FeatureType, ResourceCode } from "../model/feature.state";
import { Injectable, Injector } from "@angular/core";
import { createSelector, Store } from "@ngxs/store";
import { SubStatus, SubscriptionState, SubscriptionType } from "../model/subscription.state";
import { Observable } from "rxjs";
import { LicenseState } from "./license.state";
import * as _ from "lodash";
import { EnterpriseSelector } from "./enterprise.state.selector";
import { OrgState } from "../model/org.state";

@Injectable({
  providedIn: "root",
})
export class LicenseStateSelector {
  private static enterpriseSelector: EnterpriseSelector;

  constructor(private store: Store,  private injector: Injector) {
    LicenseStateSelector.enterpriseSelector = injector.get<EnterpriseSelector>(EnterpriseSelector);
  }

  subscriptions$(): Observable<SubscriptionState[]> {
    return this.store.select(LicenseState.subscriptions);
  }

  getAllSubscriptions() {
    return this.store.selectSnapshot(LicenseState.subscriptions);
  }

  getFreeOrgsSubs() {
    //get orgs owned by user
    const ownedBizOrgs = LicenseStateSelector.enterpriseSelector.getOwnedBizOrgs();
    let allSubs = this.getAllSubscriptions() ?? [];
    let currentSubs = allSubs.filter(s => (s.status == SubStatus.Active || s.status == SubStatus.Pending || s.status == SubStatus.PaymentFailed) && s.type == SubscriptionType.Free);
    
    const existingList: SubscriptionState[] = _.intersectionWith(
      currentSubs,
      ownedBizOrgs,
      (a: SubscriptionState, r: OrgState) =>
        r.id == a.orgId
    );

    return _.uniqBy(existingList, (e: SubscriptionState) =>
      e.orgId);
  }

  getOrgSubscription(orgId: string): SubscriptionState {
    //     //test
    //     var sub = new SubscriptionState();
    //     sub.type = SubscriptionType.Enterprise;
    
    // return sub;
    const subs = this.store.selectSnapshot(LicenseState.subscriptions);
    return subs.find((s) => s.orgId === orgId);
  }

  getCurrentOrgSubscription(): SubscriptionState {
    return this.store.selectSnapshot(LicenseState.currentOrgSubscription);
  }

  getCurrentOrgFeatures(): FeatureState[] {
    return this.store.selectSnapshot(LicenseState.currentOrgFeatures);
  }

  getFeatureByCode(code: string): FeatureState {
    return this.store.selectSnapshot(LicenseStateSelector.feature(code));
  }

  feature$(code: string): Observable<FeatureState> {
    return this.store.select(LicenseStateSelector.feature(code));
  }

  static feature(code: string) {
    return createSelector(
      [LicenseState.currentOrgFeatures],
      (features: FeatureState[]) => {
        if (code == null || code.trim() == "") return null;
        const feature = features.find(
          (o) =>
            o.productCode &&
            o.productCode.toLowerCase() === code.toLowerCase()
        );
        return feature ? _.cloneDeep(feature) : null;
      }
    );
  }

  // getFeatureRemaining(feature: FeatureState){
  //   if (feature == null) return 0;
  //   return feature.used < feature.limit ? feature.limit - feature.used : 0;
  // }

  // isFeatureEnabled(code: string): boolean {
  //   // return false; //test
  //   const feature = this.getFeatureByCode(code);
  //   return feature == null ? false : !feature.isRestricted;
  // }

  // isEnabled(feature: FeatureState): boolean {
  //   return feature == null ? false : !feature.isRestricted;
  // }
}
