import { CheckConsumptionRes } from './../../../../core/hub/org.hub';
import { EnterpriseSelector } from "./../../../../core/states/enterprise.state.selector";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogAddUserComponent } from "./dialog-add-user.component";
import { RoleEntity } from "../../../../core/model/userRole.model";

@Component({
  selector: "app-dialog-license-limit-exceed",
  templateUrl: "./dialog-license-limit-exceed.component.html",
  styleUrls: ["./dialog-license-limit-exceed.component.scss"],
})
export class DialogLicenseLimitExceedComponent implements OnInit {
  msg: string;
  upgradeOption: boolean = false;
  checkConsumptionRes: CheckConsumptionRes;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogAddUserComponent>,
    private enterpriseSelector: EnterpriseSelector,
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.checkConsumptionRes = this.data.checkConsumptionRes;

      if (this.checkConsumptionRes.reason == CheckConsumptionRes.INVALID_PROD_REASON) {
        this.msg = "Feature not found.";
        return;
      }

      var orgUser = this.enterpriseSelector.getCurrentOrgUser();
      var role = orgUser?.role;
      var ownerAdmin =  role &&
      (role.toUpperCase() == RoleEntity[RoleEntity.OWNER] ||
        role.toUpperCase() == RoleEntity[RoleEntity.ADMIN]);
      if (this.checkConsumptionRes.allowed == false) {
          if (
            this.checkConsumptionRes.reason ==
            CheckConsumptionRes.NO_CREDITS_REASON
          ) {
            this.upgradeOption = true;
            this.msg = ownerAdmin
              ? "Your organization has hit one of the limits set in your Free Tier Plan. Enable Full Tier Pay-As-You-Go Plan to remove the restriction."
              : "This organization have hit one of the limits set in the organization's Free Tier Plan. We have notified the owner of the account to have the restriction removed. Please try again later.";
            return;
          } else if (
            this.checkConsumptionRes.reason ==
            CheckConsumptionRes.THRESHOLD_EXCEED_REASON
          ) {
            this.msg = ownerAdmin
              ? "Your organization has hit one of the limits set by the system put in place as a security safeguard. Please contact our customer support immediately at (646) 868-8838 or support@everleagues.com for help."
              : "This organization have hit one of the limits set by the system, put in place as a security safeguard. We have notified the owner of the account to have the restriction removed. Please try again later.";
            return;
          }
        if (
          this.checkConsumptionRes.reason ==
          CheckConsumptionRes.INSUFFICIENT_CREDITS_REASON
        ) {
          this.msg = `Current usage exceeds limit. $${this.checkConsumptionRes.available.toFixed(2)} remaining.`;
          return;
        }

        if (
          this.checkConsumptionRes.errorMsg &&
          this.checkConsumptionRes.errorMsg.trim() != ""
        )
          this.msg = this.checkConsumptionRes.errorMsg;
        return;
      } 
      this.dialogRef.close();
    }
  }
}
