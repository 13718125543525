import { BaseHub } from "./base.hub";
import { Injectable } from "@angular/core";
import { SubSink } from "subsink";
import { HubStateSelector } from "../states/hub.state.selector";
import { MessagingSelector } from "../states/messaging.state.selector";
import { AuthStateSelector } from "../states/auth.state.selector";
import { NetworkService } from "../services/network.service";
import { environment as ENV } from "../../../environments/environment";
import { HubState } from "../states/hub.state";
import { Select } from "@ngxs/store";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import { HubConnectionState, HubHandshakeStatus } from "../model/hubConnection.state";
import { Observable, Subject } from "rxjs";
import { AppState } from "../states/app.state";
import { UserDataState } from "../states/user-data.state";
import { ContactLabelState } from "../model/contact-label.state";
import { catchError, map, tap } from "rxjs/operators";
import { UserProfileState } from "../model/user-profile.state";
import { CardState } from "../model/card.state";
import { ShortGuid } from "../util/shortGuid";
import { UserDataSelector } from "../states/user-data.state.selector";
import { AppStateSelector } from "../states/app.state.selector";
import { EnterpriseSelector } from "../states/enterprise.state.selector";
import { OrgUserState, ContactState } from '../model/org.state';
import { Feed } from '../model/feed';
import { EnterpriseState } from '../states/enterprise.state';
import { PubSub } from '../services/pubsub.service';
import { HubEvent } from './hub.event';
import { PluginManager } from "../../tools/plugin.manager";
import * as _ from "lodash";
import * as moment from 'moment';
import 'moment-timezone';
import { TokenProvider } from '../services/jwt-token.provider';
import { Frequency } from "../enum/frequency.enum";
import { Dictionary } from "../util/dictionary";
import { FeedState } from "../states/feed.state";
import { InMemFeedState } from "../states/inmem.feed.state";

@Injectable({
  providedIn: "root",
})
export class SystemHub extends BaseHub {
  //System hub listener events
  private USER_PROFILE: string = "user-profile";
  private CARDS: string = "cards";
  private LABEL: string = "contact-labels";
  private FEED: string = "feed";

  //System hub method name
  private HANDSHAKE_METHOD: string = "handshake";
  private CREATE_CONTACT_LABEL_METHOD: string = "create-contact-label";
  private CONTACT_LABEL_DELETE: string = "contact-label-delete";
  private CONTACT_LABEL_UPDATE: string = "update-contact-label";

  private ASSIGN_LABELS_TO_CONTACT_METHOD: string = "assign-labels-to-contact";
  private ASSIGN_CONTACT_LABEL_METHOD: string = "assign-contact-label";
  private ASSIGN_PRIVATE_CIRCLE_METHOD: string = "assign-private-circle";
  private REMOVE_LABEL_METHOD: string = "remove-label";
  private CONTACT_SYSTEMLABEL_READ = "contact-systemlabel-read";
  private DISMISS_CARD_METHOD: string = "dismiss-card";
  private STORE_USER_SESSION_METHOD: string = "store-user-session";
  private CLEAR_USER_SESSION_METHOD: string = "clear-user-session";
  private CARD_EXPIRATION_UPDATE_METHOD: string = "card-expiration-update";
  private REMOVE_2FA_PHONE_METHOD: string = "twofa-phone-remove";
  private TOGGLE_2FA_METHOD: string = "twofa-toggle";
  private UPDATE_TIMEZONE_METHOD: string = "timezone-update";
  private UPDATE_SETTINGS_METHOD: string = "settings-update";

  private ADD_FCM_TOKEN_METHOD: string = "add-push-token";
  private DELETE_FCM_TOKEN_METHOD: string = "delete-push-token";

  private USR_PROFILE_READ: string = "user-profile-read";
  private USR_PROFILE_UPDATE: string = "user-profile-update";
  private USR_LANGUAGE_UPDATE: string = "user-language-update";

  private FEED_READ: string = "feed-read";
  private FEED_CLEAR: string = "feed-clear";
  private ORG_FEED_READ_RECENT: string = "org-feed-read-recent";
  private ORG_FEED_READ_ARCHIVE: string = "org-feed-read-archive";
  private FEED_COUNT: string = "feed-count";
  private UPDATE_FEED_STATUS: string = "update-feed-status";
  private SET_ALL_ORG_FEED_READ = "set-all-org-feeds-read";

  private UPDATE_LAST_LOGGED_IN: string = "update-last-logged-in";

  //System hub trigger event
  private UPDATE_USER_PROFILE: string = "update-user-profile";
  private NEW_CONTACT_LABEL: string = "new-contact-label";
  private DELETE_CONTACT_LABEL: string = "delete-contact-label";
  private NEW_CARD: string = "new-card";
  private NEW_FEED: string = "new-feed";
  private LATEST_FEEDS: string = "latest-feed";
  private RECENT_ORG_FEED: string = "recent-org-feed";
  private FEED_STATUS_UPDATE: string = "feed-status-update";
  private ORG_FEED_READ_SET: string = "org-feed-read-set";
  private ORGS_FEED_READ_SET = "orgs-feed-read-set";
  private UPDATE_USER_LANGUAGE: string = "update-user-language";
  private UPDATE_USER_TIMEZONE: string = "update-user-timezone";
  private UPDATE_EMAIL_FREQUENCY: string = "update-email-frequency";

  private _subs: SubSink;

  private _hubStateSelector: HubStateSelector;
  onNewMessageTap: Subject<Notification>;
  onNewMeetTap: Subject<Notification>;
  onFeedCleared: Subject<any>;
  onFeedStatusUpdate: Subject<any>;
  onAllOrgFdsRead: Subject<any>;

  constructor(
    msgSnapshot: MessagingSelector,
    hubStateSnapshot: HubStateSelector,
    authSnapshot: AuthStateSelector,
    networkService: NetworkService,
    pubSub: PubSub,
    appSelector: AppStateSelector,
    userDataSelector: UserDataSelector,
    tokenProvider: TokenProvider,
    private enterpriseSelector: EnterpriseSelector,
  ) {
    super(
      "systemHub",
      ENV.systemHubLink,
      msgSnapshot,
      appSelector,
      authSnapshot,
      networkService,
      pubSub,
      userDataSelector,
      tokenProvider
    );
    this._hubStateSelector = hubStateSnapshot;
    this._subs = new SubSink();
    this.onNewMeetTap = new Subject<Notification>();
    this.onNewMessageTap = new Subject<Notification>();
    this.onFeedCleared = new Subject<any>();
    this.onFeedStatusUpdate = new Subject<any>();
    this.onAllOrgFdsRead = new Subject<any>();
    console.log("[%s] init", this.name);
    this.initSystemHub();
  }

  //#region ngxs operators
  @Select(HubState.syshub)
  sysHubConnection$: Observable<HubConnectionState>;

  @Emitter(AppState.setVersion)
  public setAppVersion: Emittable<string>;

  @Emitter(UserDataState.setUserProfile)
  public setUserProfile: Emittable<UserProfileState>;

  @Emitter(UserDataState.setLanguage)
  public setLanguage: Emittable<string>;

  @Emitter(UserDataState.setTimezone)
  public setTimezone: Emittable<string>;

  @Emitter(UserDataState.setEmailFrequency)
  public setEmailFrequency: Emittable<Frequency>;

  @Emitter(UserDataState.updateUserProfile)
  public updateUserProfileState: Emittable<UserProfileState>;

  @Emitter(EnterpriseState.addOrUpdateOrgUser)
  public addOrUpdateOrgUser: Emittable<OrgUserState[]>;

  @Emitter(EnterpriseState.addOrUpdateContacts)
  public addOrUpdateContacts: Emittable<ContactState[]>;

  @Emitter(UserDataState.addOrUpdateCards)
  public addOrUpdateCards: Emittable<CardState[]>;

  @Emitter(UserDataState.removeCard)
  public removeCard: Emittable<string>;

  @Emitter(UserDataState.addOrUpdateContactLabels)
  public addOrUpdateContactLabels: Emittable<ContactLabelState[]>;

  @Emitter(UserDataState.assignLabelsToContact)
  public assignLabelsToContact: Emittable<{
    contactId: string;
    labels: ContactLabelState[];
  }>;

  @Emitter(UserDataState.removeLabel)
  public removeLabel: Emittable<string>;

  @Emitter(HubState.setSysHubConnection)
  setSysHubConnection: Emittable<HubConnectionState>;

  @Emitter(UserDataState.addOrUpdateFcmToken)
  addOrUpdateFcmToken: Emittable<string>;

  @Select(UserDataState.cards)
  cards$: Observable<CardState[]>;

  @Emitter(HubState.updateSysHubHandshakeStatus)
  updateHandshakeStatus: Emittable<HubHandshakeStatus>;

  @Emitter(UserDataState.addOrUpdateTrialFlag)
  public addOrUpdateTrialFlag: Emittable<boolean>;

  @Emitter(FeedState.addOrUpdateFeeds)
  public addOrUpdateFeeds: Emittable<Feed[]>;

  @Emitter(FeedState.setFeedsAsRead)
  public setFeedsAsRead: Emittable<string>;

  @Emitter(InMemFeedState.addOrUpdateFeeds)
  public addOrUpdateInMemFeeds: Emittable<Feed[]>;

  @Emitter(FeedState.addOrUpdateUnreads)
  public addOrUpdateUnreads: Emittable<Feed[]>;

  @Emitter(FeedState.removeUnreads)
  public removeUnreads: Emittable<Feed[]>;

  @Emitter(FeedState.removeAllUnreads)
  public removeAllUnreads: Emittable<void>;

  @Emitter(FeedState.clean)
  public cleanFeedState: Emittable<void>;

  //--- todo to be removed -------
  @Emitter(UserDataState.addFeedUnreads)
  public addFeedUnreads: Emittable<Feed[]>;

  @Emitter(UserDataState.clearFeedUnread)
  clearUnreadFeeds: Emittable<string>;

  @Emitter(UserDataState.clearAllFeedUnread)
  clearAllUnreadFeeds: Emittable<string>;

  @Emitter(UserDataState.addPendingClearFeed)
  addPendingClearFeed: Emittable<string[]>;

  @Emitter(UserDataState.removePendingClearFeed)
  removePendingClearFeed: Emittable<null>;
  //-----------------------------------------
  //#endregion

  get setHubConnectionStatus(): Emittable<HubConnectionState> {
    return this.setSysHubConnection;
  }
  get hub$(): Observable<HubConnectionState> {
    return this.sysHubConnection$;
  }
  get hubSnapshot(): HubConnectionState {
    return this._hubStateSelector.getSysHub();
  }
  get setHandshakeStatus(): Emittable<HubHandshakeStatus> {
    return this.updateHandshakeStatus;
  }

  private initSystemHub() {
    let self = this;
    if (self._subs) self._subs.unsubscribe();

    self._subs.sink = self.onHubConnected$.subscribe(() => {
      console.log("[systemhub] onHubConnected$");
      if (!self.connection) {
        console.log("[systemhub] hub connection is null");
        return;
      }

      console.log("[systemhub] register user profile event");
      self.register(self.USER_PROFILE);
      console.log("[systemhub] register card event");
      self.register(self.CARDS);
      console.log("[systemhub] register label event");
      self.register(self.LABEL);
      self.register(self.FEED);
      console.log("[systemhub] start handshake");
      // self.handshake();

      self.clearPendingFeeds();
    });

    self._subs.sink = self
      .channelEvent$(self.USER_PROFILE)
      .subscribe((event) => {
        console.log("[systemhub]-onHubEvent-user-profile, %o", event);
        self.onUserProfileEventReceived(event);
      });

    self._subs.sink = self.channelEvent$(self.CARDS).subscribe((event) => {
      console.log("[systemhub]-onHubEvent-card, %o", event);
      self.onCardEventReceived(event);
    });

    self._subs.sink = self.channelEvent$(self.LABEL).subscribe((event) => {
      console.log("[systemhub]-onHubEvent-label, %o", event);
      self.onContactLabelEventReceived(event);
    });

    self._subs.sink = self.channelEvent$(self.FEED).subscribe((event) => {
      self.onFeedEventReceived(event);
    })
  }

  handshakeMethod(): Promise<any> {
    return this.invoke(this.HANDSHAKE_METHOD).then((event) => {
      if (!event || !event.isSuccess) {
        this.updateHandshakeStatus.emit(HubHandshakeStatus.Failed);
        throw new Error(event.error);
      }

      return event.dto;
    }).catch((err) => {
      return err;
    });
  }

  updateLastLoggedIn(): Promise<any> {
    return this.invoke(this.UPDATE_LAST_LOGGED_IN).then((event) => {
      if (!event || !event.isSuccess) {
        throw new Error(event.error);
      }

      return event.dto;
    }).catch((err) => {
      return err;
    });
  }

  updateHandshakeData(data: any): boolean {
    if (data.length == 0) return false;

    try {
      if (data.userProfile) {
        let profile = this.parseUserProfile(data.userProfile);
        if (profile) {
          //console.log("[SystemHub] update userprofile");
          this.setUserProfile.emit(profile);
          if (data.userProfile.language) {
            this.setLanguage.emit(data.userProfile.language);
          }

          if (data.userProfile.timezone) {
            this.setTimezone.emit(data.userProfile.timezone);
          } else {
            this.getTimezone();
          }

          if (data.userProfile.emailFrequency) {
            this.setEmailFrequency.emit(data.userProfile.emailFrequency);
          }
        }
      }

      if (data.cards) {
        let cards = this.parseCardsList(data.cards);
        if (cards && cards.length > 0) {
          //console.log("[SystemHub] update cards");
          this.addOrUpdateCards.emit(cards);
        }
      }

      if (data.contactLabels) {
        let labels = this.parseContactLabelList(data.contactLabels);
        if (labels && labels.length > 0) {
          //console.log("[SystemHub] update contactLabels");
          this.addOrUpdateContactLabels.emit(labels);
        }
      }

      // update trial
      if (data.hasTrial != null) {
        console.log("[SystemHub] update trial flag");
        this.addOrUpdateTrialFlag.emit(data.hasTrial);
      }

      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  // private handshake() {
  //   this.updateHandshakeStatus.emit(HubHandshakeStatus.Pending);
  //   this.invoke(this.HANDSHAKE_METHOD)
  //     .then((res: HubEvent) => {
  //       if (!res || !res.isSuccess) {
  //         console.error(res.error);
  //         this.updateHandshakeStatus.emit(HubHandshakeStatus.Failed);
  //       } else {
  //         console.log("[SystemHub] success handshake, %o", res);
  //         this.updateUserDataState(res);
  //         this.updateHandshakeStatus.emit(HubHandshakeStatus.Completed);
  //       }
  //     })
  //     .catch((err) => console.error(err));
  // }

  private updateUserDataState(event: HubEvent) {
    if (!event) return [];
    if (!event.dto) return [];
    if (event.dto.length == 0) return [];

    const data = event.dto;
    if (data.userProfile) {
      let profile = this.parseUserProfile(data.userProfile);
      if (profile) {
        console.log("[SystemHub] update userprofile");
        this.setUserProfile.emit(profile);
        if (data.userProfile.language) {
          this.setLanguage.emit(data.userProfile.language);
        }
      }
    }

    if (data.cards) {
      let cards = this.parseCardsList(data.cards);
      if (cards && cards.length > 0) {
        console.log("[SystemHub] update cards");
        this.addOrUpdateCards.emit(cards);
      }
    }

    if (data.contactLabels) {
      let labels = this.parseContactLabelList(data.contactLabels);
      if (labels && labels.length > 0) {
        console.log("[SystemHub] update contactLabels");
        this.addOrUpdateContactLabels.emit(labels);
      }
    }
    // update trial
    if (data.hasTrial != null) {
      console.log("[SystemHub] update trial flag");
      this.addOrUpdateTrialFlag.emit(data.hasTrial)
    }
  }

  dismissCard(cardId: string) {
    return this.invoke$(this.DISMISS_CARD_METHOD, cardId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.removeCard.emit(event.dto);
      })
    );
  }

  createContactLabels(labelNames: string[]) {
    return this.invoke$(this.CREATE_CONTACT_LABEL_METHOD, labelNames).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseContactLabelList(event.dto);
      }),
      tap((labels: ContactLabelState[]) =>
        this.addOrUpdateContactLabels.emit(labels)
      )
    );
    //     return this.api.postAsync('v2/contact/label', JSON.stringify(labelNames), this.localDb.CurrentUser.id)
    // return Promise.resolve([]).then(res => {
    //   this.addOrUpdateContactLabels.emit(res);
    //   return true;
    // });
  }

  deleteContactLabel(id: string) {
    return this.invoke$(this.CONTACT_LABEL_DELETE, id).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseContactLabelState(event.dto);
      }),
      tap((label: ContactLabelState) => this.removeLabel.emit(label.labelId))
    );
  }

  updateContactLabel(id: string, name: string) {
    return this.invoke$(this.CONTACT_LABEL_UPDATE, id, name).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseContactLabelState(event.dto);
      }),
      tap((label: ContactLabelState) =>
        this.addOrUpdateContactLabels.emit([label])
      )
    );
  }

  assignLabelByContact(contactId: string, labelIds: ContactLabelState[]) {
    return this.invoke$(
      this.ASSIGN_LABELS_TO_CONTACT_METHOD,
      contactId,
      labelIds
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseContactLabelList(event.dto);
      }),
      tap((labels: ContactLabelState[]) =>
        this.assignLabelsToContact.emit({
          contactId: contactId,
          labels: labels,
        })
      )
    );
    //   return this.api.postAsync("v2/contact/" + contactId + "/label/all", JSON.stringify(labelIds), contactId)
  }

  assignContactByLabel(labelId: string, ouId: string, contactIds: string[]) {
    return this.invoke$(
      this.ASSIGN_CONTACT_LABEL_METHOD,
      labelId,
      ouId,
      contactIds
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseContactLabelList(event.dto);
      }),
      tap((labels: ContactLabelState[]) =>
        this.addOrUpdateContactLabels.emit(labels)
      )
    );
    //      return this.api.postAsync("v2/contact/label/" + labelId + "/ou/" + ouId + "/batch", JSON.stringify(contactIds), labelId)
  }

  readContactSystemLabel(ouId: string, userId: string) {
    return this.invoke$(
      this.CONTACT_SYSTEMLABEL_READ,
      ouId,
      userId
    ).pipe(
      map(event => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseContactLabelList(event.dto);
      }),
      tap((labels: ContactLabelState[]) =>
        this.addOrUpdateContactLabels.emit(labels)
      )
    );
    //      return this.api.postAsync("v2/contact/" + contactId + "/circle", JSON.stringify(labelId), contactId)
  }

  assignPrivateCircle(ouId: string, clientId: string, labelId: string, contactId: string) {
    return this.invoke$(
      this.ASSIGN_PRIVATE_CIRCLE_METHOD,
      ouId,
      clientId,
      labelId,
      contactId
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseContactLabelState(event.dto);
      }),
      tap((label: ContactLabelState) =>
        this.addOrUpdateContactLabels.emit([label])
      )
    );
    //      return this.api.postAsync("v2/contact/" + contactId + "/circle", JSON.stringify(labelId), contactId)
  }

  removeLabelFromContact(ouId: string, labelId: string, contactId: string) {
    return this.invoke$(this.REMOVE_LABEL_METHOD, ouId, labelId, contactId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseContactLabelState(event.dto);
      }),
      tap((label: ContactLabelState) =>
        this.addOrUpdateContactLabels.emit([label])
      )
    );
    //      return this.api.deleteAsync("v2/contact/" + contactId + "/label/" + labelId)
  }

  updateUserLanguage(language: string) {
    return this.invoke$(this.USR_LANGUAGE_UPDATE, language).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return event.dto;
      }),
      tap((result: boolean) => { })
    );
  }

  readUserProfile() {
    return this.invoke$(this.USR_PROFILE_READ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseUserProfile(event.dto);
      }),
      tap((state: UserProfileState) => {
        this.updateUserProfileState.emit(state);
      })
    );
  }

  updateUserProfile(firstName: string, lastName: string) {
    const userId = this.userDataSelector.userId;
    return this.invoke$(this.USR_PROFILE_UPDATE, {
      userId: userId,
      firstName: firstName,
      lastName: lastName,
    }) //1: Profile update
      .pipe(
        map((event) => {
          if (!event || !event.isSuccess) throw new Error(event.error);
          return this.parseUserProfile(event.dto);
        }),
        tap((state: UserProfileState) => {
          console.log("[updateUserProfile] updateUserProfileState");
          this.updateUserProfileState.emit(state);
        }),
        tap((state: UserProfileState) => {
          var orgUser = this.enterpriseSelector.getCurrentOrgUser();
          if (orgUser) {
            orgUser.firstName = state.firstName;
            orgUser.lastName = state.lastName;
            console.log("[updateUserProfile] addOrUpdateOrgUser");
            this.addOrUpdateOrgUser.emit([orgUser]);
          }
        }),
        tap((state: UserProfileState) => {
          var contact = this.enterpriseSelector.getCurrentUserContact();
          if (contact) {
            contact.firstName = state.firstName;
            contact.lastName = state.lastName;
            console.log("[updateUserProfile] addOrUpdateContacts");
            this.addOrUpdateContacts.emit([contact]);
          }
        })
      );
  }

  updateCardExpiration(cardExpiration: number) {
    return this.invoke(this.CARD_EXPIRATION_UPDATE_METHOD, cardExpiration);
  }

  toggle2fa(toggle: boolean) {
    return this.invoke(this.TOGGLE_2FA_METHOD, toggle);
  }

  remove2faPhone() {
    return this.invoke(this.REMOVE_2FA_PHONE_METHOD);
  }

  updateSettings(cardExpiration: number, timezone: string, emailFreq: Frequency) {
    return this.invoke(this.UPDATE_SETTINGS_METHOD, cardExpiration, timezone, emailFreq);
  }

  updateTimezone(timezone: string) {
    return this.invoke(this.UPDATE_TIMEZONE_METHOD, timezone).then((event) => {
      if (!event || !event.isSuccess) throw new Error(event.error);
      this.setTimezone.emit(event.dto);
    })
  }

  private getTimezone() {
    var tz = moment.tz.guess();
    console.log("timezone: , %o", tz);
    this.updateTimezone(tz);
  }
  //#region  Feeds

  getLatestFeed(): Observable<{ feeds: Feed[], count: number }> {
    return this.invoke$(this.FEED_READ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        var feeds = this.parseList<Feed>(event.dto.feeds, this.parseFeedState.bind(this));
        var count = event.dto.count;
        return { feeds, count };
      }),
      // tap((res: any) => {
      //   //this.updateUserProfileState.emit(state);
      //   console.log("[getLatestFeed] %o", res);
      // })
    );
  }

  //clear feed after hub is connected (if there are any failed-to-clear feeds while hub is disconnected)
  async clearPendingFeeds() {
    let fds = this.userDataSelector.pendingFeedsToClear;
    if (!fds || fds.length == 0) return;
    this.clearFeed(null, fds, false, true).toPromise();
  }

  clearFeed(key, feedIds: string[], clearAll = false, isPending = false) {
    return this.invoke$(this.FEED_CLEAR, feedIds)
      .pipe(
        map((event) => {
          if (!event || !event.isSuccess) {
            this.addPendingClearFeed.emit(feedIds);
            throw new Error(event.error);
          }
          return event.dto;
        }),
        tap({
          next: res => {
            if (clearAll == true) {
              this.clearAllUnreadFeeds.emit(null);
            } else if (key) {
              this.clearUnreadFeeds.emit(key);
            }

            if (isPending) {
              //console.log("isPending, clearing pending feeds");
              this.removePendingClearFeed.emit(null);
            }

            this.onFeedCleared.next(res);
            return res;
          },
          error: err => { 
            //console.log("clearFeed error 1 %o", err); 
            console.error(err); },
        }),
        catchError((err) => {
          this.addPendingClearFeed.emit(feedIds);
          //console.log("clearFeed error 2 %o", err);
          throw new Error(err);

        })
      );

  }

  getRecentFeed(orgId: string): Promise<Feed[]> {
    return this.invoke(this.ORG_FEED_READ_RECENT, orgId).then((event) => {
      if (!event || !event.isSuccess) throw new Error(event.error);
        var feeds = this.parseList<Feed>(event.dto, this.parseFeedState.bind(this));
        return feeds;
    });
  }

  getOrgFeed(orgId: string): Promise<Feed[]> {
    return this.invoke(this.ORG_FEED_READ_ARCHIVE, orgId).then((event) => {
      if (!event || !event.isSuccess) throw new Error(event.error);
        var feeds = this.parseList<Feed>(event.dto, this.parseFeedState.bind(this));
        this.addOrUpdateInMemFeeds.emit(feeds);
        return feeds;
    });
  }

  getFeedCount(): Promise<Dictionary<number>> {
    return this.invoke(this.FEED_COUNT).then(
      (event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return event.dto;
      }
    );
  }

  updateFeedStatus(feedIds: string[]): Promise<Feed[]> {
    return this.invoke(this.UPDATE_FEED_STATUS, feedIds).then((event) => {
      if (!event || !event.isSuccess) throw new Error(event.error);
        var feeds = this.parseList<Feed>(event.dto, this.parseFeedState.bind(this));
        return feeds;
    })
  }

  setAllOrgFeedRead(orgId: string): Promise<string> {
    return this.invoke(this.SET_ALL_ORG_FEED_READ, orgId).then((event) => {
      if (!event || !event.isSuccess) throw new Error(event.error);
      return event.dto;
    })
  }

  //#endregion

  onFeedEventReceived(event: HubEvent) {
    if (!event) return;
    if (!event.dto) return;
    console.log("feed event! %o", event);
    if (event.name === this.NEW_FEED) {
      console.log("new feed! %o", event.dto);
      if (!event.dto) return;
      let feed = this.parseFeedState(event.dto);
      this.addFeedUnreads.emit([feed]); //old implementation to be removed
      this.addOrUpdateFeeds.emit([feed]);
      this.addOrUpdateUnreads.emit([feed]);
      //receive 1 new feed event for now
      this.pubSub.next(PubSub.ON_NEW_FEED_RECEIVED, feed);

      const isExtTools = feed.event != null ? _.startsWith(feed.event, PluginManager.EXT_PREFIX) : false;
      if (isExtTools) {
        this.pubSub.next(PubSub.ON_EXT_FEED_RECEIVED, [{ event: feed.event, payload: feed.data }]);
      }
    } else if (event.name === this.LATEST_FEEDS) {
      this.onFeedCleared.next(event.dto);
    } else if (event.name === this.FEED_STATUS_UPDATE) {
      var feeds = this.parseList<Feed>(event.dto, this.parseFeedState.bind(this));
      this.addOrUpdateFeeds.emit(feeds);
      this.removeUnreads.emit(feeds);
      this.onFeedStatusUpdate.next(event.dto);
    } else if (event.name === this.ORG_FEED_READ_SET) {
      this.setFeedsAsRead.emit(event.dto);
      this.removeAllUnreads.emit(null);
      this.onAllOrgFdsRead.next(event.dto);
    } else if (event.name === this.ORGS_FEED_READ_SET) {
      this.cleanFeedState.emit(null);
    }
  }


  onUserProfileEventReceived(event: HubEvent) {
    if (!event) return;
    if (!event.dto) return;

    if (event.name == this.UPDATE_USER_PROFILE) {
      var profile = this.parseUserProfile(event.dto);
      this.updateUserProfileState.emit(profile);
      if (event.dto.timezone && event.dto.timezone != this.userDataSelector.timezone) {
        this.setTimezone.emit(event.dto.timezone);
      }

      if (
        event.dto.emailFrequency &&
        event.dto.emailFrequency != this.userDataSelector.emailFrequency
      ) {
        this.setEmailFrequency.emit(event.dto.emailFrequency);
      }

    } else if (event.name == this.UPDATE_USER_LANGUAGE) {
      this.setLanguage.emit(event.dto);
    } else if (event.name == this.UPDATE_USER_TIMEZONE) {
      this.setTimezone.emit(event.dto);
    } else if (event.name == this.UPDATE_EMAIL_FREQUENCY) {
      this.setEmailFrequency.emit(event.dto);
    }
  }

  onCardEventReceived(event: HubEvent) {
    if (!event) return [];
    if (!event.dto) return [];
    if (event.dto.length == 0) return [];

    if (event.name == this.NEW_CARD) {

      var cards = this.parseCardState(event.dto);
      this.addOrUpdateCards.emit([cards]);
    }
  }

  onContactLabelEventReceived(event: HubEvent) {
    if (!event) return;
    if (!event.dto) return;

    if (event.name == this.DELETE_CONTACT_LABEL) {
      var label = this.parseContactLabelState(event.dto);
      if (label) {
        this.removeLabel.emit(label.labelId);
      }
    } else {
      var label = this.parseContactLabelState(event.dto);
      this.addOrUpdateContactLabels.emit([label]);
    }

  }

  parseUserProfile(dto: any) {
    let user = new UserProfileState();
    user.userId = dto.userId;
    user.matrixId = dto.matrixId;
    user.firstName = dto.firstName;
    user.lastName = dto.lastName;
    user.displayName = dto.displayName;
    user.imageUrl = dto.imageUrl ? ENV.publicStorageLink + "avatars/users/" + dto.imageUrl : null;;
    user.email = dto.email;
    user.phoneNumber = dto.phoneNumber;
    user.userName = dto.userName;
    user.twoFactorEnabled = dto.twoFactorEnabled;
    user.emailVerified = dto.emailVerified;
    user.cardExpiration = dto.cardExpiration;
    user.phoneNumberConfirmed = dto.phoneNumberConfirmed;
    return user;
  }

  parseCardsList(dto: any[]): CardState[] {
    let cards: CardState[] = [];
    dto.forEach((d) => cards.push(this.parseCardState(d)));
    return cards;
  }

  parseCardState(d: any): CardState {
    let card = new CardState();
    card.cardId = d.cardId;
    card.orgId = d.orgId;
    card.avatarUrl = d.avatarUrl;
    card.module = d.module;
    card.title = d.title;
    card.subTitle = d.subTitle;
    card.mediaUrl = d.mediaUrl;
    card.mediaName = d.mediaName;
    card.description = d.description;
    card.actions = d.actions;
    card.createdOn = d.createdOn;
    card.createdBy = d.createdBy;
    card.mediaId = d.fwt ? ShortGuid.New() : null;
    card.isFile = card.mediaUrl ? card.mediaUrl.includes("/files/") : false;
    card.isImage = card.mediaUrl ? card.mediaUrl.includes("/images/") : false;
    card.isSystem = d.isSystem;
    card.fwt = d.fwt;
    card.fwtEncoded = d.fwtEncoded;
    card.dismissWhenClick = d.dismissWhenClick;

    return card;
  }

  parseContactLabelList(dto: any): ContactLabelState[] {
    let labels: ContactLabelState[] = [];
    dto.forEach((d) => labels.push(this.parseContactLabelState(d)));
    return labels;
  }

  parseContactLabelState(d: any): ContactLabelState {
    let label = new ContactLabelState();
    label.labelId = d.labelId;
    label.name = d.name;
    label.contactIds = d.contactIds;
    label.isSystemLabel = d.isSystemLabel;

    return label;
  }

  parseFeedState(d: any): Feed {
    let feed = new Feed();
    if (d) {
      feed.id = d.id;
      feed.orgId = d.orgId;
      feed.body = d.body;
      feed.creatorId = d.creatorId;
      feed.event = d.event;
      feed.module = d.module;
      feed.subTitle = d.subTitle;
      feed.title = d.title;
      feed.timestamp = d.timestamp;
      feed.data = d.data;
      feed.status = d.status;
      feed.batchId = d.group;
      feed.isSystem = d.isSystem;
    }
    return feed;
  }
}
