import { AccessPermission, AccessRight } from "./cloud-access-right";
import { CloudFolder } from "./cloud-folder";
import { OriginFile, OriginResource } from "./origin-file";
import { SharedFolder } from "./shared-folder";

export class OriginFolder extends CloudFolder implements OriginResource {
    dummyFolder?: SharedFolder;

    get sharedDummyFolderPath(): string {
        return this.dummyFolder?.path ?? "";
    }

    folders: OriginFolder[] = [];
    files: OriginFile[] = [];

    override hasPath(path: string): boolean {
        return super.hasPath(path) || (this.dummyFolder && this.dummyFolder.path == path);
    }

    override hasExactPath(path: string): boolean {
        return super.hasPath(path);
    }

    isSameResource(resource){
        return (
          resource instanceof OriginFolder &&
          resource.path == this.path &&
          ((resource.dummyFolder == null && this.dummyFolder == null) ||
            (resource.dummyFolder &&
              this.dummyFolder &&
              resource.dummyFolder?.path == this.dummyFolder?.path))
        );
      }

    hasPermission(userId: string, permission: AccessPermission, folderContentOnly: boolean = false, sharePermission: boolean = false){
        var grantees = this.grantees;
        if (!!this.dummyFolder && !!this.dummyFolder.grantees && this.dummyFolder.grantees.length !== 0) grantees = grantees.concat(this.dummyFolder.grantees);
        if (!grantees || grantees.length == 0) return false;
        var currentGrantee = grantees
          .filter((g) => !sharePermission || g.isSharedAccess)
          .find((g) => g.identity == userId);
        if (!currentGrantee) return false;
        return (currentGrantee.permission & permission) != 0 || currentGrantee.permission == permission;
    }

    /** DTO is OriginFolderDto from file server. */
    public static parse(dto: any) {
        if (!dto) return null;
        var obj = new OriginFolder();

        obj.container = dto.container;
        obj.name = dto.displayName;
        obj.path = dto.path;
        obj.createdOn = dto.createdOn;
        obj.modifiedOn = dto.modifiedOn;
        obj.isDefault = dto.isDefault;
        obj.enableNotifications = dto.enableNotifications;
        obj.ownerId = dto.ownerIdentity;

        if (dto.folders) {
            dto.folders.forEach(f => obj.folders.push(OriginFolder.parse(f)));
        }

        if (dto.files) {
            dto.files.forEach(f => obj.files.push(OriginFile.parse(f)));
        }

        if (dto.grantees) {
            dto.grantees.forEach(f => obj.grantees.push(AccessRight.parse(f)));
        }

        if (obj.isShared){
            obj.icon = "folder_shared";
        }

        if (dto.fromSharedDummyFolder) {
            obj.dummyFolder = SharedFolder.parse(dto.fromSharedDummyFolder);
        }

        return obj;
    }
}

