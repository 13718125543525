export class SubscriptionState {
  id: string;
  subscriptionId: string; // old Id, to be removed
  orgId: string;
  startedOn: Date;
  expiredOn: Date;
  status: SubStatus;
  statusName: string;
  reason: string;
  type: SubscriptionType;
  typeName: string;
  freeCredits: number;
  // accumulatedCost: number;
  manualBilling: boolean;

  constructor() {
    this.manualBilling = false;
  }
}
export class Invoice {
  id: string;
  subId: string;
  invoiceNo: string;
  total: number;
  subTotal: number;
  createdOn: Date;
  startedOn: Date;
  dueOn: Date;
  endedOn: Date;
  paid: boolean;
  addrStreet: string;
  addrCity: string;
  addrState: string;
  addrPostalCode: string;
  addrCountry: string;

  items: InvoiceLineState[];

  constructor() {
    this.items = [];
  }
}

export class InvoiceLineState {
  id: string;
  invoiceId: string;
  subId: string;
  name: string;
  description: string;
  quantity: number;
  total: number;
  unit: string;
  unitPrice: number;
  reserved: number;
  chargable: number;
  type: LineItemType;

}

export enum LineItemType {
  LineItem = 0,
  Discount = 1,
  Tax = 2,
}

export enum AddOnStatus {
  NotAllocated = 0,
  Available = 1,
  NotApplicable = 9
}

export enum SubscriptionType {
  Free = 0,
  Standard = 1,
  Enterprise = 2
}

export enum SubStatus {
  Inactive = 0,
  Active = 1,
  Pending = 2,
  Expired = 4,
  PaymentFailed = 5,
  Canceled = 9
}
