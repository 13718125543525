export class FeatureState {
  id: string;
  subscriptionId: string;
  orgId: string;
  productCode: string;
  type: FeatureType;
  displayName: string;
  unit: string;

  constructor() {
  }
}

export enum FeatureType {
  System = 1,
  Custom = 2,
}

export class ResourceCode {
  static readonly CLOUD_STORAGE_DOWNLOAD = "cloud-storage-download";
  static readonly CLOUD_STORAGE_UPLOAD = "cloud-storage-upload";
  static readonly VIDEO_CONFERENCE = "video-conference";
  static readonly INT_LICENSE = "internal-user";
  static readonly EXT_LICENSE = "external-user";
}
