import { FlowManager } from './../../workflow/flow-manager';
import { Router } from "@angular/router";
import { DialogNewOrgComponent } from "./../dialog/new-org/dialog-new-org.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Component, OnInit, OnDestroy } from "@angular/core";
// import { EsignAuthService } from "../../../esign/service/esignauth.service";
import { Emittable, Emitter } from '@ngxs-labs/emitter';
import { EnterpriseState } from '../../states/enterprise.state';
import { EnterpriseSelector } from '../../states/enterprise.state.selector';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from "rxjs/operators";
import { OrgState, OrgUserState } from '../../model/org.state';
import { SubSink } from 'subsink';
import { UserDataSelector } from '../../states/user-data.state.selector';
import { HistoryState } from '../../states/history.state';
import { LoginHistoryState } from '../../model/login-history.state';
import { map } from 'rxjs/operators';
import * as _ from "lodash";
import { UserStatus } from '../../enum/user-status.enum';
import { EntityOrgStatus } from '../../enum/entity-status.enum';
import { SubscriptionState, SubscriptionType, SubStatus } from '../../model/subscription.state';
import { LicenseState } from '../../states/license.state';
import { RoleEntity } from '../../model/userRole.model';
import { DialogUpgradeSubComponent } from "../../../organization/subscription/dialog/dialog-upgrade-sub.component";
import { LicenseStateSelector } from "../../../core/states/license.state.selector";
import { UIService } from "../../../core/services/ui.service";
import { OrgType } from "app/core/enum/org-type";

@Component({
  selector: "ui-current-ou",
  templateUrl: "./current-ou.component.html",
  styleUrls: ["./current-ou.component.scss"]
})
export class CurrentOUComponent implements OnInit, OnDestroy {

  @Select(EnterpriseState.internalOrgs)
  internalOrgs$: Observable<OrgState[]>;

  @Select(EnterpriseState.serviceOrgs)
  serviceOrgs$: Observable<OrgState[]>;

  @Select(EnterpriseState.externalOrgs)
  externalOrgs$: Observable<OrgState[]>;

  @Select(EnterpriseState.selectedOrg) selectedOrg$: Observable<OrgState>;

  @Select(EnterpriseState.users)
  users$: Observable<OrgUserState[]>;

  @Select(LicenseState.subscriptions)
  subscriptions$: Observable<SubscriptionState[]>;

  @Emitter(HistoryState.save)
  saveLoginHistory: Emittable<LoginHistoryState>;

  @Select(LicenseState.currentOrgSubscription)
  currentOrgSubscription$: Observable<SubscriptionState>;

  orgUnits: any[] = [];
  currentOU: any;
  currentOUId: string;
  oldOUId: string;
  currentOURole: string;
  joinedOUDate: Date;
  hasBusinessOrg: boolean;
  private _orgUsers: { [orgId: string]: OrgUserState } = {};
  private _currentUserId: string;
  // group orgs
  orgMyOrgs: any[] = [];
  orgPartners: any[] = [];
  orgClients: any[] = [];

  //subscriptions
  subscriptions: SubscriptionState[] = [];

  private _subSink: SubSink;

  isOrgLoading = true;

  sub: SubscriptionState;

  get isFreePlan(): boolean {
    return this.sub && this.sub.type == SubscriptionType.Free;
  }

  get isOwner(): boolean {
    let orgUser = this.enterpriseSelector.getCurrentOrgUser();
    if (!orgUser) return false;
    let role: any = RoleEntity[orgUser.role];
    return (
        role == RoleEntity.OWNER
    );
  }

  get isPersonalSpace(): boolean {
    return this.enterpriseSelector.getCurrentOrgType() == OrgType.Personal;
  }

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    // private esignauth: EsignAuthService,
    private enterpriseSelector: EnterpriseSelector,
    private userSelector: UserDataSelector,
    private router: Router,
    private flowManager: FlowManager,
    private uiService: UIService,
    private licenseSelector: LicenseStateSelector,
  ) {
    this._subSink = new SubSink();
  }

  ngOnInit() {
    this._currentUserId = this.userSelector.userId;
    this.sub = this.licenseSelector.getCurrentOrgSubscription();

    this._subSink.sink = this.currentOrgSubscription$.pipe(
      distinctUntilChanged((prev, curr) => {
        if (curr == null) return true;
        if (prev == null && curr != null) return false;
        return prev.id === curr.id && prev.status === curr.status && prev.type === curr.type && prev.reason === curr.reason;
      })
    ).subscribe((sub) => {
      this.sub = sub;
    });

    this._subSink.sink = this.externalOrgs$.subscribe(res => {
      this.orgPartners = this.sortOrgs(res);
    });

    this._subSink.sink = this.internalOrgs$.subscribe(res => {
      this.orgMyOrgs = this.sortOrgs(res);
      this.hasBusinessOrg = this.orgMyOrgs.length == 1 ? false : true
    });

    this._subSink.sink = this.serviceOrgs$.subscribe(res => {
      this.orgClients = this.sortOrgs(res);
    });

    this._subSink.sink = this.selectedOrg$.subscribe(res => {
      if (res) {
        const userId = this.userSelector.userId; // Retrieve the current user's ID
        const orgId = res.id; // Retrieve the current organization's ID
        const storageKey = `joinedOUDate_${userId}_${orgId}`; // Create a unique storage key for each user and organization

        // Check if joinedOUDate is already stored for this user and organization
        const storedDate = localStorage.getItem(storageKey);
        if (!storedDate) {
          // If not, set it and store it
          this.joinedOUDate = new Date(res.joinedOn);
          localStorage.setItem(storageKey, this.joinedOUDate.toISOString());
        } else {
          // If it's stored, use the stored date
          this.joinedOUDate = new Date(storedDate);
        }
        // this.joinedOUDate = new Date(res.joinedOn);
        var ou = this.enterpriseSelector.getCurrentOrgUser();
        if (ou) {
          const roles = this.enterpriseSelector.getOrgRoles(this.currentOUId);
          const role = roles.find(
            (r) => r.normalizedName.toLowerCase() === ou.role.toLowerCase()
          );
          this.currentOURole = role ? role.label : ou.role;
        }
        this.currentOUId = res.id;
        this.currentOU = res;
        this.isOrgLoading = false;
      }
    })

    this._subSink.sink = this.users$.pipe(
      map((orgUsers: OrgUserState[]) => orgUsers.filter(u => u.userId === this._currentUserId))
    ).subscribe((orgUsers: OrgUserState[]) => {
      if (orgUsers && orgUsers.length > 0) {
        this._orgUsers = {};
        orgUsers.forEach(u => {
          // var org = this.enterpriseSelector.getOrg(u.orgId);
          // console.log("[CurrentOU] org user: org: %s, state: %s, uid: %s", org.name, u.status, u.userId);
          this._orgUsers[u.orgId] = u;
        });
        //console.log("[CurrentOU] %o", this._isUserSuspended);
      }
    });

    this._subSink.sink = this.subscriptions$.subscribe(res => {
      this.subscriptions = res;
      console.log(this.subscriptions)
    });
  }

  private sortOrgs(orgs: OrgState[]) {
    if (!orgs) return [];
    return orgs.sort(function (a, b) {
      var nameA = a.name.toUpperCase();
      var nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });

  }

  getFontWeight(ouId) {
    return (this.currentOUId === ouId) ? "900" : "500";
  }

  getCursor(ouId) {
    return (this.currentOUId === ouId) ? "none" : "pointer";
  }

  switch(newValue, newOrg = false) {
    if (newValue === this.currentOUId) return;
    if (newValue === "new") {
      return this.currentOUId;
    } else {
      // this.switchOrg.emit(newValue).subscribe(() => {
      //   // set loading after emit for new orgs
      //   if (setLoading)
      //     this.isOrgLoading = true;
      // });
      this.flowManager.switchOrg(newValue).catch(() => {
        this.openSnackBar("Failed to prepare org", "OK");
      });

      if (newOrg) {
        this.router.navigateByUrl("/main/dashboard?status=newOrg");
      } else {
        this.router.navigateByUrl("/main/dashboard");
      }

      // save login history
      let history: LoginHistoryState = { userId: this.userSelector.userId, orgId: newValue }
      this.saveLoginHistory.emit(history);

      // this.esignauth.updateOrg(this.currentOU);
      return newValue;
    }
  }

  newOrgDialog() {
    const dialogRef = this.dialog.open(DialogNewOrgComponent, {
      width: "700px", panelClass: "border-dialog", height: "530px",
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        if (res.status === "success") {
          this.switch(res.response.id, true);
        }
        else if (res.status === "pending") {
          this.switch(res.response.id);
        } else if (res.status === "paymentfailed") {
          this.switch(res.response.id);
        }
        else if (res.status === "failed") {
          // error
          this.openSnackBar(res.response, "OK");
        }
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

  ngOnDestroy() {
    if (this._subSink) this._subSink.unsubscribe();
  }

  upgradePlan() {
    const dialogRef = this.dialog.open(DialogUpgradeSubComponent, {
      width: "500px",
      panelClass: "border-dialog"
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (res.status === "success") {
          this.sub.status = SubStatus.Pending;
        } else
          if (res.status === "failed") {
            // error
            this.uiService.openSnackBar(res.error, "OK");
          }
      }
    });
  }

  isDisableOrg(org: OrgState): boolean{
    let orgUser = this._orgUsers[org.id];
    //let owner access org when not enough license to upgrade sub
    if (
      this.isOrgActive(org) &&
      (orgUser.status === UserStatus.SubscriptionLimitExceed || this.isSubPaymentFailed(org.id)) &&
      orgUser.role == RoleEntity[RoleEntity.OWNER]
    )
      return false;

    return this.isOrgInactive(org) || this.isUserSuspended(org);
  }

  //#region orgStatus helper method
  getUserStatus(orgId): UserStatus {
    let orgUser = this._orgUsers[orgId];
    if (!orgUser) return UserStatus.Unknown;
    let userStatus = orgUser.status;
    if (userStatus) {
      return userStatus;
    } else {
      return UserStatus.Unknown;
    }
  }

  isUserSuspended(org: OrgState): boolean {
    if (org == null) return true;
    let orgUser = this._orgUsers[org.id];
    let userStatus = this.getUserStatus(org.id);
    if (
      ((!this.isOrgInactive(org) && this.isSubPaymentFailed(org.id)) ||
        userStatus === UserStatus.SubscriptionLimitExceed) &&
      orgUser &&
      orgUser.role != RoleEntity[RoleEntity.OWNER]
    )
      return true;
    if (userStatus === UserStatus.ExpiredLicense || userStatus === UserStatus.Suspended || userStatus === UserStatus.Unknown)
      return true;
    else return false;
  }

  getInactiveTooltip(ou: OrgState): string {
    if (this.isUserSuspended(ou)) {
      return "You are suspended from this organization"
    }
    if (ou.status === EntityOrgStatus.Inactive) {
      return "Your organization license is inactive"
    }
  }
  //#endregion

  //#region new payg orgStatus  
  getOrgSubState(orgId: string): SubStatus {
    var sub = this.subscriptions.filter(x => x.orgId === orgId)[0];
    if (sub) {
      return sub.status;
    }
    else return SubStatus.Inactive;
  }
  isOrgPending(ou: OrgState): boolean {
   if(ou.status == EntityOrgStatus.Pending) return true;
    return false;
  }

  isOrgActive(ou: OrgState): boolean {
    if(ou.status == EntityOrgStatus.Active) return true;
     return false;
  }

  isOrgInactive(ou: OrgState): boolean {
    if(ou.status == EntityOrgStatus.Inactive || ou.status == EntityOrgStatus.Suspended) return true;
    return false;
  }

  isSubPaymentFailed(orgId: string): boolean {
    if (this.getOrgSubState(orgId) == SubStatus.PaymentFailed) return true;
    return false;
  }

  //#endregion
}
