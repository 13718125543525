import { LicenseState } from './../../core/states/license.state';
import { FlowManager } from './../../core/workflow/flow-manager';
import { AuthService } from './../../core/auth/auth.service';
import { NotificationService } from './../../core/services/notify.service';
import {
  Component,
  HostBinding,
  HostListener,
  OnInit,
  OnDestroy,
  ViewChild,
  Injector,
} from "@angular/core";
import { ResizeService } from "../../resize/resize.service";
import { routerAnimation } from "../../utils/page.animation";
import { Router, NavigationEnd, ActivatedRoute, Params } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DialogChangePasswordComponent } from "../../core/ui/dialog/change-password/dialog-change-password.component";
import { UIService } from "../../core/services/ui.service";
import { environment } from "../../../environments/environment";
import { Channel } from "../../core/model/channel.model";
import { Observable, Subscription } from "rxjs";
import { MessagingService } from "../../core/services/firebase-messaging.service";
import { OrgType } from "../../core/enum/org-type";
import { filter, map, mergeMap, distinctUntilChanged, takeWhile, startWith, skip } from "rxjs/operators";
import {
  SideItemButton,
} from "../../sidemenu/side-item-button";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import { UserDataSelector } from "../../core/states/user-data.state.selector";
import { EnterpriseSelector } from "../../core/states/enterprise.state.selector";
import { SubSink } from "subsink";
import {
  ChannelState,
  OrgState,
  OrgUserState,
} from "../../core/model/org.state";
import { Select } from "@ngxs/store";
import { EnterpriseState } from "../../core/states/enterprise.state";
import { UserStatus } from "../../core/enum/user-status.enum";
import { PubSub } from "../../core/services/pubsub.service";
import { AppStateSelector } from "../../core/states/app.state.selector";
import { UserDataState } from "../../core/states/user-data.state";
import { UserProfileState } from "../../core/model/user-profile.state";
import { SystemHub } from "../../core/hub/system.hub";
import { AppState } from "../../core/states/app.state";
import { MessagingSelector } from "../../core/states/messaging.state.selector";
import { TranslateService } from "@ngx-translate/core";
import { OrgHub } from '../../core/hub/org.hub';
import { UIState } from '../../core/states/ui.state';
import { UIStateSelector } from '../../core/states/ui.state.selector';
import { Dictionary, StringDictionary } from '../../core/util/dictionary';
import * as _ from "lodash";
import { DialogSwitchOUComponent } from '../../core/ui/dialog/switch-ou/dialog-switch-ou.component';
import { Feed } from '../../core/model/feed';
import { LoginHistoryState } from '../../core/model/login-history.state';
import { HistoryState } from '../../core/states/history.state';
import { HubConnectionStatus } from '../../core/model/hubConnection.state';
import { DateAgoPipe } from '../../core/pipe/date-ago.pipe';
import { RoleEntity } from '../../core/model/userRole.model';
import { OrgUserComponent } from '../../organization/user/user.component';
import { FriendsComponent } from '../../friends/friends.component';
import { BroadcastChannel as BC } from 'broadcast-channel';
import { BrowserCheck } from '../../core/util/browser-check';
import { NotificationData } from '../../core/model/notification-data.model';
import { AuthGuard } from '../../core/auth/auth.guard';
import { UserService } from '../../core/services/user.service';
import { FeedService, FeedToRemove } from '../../core/services/feed.service';
import { MenuItem } from '../../core/ui/menu-item';
import { PluginManager } from '../../tools/plugin.manager';
import { NotificationEventType } from '../../core/enum/notification-event-type.enum';
import { IdleService } from '../../core/services/idle.service';
import { DialogNewOrgComponent } from '../../core/ui/dialog/new-org/dialog-new-org.component';
import { DialogInviteUsersComponent } from '../../core/ui/dialog/invite-users/dialog-invite-users.component';
import { SharedFolder } from '../../core/model/shared-folder';
import { SharedFile } from '../../core/model/shared-file';
import { SubscriptionState, SubStatus } from '../../core/model/subscription.state';
import { LicenseStateSelector } from '../../core/states/license.state.selector';
import { UIStatus } from '../../core/states/ui.state';
import { EntityOrgStatus, EntityStatus } from '../../core/enum/entity-status.enum';
import { MatMenuTrigger } from '@angular/material/menu';
import { FeedStateSelector } from '../../core/states/feed.state.selector';
import { RedirectHandler } from '../../core/feed/RedirectHandler';

import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { MatDrawer } from '@angular/material/sidenav';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { SmsPopUpContentComponent } from 'app/tools/sms/pages/sms-pop-up-content/sms-pop-up-content.component';
// interface FreshchatWindow extends Window {
//   fcWidget: any
// }
// declare var window: FreshchatWindow;

@Component({
  selector: "app-main-page",
  templateUrl: "./main-page.component.html",
  styleUrls: ["./main-page.component.scss"],
  animations: [routerAnimation],
  providers: [DateAgoPipe]
})
export class MainPageComponent implements OnInit, OnDestroy {

  hide(item: FuseNavigationItem, hide: boolean): boolean {
    return true
  }

  show(item: FuseNavigationItem, hide: boolean): boolean {
    return false
  }

  @ViewChild(MatMenuTrigger) alertMenuTrigger: MatMenuTrigger;
  
  @Select(UserDataState.userProfile)
  public userProfile$: Observable<UserProfileState>;

  @Select(UserDataState.language)
  public language$: Observable<string>;

  @Select(FeedStateSelector.hasUnreadActivity)
  public unreadActivityCount$: Observable<number>;

  @Select(FeedStateSelector.msgsTotalUnreads)
  public currentMsgUnreadCount$: Observable<number>;

  @Select(FeedStateSelector.channelTotalUnreads)
  public channelTotalUnreads$: Observable<number>;

  @Select(EnterpriseState.chUnreads)
  public unreads$: Observable<StringDictionary>;

  @Select(FeedStateSelector.sharedTotalUnreads)
  public storageTotalUnreads$: Observable<number>;

  @Emitter(HistoryState.save)
  saveLoginHistory: Emittable<LoginHistoryState>;

  @Emitter(UIState.setRedirectOnSwitchOrg)
  setRedirectOnSwitchOrg: Emittable<boolean>;

  OrgType = OrgType;
  // Add router animation
  @HostBinding("@routerAnimation") routerAnimation = true;
  // Applying theme class
  @HostBinding("class.dark-theme") darkTheme = false;

  private _sidenavMode = "side";
  private _boxedLayout = false;
  public _quickShortcutsExpand = true;
  public sideNavOpened = false;
  public thinMargin = false;
  public version: string = environment.version;
  public build: string = environment.build;
  public title: string;
  language: string;
  languageDisplay: string;
  lastConnectionStatus: any;

  //menu
  private _toolMenu: string[] = [];
  toolMenus: MenuItem[] = [];

  //NGXS part
  private _subs: SubSink;
  private _channels: ChannelState[] = [];
  private _userProfile: UserProfileState;
  private _currentOrg: OrgState;
  private _currentOrgUser: OrgUserState;
  private _currentMsgUnreadCount: number;
  private _currentChUnreadCount: number;
  private _currentStorageUnreadCount: number;
  private _currentOrgNotificationCount: number;
  private _unreads: StringDictionary;
  private _hasPersonalOrgUnreads: boolean = false;
  private _isIndicatorGlowing: boolean = false;
  private _currentOrgSub: SubscriptionState;

  orgAlerts: OrgAlert[] = [];
  //notificationGroup: NotificationGroup[] = [];
  orgId: string;
  orgType: OrgType;
  userId: string;
  public userDisplayName: string;

  @Select(EnterpriseState.currentChannels)
  onChannelsUpdated$: Observable<ChannelState[]>;
  @Select(EnterpriseState.selectedOrg)
  selectedOrg$: Observable<OrgState>;

  @Select(EnterpriseState.currentOrgUser)
  currentOrgUser$: Observable<OrgUserState>;

  @Select(LicenseState.currentOrgSubscription)
  currentOrgSubscription$: Observable<SubscriptionState>;

  @Emitter(AppState.setBackground)
  public setBackgrond: Emittable<boolean>;

  @Emitter(UserDataState.setLanguage)
  public setLanguage: Emittable<string>;

  @Emitter(UserDataState.addFeedUnreads)
  public addFeedUnreads: Emittable<Feed[]>;
  //NGXS end

  isAdmin = false;

  //alert notif
  alertNotifs: AlertNotification[] = [];

  //flags
  hasHubConnected = false;
  isRedirectToLaunchPage = false;
  isFirstLoad = true;
  reevaluateMyDrive = false;

  // broadcast channel
  public channel = new BC('elWebApp');

  // sub
  private _licenseSub: Subscription;

  isScreenSmall: boolean;

  public defaultnavigation: FuseNavigationItem[] = [  
    // default: [
    {
      id   : 'side.dashboard',
      title: this.translateService.instant('MAIN.DASHBOARD'),
      type : 'basic',
      icon : 'heroicons_outline:template',
      link : '/main/dashboard'
    },
    {
      id   : 'side.activity',
      title: this.translateService.instant('MAIN.ACTIVITY'),
      type : 'basic',
      icon : 'heroicons_outline:bell',
      link : '/main/activity'
    },          
    {
      id   : 'side.chat',
      title: this.translateService.instant('MAIN.CHAT'),
      type : 'basic',
      icon : 'heroicons_outline:chat-alt',
      link : '/main/chat'
    },
    {
      id   : 'side.openmeet',
      title: this.translateService.instant('MAIN.OPEN_MEET'),
      type : 'basic',
      icon : 'heroicons_outline:video-camera',
      link : '/main/openmeet'
    },
    {
      id   : 'side.teams',
      title: this.translateService.instant('MAIN.TEAM'),
      type : 'basic',
      icon : 'heroicons_outline:globe',
      link : '/main/teams',
    },
    {
      id   : 'side.feed',
      title: this.translateService.instant('MAIN.FEED'),
      type : 'basic',
      icon : 'heroicons_outline:globe',
      link : '/main/teams/feed',
    },
    {
      id   : 'side.directory',
      title: this.translateService.instant('MAIN.DIRECTORY'),
      type : 'collapsable',
      icon : 'heroicons_outline:user-group',
      children: [
        {
          id   : 'side.connections',
          title: this.translateService.instant('MAIN.CONNECTIONS'),
          type : 'basic',
          icon : 'heroicons_outline:user',
          link : '/main/directory/connections',
        },
        {
          id   : 'side.labels',
          title: this.translateService.instant('MAIN.LABELS'),
          type : 'basic',
          icon : 'heroicons_outline:tag',
          link : '/main/directory/labels',
        },
      ]
    },
    {
      //el-permission permission-type="OU_DELEGATECLIENT_CREATE"
      id   : 'side.operation',
      title: this.translateService.instant('MAIN.OPERATION'),
      type : 'collapsable',
      children: [
        {
          id   : 'side.client-assignment',
          title: this.translateService.instant('MAIN.CLIENT_ASSIGNMENT'),
          type : 'basic',
        },
        {
          id   : 'side.private-circle',
          title: this.translateService.instant('MAIN.PRIVATE_CIRCLE'),
          type : 'basic',
        },
      ]
    },
    {
      id   : 'side.settings',
      title: this.translateService.instant('MAIN.SETTINGS'),
      type : 'collapsable',
      children: [
        {
          //el-permission permission-type="OU_UPDATE"
          id   : 'side.organization',
          title: this.translateService.instant('MAIN.ORGANIZATION'),
          type : 'basic',
          icon : 'heroicons_outline:building-office',
          link : '/main/org/settings',
        },
        {
          //el-permission permission-type="OU_READ"
          id   : 'side.organization-units',
          title: this.translateService.instant('MAIN.ORGANIZATION_UNITS'),
          type : 'basic',
          icon : 'heroicons_outline:building-office-2',
          link : '/main/org/ou',
        },
        {
          //el-permission permission-type="ORG_SETTINGS_UPDATE"
          id   : 'side.subscriptions',
          title: this.translateService.instant('MAIN.SUBSCRIPTIONS'),
          type : 'basic',
          icon : 'heroicons_outline:currency-dollar',
          link : '/main/org/subscription',
        },
        {
          //el-permission permission-type="OU_USER_READ"
          id   : 'side.users',
          title: this.translateService.instant('MAIN.USERS'),
          type : 'basic',
          icon : 'heroicons_outline:user-add',
          link : '/main/org/user',
        },
      ]
    },
    {
      id   : 'side.friends',
      title: this.translateService.instant('MAIN.FRIEND'),
      type : 'basic',
      icon : 'heroicons_outline:user',
      link : '/main/friends',
    },
    {
      id   : 'side.storage',
      title: this.translateService.instant('MAIN.STORAGE'),
      type : 'collapsable',
      children: [
        {
          id   : 'side.files',
          title: this.translateService.instant('MAIN.MY_FILES'),
          type : 'basic',
        },
        {
          id   : 'side.files-drive',
          title: this.translateService.instant('MAIN.SHARED_DRIVE'),
          type : 'basic',
        },
        {
          id   : 'side.files-shared',
          title: this.translateService.instant('MAIN.SHARED_WITH_ME'),
          type : 'basic',
        },
      ]
    },
    {
      id   : 'tools.openai',
      title: '',
      type : 'collapsable',
            children: [
        {
          id   : 'openai/chatmain',
          title: '',
          type : 'basic',
          link : '/main/tools/openai/chatmain',
        },
        {
          id   : 'openai/aitextedit',
          title: '',
          type : 'basic',
          link : '/main/tools/openai/aitextedit',
        },
        {
          id   : 'openai/imagetool',
          title: '',
          type : 'basic',
          link : '/main/tools/openai/imagetool',
        },
      ]
    },
    {
      id   : 'tools.ustax',
      title: '',
      type : 'collapsable',
            children: [
        {
          id   : 'ustax/history',
          title: '',
          type : 'basic',
          link : '/main/tools/ustax/history',
        },
        {
          id   : 'ustax/archive',
          title: '',
          type : 'basic',
          link : '/main/tools/ustax/archive',
        },
        {
          id   : 'ustax/settings',
          title: '',
          type : 'basic',
          link : '/main/tools/ustax/settings',
        },
      ]
    },  
    {
      id   : 'tools.ezsign',
      title: '',
      type : 'basic',
    },  
    {
      id   : 'tools.incomeexpense',
      title: '',
      type : 'basic',
    },  
    // {
    //   id   : 'tools.home',
    //   title: '',
    //   type : 'basic',
    // },
    {
      id   : 'tools.elnet',
      title: '',
      type : 'collapsable',
            children: [
        {
          id   : 'elnet/companyinfo',
          title: '',
          type : 'basic',
          link : '/main/tools/elnet/companyinfo',
        },
        {
          id   : 'elnet/userinfo',
          title: '',
          type : 'basic',
          link : '/main/tools/elnet/userinfo',
        },
        {
          id   : 'elnet/deviceinfo',
          title: '',
          type : 'basic',
          link : '/main/tools/elnet/deviceinfo',
        },
        {
          id   : 'elnet/elnetdashboard',
          title: '',
          type : 'basic',
          link : '/main/tools/elnet/elnetdashboard',
        },
        {
          id   : 'elnet/mydevice',
          title: '',
          type : 'basic',
          link : '/main/tools/elnet/mydevice',
        },
      ]
    },
    {
      id   : 'tools.elsms',
      title: '',
      type : 'collapsable',
            children: [
        {
          id   : 'elsms/companyinfo',
          title: '',
          type : 'basic',
          link : '/main/tools/sms/companyinfo',
        },
        {
          id   : 'elsms/userinfo',
          title: '',
          type : 'basic',
          link : '/main/tools/sms/userinfo',
        },
        {
          id   : 'elsms/smsinfo',
          title: '',
          type : 'basic',
          link : '/main/tools/sms/smsinfo',
        },
        {
          id   : 'elsms/elsmsdashboard',
          title: '',
          type : 'basic',
          link : '/main/tools/sms/smsdashboard',
        },
        {
          id   : 'elsms/mysms',
          title: '',
          type : 'basic',
          link : '/main/tools/sms/mysms',
        },
      ]
    }, 
    {
      id   : 'side.help',
      title: 'Help',
      type : 'basic',
    },  
  ]

  public navigation: FuseNavigationItem[] = [  
    // default: [
    {
      id   : 'side.dashboard',
      title: this.translateService.instant('MAIN.DASHBOARD'),
      type : 'basic',
      icon : 'heroicons_outline:template',
      link : '/main/dashboard'
    },
    {
      id   : 'side.activity',
      title: this.translateService.instant('MAIN.ACTIVITY'),
      type : 'basic',
      icon : 'heroicons_outline:bell',
      link : '/main/activity'
    },         
    {
      id   : 'side.chat',
      title: this.translateService.instant('MAIN.CHAT'),
      type : 'basic',
      icon : 'heroicons_outline:chat-alt',
      link : '/main/chat'
    },
    {
      id   : 'side.openmeet',
      title: this.translateService.instant('MAIN.OPEN_MEET'),
      type : 'basic',
      icon : 'heroicons_outline:video-camera',
      link : '/main/openmeet'
    },
    {
      id   : 'side.teams',
      title: this.translateService.instant('MAIN.TEAM'),
      type : 'basic',
      icon : 'heroicons_outline:globe',
      link : '/main/teams',
    },
    {
      id   : 'side.feed',
      title: this.translateService.instant('MAIN.FEED'),
      type : 'basic',
      icon : 'heroicons_outline:globe',
      link : '/main/teams/feed',
    },
    {
      id   : 'side.directory',
      title: this.translateService.instant('MAIN.DIRECTORY'),
      type : 'collapsable',
      icon : 'heroicons_outline:user-group',
      children: [
        {
          id   : 'side.connections',
          title: this.translateService.instant('MAIN.CONNECTIONS'),
          type : 'basic',
          icon : 'heroicons_outline:user',
          link : '/main/directory/connections',
        },
        {
          id   : 'side.labels',
          title: this.translateService.instant('MAIN.LABELS'),
          type : 'basic',
          icon : 'heroicons_outline:tag',
          link : '/main/directory/labels',
        },
      ]
    },
    {
      //el-permission permission-type="OU_DELEGATECLIENT_CREATE"
      id   : 'side.operation',
      title: this.translateService.instant('MAIN.OPERATION'),
      type : 'collapsable',
      icon : 'heroicons_outline:clipboard-check',
      hidden: this.show.bind(this, true),
      children: [
        {
          id   : 'side.client-assignment',
          title: this.translateService.instant('MAIN.CLIENT_ASSIGNMENT'),
          type : 'basic',
          icon : 'heroicons_outline:users',
          link : '/main/operation/client/assign',
        },
        {
          id   : 'side.private-circle',
          title: this.translateService.instant('MAIN.PRIVATE_CIRCLE'),
          type : 'basic',
          icon : 'heroicons_outline:user-circle',
          link : '/main/operation/circle/associate',
        },
      ]
    },
    {
      //el-permission [list-permission]="[
      //   'OU_UPDATE',
      //   'OU_READ',
      //   'OU_GROUP_UPDATE',
      //   'OU_USER_READ'
      // ]
      id   : 'side.settings',
      title: this.translateService.instant('MAIN.SETTINGS'),
      type : 'collapsable',
      icon : 'heroicons_outline:cog',
      hidden: this.show.bind(this, true),
      children: [
        {
          //el-permission permission-type="OU_UPDATE"
          id   : 'side.organization',
          title: this.translateService.instant('MAIN.ORGANIZATION'),
          type : 'basic',
          icon : 'heroicons_outline:building-office',
          link : '/main/org/settings',
        },
        {
          //el-permission permission-type="OU_READ"
          id   : 'side.organization-units',
          title: this.translateService.instant('MAIN.ORGANIZATION_UNITS'),
          type : 'basic',
          icon : 'heroicons_outline:building-office-2',
          link : '/main/org/ou',
        },
        {
          //el-permission permission-type="ORG_SETTINGS_UPDATE"
          id   : 'side.subscriptions',
          title: this.translateService.instant('MAIN.SUBSCRIPTIONS'),
          type : 'basic',
          icon : 'heroicons_outline:currency-dollar',
          link : '/main/org/subscription',
        },
        {
          //el-permission permission-type="OU_USER_READ"
          id   : 'side.users',
          title: this.translateService.instant('MAIN.USERS'),
          type : 'basic',
          icon : 'heroicons_outline:user-add',
          link : '/main/org/user',
        },
      ]
    },
    {
      id   : 'side.friends',
      title: this.translateService.instant('MAIN.FRIEND'),
      type : 'basic',
      icon : 'heroicons_outline:user',
      link : '/main/friends',
    },  
    {
      //el-permission permission-type="OU_DELEGATECLIENT_CREATE"
      id   : 'side.storage',
      title: this.translateService.instant('MAIN.STORAGE'),
      type : 'collapsable',
      icon : 'heroicons_outline:cloud',
      children: [
        {
          id   : 'side.files',
          title: this.translateService.instant('MAIN.MY_FILES'),
          type : 'basic',
          // icon : 'heroicons_outline:document-text',
          icon : 'heroicons_outline:folder',
          link : '/main/storage/local',
        },
        {
          id   : 'side.files-drive',
          title: this.translateService.instant('MAIN.SHARED_DRIVE'),
          type : 'basic',
          icon : 'heroicons_outline:database',
          link : '/main/storage/drive',
        },
        {
          id   : 'side.files-shared',
          title: this.translateService.instant('MAIN.SHARED_WITH_ME'),
          type : 'basic',
          // icon : 'heroicons_outline:document-duplicate',
          icon : 'heroicons_outline:folder-open',
          link : '/main/storage/shared',
        },
      ]
    },
    // {
    //   id   : 'side.tools',
    //   title: this.translateService.instant('ORGANIZATION.SETTING.TOOLS'),
    //   type : 'group',
    //   children: [
    {
      id   : 'tools.openai',
      title: '',
      type : 'collapsable',
            children: [
        {
          id   : 'openai/chatmain',
          title: '',
          type : 'basic',
          link : '/main/tools/openai/chatmain',
        },
        {
          id   : 'openai/aitextedit',
          title: '',
          type : 'basic',
          link : '/main/tools/openai/aitextedit',
        },
        {
          id   : 'openai/imagetool',
          title: '',
          type : 'basic',
          link : '/main/tools/openai/imagetool',
        },
      ]
    },
    {
      id   : 'tools.ustax',
      title: '',
      type : 'collapsable',
            children: [
        {
          id   : 'ustax/history',
          title: '',
          type : 'basic',
          link : '/main/tools/ustax/history',
        },
        {
          id   : 'ustax/archive',
          title: '',
          type : 'basic',
          link : '/main/tools/ustax/archive',
        },
        {
          id   : 'ustax/settings',
          title: '',
          type : 'basic',
          link : '/main/tools/ustax/settings',
        },
      ]
    },  
    {
      id   : 'tools.ezsign',
      title: '',
      type : 'basic',
    },  
    {
      id   : 'tools.incomeexpense',
      title: '',
      type : 'basic',
    },
    // {
    //   id   : 'tools.home',
    //   title: '',
    //   type : 'basic',
    // },
    {
      id   : 'tools.elnet',
      title: '',
      type : 'collapsable',
            children: [
        {
          id   : 'elnet/companyinfo',
          title: '',
          type : 'basic',
          link : '/main/tools/elnet/companyinfo',
        },
        {
          id   : 'elnet/userinfo',
          title: '',
          type : 'basic',
          link : '/main/tools/elnet/userinfo',
        },
        {
          id   : 'elnet/deviceinfo',
          title: '',
          type : 'basic',
          link : '/main/tools/elnet/deviceinfo',
        },
        {
          id   : 'elnet/elnetdashboard',
          title: '',
          type : 'basic',
          link : '/main/tools/elnet/elnetdashboard',
        },
        {
          id   : 'elnet/mydevice',
          title: '',
          type : 'basic',
          link : '/main/tools/elnet/mydevice',
        },
      ]
    },
    {
      id   : 'tools.elsms',
      title: '',
      type : 'collapsable',
            children: [
        {
          id   : 'elsms/companyinfo',
          title: '',
          type : 'basic',
          link : '/main/tools/sms/companyinfo',
        },
        {
          id   : 'elsms/userinfo',
          title: '',
          type : 'basic',
          link : '/main/tools/sms/userinfo',
        },
        {
          id   : 'elsms/smsinfo',
          title: '',
          type : 'basic',
          link : '/main/tools/sms/smsinfo',
        },
        {
          id   : 'elsms/elsmsdashboard',
          title: '',
          type : 'basic',
          link : '/main/tools/sms/smsdashboard',
        },
        {
          id   : 'elsms/mysms',
          title: '',
          type : 'basic',
          link : '/main/tools/sms/mysms',
        },
      ]
    },     
    {
      id   : 'side.help',
      title: 'Help',
      type : 'basic',
      icon : 'heroicons_outline:question-mark-circle',
      link : 'http://everleagues.freshdesk.com',
      externalLink: true,
      target: '_blank'
    },  
  ]

  @ViewChild('drawer') drawer: MatDrawer;

  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean = true;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    public resizeService: ResizeService,
    private router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private messagingService: MessagingService,
    private activatedRoute: ActivatedRoute,
    private userDataSelector: UserDataSelector,
    private enterpriseSelector: EnterpriseSelector,
    private appStateSelector: AppStateSelector,
    private pubSub: PubSub,
    private systemHub: SystemHub,
    private translateService: TranslateService,
    private orgHub: OrgHub,
    private uiStateSelector: UIStateSelector,
    private uiService: UIService,
    private appState: AppState,
    private orgUserComponent: OrgUserComponent,
    private friendsComponent: FriendsComponent,
    private authService: AuthService,
    private authGuard: AuthGuard,
    private flowManager: FlowManager,
    private userService: UserService,
    private feedService: FeedService,
    private pluginManager: PluginManager,
    private idleService: IdleService,
    private licenseSelector: LicenseStateSelector,
    private notifyService: NotificationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private injector: Injector
  ) {
    this.onResize();
    this.language = this.userDataSelector.language;
    this.translateService.use(this.language);
    this.setLanguageDisplay(this.language);
    this._subs = new SubSink();
    this.toolMenus = [];

    this.subscribeRouting();
    this.setupPluginManager();
  }

  //#region Page Events

      // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
     get currentYear(): number
     {
         return new Date().getFullYear();
     }
 
     // -----------------------------------------------------------------------------------------------------
     // @ Lifecycle hooks
     // -----------------------------------------------------------------------------------------------------
 
     /**
      * On init
      */

  async ngOnInit() {

    // // Subscribe to media changes
    // this._fuseMediaWatcherService.onMediaChange$
    //     .pipe(takeUntil(this._unsubscribeAll))
    //     .subscribe(({matchingAliases}) => {

    //     // Check if the screen is small
    //     this.isScreenSmall = !matchingAliases.includes('md');
    // });

    // // Subscribe to media changes
    // this._fuseMediaWatcherService.onMediaChange$
    //     .pipe(takeUntil(this._unsubscribeAll))
    //     .subscribe(({matchingAliases}) => {

    //         // Set the drawerMode and drawerOpened if the given breakpoint is active
    //         if (matchingAliases.includes('md'))
    //         {
    //             this.drawerMode = 'side';
    //             this.drawerOpened = true;
    //         }
    //         else
    //         {
    //             this.drawerMode = 'over';
    //             this.drawerOpened = false;
    //         }
    //     });
    // version
    //if (!environment.production) this.version += "+" + this.build;
    this.version += " build " + this.build;
    this.appStateSelector.initDeviceId();

    this.initBroadcastChannel();

    this.subscribeLanguage();
    this.subscribePluginLaunchRedirect();
    this.subscribeExternalToolMenu();
    this.subscribeEnterprise();
    this.checkQueryParam();
    this.subscribeLicense();
    this.subscribeMessaging();
    this.subscscribeActivity();
    this.subscribeUserProfile();
    this.subscribeFirebaseMessaging();
    this._subscribeCloudStorageOrgSettings();
    await this.subscribeOrgFeed();

    this.onForeground();

    await this.appState.ready()
      .then(() => {
        this.subscribeOrgHubConnectionStatus();
        this.idleService.initIdle();
      });

    await this.pubSub.next(PubSub.ON_APP_INIT); 

    this.initializeSidebar()
  }

  isActive(route: string): boolean {
    return this.router.url.includes(route);
  }

  toolsMenu() {
    const mainNavigationComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');
    
    if (mainNavigationComponent) {
    const openai = this._fuseNavigationService.getItem('tools.openai', this.navigation);
    const ustax = this._fuseNavigationService.getItem('tools.ustax', this.navigation);
    const ezsign = this._fuseNavigationService.getItem('tools.ezsign', this.navigation);
    const incomeexpense = this._fuseNavigationService.getItem('tools.incomeexpense', this.navigation);
    // const home = this._fuseNavigationService.getItem('tools.home', this.navigation);
    const elnet = this._fuseNavigationService.getItem('tools.elnet', this.navigation);
    const elsms = this._fuseNavigationService.getItem('tools.elsms', this.navigation);
    
    openai['hidden'] = this.hide.bind(this, true)
    ustax['hidden'] = this.hide.bind(this, true)
    ezsign['hidden'] = this.hide.bind(this, true)
    incomeexpense['hidden'] = this.hide.bind(this, true)
    // home['hidden'] = this.hide.bind(this, true)
    elnet['hidden'] = this.hide.bind(this, true)
    elsms['hidden'] = this.hide.bind(this, true)

    for (var menu of this.toolMenus) {
        const menuItem = this._fuseNavigationService.getItem('tools.' + menu.path, this.navigation);
        if (menuItem) {
        menuItem['hidden'] = this.show.bind(this, true)
        // check for drawer status
        if (this.drawerOpened) {
          menuItem['title'] = this.translateService.instant(menu.localization)
        } else {
          menuItem['title'] = ''
        }
        menuItem['icon'] = menu.icon
        if (menu.subMenus && menu.subMenus.length > 0) {
          let submenus = []
          for (var subMenu of menu.subMenus) {
            const newMenuItem: FuseNavigationItem = {
              id   : subMenu.path,
              title: this.translateService.instant(subMenu.localization),
              type : 'basic',
              icon : subMenu.icon,
              link : '/main/tools/' + subMenu.path
            };
            submenus.push(newMenuItem)
          }
          menuItem['children'] = submenus
        } else {
          menuItem['link'] = '/main/tools/' + menu.path
          }
        }
        }

    mainNavigationComponent.refresh()  
    
    }
  }

  updateMenu() {

    const mainNavigationComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');
    
    if (mainNavigationComponent) {
      if (!this.isUserSubLimiExceed && !this.isSubPaymentFailed && !this.isUserSuspended && this.isOrgActive) {

      const activity = this._fuseNavigationService.getItem('side.activity', this.navigation);
      const teams = this._fuseNavigationService.getItem('side.teams', this.navigation);
      const feed = this._fuseNavigationService.getItem('side.feed', this.navigation);
      const operation = this._fuseNavigationService.getItem('side.operation', this.navigation);
      const settings = this._fuseNavigationService.getItem('side.settings', this.navigation);
      const friends = this._fuseNavigationService.getItem('side.friends', this.navigation);
      const openmeet = this._fuseNavigationService.getItem('side.openmeet', this.navigation);
      const shared_drive = this._fuseNavigationService.getItem('side.files-drive', this.navigation);
      const organization = this._fuseNavigationService.getItem('side.organization', this.navigation);
      const subscriptions = this._fuseNavigationService.getItem('side.subscriptions', this.navigation);
      // const tools = this._fuseNavigationService.getItem('side.tools', this.navigation);

      // if business
      if (this.isBusinessOrg == true) {
        activity['hidden'] = this.show.bind(this, true)
        this.updateNotif('activity', this._currentOrgNotificationCount)
        teams['hidden'] = this.show.bind(this, true)
        feed['hidden'] = this.hide.bind(this, true)
        this.updateNotif('teams', this._currentChUnreadCount)
        friends['hidden'] = this.hide.bind(this, true)
        if (this.isOrgAdmin == true) {
          operation['hidden'] = this.show.bind(this, true)
          openmeet['hidden'] = this.show.bind(this, true)
          settings['hidden'] = this.show.bind(this, true)
          shared_drive['hidden'] = this.hide.bind(this, true)
          organization['hidden'] = this.hide.bind(this, true)
          subscriptions['hidden'] = this.hide.bind(this, true)
        } else if (this.isOrgCoworker == true) {
          operation['hidden'] = this.hide.bind(this, true)
          openmeet['hidden'] = this.show.bind(this, true)
          settings['hidden'] = this.hide.bind(this, true)
          shared_drive['hidden'] = this.hide.bind(this, true)
          organization['hidden'] = this.hide.bind(this, true)
          subscriptions['hidden'] = this.hide.bind(this, true)
        } else if (this.isOrgOwner == true) {
          operation['hidden'] = this.show.bind(this, true)
          openmeet['hidden'] = this.show.bind(this, true)
          settings['hidden'] = this.show.bind(this, true)
          shared_drive['hidden'] = this.show.bind(this, true)
          organization['hidden'] = this.show.bind(this, true)
          subscriptions['hidden'] = this.show.bind(this, true)
        } else {
          operation['hidden'] = this.hide.bind(this, true)
          openmeet['hidden'] = this.hide.bind(this, true)
          settings['hidden'] = this.hide.bind(this, true)
          shared_drive['hidden'] = this.hide.bind(this, true)
          organization['hidden'] = this.hide.bind(this, true)
          subscriptions['hidden'] = this.hide.bind(this, true)
        }
      } else { //if personal space
        activity['hidden'] = this.show.bind(this, true)
        this.updateNotif('activity', this._currentOrgNotificationCount)
        teams['hidden'] = this.hide.bind(this, true)
        feed['hidden'] = this.show.bind(this, true)
        this.updateNotif('feed', this._currentChUnreadCount)
        operation['hidden'] = this.hide.bind(this, true)
        settings['hidden'] = this.hide.bind(this, true)
        friends['hidden'] = this.show.bind(this, true)
        openmeet['hidden'] = this.hide.bind(this, true)
        shared_drive['hidden'] = this.hide.bind(this, true)
        organization['hidden'] = this.hide.bind(this, true)
        subscriptions['hidden'] = this.hide.bind(this, true)
        // tools['hidden'] = this.hide.bind(this, true)
      }

      }
      
      // if ((this.isUserSubLimiExceed || this.isSubPaymentFailed) && this.isOrgActive) {
      //   this.navigation = 
      //   [{
      //     //el-permission permission-type="ORG_SETTINGS_UPDATE"
      //     id   : 'side.settings',
      //     title: this.translateService.instant('MAIN.SETTINGS'),
      //     type : 'collapsable',
      //     icon : 'heroicons_outline:cog',
      //     hidden: this.show.bind(this, true),
      //     children: [
      //       {
      //         //el-permission permission-type="ORG_SETTINGS_UPDATE"
      //         id   : 'side.subscriptions',
      //         title: this.translateService.instant('MAIN.SUBSCRIPTIONS'),
      //         type : 'basic',
      //         link : '/main/org/subscription',
      //       },
      //     ]
      //   }]
      // }

      if (!this.isOrgActive) {
        this.navigation = []
      }
      mainNavigationComponent.refresh();
    }
  }

  initializeSidebar() {
    this.updateNotif('chat', this._currentMsgUnreadCount)
    this.updateMenu()
    this.toolsMenu()
  }

  updateNotif(id,count) {
    const mainNavigationComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');
    if (mainNavigationComponent) {
    const menuItem = this._fuseNavigationService.getItem('side.' + id, this.navigation);

    // If the count is available and is bigger than zero...
    if (count > 0)
    {
        // Add the count as a badge
        menuItem['badge'] = {
            title: count.toString(),
            classes: 'px-2 bg-warn-500 text-white rounded-full'
        };
    } else {
        menuItem['badge'] = {
        title: ""
      };
    }
    // Refresh the navigation
    mainNavigationComponent.refresh();

    }
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
        
    if (this._subs) this._subs.unsubscribe();

    this.uiService.disableSystemErrorMessage();
    this.pubSub.next(PubSub.BEFORE_APP_DESTROY);
    this.channel.close();
  }

  toggleNavigation(name: string): void
  {
      // Get the navigation
      const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

      if (navigation)
      {
          // Toggle the opened status
          // navigation.toggle();
          this.drawerOpened = !this.drawerOpened
        this.checkToggle()
        this.toolsMenu()
        navigation.refresh()  
      }
  }

  checkToggle()
  {
      // Get the navigation
      const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

      if (navigation)
      {
          var menus = [
            'side.dashboard','side.activity','side.chat','side.teams','side.feed','side.directory','side.connections','side.labels',
            'side.operation','side.client-assignment','side.private-circle',
            'side.settings','side.organization','side.organization-units','side.subscriptions','side.users',
            'side.friends','side.openmeet','side.storage','side.files','side.files-shared','side.help',
            // 'tools.ustax','ustax/history','ustax/archive','ustax/settings',
            // 'tools.ezsign','tools.incomeexpense','tools.home','tools.elnet'
          ]
          if (this.drawerOpened == false) {
            
            for (var index in menus) {
               const menu = this._fuseNavigationService.getItem(menus[index], this.navigation)
               menu['title'] = ''
            }
          } else {
            for (var index in menus) {
              const menu = this._fuseNavigationService.getItem(menus[index], this.navigation)
              console.log('default2')
              const defaultmenu = this._fuseNavigationService.getItem(menus[index], this.defaultnavigation)
              console.log(defaultmenu)
              console.log('default1')
              menu['title'] = defaultmenu.title
           }
          }
        navigation.refresh()  
      }
  }

  checkQueryParam() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      let status: string = params.status;
      if (status === "gettingStarted") {
        // show getting started dialog (create account)
        this.initGettingStartedNewAccount();
      } else if (status === "newOrg") {
        // triggered when DialogNewOrgComponent is successful
        this.initGettingStartedNewOrg();
      }
    });
  }

  ngAfterViewInit() {
    this.pubSub.next(PubSub.AFTER_APP_INIT);
    // to be remove after esign intergrated
    // this.pubSub.next<string[]>(PubSub.MENU_ENABLED, [
    //   "tax_menu",
    //   "tax_case_menu",
    //   "tax_settings_menu",
    //   "tax_my_tax_case",
    //   "iet_menu",
    // ]);
  }

  onForeground() {
    var visibilityChange;
    if (typeof document.hidden !== "undefined") {
      visibilityChange = "visibilitychange";
    } else if (typeof document["mozHidden"] !== "undefined") {
      visibilityChange = "mozvisibilitychange";
    } else if (typeof document["msHidden"] !== "undefined") {
      visibilityChange = "msvisibilitychange";
    } else if (typeof document["webkitHidden"] !== "undefined") {
      visibilityChange = "webkitvisibilitychange";
    }
    document.addEventListener(
      visibilityChange,
      (res) => {
        this.setBackgrond.emit(document.hidden);
        //this._isBackground = document.hidden;
      },
      false
    );
  }

  setupPluginManager() {
    //this.pluginManager.buildRoutes();
    this.pluginManager.onMenuUpdated$.subscribe(this.addOrUpdateMenuItem.bind(this));
  }

  private addOrUpdateMenuItem(items: MenuItem[]) {
    console.log("[MainPage][check tools] addOrUpdateMenuItem: %o", items);
    if (!this.toolMenus) {
      this.toolMenus = [];
    }
    if (!items || items.length == 0) return;

    items.forEach(item => {
      if (!item.isEnabled) {
        this.disableMenuItem(item.code);
      } else {
        var idx = this.toolMenus.findIndex(i => i.code == item.code);
        if (idx == -1) {
          this.toolMenus = [...this.toolMenus, item];
        } else {

          var existing = this.toolMenus[idx];

          existing.isEnabled = item.isEnabled;
          existing.localization = item.localization;
          existing.showBadge = item.showBadge;
          existing.path = item.path;
          existing.subMenus = [...item.subMenus.filter(s => s.isEnabled)];
        }
      }
    });

    //console.log("[MainPage] %o", this.toolMenus);
  }

  private disableMenuItem(appId: string) {
    this.toolMenus = [...this.toolMenus.filter((i) => i.code != appId)];
  }

  //#endregion

  //#region getter
  get displayName(): string {
    if (!this._userProfile) return "";
    return `${this._userProfile.firstName} ${this._userProfile.lastName}`;
  }

  get imageUrl(): string {
    if (!this._userProfile) return "";

    return this._userProfile.imageUrl;
  }

  get isUserSuspended(): boolean {
    if (!this._currentOrgUser) return true;
    return this._currentOrgUser.status === UserStatus.Suspended;
  }

  get isUserSubLimiExceed(): boolean {
    if (!this._currentOrgUser) return true;
    return this._currentOrgUser.status === UserStatus.SubscriptionLimitExceed;
  }

  // get isOrgSuspended(): boolean {
  //   if (!this._currentOrg) {
  //     return true;
  //   }
  //   return this._currentOrg.status !== EntityStatus.Active;
  // }
  // get isUserExpired(): boolean {
  //   if (!this._currentOrgUser) return true;
  //   return this._currentOrgUser.status === UserStatus.ExpiredLicense;
  // }

  get isBusinessOrg(): boolean {
    if (!this._currentOrg) return false;

    return this._currentOrg.type === OrgType.Business;
  }

  get isOrgEmployee(): boolean {
    if (!this._currentOrgUser) return false;
    let role = RoleEntity[this._currentOrgUser.role];
    if (role === RoleEntity.ADMIN || role === RoleEntity.COWORKER || role === RoleEntity.OWNER) return true;
    return false;
  }

  get isOrgCoworker(): boolean {
    if (!this._currentOrgUser) return false;
    let role = RoleEntity[this._currentOrgUser.role];
    if (role === RoleEntity.COWORKER) return true;
    return false;
  }

  get isOrgOwner(): boolean {
    if (!this._currentOrgUser) return false;
    let role = RoleEntity[this._currentOrgUser.role];
    if (role === RoleEntity.OWNER) return true;
    return false;
  }

  get isOrgAdmin(): boolean {
    if (!this._currentOrgUser) return false;
    let role = RoleEntity[this._currentOrgUser.role];
    if (role === RoleEntity.ADMIN) return true;
    return false;
  }

  get isLicensePending(): boolean {
    if (!this._currentOrgSub) return false;

    return (this._currentOrgSub.status === SubStatus.Pending);
  }

  get isLicenseInactive(): boolean {
    if (!this._currentOrgSub) return true;

    return (this._currentOrgSub.status === SubStatus.Inactive || this._currentOrgSub.status === SubStatus.Expired ||
      this._currentOrgSub.status === SubStatus.Canceled);
  }

  get isLicensePaymentFailed(): boolean {
    return (this._currentOrgSub.status === SubStatus.PaymentFailed);
  }

  get isLicenseActive(): boolean {
    if (!this._currentOrgSub) return false;

    return (this._currentOrgSub.status === SubStatus.Active);
  }

  get hasAlert(): boolean {
    return this.orgAlerts && this.orgAlerts.length > 0;
  }

  get isOrgActive() {
    if (this._currentOrg) {
      return (this._currentOrg.status === EntityOrgStatus.Active);
    } else return true;
  }

  get isSubPaymentFailed(): boolean {
    if (!this._currentOrgSub) return false;

    return (this._currentOrgSub.status === SubStatus.PaymentFailed);
  }

  get isOrgLoading() {
    if (this._currentOrg) {
      return false;
    } else return true;
  }

  get isOrgSuspended() {
    if (!this._currentOrg) {
      return true;
    }
    return (this._currentOrg.status === EntityOrgStatus.Suspended);
  }
  //#endregion

  //#region Private

  @HostListener("window:resize", ["$event"])
  onResize() {
    if (window.innerWidth <= 1280) {
      this.sideNavOpened = false;
      this._sidenavMode = "over";
    } else {
      this.sideNavOpened = true;
      this._sidenavMode = "side";
    }
  }

  private subscribeExternalToolMenu() {
    console.info("[subscribeExternalToolMenu]");
    // this._subs.sink = this.pubSub.subscribe<string[]>(
    //   PubSub.MENU_ENABLED,
    //   (val) => {
    //     console.info("[subscribeExternalToolMenu] - received: %o", val);
    //     if (!val) return;
    //     if (!this._toolMenu) this._toolMenu = [];
    //     this._toolMenu = _.uniq([...this._toolMenu, ...val]);
    //     console.info("[subscribeExternalToolMenu] - final: %o", this._toolMenu);
    //   },
    //   (err) => console.error(err)
    // );

    this._subs.sink = this.orgHub.onToolsUpdate.subscribe((dto) => {
      //console.log("onToolsUpdated %o", dto);
      var org = this.enterpriseSelector.getCurrentOrg();
      if (org && org.id == dto.orgId) {        
        //console.log("same org %o", org);
        //inform plugin to show/hide tools
        this.passOrgDetailsToTools(org, PubSub.ON_TOOLS_UPDATED);

        //push to plugin to handle if user is currently in the plugin page
        try {
          if (!dto.isEnabled) {
            this.disableMenuItem(dto.appId);
          }
        } catch (exp) {
          console.error(exp);
        }
      }
    });
  }

  private subscribeRouting() {
    // set page title and detect url
    this._subs.sink = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((e: NavigationEnd) => {
          // set redirect if dashboard is detected on first time loading the app
          if (this.isFirstLoad) {
            if (e.url.includes("/dashboard") || e.url === "/") {
              this.isRedirectToLaunchPage = true;
            }
            this.isFirstLoad = false;
          }
          // set thin margin for page
          if (
            e.url.includes("/main/chat") ||
            e.url.includes("/main/directory/connections")
          ) {
            this.thinMargin = true;
          } else {
            this.thinMargin = false;
          }
          return this.activatedRoute;
        }),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        this.title = event["pageTitle"];
      });
  }

  private subscribeEnterprise() {
    // on org updated
    this._currentOrg = this.enterpriseSelector.getCurrentOrg();
    this._currentOrgUser = this.enterpriseSelector.getCurrentOrgUser();
    this.updateMenu();
    this.toolsMenu();

    this._subs.sink = this.selectedOrg$.pipe(
      distinctUntilChanged((prev, curr) => {
        if (curr == null) return true;
        if (prev == null && curr != null) return false;
        return prev.id === curr.id && prev.name === curr.name && prev.status === curr.status;
      })
    ).subscribe(async (res) => {
      if (!res) {
        this._currentOrg = null;
        this.orgId = null;
        this.orgType = null;
      } else {
        //#region detect if current org status changes from pending to active. Used to show new org dialog without using queryparam
        if (this._currentOrg) {
          //  check if its same org
          if (this._currentOrg.id == res.id) {
            // check for newly created org
            if (this._currentOrg.status == EntityOrgStatus.Pending) {
              if (res.status == EntityOrgStatus.Active) {
                this.openWelcomeNewOrgDialog();
              }
            }
          } else {
            this.orgId = res.id;
            this.orgType = res.type;
            console.log("[MainPage] Org changed: ,%o", res);
          }
        } else {
          this.orgId = res.id;
          this.orgType = res.type;
          console.log("[MainPage] Prev not detected: ,%o", res);
        }
        //#endregion

        //save current orgId to server
        this.userService.updateCurrentOrgId(res.id);

        this._currentOrg = { ...res };
        this.orgId = res.id;
        this.orgType = res.type;
        this.updateMenu()
        this.toolsMenu()
        this.toolMenus = [];//only reset when org switch, not when tools update
        this.passOrgDetailsToTools(res, PubSub.ON_ORG_SWITCHED);

        var isRedirectOnSwitch = this.uiStateSelector.isRedirectOnSwitchOrg;
        if (!isRedirectOnSwitch) {
          // reset redirect back to true
          this.setRedirectOnSwitchOrg.emit(true);
        } else {
          // route to dashboard if no url
          //this.navigateToDashboard();
        }
        // this.setUIReady.emit(null);
      }
    });
    this._channels = this.enterpriseSelector.getCurrentChannels();
    this._unreads = this.enterpriseSelector.getChUnreads();
    this.onPersonalOrgUnreadsChanged();
    //subscribe
    this._subs.sink = this.unreads$.subscribe(res => {
      this._unreads = res;
      this.onPersonalOrgUnreadsChanged();
    });

    this._subs.sink = this.channelTotalUnreads$.subscribe((res) => {
      this._currentChUnreadCount = res;
      this.updateNotif('teams', this._currentChUnreadCount)
      this.updateNotif('feed', this._currentChUnreadCount)
    });

    this._subs.sink = this.storageTotalUnreads$.subscribe((res) => {
      this._currentStorageUnreadCount = res;
      this.updateNotif('storage', this._currentStorageUnreadCount)
    });

    this._subs.sink = this.pubSub.subscribe(
      PubSub.ON_CURRENT_ORG_DELETED,
      async () => {
        this.uiService.openSnackBar(
          this.translateService.instant("DASHBOARD.UNAUTHORIZED.DELETED_DESC_1") +
          this._currentOrg?.name +
          this.translateService.instant("DASHBOARD.UNAUTHORIZED.DELETED_DESC_2"),
          "OK"
        );
        this.flowManager.switchOrg(null);
      }, null, null, true
    );

    this._subs.sink = this.pubSub.subscribe(
      PubSub.ON_USER_SUSPENDED,
      async () => {
        const dialog = this.uiService.openCountdownDialog(
          this.translateService.instant("DASHBOARD.SUSPENDED.TITLE"),
          this.translateService.instant("DASHBOARD.SUSPENDED.DESC_1"),
          this.translateService.instant("DASHBOARD.SUSPENDED.PS_REDIRECT_CD"),
          this.translateService.instant("DASHBOARD.SUSPENDED.REDIRECT_NOW"),
          10,
          () => {
            // redirect to PS org if possible, if not, switch to next available org
            var personalOrg = this.enterpriseSelector.getPersonalOrg();
            (!!personalOrg) ? this.flowManager.switchOrg(personalOrg.id) : this.flowManager.switchOrg(null);
          }
        );
      }, null, null, true
    );


    this._subs.sink = this.currentOrgUser$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          if (curr == null) return true;
          if (prev == null && curr != null) return false;
          return JSON.stringify(prev) === JSON.stringify(curr);
        }
        )
      ).subscribe((res) => {
        if (!res) return;
        this._currentOrgUser = { ...res };
        this.updateMenu();
        this.toolsMenu();
        console.log("[MainPage] currentOrgUser$: %o", this._currentOrgUser);
        if (this.isOrgSuspended) {
          this.uiService.openSnackBar(
            this.translateService.instant("DASHBOARD.UNAUTHORIZED.DESC_1") +
            this._currentOrg?.name +
            this.translateService.instant("DASHBOARD.UNAUTHORIZED.DESC_2"),
            "OK"
          );

          var personalOrg = this.enterpriseSelector.getPersonalOrg();
          if (personalOrg) {
            this.flowManager.switchOrg(personalOrg.id).catch(() => {
              this.uiService.openSnackBar("Failed to prepare org", "OK");
            });
            // save login history
            let history: LoginHistoryState = {
              userId: this.userDataSelector.userId,
              orgId: personalOrg.id
            };
            this.saveLoginHistory.emit(history);
          } else {
            var plugin = this.pluginManager.getLaunchToRedirectPlugin();
            if (plugin && plugin.launchToRedirectPath) {
              console.log("[PluginManager] MainPage - currentOrgUser$: %s", plugin.launchToRedirectPath);
              this.router.navigate(["main", "tools", ...plugin.launchToRedirectPath.split("/")]);
            } else {
              console.log("[PluginManager] MainPage - currentOrgUser$: dashboard");
              this.router.navigate(["/main/dashboard"]);
            }
            //this.navigateToDashboard();
          }
        }
      });

    this._subs.sink = this.onChannelsUpdated$.subscribe((res) => {
      if (!res) return;
      this._channels = [...res];
      this.onPersonalOrgUnreadsChanged();
    });
  }

  private subscribeMessaging() {
    this._subs.sink = this.currentMsgUnreadCount$.subscribe((res) => {
      //console.log("[currentMsgUnreadCount] %s", res);
      this._currentMsgUnreadCount = res;
      this.updateNotif('chat', this._currentMsgUnreadCount)
    });
  }

  private subscscribeActivity() {
    this._subs.sink = this.unreadActivityCount$.subscribe((res) => {
      this._currentOrgNotificationCount = res;
    })
  }

  private async passOrgDetailsToTools(org: OrgState, sub: string) {
    // this.toolMenus = [];
    if (this.authGuard != null) {
      this.authGuard.clearToolMenu();
    }

    var currentOrgUser = this.enterpriseSelector.getCurrentOrgUser();

    this.pubSub.next<{
      id: string;
      name: string;
      isPersonal: boolean;
      industryId: string;
      userId: string;
      role: string;
      extTools: Dictionary<boolean>
    }>(sub, {
      id: org.id,
      name: org.name,
      isPersonal: org.type === OrgType.Personal,
      industryId: org.industryId,
      userId: currentOrgUser.userId,
      role: currentOrgUser.role,
      extTools: org.extTools
    });
  } 

  private navigateToDashboard() {
    if (!this._currentOrg) return; //don't do anything

    if (!this._currentOrgUser) return;

    //console.log("[MainPage] - status: %s", status);
    this.router.navigate(["/main/dashboard"], {});
  }

  private subscribeUserProfile() {
    this._userProfile = this.userDataSelector.userProfile;
    this.onUserProfileChanged();
    this._subs.sink = this.userProfile$.subscribe((res) => {
      //console.log("[MainPage] trigger profile changed, %o", res);
      if (!res) return;
      this._userProfile = { ...res };
      this.onUserProfileChanged();
    });
  }

  private subscribeLanguage() {
    this._subs.sink = this.language$.subscribe((res) => {
      if (!res) return;
      this.language = res;
      this.setLanguageDisplay(res);
      this.translateService.use(res);
    });
  }

  //handle when in foreground
  private subscribeFirebaseMessaging() {
    this._subs.sink = this.messagingService.currentMessage.subscribe(
      (message) => {
        if (message) {
          var notification = NotificationData.parseFromFirebase(message);

          // comment out showing toast notification when app in foreground
          // this.notifyService.notificationRedirection(
          //   notification.eventName,
          //   notification.body,
          //   notification.title,
          //   notification.orgId,
          //   notification.data
          // );
          // if (message.data.event_type === EventType.OPEN_POST) {
          //   this.showPostNotificationSnackBar(
          //     message.notification.body,
          //     message.notification.title,
          //     message.data.orgUnitId
          //       ? message.data.orgUnitId
          //       : message.data.orgId,
          //     message.data.channelId,
          //     message.data.postId
          //   );
          // } else {
          //   this.notifyService.showNotificationSnackBar(
          //     message.data.eventName,
          //     message.notification.body,
          //     message.notification.title,
          //     message.data.orgUnitId ? message.data.orgUnitId : message.data.orgId,
          //     message.data
          //   );
          // }
        }
      }
    );


    // sync with service worker
    this.messagingService.pingServiceWorker();

    // fcm notifications
    const fcmToken = this.userDataSelector.getFcmToken();
    if (!fcmToken) {
      this.messagingService.requestPermission();
    }
    this.messagingService.receiveMessage();
  }


  public loadingOrgFeed = false;
  private subscribeOrgFeed() {
    this.loadingOrgFeed = true;
    this._subs.sink = this.systemHub.onHubConnected$.subscribe(async (res) => {
      let latestFeeds = await this.systemHub.getFeedCount();
      this.loadingOrgFeed = false;
      this._buildOrgAlert(latestFeeds);
    });

    this._subs.sink = this.pubSub.subscribe(
      PubSub.ON_NEW_FEED_RECEIVED,
      this.onFeedReceived.bind(this),
      (err) => console.error(err),
      null,
      true
    );

    //clear locally (if there's no internet connection)
    // this._subs.sink = this.feedService.onFeedClear.subscribe((res) => {
    //   this.clearFeeds(res);
    // }, (error) => console.error(error));

    this._subs.sink = this.systemHub.onFeedStatusUpdate.subscribe((res) => {
      if (res) {
        this.onFeedRead(res);
      }
    })

    this._subs.sink = this.systemHub.onAllOrgFdsRead.subscribe((res) => {
      if (res) {
        this.onOrgFeedRead(res);
      }
    })

    // this._subs.sink = interval(20 * 1000)
    //   .pipe(startWith(0))
    //   .subscribe(async () => {
    //     //console.log("subscribeOrgFeedInterval - count: %s", this.notificationGroup.length);
    //     this.loadingOrgFeed = this.notificationGroup.length == 0;
    //     try {
    //       if (this.authStateSelector.isAuthenticated) {
    //         let latestFeeds = await this.systemHub.getLatestFeed().toPromise();
    //         this.loadingOrgFeed = false;
    //         //console.log("subscribeOrgFeedInterval - res: %o", latestFeeds);
    //         this.buildNotificationGroup(latestFeeds);
    //       }
    //     } catch (err) {
    //       console.error(err);
    //     }
    //   });
  }

  private subscribeLicense() {
    this._subs.sink = this.currentOrgSubscription$.pipe(
      distinctUntilChanged((prev, curr) => {
        if (curr == null) return true;
        if (prev == null && curr != null) return false;
        return prev.id === curr.id && prev.status === curr.status && prev.type === curr.type;
      })
    ).subscribe(async (res) => {
      // console.log("[MainPage] License update: ,%o", res);
      // console.log(this._currentOrgSub)
      // if (this._currentOrgSub) {
      //   //  check if its same org
      //   if (this._currentOrgSub.orgId == res.orgId) {
      //     // check for newly created org
      //     if (this._currentOrgSub.status == SubStatus.Pending || this._currentOrgSub.status == SubStatus.PaymentFailed) {
      //       if (res.status == SubStatus.Active) {
      //         this.openWelcomeNewOrgDialog();
      //       }
      //     }
      //   } else {
      //     console.log("[MainPage] Org changed: ,%o", res);
      //   }
      // } else {
      //   console.log("[MainPage] Prev not detected: ,%o", res);
      // }

      this._currentOrgSub = res;

      // if (this._licenseSub) this._licenseSub.unsubscribe();

      // // if pending/failed status, subscribe to sub changes
      // if (this._currentOrgSub.status == SubStatus.Pending || this._currentOrgSub.status == SubStatus.PaymentFailed) {
      //   this._licenseSub = this.currentOrgSubscription$.pipe(
      //     startWith(res),
      //     distinctUntilChanged((prev, curr) => {
      //       if (curr == null) return true;
      //       if (prev == null && curr != null) return false;
      //       return prev.id === curr.id
      //     })
      //   ).subscribe(async (res) => {
      //     if (res.status == SubStatus.Active) {
      //       console.log("[MainPage] License status changed to active");
      //       console.log(res)
      //       this.initGettingStartedNewOrg();
      //     }
      //   });
      // }
    });
  }

  private subscribePluginLaunchRedirect() {
    this.pluginManager.onLaunchRedirect$.subscribe(path => {
      console.log("[MainPage] onLaunchRedirect: %s", path);
      console.log("[PluginManager] MainPage - onLaunchRedirect: %o", { path, isRedirectToLaunchPage: this.isRedirectToLaunchPage });

      //isRedirecting is a temporary workaround to prevent notification redirection from chat pg back to dashboard pg
      if (this.notifyService.isRedirecting) {
        this.notifyService.isRedirecting = false;
      } else {
        // redirect to tools or dashboard page if dashboard is detected
        if (this.isRedirectToLaunchPage) {
          if (path) {
            console.log("[PluginManager] MainPage - navigate to: %o", ["main", "tools", ...path.split("/").filter(i => i)]);
            this.router.navigate(["main", "tools", ...path.split("/")])
              .then(val => console.log("[PluginManager] MainPage: val: %o", val))
              .catch(err => console.log(err));
            //this.router.navigate(["main", "chat", "all"]);
          } else {
            console.log("[PluginManager] MainPage - navigate to: dashboard");
            this.router.navigate(["/main/dashboard"]);
          }
        }
      }

    });
  }

  private _subscribeCloudStorageOrgSettings(){
    let prevSettings = this._currentOrg?.myDriveEnabledGroup;
    this._subs.sink = this.selectedOrg$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          if (curr == null) return true;
          if (prev == null && curr != null) return false;
          return (
            JSON.stringify(prev.myDriveEnabledGroup) ===
            JSON.stringify(curr.myDriveEnabledGroup)
          );
        })
      )
      .subscribe((res) => {
        if (res) {
          if (
            prevSettings != null &&
            JSON.stringify(prevSettings) !==
              JSON.stringify(res.myDriveEnabledGroup)
          ) {
            this.reevaluateMyDrive = true;
          }
          prevSettings = res.myDriveEnabledGroup;
        }
      });
  }

  get isIndicatorGlowing(): boolean {
    return this._isIndicatorGlowing;
  }

  private showGlowEffect() {
    this._isIndicatorGlowing = true;
    setTimeout(() => {
      this._isIndicatorGlowing = false;

    }, 10000);
  }

  private passFeedsToExt(feeds: Feed[]) {
    const extFeeds = feeds.filter(f =>
      f.event != null ? _.startsWith(f.event, PluginManager.EXT_PREFIX) : false
    );
    if (!extFeeds || extFeeds.length == 0) return;

    var data = extFeeds.map(i => {
      return { event: i.event, payload: i.data };
    });

    this.pubSub.next(PubSub.ON_EXT_FEED_RECEIVED, data);
  }

  // private clearFeeds(fdr: FeedToRemove) {
  //   //console.log("[clearLocalFeeds] main-page orgId: %s, key: %s, feedIds: %o", fdr.orgId, fdr.key, fdr.feedIds);
  //   //console.log(this.notificationGroup);

  //   let idx = this.notificationGroup.findIndex((n) =>
  //     this.enterpriseSelector.isSameOrg(n.orgId, fdr.orgId)
  //   );

  //   if (idx == -1) {
  //     idx = this.notificationGroup.findIndex((n) =>
  //       n.isSystem
  //     );
  //   }

  //   //console.log("[clearLocalFeeds] notif grp idx: " + idx);
  //   if (idx != -1) {
  //     let fdDisplays = this.notificationGroup[idx].feeds;
  //     var tag = fdr.key ? fdr.key : fdr.feedIds[0];
  //     let fdIdx = fdDisplays.findIndex((f) => f.tag == tag);

  //     //console.log("[clearLocalFeeds] tag: %s", tag);
  //     //console.log("[clearLocalFeeds] fdIdx: %s", fdIdx);
  //     this.notificationGroup[idx].feeds.splice(fdIdx, 1);

  //     //console.log(this.notificationGroup);

  //     //if there are no more feeds for this org group
  //     if (this.notificationGroup[idx].feeds.length == 0) {
  //       this.notificationGroup.splice(idx, 1);
  //     } else {
  //       this.notificationGroup[idx].count = this.getFeedCount(this.notificationGroup[idx].feeds);
  //       this.notificationGroup[idx].timestamp = this.notificationGroup[idx].feeds[0].timestamp;
  //     }
  //   }
  // }

  private onFeedReceived(val: Feed) {
    this.showGlowEffect();
    if (!val) return;

    // if (val.isSystem) {
    //   this.onSysFeedReceived(val);
    // } else {
    //   this.onOrgFeedReceived(val);
    // }

    // this.sortNotifGroup(this.notificationGroup);
    var idx = this.orgAlerts.findIndex(f => f.orgId == val.orgId);
    if (idx == -1) {
      var org = this.enterpriseSelector.getOrg(val.orgId);
      if (org) {
        var oa = new OrgAlert(val.orgId, org.name, 1);
        this.orgAlerts.unshift(oa);
      } 
    } else {
      this.orgAlerts[idx].count++;
    }

    this.showAlertNotification(val);
  }

  private onFeedRead(fdsRemove: Feed[]) {
    var grps = _.groupBy(fdsRemove, (d: Feed) => d.orgId);

    Object.keys(grps).forEach((parent) => {
        if (!parent) return;
        const removedFds: Feed[] = grps[parent];

        var idx = this.orgAlerts.findIndex(f => f.orgId == parent);
        if (idx != -1) {
          this.orgAlerts[idx].count = this.orgAlerts[idx].count - removedFds.length;
          if (this.orgAlerts[idx].count <= 0) {
            this.orgAlerts.splice(idx, 1);
          }
        }
    });
  }

  private onOrgFeedRead(orgId: string) {
    if (!orgId) return;

    var idx = this.orgAlerts.findIndex(f => f.orgId == orgId);
    if (idx != -1) {
      this.orgAlerts.splice(idx, 1);
    }
  }

  private _buildOrgAlert(alerts: Dictionary<number>) {
    this.orgAlerts = []
    Object.keys(alerts).forEach((key) => {
      var org = this.enterpriseSelector.getOrg(key);
      if (org) {
        var oa = new OrgAlert(key, org.name, alerts[key]);
        this.orgAlerts.push(oa);
      }    
  });
  }

  // private onSysFeedReceived(val: FeedState) {
  //   let idx = this.notificationGroup.findIndex((n) => n.isSystem);

  //   const title = this.getFeedTitle(val);
  //   const body = this.getFeedBody(val, 1);
  //   const tag = this.getFeedTag(val);
  //   let fd = new FeedDisplay(val.id, [val], title, body, val.timestamp, tag);

  //   if (idx != -1) { //sys alert group already exists 
  //     this.notificationGroup[idx].feeds.unshift(fd);
  //     this.notificationGroup[idx].count = this.getFeedCount(this.notificationGroup[idx].feeds);
  //     this.notificationGroup[idx].timestamp = val.timestamp;
  //   } else {
  //     this.createSysNotificationGroup([fd]);
  //   }
  // }

  // private onOrgFeedReceived(val: FeedState) {
  //   let idx = this.notificationGroup.findIndex((n) =>
  //     this.enterpriseSelector.isSameOrg(n.orgId, val.orgId)
  //   );

  //   const tag = this.getFeedTag(val);

  //   //org group already exist
  //   if (idx != -1) {
  //     let fdDisplays = this.notificationGroup[idx].feeds;
  //     let fd = fdDisplays.find((f) => f.tag == tag);

  //     //feed display already exist
  //     if (fd) {
  //       let alreadyExists = fd.feeds.findIndex(f => f.id == val.id) != -1;

  //       if (!alreadyExists) {
  //         fd.feeds.push(val);
  //         fd.body = this.getFeedBody(val, fd.feeds.length);
  //         fd.timestamp = val.timestamp;

  //         this.sortFeeds(fdDisplays);
  //       }
  //     } else {
  //       const title = this.getFeedTitle(val);
  //       const body = this.getFeedBody(val, 1);
  //       let fd = new FeedDisplay(val.id, [val], title, body, val.timestamp, tag);
  //       this.notificationGroup[idx].feeds.unshift(fd);
  //     }

  //     this.notificationGroup[idx].count = this.getFeedCount(this.notificationGroup[idx].feeds);
  //     this.notificationGroup[idx].timestamp = val.timestamp;
  //   } else {
  //     const personalOrg = this.enterpriseSelector.getPersonalOrg();

  //     if (!personalOrg) return;

  //     let isConnectedOrg = this.enterpriseSelector.isSameOrg(val.orgId, personalOrg.id);

  //     const org =
  //     isConnectedOrg == true ? personalOrg : this.enterpriseSelector.getOrg(val.orgId);

  //     if (!org) return;

  //     const title = this.getFeedTitle(val);
  //     const body = this.getFeedBody(val, 1);
  //     //const tag = this.getFeedTag(val);
  //     let fd = new FeedDisplay(val.id, [val], title, body, val.timestamp, tag);
  //     this.createOrgNotificationGroup(val.orgId, org.name, [fd]);
  //   }
  // }

  // private buildNotificationGroup(latestFeeds: any) {
  //   this.notificationGroup = [];
  //   if (!latestFeeds) return;

  //   let allFeeds = _.partition(latestFeeds.feeds, (feed: FeedState) => feed.isSystem);
  //   let sysFeeds = allFeeds[0];
  //   let orgFeeds = allFeeds[1];

  //   //build sys grp
  //   this.buildSystemGroup(sysFeeds);

  //   //build org grp
  //   this.buildOrgNotifGroup(orgFeeds);

  //   //console.log("Notification group: ,%o", this.notificationGroup);
  //   this.sortNotifGroup(this.notificationGroup);
  // }

  // private buildSystemGroup(sysFeeds) {
  //   if (!sysFeeds || sysFeeds.length == 0) return;
  //   this.sortFeeds(sysFeeds);
  //   let displayedFeeds = this.buildFeeds(sysFeeds);

  //   let idx = this.notificationGroup.findIndex((n) => n.isSystem);
  //   if (idx != -1) { //sys alert group already exists
  //     this.notificationGroup[idx].feeds = displayedFeeds;
  //     this.notificationGroup[idx].count = displayedFeeds.length;
  //     this.notificationGroup[idx].timestamp = displayedFeeds[0].timestamp;
  //   } else {
  //     this.createSysNotificationGroup(displayedFeeds);
  //   }
  // }

  // private buildOrgNotifGroup(orgFeeds) {
  //   if (!orgFeeds || orgFeeds.length == 0) return;

  //   var orgs = this.enterpriseSelector.getOrgs();

  //   if (!orgs) return;

  //   let dict = this.groupOrgFeeds(orgFeeds);

  //   for (var key in dict) {
  //     let feeds = dict[key];

  //     this.sortFeeds(feeds);

  //     let displayedFeeds = this.buildFeeds(feeds);
  //     let idx = this.notificationGroup.findIndex((n) => this.enterpriseSelector.isSameOrg(n.orgId, feeds[0].orgId));

  //     if (idx != -1) {
  //       this.notificationGroup[idx].feeds = displayedFeeds;
  //       this.notificationGroup[idx].count = feeds.length;
  //       this.notificationGroup[idx].timestamp = feeds[0].timestamp;
  //     } else {
  //       let org = orgs.find((o) => o.id == key);
  //       if (org) this.createOrgNotificationGroup(org.id, org.name, displayedFeeds);
  //     }
  //   }

  // }

  // private groupOrgFeeds(feeds: FeedState[]): Dictionary<FeedState[]> {
  //   const personalOrg = this.enterpriseSelector.getPersonalOrg();

  //   let dict: Dictionary<FeedState[]> = {};

  //   feeds.forEach((feed: FeedState) => {
  //     let res = this.enterpriseSelector.isSameOrg(feed.orgId, personalOrg.id);
  //     if (res == true) {
  //       if (dict[personalOrg.id]) {
  //         dict[personalOrg.id] = [...dict[personalOrg.id], feed];
  //       } else {
  //         dict[personalOrg.id] = [feed];
  //       }
  //     } else {
  //       if (dict[feed.orgId]) {
  //         dict[feed.orgId] = [...dict[feed.orgId], feed];
  //       } else {
  //         dict[feed.orgId] = [feed];
  //       }
  //     }
  //   });

  //   return dict;
  // }

  // private buildFeeds(feeds: FeedState[]): FeedDisplay[] {
  //   let toDisplay: FeedDisplay[] = [];

  //   const grp = _.groupBy(feeds, (o: FeedState) => {
  //     if (o.isComment) return o.data.postId;
  //     if (o.isMsg) return o.data.roomId;
  //     return o.id;
  //   });

  //   Object.keys(grp).forEach((parent) => {
  //     if (!parent) return;

  //     const fds = grp[parent];

  //     const title = this.getFeedTitle(fds[0]);
  //     let body = this.getFeedBody(fds[0], fds.length);
  //     let f = new FeedDisplay(
  //       fds[0].id,
  //       fds,
  //       title,
  //       body,
  //       fds[0].timestamp,
  //       parent
  //     );
  //     toDisplay.push(f);
  //   });

  //   return toDisplay;
  // }

  // private createOrgNotificationGroup(
  //   orgId: string,
  //   orgName: string,
  //   feeds: FeedDisplay[]
  // ) {
  //   const sum = this.getFeedCount(feeds);
  //   this.notificationGroup.push({
  //     orgId: orgId,
  //     name: orgName,
  //     feeds: feeds,
  //     count: sum,
  //     timestamp: feeds[0].timestamp,
  //     isSystem: false
  //   });
  // }

  // private createSysNotificationGroup(
  //   feeds: FeedDisplay[]
  // ) {
  //   const sum = this.getFeedCount(feeds);
  //   this.notificationGroup.push({
  //     orgId: null,
  //     name: this.translateService.instant("REPEAT.GENERAL"),
  //     feeds: feeds,
  //     count: sum,
  //     timestamp: feeds[0].timestamp,
  //     isSystem: true
  //   });
  // }

  // private getFeedCount(feeds: FeedDisplay[]) {
  //   return feeds.map(f => f.feeds.length).reduce((prev, curr) => prev + curr, 0);
  // }

  private getFeedTag(feed: Feed) {
    if (feed.isMsg) return feed.data.roomId ? feed.data.roomId : feed.id;
    if (feed.isComment) return feed.data.postId ? feed.data.postId : feed.id;
    return feed.id;
  }

  // private getFeedTitle(feed: FeedState) {
  //   if (!feed) return "";
  //   if (!feed.data) return `${feed.title}:`;
  //   const teamName = feed.data.teamName ? feed.data.teamName : "";
  //   const chName = feed.data.chName ? feed.data.chName : "";
  //   const roomName = feed.data.roomName ? feed.data.roomName : "";

  //   switch (feed.event) {
  //     case NotificationEventType.NEW_POST:
  //       return teamName.trim() != "" && chName.trim() != "" ? `${teamName}/${chName}:` : "";
  //     case NotificationEventType.NEW_COMMENT:
  //       return teamName.trim() != "" && chName.trim() != "" ? `${teamName}/${chName}:` : "";
  //     case NotificationEventType.NEW_MESSAGE:
  //       return roomName.trim() != "" ? `${roomName}:` : "";
  //     case NotificationEventType.NEW_MEET:
  //       return roomName.trim() != "" ? `${roomName}:` : "";
  //     case NotificationEventType.JOIN_REQ_APPROVED:
  //       return "";
  //     default:
  //       return `${feed.title}:`;
  //   }
  // }

  // private getFeedBody(feed: FeedState, count) {
  //   if (feed.isMsg) {
  //     return count == 1
  //       ? `You have ${count} new message in the room.`
  //       : `You have ${count} new messages in the room.`;
  //   } else if (feed.isComment) {
  //     if (feed.data.postCreator) {
  //       return count == 1
  //         ? `You have a new comment in ${feed.data.postCreator}'s post.`
  //         : `You have ${count} new comments in ${feed.data.postCreator}'s post.`;
  //     } else {
  //       return count == 1
  //         ? `You have a new comment in a post.`
  //         : `You have ${count} new comments in a post.`;
  //     }
  //   } else {
  //     return feed.body;
  //   }
  // }

  private initBroadcastChannel() {
    // post message before listening
    this.channel.addEventListener('message', (msg) => {
      if (msg === 'ping-all-tabs') {
        // ping received from other tab, attempt to block others
        this.channel.postMessage('block');
      } else if (msg === 'block') {
        // an active tab is attempting to block all tabs
        if (BrowserCheck.isIOS()) {
          // do nothing for now
        } else {
          this.router.navigateByUrl("/multitab", { skipLocationChange: true });
        }
      } else if (msg === 'logout') {
        // if other tabs logged out, log out all tabs
        this.authService.logout();
      }
    });

    // communicated with other tab
    this.channel.postMessage('ping-all-tabs');
  }

  private expandHeader(e, orgId: string) {
    e.stopPropagation();
    console.log("expandHeader");
    // this.systemHub.clearFeedUnread(orgId)
    //   .subscribe(res => {
    //     console.log("[after clear] %o", res);
    //     this.buildNotificationGroup(res);
    //   });
  }

  // clearAll(e) {
  //   e.stopPropagation();
  //   this.feedService.clearAllFeeds();
  //   this.notificationGroup = [];
  // }

  goToOrg(grp: OrgAlert) {
    this.alertMenuTrigger.closeMenu();
    if (grp.orgId == this._currentOrg.id) this.router.navigateByUrl("/main/activity");
    else this.switchOrganization(grp.orgId, "/main/activity");
  }

  goToFeed(feed: Feed) {
    var handler = this._createHandler(feed.event);
    handler.handle(feed);
  }

  private _createHandler(event: string) {
    var handlerType = RedirectHandler.map(event);
    return new handlerType(this.injector);
  }

  // goToFeed(feedDisplay: FeedDisplay) {
  //   console.log(feedDisplay);
  //   let feed = feedDisplay.feeds[0];
  //   this.redirectFromFeed(feed);
  // }

  // private async redirectFromFeed(feed: FeedState) {
  //   this.removeAlertNotifWithTag(feed.id);
  //   this.feedService.clearFeedUnreads(feed.orgId, feed.unreadKey);
  //   //let feedIds = feedDisplay.feeds.map(f => f.id);
  //   //this.feedService.clearFeeds(feed.orgId, feed.unreadKey, feedIds);

  //   const orgId = feed.orgId;

  //   let orgUser = this.enterpriseSelector.getUserByOrg(orgId);
  //   if (orgUser && orgUser.status == UserStatus.Suspended) {
  //     let msg = this.translateService.instant("MAIN.ALERT.SUSPENDED_ORG");
  //     let action = this.translateService.instant("OK");
  //     this.uiService.openSnackBar(msg, action, 5000);
  //     return;
  //   }

  //   if (feed.event === NotificationEventType.NEW_POST ||
  //     feed.event === NotificationEventType.NEW_COMMENT) {
  //     const channelId = feed.data.channelId;
  //     const postId = feed.data.postId;
  //     let url = "/main/teams/" + channelId + "/post/" + postId;
  //     if (feed.title === "Personal Space") {
  //       url = "/main/teams/feed/" + postId;
  //     }
  //     if (this.enterpriseSelector.isCurrentOrg(orgId)) {
  //       this.router.navigate([url]);
  //     } else {
  //       this.switchOrganization(orgId, url);
  //     }
  //   } else if (feed.event === NotificationEventType.NEW_MESSAGE) {
  //     const roomMatrixId = feed.data.roomMatrixId;
  //     const msgId = feed.data.msgId;
  //     const url = "/main/chat/" + roomMatrixId;

  //     if (this.enterpriseSelector.isCurrentOrg(orgId)) {
  //       this.router.navigate([url], { queryParams: { msgId: msgId } });
  //     } else {
  //       this.switchOrganization(orgId, url, { queryParams: { msgId: msgId } });
  //     }
  //   } else if (feed.event === NotificationEventType.NEW_SHARED) {
  //     const url = "/main/storage/shared";
  //     // const item  = feed.data.item;
  //     const isFolder = feed.data.isFolder;
      
  //     const originPath = feed.data.originPath; //_default/
  //     const originContainer = feed.data.originContainer;
  //     const path = feed.data.path; //_shared/
  //     const name = feed.data.itemName; 

  //     console.log("/main/storage", feed.data)

  //     // if (isFolder == "True"){
  //     //   folder = SharedFolder.parse(JSON.parse(item));
  //     //   originPath = folder.originPath;
  //     //   originContainer = folder.originContainer;
  //     //   path = folder.path;
  //     // }else{
  //     //   file = SharedFile.parse(JSON.parse(item));
  //     //   originPath = file.originPath;
  //     //   originContainer = file.originContainer;
  //     //   path = file.path;
  //     // }
      
  //     if (this.enterpriseSelector.isCurrentOrg(orgId)) {
  //       this.router.navigate([url], { queryParams: { redirectOriginPath: originPath, redirectOriginContainer: originContainer, redirectPath: path, isFolder: isFolder, itemName: name } });
  //     } else {
  //       this.switchOrganization(orgId, url, { queryParams: { redirectOriginPath: originPath, redirectOriginContainer: originContainer, redirectPath: path, isFolder: isFolder, itemName: name } });
  //     }
  //   } else if (feed.event === NotificationEventType.NEW_MEET) {
  //     const roomMatrixId = feed.data.roomMatrixId;
  //     const meetUrl = feed.data.meetUrl;
  //     const url = "/main/chat/" + roomMatrixId + "/meet/" + encodeURIComponent(meetUrl);

  //     if (this.enterpriseSelector.isCurrentOrg(orgId)) {
  //       this.router.navigate([url]);
  //     } else {
  //       this.switchOrganization(orgId, url);
  //     }
  //   } else if (_.startsWith(feed.event, PluginManager.EXT_PREFIX)) {
  //     if (!this.enterpriseSelector.isToolEnabled(orgId, feed.event)) {
  //       let msg = this.translateService.instant("ORGANIZATION.TOOLS.REDIRECT_DISABLED_TOOLS_ERR");
  //       let action = this.translateService.instant("OK");
  //       this.uiService.openSnackBar(msg, action);
        
  //       return;
  //     }
  //     if (!this.enterpriseSelector.isCurrentOrg(orgId)) {
  //       await this.switchOrganization(orgId, null);
  //     }

  //     var plugin = this.pluginManager.get(feed.event);
  //     if (plugin && plugin.tapRedirectPath) {
  //       //redirect to tool page 
  //       this.router.navigate(["main", "tools", ...plugin.tapRedirectPath.split("/").filter(i => i)], { state: feed.data });
  //     }
  //   } else if (feed.event === NotificationEventType.JOIN_REQ_APPROVED) {
  //     const url = "/main/dashboard/";

  //     if (this.enterpriseSelector.isCurrentOrg(orgId)) {
  //       console.log("[MainPage] going dashboard from JOIN_REQ_APPROVED");
  //       this.router.navigate([url]);
  //     } else {
  //       this.switchOrganization(orgId, url);
  //     }
  //   } else if (feed.event === NotificationEventType.JOIN_REQ_RECEIVED) {
  //     const url = feed.data.isPersonalSpace == "True" ? "main/friends" : "main/org/user";
  //     let qParams = { queryParams: { reqId: feed.data.reqId } };

  //     if (this.enterpriseSelector.isCurrentOrg(orgId)) {        
  //       this.router.navigate([url], qParams);
  //     } else {
  //       this.switchOrganization(orgId, url, qParams);
  //     }
  //   }
  // }

  async switchOrganization(orgId: string, url: string, queryParams: any = null) {
    let org = this.enterpriseSelector.getOrg(orgId);
    console.log(org);
    if (!org) return;

    // disable default redirect to dashboard and handle redirect manually
    await this.setRedirectOnSwitchOrg.emit(false);
    await this.flowManager.switchOrg(org.id).catch(() => {
      this.uiService.openSnackBar("Failed to prepare org", "OK");
    });

    // save login history
    let history: LoginHistoryState = {
      userId: this.userDataSelector.userId,
      orgId: org.id
    };

    await this.saveLoginHistory.emit(history).toPromise();

    if (!url) return;

    if (queryParams) {
      this.router.navigate([url], queryParams);
    } else {
      this.router.navigateByUrl(url);
    }
  }

  private subscribeOrgHubConnectionStatus() {
    this._subs.sink = this.orgHub.onHubConnectionStatusChanged.subscribe(state => {
      if (this.hasHubConnected) {
        // web app update UI if disconnected
        if (state.status === HubConnectionStatus.Offline && this.lastConnectionStatus != HubConnectionStatus.Offline) {
          this.lastConnectionStatus = HubConnectionStatus.Offline;
          this.uiService.disableSystemErrorMessage();
          this.uiService.enableSystemErrorMessage("Running in offline mode.");
        } else if (state.status === HubConnectionStatus.Connected) {
          this.lastConnectionStatus = HubConnectionStatus.Connected;
          this.uiService.disableSystemErrorMessage();
        } else if (state.status === HubConnectionStatus.Connecting && this.lastConnectionStatus != HubConnectionStatus.Connecting) {
          this.lastConnectionStatus = HubConnectionStatus.Connecting;
          this.uiService.disableSystemErrorMessage();
          this.uiService.enableSystemErrorMessage("Reconnecting to server...");
        }
      } else {
        if (state.status === HubConnectionStatus.Connected)
          this.hasHubConnected = true;
      }
    })
  }

  showPostNotificationSnackBar(
    body: string,
    title: string,
    orgId: string,
    channelId: string,
    postId: string
  ) {
    if (!channelId || !postId) return;
    // dont run if user is currently in channel/post
    if (channelId !== this.uiStateSelector.activeChannel) {
      let sbMessage = title + ": " + body;
      let sb = this.snackBar.open(sbMessage, "SHOW", {
        duration: 5000,
      });
      sb.onAction().subscribe(() => {
        if (!this.enterpriseSelector.isCurrentOrg(orgId)) {
          const dialogRef = this.dialog.open(DialogSwitchOUComponent, {
            width: "400px",
            data: {
              orgId: orgId,
              disableRedirect: true
            },
          });

          dialogRef.afterClosed().subscribe((res) => {
            if (res && res.status === "success") {
              this.routeToPost(orgId, channelId, postId);
            }
          });
        } else {
          // user in correct org
          this.routeToPost(orgId, channelId, postId);
        }
      });
    }
  }

  routeToPost(orgId: string, channelId: string, postId: string) {
    // let personalOrg = this.enterpriseSelector.getPersonalOrg();
    // console.log({ ...personalOrg })
    // console.log(orgId)
    // console.log("routing")
    // if (personalOrg.id === orgId) {
    // this.router.navigateByUrl("/main/teams/feed/" + postId);
    // } else {
    this.router.navigateByUrl("/main/teams/" + channelId + "/post/" + postId);
    // }
  }

  onUserProfileChanged() {
    if (this._userProfile) {
      this.userId = this._userProfile.userId;
      this.userDisplayName = `${this._userProfile.firstName ? this._userProfile.firstName : ""} ${this._userProfile.lastName ? this._userProfile.lastName : ""}`;
    } else{
      this.userId = null;
    }
  }

  hasTeamUnread(teamId: string): boolean {
    if (!this._channels) return;
    if (!this._unreads) return;

    return _.some(this._channels.filter(c => c.teamId === teamId), (ch: ChannelState) => {
      return this._unreads.count(ch.id) > 0;
    });

    // const allChIds = this._channels.filter(c => c.teamId === teamId)
    //   .map(c => c.id);

    // if (!allChIds || allChIds.length == 0) return false;

    // for (var i = 0; i < allChIds.length; i++) {
    //   if (this._unreads.containsKey(allChIds[i])) {
    //     return true;
    //   }
    // }

    // return false;
  }

  onPersonalOrgUnreadsChanged() {
    if (!this._currentOrg) return;
    if (this._currentOrg.type !== OrgType.Personal) return;

    this._hasPersonalOrgUnreads = _.some(this._channels, (ch: ChannelState) => {
      return this._unreads.count(ch.id) > 0;
    });
  }
  //#endregion

  //#region Side Menu Events

  isToolMenuEnable(key: string) {
    if (!this._toolMenu) return false;

    return this._toolMenu.some((i) => i === key);
  }

  //#endregion

  //#region Side Menu

  set sidenavMode(val) {
    this._sidenavMode = val;
    setTimeout(() => this.resizeService.resizeInformer$.next(true), 500);
  }

  get sidenavMode() {
    return this._sidenavMode;
  }

  //#endregion

  //#region Main Frame Content
  set boxedLayout(val) {
    this._boxedLayout = val;
    setTimeout(() => this.resizeService.resizeInformer$.next(true), 500);
  }

  get boxedLayout() {
    return this._boxedLayout;
  }
  //#endregion

  //#region Top Toolbar

  //#region Getting started process for new account and new org
  initGettingStartedNewAccount() {
    this.openWelcomeNewAccountDialog();
  }

  initGettingStartedNewOrg() {
    this.appState.ready()
      .then(() => {
        this.uiStateSelector.onUIStatusChanged().pipe(
          takeWhile(state => state === UIStatus.Ready, true)).subscribe(state => {
            if (state == UIStatus.Ready) {
              //this.openWelcomeNewOrgDialog();
            }
          });
      });
  }

  private openWelcomeNewAccountDialog() {
    this.uiService.openWelcomeNewAccountDialog();
  }

  private openWelcomeNewOrgDialog() {
    this.uiService.openWelcomeNewOrgDialog(this._currentOrg.id);
  }
  //#endregion

  help() {
    window.open("http://everleagues.freshdesk.com", "_blank");
  }

  logout(event) {
    event.preventDefault();
    //trigger logout on other tabs
    this.channel.postMessage('logout');
    this.authService.logout(false); //app.component will handle
  }

  changePassword() {
    const dialogRef = this.dialog.open(DialogChangePasswordComponent, {
      width: "400px", height: '490px',
      panelClass: "border-dialog"
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === "success") {
        this.uiService.openSnackBar("Successfully changed password", "OK");
      } else if (res && res.error) {
        // error
        this.uiService.openSnackBar(res.error, "OK");
      }
    });
  }
  //#endregion

  //#region Menu Footer
  openPage(pagetype) {
    if (pagetype === "android") {
      window.open(
        "https://play.google.com/store/apps/details?id=com.everleague.app",
        "_blank"
      );
    } else if (pagetype === "apple") {
      window.open(
        "https://itunes.apple.com/app/everleagues/id1316297335",
        "_blank"
      );
    } else if (pagetype === "everleagues") {
      window.open(
        "https://everleagues.com/",
        "_blank"
      );
    }
  }
  //#endregion

  //#region Common

  private sortFeeds(feeds) {
    feeds.sort((a, b) => {
      if (!a || !b) return 0;

      if (a.timestamp > b.timestamp) {
        return -1;
      }
      if (a.timestamp < b.timestamp) {
        return 1;
      }
      return 0;
    });
  }

  // private sortNotifGroup(group: NotificationGroup[]) {
  //   group.sort((a, b) => {
  //     if (!a || !b) return 0;
  //     if (a.isSystem) return -1; //make sure system alert is always first
  //     if (b.isSystem) return 1;

  //     if (a.timestamp > b.timestamp) {
  //       return -1;
  //     }
  //     if (a.timestamp < b.timestamp) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  // }

  // private openMeetSnackBar(message: string, meetUrl: string, roomMatrixId: string) {
  //   let sb = this.snackBar.open(message, "JOIN", {
  //     duration: 10000
  //   });
  //   sb.onAction().subscribe(() => {
  //     if (meetUrl) {
  //       meetUrl = this.meetService.forceHttps(meetUrl);
  //       this.meetService.joinMeet(meetUrl).then(res => {
  //         let url = this.meetService.getHostName(res.url);
  //         window.open(
  //           "/meet?q=" +
  //           url +
  //           "&jwt=" +
  //           res.accessToken +
  //           "&room=" +
  //           res.room +
  //           "&roomid=" +
  //           roomMatrixId,
  //           "_blank"
  //         );
  //       });
  //     }
  //   });
  // }

  //#endregion

  changeLanguage(lang: string) {
    // Change selected language

    this.systemHub.updateUserLanguage(lang).subscribe(res => {
      this.language = lang;
      this.setLanguage.emit(lang);
      this.setLanguageDisplay(lang);
    }, (err) => {

      console.error(err);

    });
  }

  setLanguageDisplay(lang: string) {
    if (lang == "en"){
      this.languageDisplay = this.translateService.instant("ENGLISH");
    }
    else if (lang == 'zh-cn'){
      this.languageDisplay = this.translateService.instant("SIMPLIFIED CHINESE");
    }
    else if (lang == 'zh-tw'){
      this.languageDisplay = this.translateService.instant("TRADITIONAL CHINESE");
    }
    else if (lang == 'es'){
      this.languageDisplay = this.translateService.instant("SPANISH");
    }
    else if (lang == 'pt-br'){
      this.languageDisplay = this.translateService.instant("BRAZILIAN PORTUGUESE");
    }
    else if (lang == 'it'){
      this.languageDisplay = this.translateService.instant("ITALIAN");
    }
    else if (lang == 'ja'){
      this.languageDisplay = this.translateService.instant("JAPANESE");
    }
  }

  get notificationCount() {
    return this.orgAlerts.map(f => f.count).reduce((prev, curr) => prev + curr, 0);
    // let total = 0;
    // this.notificationGroup.forEach(m => total += m.feeds.length);
    // return total;
  }

  quickShortcutsClick(status) {
    this._quickShortcutsExpand = status;
  }

  gotoQuickshortcut(url: string) {
    this.router.navigateByUrl(url);
  }

  openAddUserDialog() {
    this.router.navigateByUrl('/main/org/user').then(res => {
      this.orgUserComponent.ngOnInit();
      this.orgUserComponent.invite();
    });
  }

  openAddFriendDialog() {
    this.router.navigateByUrl('/main/friends').then(res => {
      this.friendsComponent.ngOnInit();
      this.friendsComponent.addUser();
    });
  }

  openSendSMSDialog(){
    const dialogRef = this.dialog.open(SmsPopUpContentComponent, {
      width: '400px',
    });
  }

  goToTool(menu: MenuItem) {
    console.log("%o", menu);
    if (menu.subMenus && menu.subMenus.length > 0) {
      //do nothing
      //console.error("no action");
    } else {
      if (menu.path, menu.pathParams && menu.pathParams.length > 0) {
        this.router.navigate(["main", "tools", ...menu.path.split("/"), ...menu.pathParams]);
      } else {
        this.router.navigate(["main", "tools", ...menu.path.split("/")]);
      }
    }
  }

  renderToolActivePath(menu: MenuItem) {
    if (menu.subMenus != null && menu.subMenus.length > 0) {
      return "";
    } else {
      return ["tools", ...menu.path.split("/")].join("/");
    }

  }

  //#region alert notification
  showAlertNotification(feed: Feed) {
    if (!feed) return;
    const tag = this.getFeedTag(feed);
    const alrt = new AlertNotification(feed.id, feed.title, feed.body, feed, tag);
    
    this.alertNotifs.unshift(alrt);

    setTimeout(() => {
      this.openSliderCSS(alrt.feedId);
    },50);
    
    setTimeout(() => {
      this.removeAlertNotif(feed.id);
    }, 10000);
  }

  removeAlertNotif(feedId: string) {
    let idx = this.alertNotifs.findIndex(a => a.feedId === feedId);
    this.closeSliderCSS(feedId);
    setTimeout(() => {
      if (idx >= 0) this.alertNotifs.splice(idx,1);
    }, 500);
    
  }

  removeAlertNotifWithTag(feedId: string) {
    let notif = this.alertNotifs.find(a => a.feedId === feedId);
    if (!notif) return;
    this.alertNotifs = this.alertNotifs.filter(a => a.tag != notif.tag);
  }

  clearAlertNotifs() {
    this.alertNotifs = [];
  }

  openSliderCSS(id: string) {
    var test = document.getElementById(id);
    test.classList.remove("slideup");
    test.classList.add("slidedown");
  }

  closeSliderCSS(id: string) {
    var test = document.getElementById(id);
    test.classList.remove("slidedown");
    test.classList.add("slideup");
  }

  //#endregion
}

export interface ChannelGroup {
  channel: Channel[];
  groupId: string;
  groupName: string;
  addButton?: SideItemButton;
}

export class OrgAlert {
  orgId: string;
  name: string;
  count: number;

  constructor(orgId: string, name: string, count: number) {
    this.orgId = orgId;
    this.name = name;
    this.count = count;
  }
}
// export class NotificationGroup {
//   orgId: string;
//   name: string;
//   feeds: FeedDisplay[];
//   count: number;
//   timestamp: number;
//   isSystem: boolean;

//   constructor() {
//     this.name = "";
//     this.feeds = [];
//     this.count = 0;
//     this.timestamp = 0;
//     this.isSystem = false;
//   }
// }

// export class FeedDisplay {
//   id: string;    //the first item of list of feeds
//   feeds: FeedState[];
//   title: string;
//   body: string;
//   timestamp: number;
//   tag: string;

//   constructor(
//     id: string,
//     feeds: FeedState[],
//     title: string,
//     body: string,
//     timestamp: number,
//     tag: string
//   ) {
//     this.id = id;
//     this.feeds = feeds;
//     this.title = title;
//     this.body = body;
//     this.timestamp = timestamp;
//     this.tag = tag;
//   }
// }

export class AlertNotification {
  feedId: string;
  feed: Feed;
  title: string;
  body: string;
  tag: string;

  constructor(feedId: string, title: string, body: string, feed: Feed, tag: string) {
    this.feedId = feedId;
    this.title = title;
    this.body = body;
    this.feed = feed;
    this.tag = tag;
  }
}