import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../api/api.service';

@Component({
  selector: 'link-preview',
  templateUrl: './link-preview.component.html',
  styleUrls: ['./link-preview.component.scss']
})

export class LinkPreviewComponent implements OnInit {
  public isLoading: boolean = false;
  public hasLinks: boolean = false;
  //public links: LinkPreviewItem[] = [];
  public item: LinkPreviewItem;
  @Input() text: string;

  constructor(
    private api: ApiService
  ) { }

  get hasTitleImg() {
    return !!this.item.title && !!this.item.image;
  }

  get defaultImg() {
    return this.isLoading ? "/assets/avatars/pending.png" : "/assets/avatars/link.png";
  }

  get defaultTitle() {
    return this.isLoading ? "" : this.item.url;
  }

  get defaultDesc() {
    return this.isLoading ? "Loading preview" : this.item.url;
  }

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    if (this.text) {
      let links = this.text.match(urlRegex);
      if (links && links.length) {
        this.hasLinks = true;
        this.isLoading = true;
        var url = links[0];

        this.item = new LinkPreviewItem("","","", url);

        let apiUrl = "linkpreview?url=" + btoa(url);
        this.api.get<LinkPreviewItem>(apiUrl).toPromise().then(m => {
          if (!!m) {
            this.item = m;         
          }

        }).finally(() => this.isLoading = false);
      }
    }
  }
}

export class LinkPreviewItem {
  title: string;
  description: string;
  image: string;
  url: string;

  constructor(title: string, desc: string, image: string, url: string) {
    this.title = title;
    this.description = desc;
    this.image = image;
    this.url = url;
  }
}
