import { MsgReaction } from './../../../core/model/message.state';
import { FileRenderer } from './../../../core/renderer/file.renderer';
import { EditorComponent } from './../editor/editor.component';
import { RoomHub } from './../../../core/hub/room.hub';
import { SubSink } from "subsink";
import { Component, OnInit, Input, OnChanges, OnDestroy, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DatePipe } from "@angular/common";
import { RelativeDatePipe } from "../../../core/pipe/relative-date.pipe";
import { FileAuthPipe } from "../../../core/pipe/file-auth.pipe";
import { ParticipantState } from "../../../core/model/participant.state";
import { MessageState, DecryptStatus } from "../../../core/model/message.state";
import {
  MessageStatus,
  Message,
  MessageSendStatus,
  MessageType,
  MessageFlag,
} from "../../../core/model/message.model";
import { MeetService } from "../../../core/services/meet.service";
import { DownloadService } from "../../../core/services/download.service";
import { DialogForwardMessageComponent } from "../../dialog/dialog-forward-message.component";
import { DialogDeleteMessageComponent } from "../../dialog/dialog-delete-message.component";
import { environment } from "../../../../environments/environment";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import { MessagingState } from "../../../core/states/messaging.state";
import { MediaService } from "../../../core/services/media.service";
import { TranslateService } from "@ngx-translate/core";
import { FcpService } from '../../../core/fcp/fcp.service';
import { StringDictionary } from '../../../core/util/dictionary';
import { MsgRenderer } from '../../../core/renderer/msg.renderer';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MessagingSelector } from '../../../core/states/messaging.state.selector';
import { ReplyToPipe } from '../../../core/pipe/reply-to.pipe';
import { DialogMsgReactionsComponent } from '../../dialog/dialog-msg-reactions.component';
import { saveAs } from "file-saver";
import { LibreOfficeService } from '../../../core/services/LibreofficeService/libreoffice.service';
import { AzureStorageModel, AzureStorageType, AzureStorageWopiActionType, WopiParameter } from '../../../core/model/libreoffice.model';
import { Base64 } from 'js-base64';
import jwt_decode  from 'jwt-decode';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: "ui-chat-message",
  templateUrl: "./chat-message.component.html",
  styleUrls: ["./chat-message.component.scss"],
  providers: [DatePipe, RelativeDatePipe, FileAuthPipe, ReplyToPipe],
})
export class ChatMessageComponent implements OnInit, OnDestroy {
  @Input() isMsgEditEnabled: boolean = true;
  @Input() isDeleteMsgEnabled: boolean = true;
  @Input() isMeetEnabled: boolean = true;
  @Input() isMentionsEnabled: boolean = true;
  private _message: MessageState = null;
  @Input()
  get message(): MessageState {
    return this._message;
  }
  set message(data: MessageState) {
    this._message = data;

    this.onMessageChangedHandler(data);
  }
  @Input() userMatrixId: string;
  private _participants: ParticipantState[] = [];
  @Input()
  get participants(): ParticipantState[] {
    return this._participants;
  }
  set participants(data: ParticipantState[]) {
    this._participants = data;
    this.isCurrentUserMessage = (this.userMatrixId === this._message.senderMatrixId);

    if (!this.isCurrentUserMessage) this.senderName = this.getSenderName(this.message.senderMatrixId);
    else this.senderName = "You"
  }
  @Input() flags: StringDictionary;
  @Input() highlighted: boolean;
  @Output() onEdited = new EventEmitter<boolean>();
  @Output() onReplied = new EventEmitter<string>();
  @Output() onReplyClicked = new EventEmitter<string>();

  private _sub: SubSink;
  @Emitter(MessagingState.addOrUpdateMessage)
  public addOrUpdateMessage: Emittable<MessageState>;

  messageText: string;
  senderName: string;
  fileName: string;
  mimeType: string;
  isCurrentUserMessage: boolean;

  private _isMediaLoaded = false;
  private FILENAME_MAX_LENGTH: number = 20;
  progress: number;
  isResendEnabled: boolean = true;

  // image blob
  blobUrl: string;
  safeBlobUrl: SafeUrl;

  constructor(
    public snackBar: MatSnackBar,
    private meetService: MeetService,
    private dialog: MatDialog,
    public downloadService: DownloadService,
    private translateService: TranslateService,
    private mediaService: MediaService,
    private fcp: FcpService,
    private roomHub: RoomHub,
    private msgRenderer: MsgRenderer,
    private sanitizer: DomSanitizer,
    private messagingSelector: MessagingSelector,
    private fileRenderer: FileRenderer,
    private replyToPipe: ReplyToPipe,
    private libreOfficeService: LibreOfficeService,
    private clipboard: Clipboard
  ) {
    this._sub = new SubSink();
  }

  ngOnInit() {
    // if (this.message) {
    //   console.log("[ChatMessage] trigger hello %s", this.getTime(this.message.serverTimeStamp));
    // } else {
    //   console.log("[ChatMessage] trigger hello. No message")
    // }
  }
  //#region getter
  get isTextType(): boolean {
    return this.message.type === MessageType.Text;
  }
  get isFileType(): boolean {
    return this.message.type === MessageType.File;
  }
  get isMeetType(): boolean {
    return this.message.type === MessageType.Meet;
  }
  get isCalendarType(): boolean {
    return this.message.type === MessageType.Calendar;
  }
  get isImageType(): boolean {
    return this.message.type === MessageType.Image;
  }

  get isDownloadable(): boolean {
    return this.isFileType || this.isImageType;
  }
  get isAudioType(): boolean {
    return this.message.type === MessageType.Audio;
  }
  get isValid(): boolean {
    // ensure its valid and decrypted
    let isValid = this.message.status === MessageStatus.Valid;
    if (isValid && this.message.isEncrypted) {
      if (this.message.isDecrypted !== DecryptStatus.Success) {
        return false;
      }
    }
    return isValid;
  }

  get isMessageSent(): boolean {
    return this.message.sendStatus === MessageSendStatus.Sent;
  }
  get isMessageSending(): boolean {
    return (
      this.message.sendStatus === MessageSendStatus.PendingToSent ||
      this.message.sendStatus === MessageSendStatus.Sending
    );
  }
  get isMessageSentFailed(): boolean {
    return this.message.sendStatus === MessageSendStatus.Failed;
  }

  get isMediaLoaded(): boolean {
    return this._isMediaLoaded;
  }

  get isMeetExp(): boolean {
    if (!this.message.meetExp) return false;
    let expiryTime = this.message.meetExp * 1000;
    let currentTime = new Date().getTime();

    return currentTime > expiryTime;
  }

  get isMeetReady(): boolean {
    if (!this.message) return false;
    if (this.message.meetUrl) return true;
    else return false;
  }

  get fullMediaUrl(): string {
    if (!this.message) return null;
    if (!this.message.mediaUrl) return null;
    if (!this.message.fwt) return null;
    var sourceUri =
      environment.apiLink + "/media/download?fwt=" + this.message.fwt;
    return sourceUri;
  }

  get isStarred(): boolean {
    if (!this.flags) return false;
    if (!this.message) return false;
    return this.flags.isExists(MessageFlag.STARRED, this.message.id);
  }

  get isReplyLater(): boolean {
    if (!this.flags) return false;
    if (!this.message) return false;
    return this.flags.isExists(MessageFlag.REPLYLATER, this.message.id);
  }

  get isDeleted(): boolean {
    return this.message.status === MessageStatus.Deleted;
  }

  get isPendingDecrypt(): boolean {
    return !this.isDeleted && this.message.isDecrypted === DecryptStatus.Pending;
  }

  get isFailedDecrypt(): boolean {
    return !this.isDeleted && this.message.isDecrypted === DecryptStatus.Fail;
  }


  //#endregion

  private async onMessageChangedHandler(message: MessageState) {
    //#region fileupload
    if (message) {
      if (
        message.sendStatus === MessageSendStatus.Sending &&
        message.mediaId
      ) {
        var sub = this.mediaService.onFileUploadProgress(message.mediaId);
        if (sub) {
          this._sub.sink = sub.subscribe((res) => {
            this.progress = res;
            if (res === 100) {
              this.progress = null;
              let msgSubscription = this.messagingSelector.getMessage$(message.tempId).subscribe((msg) => {
                if (msg) {
                  if (msg.sendStatus === MessageSendStatus.Sent) {
                    setTimeout(() => {
                      // give some time for messagestate to be added
                      this.getBlobUrl();
                      msgSubscription.unsubscribe();
                    }, 2000);
                  }
                }
              })
            }
          });
        }
      }

      // set mimeType
      if (message.mimeType) {
        this.mimeType = message.mimeType;
      } else {
        this.mimeType = this.mediaService.getMimeTypeWithFileName(message.mediaName)
      }
      if (message.type === MessageType.Audio) {
        this.mimeType = "audio/mpeg";
      }
      //#endregion

      this.messageText = this.getMsgContent();

      if (this.isFileType)
        this.fileName = this.shortenFileName(message.mediaName);

      if (message.mediaUrl && message.type === MessageType.Image || message.type === MessageType.Audio) {

        // get blobUrl
        this.getBlobUrl();
      }
    }
  }

  private getBlobUrl() {
    this.mediaService.getBlobUrl(this.message.id).then((blobUrl) => {
      this.safeBlobUrl = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
      this.blobUrl = blobUrl;
    });
  }

  ngOnDestroy() {
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }

  //#region message commands
  openFile(): void {
    //LibreOffice Integration
    if (this.message.mediaName && this.message.mediaName.length >0) {
      let file_extension = this.message.mediaName.substring(this.message.mediaName.lastIndexOf('.') + 1);
      this.libreOfficeService.isWopiSupportedFileType(file_extension).subscribe(data_ret=> {
        //this.http.get<MyTypeResult>('https://elofficedev.azurewebsites.net/wopi/check/'+ temp_file.extension).subscribe(data=> {     
          if (data_ret.Result) {  
            console.log ("LibreOffice supported!");
            if (this.libreOffice_Integration(data_ret.Action) == false){
              let mimetype = this.mediaService.getMimeTypeWithFileName(this.message.mediaName);
              if (this.message.type == MessageType.Image) {
                this.openImage(this.fullMediaUrl, mimetype);
              } else if (mimetype == "pdf") {
                this.openPDF(this.fullMediaUrl);
              } else {
                this.downloadFile();
              }                    
            }
          } else {
            let mimetype = this.mediaService.getMimeTypeWithFileName(this.message.mediaName);
            if (this.message.type == MessageType.Image) {
              this.openImage(this.fullMediaUrl, mimetype);
            } else if (mimetype == "pdf") {
              this.openPDF(this.fullMediaUrl);
            } else {
              this.downloadFile();
            }        
          }
        }
      );
    } else {  
        let mimetype = this.mediaService.getMimeTypeWithFileName(this.message.mediaName);
        if (this.message.type == MessageType.Image) {
          this.openImage(this.fullMediaUrl, mimetype);
        } else if (mimetype == "pdf") {
          this.openPDF(this.fullMediaUrl);
        } else {
          this.downloadFile();
        }
    }
  }

  libreOffice_Integration (dataAction: string):boolean {
    console.log ("LibreOffice Integration!");

    let boolean_Integration: boolean = true;

    let temp_fullpath: string = null;
    let temp_file_extension: string = null;
    let temp_container: string = null;

    //temp_fullpath = message.fwt;
    let  temp_fullpath1: any= jwt_decode(this.message.fwt);
    // if (temp_fullpath1.url.indexOf(this.message.orgId) >=0) {
    //   temp_fullpath= decodeURIComponent(temp_fullpath1.url.substring(temp_fullpath1.url.indexOf(this.message.orgId) + this.message.orgId.length+1));
    //   temp_container = this.message.orgId
    // } else {
      let temp_indexNumber = temp_fullpath1.url.toLowerCase().indexOf('matrix');
      if (temp_indexNumber > 1) {
        temp_fullpath= decodeURIComponent(temp_fullpath1.url.substring(temp_indexNumber ));
        temp_container = temp_fullpath1.url.substring(0,temp_indexNumber-1);
      } else {
        return false;
      }
    //}
    //temp_fullpath = "matrix/!txRHGfYmlMIVrbrnjV:matrixapp.everleagues.com/files/YjfuTXZsjUenpdLlJB-IBA"
    temp_file_extension = this.message.mediaName.substring(this.message.mediaName.lastIndexOf('.') + 1);
    
    let temp_userName: string = this.libreOfficeService.getUserName();
    let temp_userId: string = this.libreOfficeService.getUserId();
    let temp_userAvator: string = this.libreOfficeService.getUserAvatar(temp_userId);        
    let temp_userEmail: string = this.libreOfficeService.getUserEmail();     
    let temp_postMessageOrigin: string = this.libreOfficeService.getPostMessageOrigin();
    let temp_ownerId: string = ""; // this.message.createdBy;
    //ReadOnly and no Collaboration for Chat attachment
    //let temp_readOnly:boolean = (temp_userId != temp_ownerId)? true:false;
    let temp_readOnly:boolean = true;
    let temp_collaboration:boolean = false;
    this.libreOfficeService.getAccessToken(temp_userId).subscribe(tokenData=> {               
      if (tokenData.Result) {           
            const temp_Request_Orginal: AzureStorageModel =  {
                fileId: temp_container + "/" + temp_fullpath,
                Container: temp_container,
                blobPath: temp_fullpath,
                BaseFileName:this.message.mediaName,
                Action:AzureStorageWopiActionType[dataAction],
                OwnerId:temp_ownerId,
                UserId: temp_userId,
                UserName:temp_userName,
                AccessToken: tokenData.Message,
              };
              const temp_Request_Encoded = Base64.encode(encodeURIComponent(JSON.stringify(temp_Request_Orginal)));
              console.log(temp_Request_Encoded);
              console.log(encodeURIComponent(temp_Request_Encoded));

              const temp_userObject: WopiParameter = {
                UserName:  temp_userName,
                UserId: temp_userId,
                ReadOnly: temp_readOnly,
                UserAvator:temp_userAvator,
                UserEmail: temp_userEmail, 
                PostMessageOrigin: temp_postMessageOrigin,                           
                Collaboration: temp_collaboration,
                StorageType: AzureStorageType.team,
              };
              
              const temp_userObject_Encoded = Base64.encode((encodeURIComponent(JSON.stringify(temp_userObject)))); 

              // const temp_URL = "https:///elofficedev.azurewebsites.net/home/detail/" + temp_Request_Encoded + "?p=" +temp_userObject_Encoded;
              // //const temp_URL = this.libreOfficeService.apiURL +"home/detail/" + (temp_Request_Encoded)+ "?p=" + (temp_userObject_Encoded);
              // window.open(temp_URL, '_blank');

              this.libreOfficeService.getView(temp_Request_Encoded+ "?p=" +temp_userObject_Encoded).subscribe((retData)=> {
                  const temp_htmlBlob = new Blob([retData], {type: 'text/html'});
                  var temp_url1 = URL.createObjectURL(temp_htmlBlob);
                  console.log ("blob path: " + temp_url1);
                  window.open(temp_url1, '_blank');
              });
                    
             
              boolean_Integration = true;
      } else {
        boolean_Integration = false;                 
      }
    });

    return boolean_Integration;
  }

  downloadFile() {
    if (this.progress) return; // block from downloading twice
    // set progress to very small % in order to show empty progress bar while waiting for download service
    // and mark download as in progress
    this.progress = 0.01;
    this.downloadService.downloadFileWithProgress(this.fullMediaUrl).subscribe(event => {
      if (event.type === HttpEventType.DownloadProgress) {
        const percentage = 100 / event.total * event.loaded;
        // console.log(percentage);
        this.progress = percentage;
      }
      // finished
      if (event.type === HttpEventType.Response) {
        saveAs(event.body, this.message.mediaName);
        this.progress = null;
      }
    }, error => {
      this.openSnackBar("Error downloading file.", "OK")
    });
    // this.downloadService.downloadFile(this.fullMediaUrl)
    //   .then((blob) => {
    //     saveAs(blob, this.message.mediaName);
    //   });
  }

  joinMeet() {
    if (this.isMeetReady) {
      if (this.message.meetUrl && this.isMeetExp !== true) {
        this.message.meetUrl = this.meetService.forceHttps(
          this.message.meetUrl
        );
        this.meetService.joinMeet(this.message.meetUrl).then(
          (res) => { this.openMeetPage(res); },
          (res) => { this.joinMeetOnRejected(res); }
        );
      } else {
        this.openSnackBar(
          this.translateService.instant("CHAT.MEETING_EXPIRED"),
          this.translateService.instant("REPEAT.OK")
        );
      }
    }
  }

  private joinMeetOnRejected(res: HttpErrorResponse) {
    if (res.status == 400) { // Bad request with custom response code
      this.openOKSnackBar(res.error.error);
    }
    else if (res.status == 401) { // Unauthorized
      this.openOKSnackBar(this.translateService.instant("DASHBOARD.UNAUTHORIZED.DESC_3"));
    }
    else {
      this.openOKSnackBar(this.translateService.instant("CHAT.MEETING_ERROR"));
    }
  }

  openMeetPage(res: any) {
    const url = this.meetService.getHostName(res.url);
    const jwt = res.accessToken;
    const room = res.room;
    const roomId = this.message.roomId;

    var w = window.open("/meet", "_blank");
    w["meetReq"] = { url: url, accessToken: jwt, room: room, roomId: roomId };

  }

  deleteMessage() {
    const dialogRef = this.dialog.open(DialogDeleteMessageComponent, {
      width: "400px",
      data: {
        message: this.message,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.status === "success") {
        this.message.status = MessageStatus.Deleted;
        // refresh if true
        this.openSnackBar(
          this.translateService.instant("CHAT.MSG_DELETED"),
          this.translateService.instant("REPEAT.OK")
        );

      } else if (res && res.error) {
        // error
        this.openSnackBar(
          res.error.error,
          this.translateService.instant("REPEAT.OK")
        );
      }
    });
  }

  forwardMessage() {
    const dialogRef = this.dialog.open(DialogForwardMessageComponent, {
      width: "500px",
      height: "70vh",
      data: {
        message: this.message,
        participants: this.participants,
        userMatrixId: this.userMatrixId
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.status === "success") {
        // refresh if true
        this.openSnackBar(
          this.translateService.instant("CHAT.MSG_FORWARDED"),
          this.translateService.instant("REPEAT.OK")
        );
      } else if (res && res.error) {
        // error
        this.openSnackBar(
          res.error,
          this.translateService.instant("REPEAT.OK")
        );
      }
    });
  }

  resendMsg() {
    // prevent user from resending while msg has an ongoing resend and the status is not yet updated to Sending
    this.isResendEnabled = false;
    if (!this.message || this.message.sendStatus != MessageSendStatus.Failed) {
      this.isResendEnabled = true;
      return;
    }

    this.roomHub.resendMessage(this.message).finally(() => { this.isResendEnabled = true; });
  }

  starMessage() {
    this.setMsgFlag(MessageFlag.STARRED);
    this.openSnackBar(
      this.translateService.instant("CHAT.MSG_STARRED"),
      this.translateService.instant("REPEAT.OK")
    );
  }
  unstarMessage() {
    this.removeMsgFlag(MessageFlag.STARRED);
    this.openSnackBar(
      this.translateService.instant("CHAT.MSG_UNSTARRED"),
      this.translateService.instant("REPEAT.OK")
    );
  }

  replyMessageLater() {
    this.setMsgFlag(MessageFlag.REPLYLATER);
    this.openSnackBar(
      this.translateService.instant("CHAT.MSG_SET_REPLY"),
      this.translateService.instant("REPEAT.OK")
    );
  }

  cancelReplyMessageLater() {
    this.removeMsgFlag(MessageFlag.REPLYLATER);
    this.openSnackBar(
      this.translateService.instant("CHAT.REMOVE_MSG_REPLY"),
      this.translateService.instant("REPEAT.OK")
    );
  }

  private setMsgFlag(flag: string) {
    if (!this.message) return;

    this.roomHub.addMsgFlagToServer(flag, this.message.id, this.message.roomMatrixId);
  }

  private removeMsgFlag(flag: string) {
    if (!this.message) return;

    this.roomHub.removeMsgFlagFromServer(flag, this.message.id, this.message.roomMatrixId);
  }
  //#endregion

  //#region render
  getMsgContent(): string {
    return this.msgRenderer.renderPlaintext(this.message);
    // let msg = this.message;
    // return msg.content;
    // if (!msg) return "";
    // if (msg.status === MessageStatus.Deleted) return "";
    // if (!msg.content || msg.content.trim() == "") return "";
    // if (!msg.isEncrypted) return msg.content;
    // const decrypted = this.plaintexts.find(
    //   (p) =>
    //     (p.msgId && msg.id && p.msgId === msg.id) ||
    //     (msg.tempId && p.tempId && p.tempId === msg.tempId)
    // );
    // if (!decrypted) {
    //   return this.fcp.getPlainText(msg.content, msg.id, msg.tempId);
    // }
    // return decrypted.plaintext;
  }

  getSenderName(senderId: string): string {
    if (!this.participants) return "Unknown";
    const ptcp = this.participants.find((p) => p.matrixId === senderId);
    if (!ptcp) return "Unknown";

    return `${ptcp.firstName} ${ptcp.lastName}`;
  }

  shortenFileName(file) {
    if (!file) return;
    return this.fileRenderer.renderFileName(file, this.FILENAME_MAX_LENGTH);
  }

  convertToLocal(exp) {
    let expired = new Date(exp);
    return expired;
  }
  //#endregion

  //#region events
  onImageLoad() {
    this._isMediaLoaded = true;
  }

  onMetadata() {
    this._isMediaLoaded = true;
  }
  //#endregion

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  private openOKSnackBar(message: string) {
    this.openSnackBar(
      message,
      this.translateService.instant("REPEAT.OK")
    );
  }

  openImage(url, mimeType: string): void {
    if (this.progress) return;
    // console.log("image url", url);
    // window.open(url, "_blank");

    this.downloadService.downloadFileWithProgress(url, false).subscribe(event => {
      if (event.type === HttpEventType.DownloadProgress) {
        const percentage = 100 / event.total * event.loaded;
        // console.log(percentage);
        this.progress = percentage;
      }
      // finished
      if (event.type === HttpEventType.Response) {
        var file = new Blob([event.body], { type: mimeType + ';base64' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
        this.progress = null;
      }
    }, error => {
      this.openSnackBar("Error downloading file.", "OK")
    });
  }

  openPDF(url: string): void {
    if (this.progress) return;
    this.downloadService.downloadFileWithProgress(url, false).subscribe(event => {
      if (event.type === HttpEventType.DownloadProgress) {
        const percentage = 100 / event.total * event.loaded;
        // console.log(percentage);
        this.progress = percentage;
      }
      // finished
      if (event.type === HttpEventType.Response) {
        var file = new Blob([event.body], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
        this.progress = null;
      }
    }, error => {
      this.openSnackBar("Error downloading file.", "OK")
    });
  }

  editText() {
    const dialogRef = this.dialog.open(EditorComponent, {
      minWidth: "60%",
      maxWidth: "80%",
      panelClass: "absolute-dialog",
      data: {
        message: this.message,
        isMeetEnabled: this.isMeetEnabled,
        isMentionsEnabled: this.isMentionsEnabled,
        participants: this.participants,
        userMatrixId: this.userMatrixId,
      },
    });
    dialogRef.afterClosed().toPromise().then((isEdited) => {
      if (isEdited) {
        this.onEdited.emit(true);
        if (this.message.type === MessageType.Image) {
          // update blobUrl
          this.getBlobUrl();
        }
      }
    });
  }

  reply(){
    let replyMsgContent = this.replyToPipe.construct(
      this.senderName,
      this.message.serverTimeStamp,
      this.message.type,
      this.fileName,
      this.message.meetingTitle,
      this.message.plaintext,
      this.message.id
    );
    this.onReplied.emit(replyMsgContent);
  }

  copyMessage() {
    this.clipboard.copy(this.message.plaintext);
  }

  getTime(timestamp) {
    return new Date(timestamp)
  }

  openReactionsDialog(reactions: MsgReaction[]){
    const dialogRef = this.dialog.open(DialogMsgReactionsComponent, {
      data: {
        reactions: reactions,
        participants: this.participants,
        userMatrixId: this.userMatrixId,
      },
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.remove) {
        let reaction = reactions.find(
          (r) =>
            r.content === res.remove.code &&
            r.senders.some((s) => s === this.userMatrixId)
        );
        if (reaction) {
          let senderIndex = reaction.senders.findIndex(
            (s) => s == this.userMatrixId
          );
          if (senderIndex == -1) return;
          reaction.senders.splice(senderIndex, 1);
          reactions = reactions.filter((r) => r.senders.length > 0);
          this.roomHub
            .reactMsg(this.message.roomMatrixId, this.message.id, reactions)
            .then((res) => {
              reactions = res;
            })
            .catch((err) => {
              this.openOKSnackBar(err);
            });
        }
      }
    });
  }
}