import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, ValidationErrors, UntypedFormArray, ValidatorFn } from '@angular/forms';
import { OUService } from '../../../services/ou.service';
import { Elements, Element as StripeElement, StripeService, CardDataOptions, Token } from 'ngx-stripe';
import { ELStripeElementOptions } from '../../../model/stripe-element.model';
import { pocolog } from 'pocolog';
import { OrgHub } from '../../../hub/org.hub';
import { Select } from '@ngxs/store';
import { Observable, timer, Subscription } from 'rxjs';
import { OrgState } from '../../../model/org.state';
import { SubSink } from 'subsink';
import { InputFreeOrgLimitStateMatcher } from '../../../state-matcher/input-free-org-limit.state-matcher';
import { UserDataSelector } from '../../../states/user-data.state.selector';
import { Router } from "@angular/router";
import { SubType } from '../../../enum/sub-type';
import { switchMap } from 'rxjs/operators';
import { LicenseState } from '../../../states/license.state';
import { SubscriptionState, SubStatus } from '../../../model/subscription.state';
import { environment as ENV } from 'environments/environment';
import { LicenseStateSelector } from 'app/core/states/license.state.selector';

export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  };
}

@Component({
  selector: 'app-dialog-new-org',
  templateUrl: './dialog-new-org.component.html',
  styleUrls: ['./dialog-new-org.component.scss']
})
export class DialogNewOrgComponent implements OnInit, OnDestroy {

  @Select(LicenseState.subscriptions)
  subscriptions$: Observable<SubscriptionState[]>;

  // expose enum to template
  SubType = SubType;

  // formGroups
  orgFormGroup: UntypedFormGroup;
  orgSubscriptionFormGroup: UntypedFormGroup;
  orgDetailsFormGroup: UntypedFormGroup;
  paymentFormGroup: UntypedFormGroup;

  // ui flags
  isFree = true;
  isLoading: boolean;
  pageState: PageState = PageState.CreatePage1;

  // error msg
  showError: boolean;
  errorMsg: string;

  industries: any;

  // created org details
  createdOrg: OrgState;

  // payment
  cardName: string;
  cardZip: string;

  // stripe
  elements: Elements;
  cardNumber: StripeElement;
  cardExpiry: StripeElement;
  cardCVC: StripeElement;

  // free org limit
  currentFreeAccountCount: number;
  orgLimitMatcher: any;

  subType = SubType.Free;
  // token
  token: Token;

  // wait for pending subscription for x milisec
  waitPendingTimer = 5000; //milisec

  get isFreePlan(): boolean {
    return this.subType === SubType.Free;
  }

  get isCreatePage1(): boolean {
    return this.pageState === PageState.CreatePage1;
  }

  get isCreatePage2(): boolean {
    return this.pageState == PageState.CreatePage2;
  }

  get isCreatePage3(): boolean {
    return this.pageState == PageState.CreatePage3;
  }

  get isPendingPage(): boolean {
    return this.pageState === PageState.Pending;
  }

  get isCompletePage(): boolean {
    return this.pageState === PageState.Complete;
  }
  get isPage1Valid(): boolean {
    return this.orgFormGroup.valid
  }

  get isPage2Valid(): boolean {
    if (this.isFree) {
      return (this.orgFormGroup.valid && this.orgSubscriptionFormGroup.valid)
    } else {
      return (this.orgFormGroup.valid && this.paymentFormGroup.valid && this.orgSubscriptionFormGroup.valid)
    }
  }

  get isPage3Valid(): boolean {
    if (this.isFree) {
      return (this.orgFormGroup.valid && this.orgDetailsFormGroup.valid && this.orgSubscriptionFormGroup.valid)
    } else {
      return (this.orgFormGroup.valid && this.paymentFormGroup.valid && this.orgDetailsFormGroup.valid && this.orgSubscriptionFormGroup.valid)
    }
  }

  get isFreeOrgUsedUp(): boolean {
    return this.currentFreeAccountCount >= ENV.freeOrgLimit;
  }

  runInitStripe = true;

  timerOrgCreated = 0;
  timerSubCreated = 0;

  private _subSink: SubSink;
  private _subSubs: Subscription;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogNewOrgComponent>, private fb: UntypedFormBuilder,
    private ouService: OUService, private stripeService: StripeService, public dialog: MatDialog, private licenseStateSelector: LicenseStateSelector
    , private orgHub: OrgHub, private userDataSelector: UserDataSelector, private router: Router) {
    this._subSink = new SubSink();
    this.ouService.getIndustry().then(res => {
      this.industries = res;
    })
      .catch(error => {
        pocolog.error(error);
        this.showError = true;
        this.errorMsg = error.error;
      });
  }
  ngOnDestroy(): void {
    if (this._subSink) this._subSink.unsubscribe();
    if (this._subSubs) this._subSink.unsubscribe();
  }

  ngOnInit() {
    this.orgFormGroup = this.fb.group({
      orgName: ['', [Validators.required, noWhitespaceValidator()]],
      ouCode: ['', [Validators.required,
      Validators.minLength(4),
      Validators.pattern('^[a-zA-Z0-9 \'\-]+$'),
      ], [this.ouCheckValidator()]],
      industry: ['', Validators.required]
    });
    this.orgSubscriptionFormGroup = this.fb.group({
      subType: [SubType.Free, Validators.required],
    });

    this.setValidatorOrgDetailsForm();
    this.removeValidatorOrgDetailsForm();

    this.paymentFormGroup = this.fb.group({
      cardName: ['', [Validators.required]],
      cardZip: ['', [Validators.required]],
    });

    this.initCurrentFreeOrgs();
    this.initSubType();
  }

  initCurrentFreeOrgs() {
    const subList = this.licenseStateSelector.getFreeOrgsSubs();
    this.currentFreeAccountCount = subList != null ? subList.length : 0;

    if (this.isFreeOrgUsedUp) {
      this.orgSubscriptionFormGroup = this.fb.group({
        subType: [SubType.Standard, Validators.required],
      });

      this._onSubTypeChanged(SubType.Standard);
    }
  }

  initSubType() {
    // set free org limit
    this.orgLimitMatcher = new InputFreeOrgLimitStateMatcher(ENV.freeOrgLimit, this.currentFreeAccountCount, this.subType);
  }

  initPaymentForm() {
    this.stripeService.elements({
      // Stripe's examples are localized to specific languages, but if
      // you wish to have Elements automatically detect your user's locale,
      // use `locale: 'auto'` instead.
      locale: 'auto'
    }).subscribe(elements => {
      // Floating labels
      var elementStyles = {
        base: {
          '::placeholder': {
            color: 'grey'
          }
        }
      };

      var elementClasses: ELStripeElementOptions = {
        style: elementStyles,
        classes: {
          focus: 'focused',
          empty: 'empty',
          invalid: 'invalid',
        }
      };

      if (!this.cardNumber) {
        this.cardNumber = elements.create('cardNumber', elementClasses);
        this.cardNumber.mount('#payment-card-number');
      }

      if (!this.cardExpiry) {
        this.cardExpiry = elements.create('cardExpiry', elementClasses);
        this.cardExpiry.mount('#payment-card-expiry');
      }
      if (!this.cardCVC) {
        this.cardCVC = elements.create('cardCvc', elementClasses);
        this.cardCVC.mount('#payment-card-cvc');
      }
    });
  }

  //#region events
  onSubTypeChanged(event) {
    if (!event || !event.value) return;
    this._onSubTypeChanged(event.value);
  }

  _onSubTypeChanged(subType) {
    this.subType = subType;
    if (this.subType !== SubType.Free) {
      this.isFree = false;
      this.setValidatorOrgDetailsForm();
      if (this.runInitStripe) {
        this.initPaymentForm();
        this.runInitStripe = false;
      }
    } else {
      this.isFree = true;
      this.removeValidatorOrgDetailsForm();
    }
  }
  //#endregion

  //#region button clicks
  async createOrg() {
    this.showError = false;
    this.orgFormGroup.markAllAsTouched();
    if (this.orgSubscriptionFormGroup.valid) {
      if (this.orgSubscriptionFormGroup.value.subType === SubType.Free) {
        this.isLoading = true;
        this.createNewOrg();
      } else {
        if (this.paymentFormGroup.valid && this.token && this.orgDetailsFormGroup.valid) {
          this.isLoading = true;
          this.createNewOrg(this.token.id);
        }
      }
    }
  }

  getCardToken() {
    this.token = null;

    this.orgFormGroup.markAllAsTouched();
    this.paymentFormGroup.markAllAsTouched();

    let additionalData: CardDataOptions = {
      name: this.paymentFormGroup.get('cardName').value,
      address_zip: this.paymentFormGroup.get('cardZip').value
    }

    if (this.orgFormGroup.valid) {
      this.isLoading = true
    }

    this.stripeService
      .createToken(this.cardNumber, additionalData)
      .subscribe(result => {
        if (result.token) {
          this.isLoading = false;
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          console.log(result.token);
          this.token = result.token;
          // move to last page
          this.pageState = PageState.CreatePage3;
        } else if (result.error) {
          this.isLoading = false;
        }
      });
  }
  back() {
    if (this.pageState == PageState.CreatePage2) {
      this.pageState = PageState.CreatePage1;
    } else if (this.pageState == PageState.CreatePage3) {
      this.pageState = PageState.CreatePage2;
    }
  }
  next() {
    if (this.pageState == PageState.CreatePage1) {
      this.pageState = PageState.CreatePage2;
    } else if (this.pageState == PageState.CreatePage2) {
      if (!this.isFree) {
        this.getCardToken();
      } else {
        this.pageState = PageState.CreatePage3;
      }
    }
  }

  close() {
    if (this.pageState === PageState.Complete || this.pageState === PageState.Pending) {
      this.dialogRef.close({ status: 'success', response: this.createdOrg });
    } else {
      this.dialogRef.close();
    }
  }
  //#endregion

  // validators
  removeValidatorOrgDetailsForm() {
    this.orgDetailsFormGroup.get('street').clearValidators();
    this.orgDetailsFormGroup.get('city').clearValidators();
    this.orgDetailsFormGroup.get('zip').clearValidators();
    this.orgDetailsFormGroup.get('state').clearValidators();
    this.orgDetailsFormGroup.get('country').clearValidators();
    this.triggerValidation(this.orgDetailsFormGroup);
  }
  setValidatorOrgDetailsForm() {
    this.orgDetailsFormGroup = this.fb.group({
      street: ["", Validators.required],
      city: ["", Validators.required],
      zip: ["", Validators.required],
      state: ["", Validators.required],
      country: ["", Validators.required],
      agreeCheck: ["", Validators.requiredTrue],
      coupon: [""],
    });
    this.triggerValidation(this.orgDetailsFormGroup);
  }

  private triggerValidation(control: AbstractControl) {
    if (control instanceof UntypedFormGroup) {
      const group = (control as UntypedFormGroup);

      for (const field in group.controls) {
        const c = group.controls[field];

        this.triggerValidation(c);
      }
    }
    else if (control instanceof UntypedFormArray) {
      const group = (control as UntypedFormArray);

      for (const field in group.controls) {
        const c = group.controls[field];

        this.triggerValidation(c);
      }
    }

    control.updateValueAndValidity({ onlySelf: false });
  }


  createNewOrg(cardToken = null) {
    this.isLoading = true;

    var orgName = this.orgFormGroup.value.orgName;
    var code = this.orgFormGroup.value.ouCode;
    var industry = this.orgFormGroup.value.industry;
    var street = this.orgDetailsFormGroup.value.street;
    var city = this.orgDetailsFormGroup.value.city;
    var zip = this.orgDetailsFormGroup.value.zip;
    var state = this.orgDetailsFormGroup.value.state;
    var country = this.orgDetailsFormGroup.value.country;
    var couponCode = this.orgDetailsFormGroup.value.coupon;
    this.orgHub.createOrg(orgName, code, industry, street, city, zip, state, country, this.subType, cardToken, couponCode)
      .subscribe(res => {

        this.createdOrg = res;
        this.processSubscription();
      }, error => {
        this.isLoading = false;
        this.showError = true;
        this.errorMsg = "Error creating organization. " + error;
      });
  }

  processSubscription() {
    // after org is created, wait for service to return completed subscription with a max wait of 5 seconds
    var waitTimer = setTimeout(() => {
      console.log("[DialogNewOrg] Pending org timeout, going to dashboard")
      if (this._subSubs) {
        this._subSubs.unsubscribe();
        this.dialogRef.close({ status: 'pending', response: this.createdOrg });
      }
    }, this.waitPendingTimer);

    this._subSubs = this.subscriptions$.subscribe(subs => {
      if (this.createdOrg) {
        var createdOrgSub = subs.filter(x => x.orgId === this.createdOrg.id)[0];
        if (createdOrgSub) {
          console.log("[DialogNewOrg] Sub update %o", createdOrgSub)
          if (createdOrgSub.status === SubStatus.Active) {
            // clear timer and sub
            if (this._subSubs) this._subSubs.unsubscribe();
            clearTimeout(waitTimer);
            this.dialogRef.close({ status: 'success', response: this.createdOrg });
          } else if (createdOrgSub.status === SubStatus.PaymentFailed) {
            // clear timer and sub
            if (this._subSubs) this._subSubs.unsubscribe();
            clearTimeout(waitTimer);
            this.dialogRef.close({ status: 'paymentfailed', response: this.createdOrg });
          }
        }
      }      
    });
  }

  // couponSuccessEvent($event) {
  //   let couponId = this.coupon.couponId
  //   this.coupon = new Coupon(); // new obj to trigger product-calc-table ngOnChange
  //   this.coupon.couponId = couponId;
  //   if ($event) {
  //     this.coupon.isValid = true;
  //     this.coupon.name = $event.name;
  //     this.coupon.percentOff = $event.percent_off;
  //   } else {
  //     this.coupon.isValid = false;
  //     this.coupon.percentOff = null;
  //     this.coupon.name = null;
  //   }
  //   this.updateTotal();
  // }

  // disable form for free limit
  orgFreeLimitCheck() {
    if (this.subType === SubType.Free) {
      if (this.currentFreeAccountCount >= ENV.freeOrgLimit) {
        return true;
      }
    }
  }

  isAtActivate() {
    return this.router.url.includes("/activate");
  }

  ouCheckValidator() {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return timer(500).pipe(
        switchMap(() => {
          return this.ouService.checkOrgId(control.value).then(res => {
            if (!res) {
              return { 'ouCodeExists': true };
            } else {
              return null
            }
          }).catch(err => {
            return { 'serverError': true };
          });
        })
      )
    }
  }
}
export enum PageState {
  CreatePage1 = 1,
  CreatePage2 = 2,
  CreatePage3 = 3,
  Pending = 4,
  Complete = 5
}
