import { State, StateContext, Selector, createSelector } from "@ngxs/store";
import { ImmutableContext, ImmutableSelector } from "@ngxs-labs/immer-adapter";
import { Receiver, EmitterAction } from "@ngxs-labs/emitter";
import { Injector, Injectable } from "@angular/core";
import * as _ from "lodash";
import { SubscriptionState } from "../model/subscription.state";
import { FeatureState } from "../model/feature.state";
import { AppState } from "./app.state";
import { OrgState } from "../model/org.state";
import { EnterpriseState } from "./enterprise.state";

export class LicenseStateModel {
  subscriptions: SubscriptionState[];
  features: FeatureState[];

  constructor() {
    this.subscriptions = [];
    this.features = [];
  }
}

@State<LicenseStateModel>({
  name: "license",
  defaults: new LicenseStateModel(),
})
@Injectable()
export class LicenseState {
  constructor(injector: Injector, private appState: AppState) {
  }

  ngxsAfterBootstrap(ctx: StateContext<LicenseStateModel>) {
    this.appState.reportReady("license");
    console.log("[LicenseStateModel] - ngxsAfterBootstrap");
  }

  //#region Selectors
  @Selector()
  @ImmutableSelector()
  static subscriptions(state: LicenseStateModel): SubscriptionState[] | null {
    var result = state.subscriptions;
    return _.cloneDeep(result);
  }

  @Selector()
  @ImmutableSelector()
  static features(state: LicenseStateModel): FeatureState[] | null {
    var result = state.features;
    return _.cloneDeep(result);
  }
  
  @Selector([LicenseState.subscriptions, EnterpriseState.selectedOrg])
  @ImmutableSelector()
  static currentOrgSubscription(
    subscriptions: SubscriptionState[],
    selectedOrg: OrgState
  ) {
    var result = subscriptions.filter(r => !!selectedOrg && r.orgId === selectedOrg.id)[0];
    return _.cloneDeep(result);
  }

  @Selector([LicenseState.features, EnterpriseState.selectedOrg])
  @ImmutableSelector()
  static currentOrgFeatures(
    features: FeatureState[],
    org: OrgState
  ): FeatureState[] {
    const orgFeatures = features.filter((r) =>
      EnterpriseState.isSameOrg(org, r.orgId)
    );

    //get smallest limit features, filter out hard limit if soft limit present
    var res = _.uniqBy(
      _.cloneDeep(orgFeatures),
      (r: FeatureState) => r.productCode
    );

    return res;
  }
  //#endregion

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateSubscriptions(
    ctx: StateContext<LicenseStateModel>,
    arg: EmitterAction<SubscriptionState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    console.time("[addOrUpdateSubscriptions]");
    const state = ctx.getState();
    if (!state.subscriptions) state.subscriptions = [];
    const all: SubscriptionState[] = _.clone(state.subscriptions);

    //new
    const newList: SubscriptionState[] = _.differenceBy(arg.payload, all, "id");

    //existing
    const existingList: SubscriptionState[] = _.intersectionBy(
      arg.payload,
      all,
      "id"
    );

    //update existing
    existingList.forEach((dto) => {
      let index = _.findIndex(all, (r) => r.id === dto.id);
      if (index !== -1) {
        all[index] = { ...dto };
      }
    });

    if (newList.length > 0) {
      state.subscriptions = [...all, ...newList];
    } else {
      state.subscriptions = [...all];
    }

    ctx.setState(state);
    console.timeEnd("[addOrUpdateSubscriptions]");
  }

  @Receiver()
  @ImmutableContext()
  public static deleteSubscriptions(
    ctx: StateContext<LicenseStateModel>,
    arg: EmitterAction<SubscriptionState[]>
  ) {
    if (!arg || !arg.payload || arg.payload.length == 0) return;
    const state = ctx.getState();
    const deletedSubs = arg.payload;

    const subs = [...state.subscriptions];

    _.forEach(deletedSubs, (sub: SubscriptionState) => {
      _.remove(subs, (o: SubscriptionState) => o.id === sub.id);
    });
    state.subscriptions = subs;
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateFeatures(
    ctx: StateContext<LicenseStateModel>,
    arg: EmitterAction<FeatureState[]>
  ) {
    if (!arg || arg.payload.length == 0) return;
    const state = ctx.getState();
    const features = _.clone(state.features);

    var payload: FeatureState[] = _.clone(arg.payload);

    const newFeatures: FeatureState[] = _.differenceWith(
      payload,
      features,
      (a: FeatureState, r: FeatureState) => r.id === a.id
    );

    const existingFeatures: FeatureState[] = _.intersectionWith(
      payload,
      features,
      (a: FeatureState, r: FeatureState) => r.id === a.id
    );

    existingFeatures.forEach((a) => {
      const existing: FeatureState = _.find(
        features,
        (m: FeatureState) => m.id === a.id
      );

      if (existing) {
        //perform update
      }
    });

    if (newFeatures.length > 0) {
      state.features = [...features, ...newFeatures];
    } else {
      state.features = [...features];
    }

    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static deleteFeatures(
    ctx: StateContext<LicenseStateModel>,
    arg: EmitterAction<string[]>
  ) {
    if (!arg || !arg.payload || arg.payload.length == 0) return;
    const state = ctx.getState();
    const deletedFeatureIds = arg.payload;

    const features = [...state.features];

    _.forEach(deletedFeatureIds, (id: string) => {
      _.remove(features, (o: FeatureState) => o.id === id);
    });
    state.features = features;
    ctx.setState(state);
  }
  
  @Receiver()
  static clean(ctx: StateContext<LicenseStateModel>) {
    ctx.setState({ ...new LicenseStateModel() });
  }
}
