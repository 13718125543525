import { Component, OnInit, ViewChild, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ITdDataTableColumn,
  TdDataTableSortingOrder,
  TdDataTableService,
  ITdDataTableSortChangeEvent,
} from "@covalent/core/data-table";
import { TdPagingBarComponent, IPageChangeEvent } from '@covalent/core/paging';
import { MatDialog } from '@angular/material/dialog';
import { EnterpriseSelector } from 'app/core/states/enterprise.state.selector';
import { PostState, OrgUserState, ChannelState, PostCommentState } from 'app/core/model/org.state';
import { DownloadService } from 'app/core/services/download.service';
import { saveAs } from "file-saver";
import { environment as ENV } from 'environments/environment';
import { FileAuthPipe } from 'app/core/pipe/file-auth.pipe';
import { FileObjState } from 'app/core/model/file-obj.state';
import { HttpEventType } from '@angular/common/http';
import { OrgHub } from 'app/core/hub/org.hub';
import { AzureStorageModel, AzureStorageType, AzureStorageWopiActionType, WopiParameter } from 'app/core/model/libreoffice.model';
import { LibreOfficeService } from 'app/core/services/LibreofficeService/libreoffice.service';
import * as _ from "lodash";
import { Base64 } from 'js-base64';
import jwt_decode  from 'jwt-decode';
declare var $: any;
@Component({
  selector: 'file-table',
  templateUrl: './file-table.component.html',
  styleUrls: ['./file-table.component.scss'],
  providers: [FileAuthPipe]
})
export class FileTableComponent implements OnInit, OnChanges { 

    @Input() 
    set data(val : FileObjState[]) {
        this.files = val;
        this.isPageLoading = val == null;
    }
    @Input() hasNextPage: boolean;
    @Input() nextPageLoading: boolean;

    // to hide columns that are shown by default, pass in column name
    @Input() hideColumns: string[]; 
    
    @Output() loadData: EventEmitter<any> = new EventEmitter<any>();


  // Table columns model
  columns: ITdDataTableColumn[] = [
    { name: 'icon', label: 'Type', sortable: true, width: 50 },
    { name: 'name', label: 'File Name', sortable: true, width: 200 },
    { name: 'createdAs', label: 'Posted as', sortable: true, width: 150 },
    { name: 'timeStamp', label: 'Posted on', sortable: true, width: 200 },
    { name: 'createdBy', label: 'Posted by', sortable: true, width: 200 },
    { name: 'download', label: '', sortable: false, width: 100, hidden: (window.screen.width <= 820) }
  ];

  // Table data
  files: FileObjState[] = [];
  isPageLoading: boolean = true; // true if page has not been initialized with data yet

  // Table parameters
  sortBy = 'name';
  sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Ascending;

  // loaders
  downloadProgress: { [id: string]: string } = {};

  @ViewChild('pagingBar', { static: false }) private pagingBar: TdPagingBarComponent;
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private downloadService: DownloadService,
    private dataTableService: TdDataTableService,
    private libreOfficeService: LibreOfficeService
  ) {}

  ngOnInit() {
    if (!!this.hideColumns && this.hideColumns.length > 0)
    this.columns = this.columns.filter(c => !this.hideColumns.some(col => col === c.name));

    this.downloadProgress = {};
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.room) {
    //   if (changes.room.previousValue && changes.room.currentValue) {
    //   }
    // }
  }

  sort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.sortBy = sortEvent.name;
    this.sortOrder = sortEvent.order;
    this.files = this.dataTableService.sortData(this.files, sortEvent.name, sortEvent.order);
  }

  openImage(id: string, url: string, mimeType: string): void {
    if (this.downloadProgress[id]) return;
    this.downloadService.downloadFileWithProgress(url, false).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.DownloadProgress) {
          const percentage = 100 / event.total * event.loaded;
          // console.log(percentage);
          this.downloadProgress[id] = percentage.toString();
        }
        // finished
        if (event.type === HttpEventType.Response) {
          var file = new Blob([event.body], { type: mimeType + ';base64' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL, "_blank");
          this.downloadProgress[id] = null;
        }
      }, 
      error: (error) => {
        this.openSnackBar("Error downloading file.", "OK")
      }
    });
  }

  openPDF(id: string, url: string): void {
    if (this.downloadProgress[id]) return;
    this.downloadService.downloadFileWithProgress(url, false).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.DownloadProgress) {
          const percentage = 100 / event.total * event.loaded;
          // console.log(percentage);
          this.downloadProgress[id] = percentage.toString();
        }
        // finished
        if (event.type === HttpEventType.Response) {
          var file = new Blob([event.body], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL, "_blank");
          this.downloadProgress[id] = null;
        }
      }, 
      error: (error) => {
        this.openSnackBar("Error downloading file.", "OK")
      }
    });
  }

  //#region File actions

  downloadFile(row: FileObjState): void {
    let url = ENV.apiLink + "/media/download?fwt=" + row.fwt;
    this.downloadLink(row.id, url, row.name);
  }

  viewFile(row: FileObjState): void {
    let url = ENV.apiLink + "/media/download?fwt=" + row.fwt;
    let mimeType = this.getMimeType(row.name);
    //LibreOffice Integration
    if (row.name && row.name.trim() !== '' && row.name.includes('.')) {
      let file_extension = row.name.substring(row.name.lastIndexOf('.') + 1);
      this.libreOfficeService.isWopiSupportedFileType(file_extension).subscribe(data_ret=> {
        //this.http.get<MyTypeResult>('https://elofficedev.azurewebsites.net/wopi/check/'+ temp_file.extension).subscribe(data=> {     
          if (data_ret.Result) {  
            console.log ("LibreOffice supported!");
            if (this.libreOffice_Integration(row, data_ret.Action) == false) {
              if (mimeType && mimeType.startsWith("image/")) {
                this.openImage(row.id, url, mimeType);
              } else if (mimeType == "application/pdf") {
                this.openPDF(row.id, url);
              } else {
                // cannot open in browser
                this.downloadLink(row.id, url, row.name);
              }                  
            }
          } else {
            if (mimeType && mimeType.startsWith("image/")) {
              this.openImage(row.id, url, mimeType);
            } else if (mimeType == "application/pdf") {
              this.openPDF(row.id, url);
            } else {
              // cannot open in browser
              this.downloadLink(row.id, url, row.name);
            }         
          }
        }
      );
    } else {
      if (mimeType && mimeType.startsWith("image/")) {
        this.openImage(row.id, url, mimeType);
      } else if (mimeType == "application/pdf") {
        this.openPDF(row.id, url);
      } else {
        // cannot open in browser
        this.downloadLink(row.id, url, row.name);
      }
    }
  }

  private getMimeType(fileName): string {
    var ext = fileName.split(".").pop();
    switch (ext) {
      case "jpg":
      case "jpeg":
      case "png":
      case "bmp":
      case "tif":
      case "tiff":
      case "webp":
      case "gif":
        return "image/" + ext;
      case "wav":
      case "aac":
      case "midi":
      case "opus":
        return "audio/" + ext;
      case "mid":
        return "audio/midi";
      case "weba":
        return "audio/webm";
      case "mp3":
        return "audio/mpeg";
      case "oga":
        return "audio/ogg";
      case "pdf":
        return "application/pdf";
      default:
        return null;
    }
  }

  private libreOffice_Integration (row: FileObjState,dataAction: string): boolean {
    console.log ("LibreOffice Integration!");

    let boolean_Integration: boolean = true;

    let temp_fullpath: string = null;
    let temp_file_extension: string = null;
    let temp_container: string = null;

    let  temp_fullpath1: any= jwt_decode(row.fwt);
    temp_fullpath= temp_fullpath1.url.substring(temp_fullpath1.url.indexOf('/') +1)  

    //temp_fullpath = "groups/459986f0-57d9-47c3-9c40-e432c71609ad/channels/24f419dd-4e8e-4b1b-b201-a7d3cee4cf74/posts/a8284646-8a44-41f8-a4c7-d47838a92b47/comments/files/dJR6VM4erESOHdn5ZNlhpw"
    if (temp_fullpath.includes('%'))  {
      temp_fullpath = temp_fullpath.substring(0, temp_fullpath.lastIndexOf('/') +1 ) + row.name;
    }
    temp_file_extension = row.name.substring(row.name.lastIndexOf('.') + 1);
    temp_container = temp_fullpath1.url.substring(0, temp_fullpath1.url.indexOf('/') );
    let temp_userName: string = this.libreOfficeService.getUserName();
    let temp_userId: string = this.libreOfficeService.getUserId();
    let temp_userAvator: string = this.libreOfficeService.getUserAvatar(temp_userId);        
    let temp_userEmail: string = this.libreOfficeService.getUserEmail();     
    let temp_postMessageOrigin: string = this.libreOfficeService.getPostMessageOrigin();
    let temp_ownerId: string = row.createdBy;
    let temp_readOnly:boolean = (temp_userId != temp_ownerId)? true:false;
    let temp_collaboration:boolean = false;
    this.libreOfficeService.getAccessToken(temp_userId).subscribe(tokenData=> {               
      if (tokenData.Result) {               
          const temp_Request_Orginal: AzureStorageModel =  {
              fileId: temp_container + "/" + temp_fullpath,
              Container: temp_container,
              blobPath: temp_fullpath,
              BaseFileName:row.name,
              Action:AzureStorageWopiActionType[dataAction],
              OwnerId:temp_ownerId,
              UserId: temp_userId,
              UserName:temp_userName,
              AccessToken: tokenData.Message,
            };

            const temp_Request_Encoded = Base64.encode(encodeURIComponent(JSON.stringify(temp_Request_Orginal)));
            console.log(temp_Request_Encoded);
            console.log(encodeURIComponent(temp_Request_Encoded));

            const temp_userObject: WopiParameter = {
              UserName:  temp_userName,
              UserId: temp_userId,
              ReadOnly: temp_readOnly,
              UserAvator:temp_userAvator,
              UserEmail: temp_userEmail, 
              PostMessageOrigin: temp_postMessageOrigin,                           
              Collaboration: temp_collaboration,
              StorageType: AzureStorageType.team,
            };
            
            const temp_userObject_Encoded = Base64.encode((encodeURIComponent(JSON.stringify(temp_userObject)))); 

            //const temp_URL = "https:///elofficedev.azurewebsites.net/home/detail/" + temp_Request_Encoded + "?p=" +temp_userObject_Encoded;
            ////const temp_URL = this.libreOfficeService.apiURL +"home/detail/" + (temp_Request_Encoded)+ "?p=" + (temp_userObject_Encoded);
            //window.open(temp_URL, '_blank');

            this.libreOfficeService.getView(temp_Request_Encoded+ "?p=" +temp_userObject_Encoded).subscribe((retData)=> {
                const temp_htmlBlob = new Blob([retData], {type: 'text/html'});
                var temp_url1 = URL.createObjectURL(temp_htmlBlob);
                console.log ("blob path: " + temp_url1);
                window.open(temp_url1, '_blank');
            });
                      
            boolean_Integration = true;
      } else {
            boolean_Integration = false;                    
      }
    });

    return boolean_Integration;
  }

  private downloadLink(id, url, fileName) {
    if (this.downloadProgress[id]) return; // block from downloading twice
    this.downloadService.downloadFileWithProgress(url).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.DownloadProgress) {
          const percentage = 100 / event.total * event.loaded;
          // console.log(percentage);
          this.downloadProgress[id] = percentage.toString();
        }
        // finished
        if (event.type === HttpEventType.Response) {
          saveAs(event.body, fileName);
          this.downloadProgress[id] = null;
        }
      }, 
      error: (error) => {
        this.openSnackBar("Error downloading file.", "OK")
      }
    });
  }

  //#endregion

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  // on scroll to bottom, get next page
  async psYReachEnd($event) {
    if (this.hasNextPage && !this.nextPageLoading) {
      this.loadData.emit();
    }
  }

}
